import React from 'react';
import qrAppStore from '../images/mobileappdown/qr-appstore.png';
import  patternCounter from '../images/mobileappdown/pattern-for-counter-section.png';
import mobleapppic from '../images/mobileappdown/moble-app-pic.png';
import playstoreBtn from '../images/mobileappdown/playstore-btn.jpg';
import qrPlaystore from '../images/mobileappdown/qr-playstore.png';
import appStoreBt from '../images/mobileappdown/app-store-btn.jpg';


function DownloadApp() {
  return (
    <div className="container-fluid download-app">
      <div className="container">
        <div className="row">
          {/* Left Section: App Image */}
          <div className="col-md-6 text-end d-flex justify-content-end">
            <img
              className="desktoponly"
              width="363"
              height="683"
              data-aos="fade-up"
              data-aos-delay="0"
              src={mobleapppic}
              alt="KLF Mobile App"
            />
          </div>
          {/* Right Section: App Info */}
          <div className="col-md-6 text-white my-auto">
            <img
              src={patternCounter}
              style={{ maxWidth: '75px', height: 'auto' }}
              className="mb-2"
              data-aos="fade-up"
              data-aos-delay="0"
              alt="KLF Pattern"
            />
            <h2 data-aos="fade-up" data-aos-delay="0">Download the KLF App</h2>
            <p data-aos="fade-up" data-aos-delay="100" className="mb-0">
              Scan the codes below & download our mobile app today!
            </p>
            <p data-aos="fade-up" data-aos-delay="200">
              You can also click and download.
            </p>

            {/* QR Codes and Download Buttons */}
            <div className="row download-qr">
              {/* App Store */}
              <div className="col-md-4" data-aos="fade-up" data-aos-delay="300">
                <a href="https://apps.apple.com/in/app/klf/id6444764749" target="_blank" rel="noopener noreferrer">
                  <img
                    className="store-btn"
                    src={appStoreBt}
                    alt="Download from App Store"
                  />
                </a>
                <img
                  className="qr-code"
                  alt="QR Code for App Store"
                  width="300"
                  height="300"
                  style={{ height: 'auto' }}
                  src={qrAppStore}
                />
              </div>
              {/* Play Store */}
              <div className="col-md-4" data-aos="fade-up" data-aos-delay="400">
                <a href="https://play.google.com/store/apps/details?id=com.dcbooksklf" target="_blank" rel="noopener noreferrer">
                  <img
                    className="store-btn"
                    src={playstoreBtn}
                    alt="Download from Play Store"
                  />
                </a>
                <img
                  className="qr-code"
                  alt="QR Code for Play Store"
                  width="300"
                  height="300"
                  style={{ height: 'auto' }}
                  src={qrPlaystore}
                />
              </div>
              {/* Empty Column for Spacing */}
              <div className="col-md-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadApp;
