import React, {useEffect, useState} from 'react'
import Footer from './Footer';
import Navbar from './Navbar';
import InitModal from './initModal';
import { useScrollToTop } from '../hooks/ScrollTop';
import siteQueries from '../queries/siteQueries';
import Pagination from '../common/Pagination/Pagination';
import Loader from '../components/Loader/Loader';
import { useNavigate} from 'react-router-dom';
import VenoBox from 'venobox';
import 'venobox/dist/venobox.min.css';

export default function VideoListSite() {

    const [isOpen, setIsOpen] = useState(false);
    const [video, setVideo] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [totalPage, setTotalPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
 
    const navigate = useNavigate();
    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);
    
    const videoData = siteQueries.useVideoMutation(
        async (response) => {
            setVideo(response?.data?.items);
          setTotalCount(response?.data?.totalItems);
          setTotalPage(response?.data?.totalPages);
          setIsLoading(false);
        },
      );

      useEffect(() => {
        videoData.mutateAsync({
            page: currentPage,
            size: pageSize,
        })
        
      }, [currentPage, pageSize,]);

      useEffect(() => {
        // Initialize VenoBox
        const venobox = new VenoBox({
          selector: '.venobox',
          numeration: true,
          infinigall: true,
          share: true,
          spinner: 'rotating-plane',
          spinColor: '#F4D03F',
          titleattr: 'data-title',
          titlePosition: 'top',
          titleStyle: 'bar',
          // Callbacks
          cb_init: function(obj) {
            console.log('VenoBox initialized');
          },
          cb_post_open: function(obj) {
            console.log('Video opened');
          },
          cb_pre_close: function(obj) {
            // Pause video when closing
            const iframe = document.querySelector('.vbox-content iframe');
            if (iframe) {
              const src = iframe.src;
              iframe.src = src;
            }
          }
        });
      
        // Cleanup function
        return () => {
          // Remove VenoBox if needed, or do any necessary cleanup
          // For now, we're just ensuring there are no dangling references.
        };
      }, [video]);


      const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
      };

      const totalPages = totalPage;

      // Helper function to extract YouTube video ID
    const getYoutubeId = (link) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = link.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };
  
  useEffect(() => {
       
    document.title = `Videos | Kerala Literature Festival 2025`;


return () => {
    document.title = 'KLF 2025 | Kerala Lit Fest | Literature Festival | Literary Festival 2025';
};

}, []);
  useScrollToTop();
  return (
    <div>
        {isLoading ? (
      <Loader /> 
    ) : (
        <>
        <Navbar handleOpen={handleOpen}  />
        <div class="innerbanner container-fluid"
        style={{marginBottom: '4rem'}}>
            <div class="container"
            >

                <div class="row ">
                    <div class="col-md-12">
                    <span class="logo-strips"></span> <h2>Videos</h2>
                    </div>
                </div>
            </div>
        </div>
            <div>
            <div className="container my-4 "
            style={{marginTop: '4rem'}}>
                <div className="row" style={{ gap: '5rem' }}>
                    {video?.map((item, index) => (
                    <div className="col-12 col-md-6 col-lg-3 mb-4" key={index}>
                        <div
                        className="card h-100 shadow-sm venobox"
                        data-vbtype="video"
                        data-autoplay="true"
                        href={item?.link}
                        data-title={item?.title}
                        style={{ maxHeight: '400px', overflow: 'hidden' }}>
                        <img 
                        className="video-img" 
                        src={`https://img.youtube.com/vi/${getYoutubeId(item?.link)}/hqdefault.jpg`}
                        alt={item?.title}
                        style={{ height: '100%', objectFit: 'cover' }}/>
                        
                        </div>
                        <div className="card-body">
                        <span className="video-title ">{item?.title}</span>
                        </div> 
                    </div>
                    ))}
                </div>
                
                <div 
                className="d-flex justify-content-center mt-4">
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
                </div>
                </div>
                
            </div>
                
        <Footer handleOpen={handleOpen} />

        <InitModal isOpen={isOpen} onClose={handleClose} />
        </>
    )}
    
    </div>
  )
}
