import React, { useEffect, useState, useRef } from 'react';
import '../../css/chatbot.css';
import { Bot, BotMessageSquare, Maximize2, Minimize2, Send, X } from 'lucide-react';
import { useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import stopwords from './chatbot.json'; // JSON file containing 1000+ stop words
import greetings from './greetings.json'; // JSON file containing 1000+ stop words
import moment from 'moment';

const Chatbot = () => {

    const [open, setOpen] = useState(false);
    const [messages, setMessages] = useState([{ name: 'Bot', message: 'Hello ! Hope you are enjoying KLF. You may type in the name of the speaker you want to follow and I will try my best to give the programmes they participate in' }]);
    const [input, setInput] = useState('');
    const [expanded, setExpanded] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setExpanded(false);
    }

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const allSessions = useSelector(state => state.sessions.allSessions);
    const allSpeakers = useSelector(state => state.sessions.allSpeakers);
    const allVenues = useSelector(state => state.sessions.allVenues);

    const fuseOptions = {
        keys: ['name'],
        threshold: 0.2,
        distance: 100,
    };

    const fuseSessions = new Fuse(allSessions, fuseOptions);
    const fuseSpeakers = new Fuse(allSpeakers, { keys: ['heading'], threshold: 0.2 });

    const messagesEndRef = useRef(null);

    const filterStopWords = (input) => {

        const stopWordsSet = new Set(stopwords.stopWords); // Convert to Set for faster lookup
    
        return input
            .toLowerCase()
            .trim()
            .replace(/[^a-zA-Z0-9\s]/g, '')
            .split(' ')
            .filter(word => !stopWordsSet.has(word))
            .join(' ');
    };

    const dateFilter = (input) => {

        let inputDate = {
            isDate: false,
            date: '',
            time: {
                from: '',
                to: ''
            }
        }

        const weeks = [
            {day: 'Thursday', date: "2025-01-23"},
            {day: 'Friday', date: "2025-01-24"},
            {day: 'Saturday', date: "2025-01-25"},
            {day: 'Sunday', date: "2025-01-26"}
        ];

        const dates = [
            { day: ['23rd', '23'], date: "2025-01-23" },
            { day: ['24th', '24'], date: "2025-01-24" },
            { day: ['25th', '25'], date: "2025-01-25" },
            { day: ['26th', '26'], date: "2025-01-26" }
        ]

        const timing = [
            { time: 'morning', date_from: "06:00:00", date_to: "11:59:59" },
            { time: 'afternoon', date_from: "12:00:00", date_to: "15:59:59" },
            { time: 'evening', date_from: "16:00:00", date_to: "20:59:59" },
            { time: 'night', date_from: "21:00:00", date_to: "23:59:59" }
        ];

        const labels = ["start", "end"];

        let date = weeks.find(week => input.toLowerCase().includes(week.day.toLowerCase()));
        let time = timing.find(timing => input.toLowerCase().includes(timing.time.toLowerCase()));
        let startOrEnd = labels.find(label => input.toLowerCase().includes(label.toLowerCase()));
        let dateMatch = dates.find(date => input.toLowerCase().includes(date.day[0].toLowerCase()) || input.toLowerCase().includes(date.day[1].toLowerCase()));
        
        if((date || dateMatch) && time){
            inputDate.date = date?.date || dateMatch?.date;
            inputDate.time = {from: time?.date_from, to: time?.date_to};
            inputDate.isDate = true;
            inputDate.isStartOrEndSpecified = startOrEnd;
        }else if(date || dateMatch){
            inputDate.date = date?.date || dateMatch?.date;
            inputDate.isDate = true;
        }else if(time){
            inputDate.time = {from: time?.date_from, to: time?.date_to};
            inputDate.isDate = true;
            inputDate.isStartOrEndSpecified = startOrEnd;
        }else{
            inputDate.isDate = false;
        }

        return inputDate

    }

    function getBotResponse(userMessage) {
        const sampleResponses = greetings
        const match = sampleResponses.find(item => 
            item?.user?.some(input => input?.toLowerCase() === userMessage)
        );
        return match ? match.bot : null;
    }    

    const onSubmit = () => {
        if (!input) return;
        
        let data = [];
        data.push({ name: 'You', message: input });
        
        
        let textInput = input.trim().toLowerCase()

        const basicMessage = getBotResponse(textInput)

        if(basicMessage){
            data.push({ name: 'Bot', message: basicMessage });
            setMessages([...messages, ...data]);
            setInput('');
            return;
        }

        const dateFilteredInput = dateFilter(textInput);

        let matchingVenue = allVenues?.find(venue =>
            textInput?.includes(venue.name.toLowerCase().trim())
        );
                

        if(matchingVenue){
            textInput = textInput?.replace(matchingVenue?.name?.toLowerCase().trim(), '');
        }
        
        let sessionDetails = '';
        
        if(dateFilteredInput.isDate){
        
            let matchingSessions = []
            if(dateFilteredInput?.date && dateFilteredInput?.time?.from){
                matchingSessions = allSessions?.filter(session => {
                    return session?.date === dateFilteredInput?.date 
                    && session?.time_from >= dateFilteredInput?.time?.from && session?.time_from <= dateFilteredInput?.time?.to;
                })
            }else if(dateFilteredInput?.date){
                matchingSessions = allSessions?.filter(session => session?.date === dateFilteredInput?.date);
            }else if(dateFilteredInput?.time?.from){
                matchingSessions = allSessions?.filter(session => session?.time_from >= dateFilteredInput?.time?.from && session?.time_from <= dateFilteredInput?.time?.to);
            }
        
            if (matchingSessions.length > 0) {
                if(matchingVenue){
                    matchingSessions = matchingSessions?.filter(session => session?.venue?.id === matchingVenue?.id);
                }
                matchingSessions?.forEach(session => {

                    let speakersName = '';
                    let moderators = '';
                    let storyTeller = '';
                    session.session_speackers.forEach(speaker => {
                        const speakerData = allSpeakers.find(s => s.id === speaker?.speaker?.id);
                        if (speakerData) {
                            speakersName += `${speakerData.heading}, `;
                        }
                    });
    
                    session.session_moderators.forEach(moderator => {
                        const moderatorData = allSpeakers.find(s => s.id === moderator?.speaker?.id);
                        if (moderatorData) {
                            moderators += `${moderatorData.heading}, `;
                        }
                    });

                    session.speakers.forEach(storyTeller => {
                        const storyTellerData = allSpeakers.find(s => s.id === storyTeller?.speaker?.id);
                        if (storyTellerData) {
                            storyTeller += `${storyTellerData.heading}, `;
                        }
                    });

                    sessionDetails += `
                        <b style="color:red;">Session:</b> ${session?.name}<br/>
                        ${speakersName.length > 0 ? `<b>Speakers:</b> ${speakersName?.slice(0, -2)}<br/>` : "" }
                        ${moderators.length > 0 ? `<b>Moderators:</b> ${moderators?.slice(0, -2)}<br/>`: ""}
                        ${storyTeller.length > 0 ? `<b>Story Teller:</b> ${storyTeller?.slice(0, -2)}<br/>`: ""}
                        <b>Date:</b> ${moment(session?.date).format('Do, dddd')} <b>Time:</b> ${moment(session?.time_from, 'HH:mm:ss').format('h:mm A')} - ${moment(session?.time_to, 'HH:mm:ss').format('h:mm A')}<br/>
                        <b>Venue:</b> ${session?.venue?.name}<br/><br/>
                    `;

                });
                
            } else {
                sessionDetails = "No sessions found for the specified date.";
            }

        }else{

            const filteredInput = filterStopWords(textInput);
            
            const sessionResult = fuseSessions.search(filteredInput);
            const sessionMatch = sessionResult.length > 0 ? sessionResult.map(item => item.item) : [];

            if (sessionMatch.length > 0) {
                if(matchingVenue){
                    sessionMatch.filter(session => session?.venue?.id === matchingVenue?.id);
                }
                sessionMatch.forEach(session => {
                    let speakersName = '';
                    let moderators = '';
                    let storyTeller = '';
                    session.session_speackers.forEach(speaker => {
                        const speakerData = allSpeakers.find(s => s.id === speaker?.speaker?.id);
                        if (speakerData) {
                            speakersName += `${speakerData.heading}, `;
                        }
                    });
    
                    session.session_moderators.forEach(moderator => {
                        const moderatorData = allSpeakers.find(s => s.id === moderator?.speaker?.id);
                        if (moderatorData) {
                            moderators += `${moderatorData.heading}, `;
                        }
                    });

                    session.speakers.forEach(storyTeller => {
                        const storyTellerData = allSpeakers?.find(s => s.id === storyTeller?.speaker?.id);
                        if (storyTellerData) {
                            storyTeller += `${storyTellerData.heading}, `;
                        }
                    });

                    sessionDetails += `
                        <b style="color:red;">Session:</b> ${session?.name}<br/>
                        ${speakersName?.length > 0 ? `<b>Speakers:</b> ${speakersName?.slice(0, -2)}<br/>` : "" }
                        ${moderators?.length > 0 ? `<b>Moderators:</b> ${moderators?.slice(0, -2)}<br/>`: ""}
                        ${storyTeller?.length > 0 ? `<b>Story Teller:</b> ${storyTeller?.slice(0, -2)}<br/>`: ""}
                        <b>Date:</b> ${moment(session?.date).format('Do, dddd')} <b>Time:</b> ${moment(session?.time_from, 'HH:mm:ss').format('h:mm A')} - ${moment(session?.time_to, 'HH:mm:ss').format('h:mm A')}<br/>
                        <b>Venue:</b> ${session?.venue?.name}<br/><br/>
                    `;

                });
            } else {

                const speakerResult = fuseSpeakers.search(filteredInput);
                const matchedSpeakers = speakerResult?.length > 0 ? speakerResult?.map(item => item.item) : [];
    
                if (matchedSpeakers.length > 0) {

                    matchedSpeakers.forEach(matchedSpeaker => {
                        let relatedSessions = allSessions.filter(session =>
                            session?.session_speackers?.some(s => s.speaker?.id === matchedSpeaker?.id) ||
                            session?.session_moderators?.some(m => m.speaker?.id === matchedSpeaker?.id) ||
                            session?.speakers?.some(s => s?.id === matchedSpeaker?.id)
                        );
                    
                        if (matchingVenue) {
                            relatedSessions = relatedSessions.filter(session => session?.venu_id === matchingVenue?.id);
                        }
                        
                        relatedSessions.forEach(session => {
                            let speakersName = '';
                            let moderators = '';
                            let storyTeller = '';
                            session?.session_speackers.forEach(speaker => {
                                const speakerData = allSpeakers?.find(s => s.id === speaker?.speaker?.id);
                                if (speakerData) {
                                    speakersName += `${speakerData?.heading}, `;
                                }
                            });

                            session?.session_moderators.forEach(moderator => {
                                const moderatorData = allSpeakers?.find(s => s.id === moderator?.speaker?.id);
                                if (moderatorData) {
                                    moderators += `${moderatorData?.heading}, `;
                                }
                            });

                            session?.speakers.forEach(storyTeller => {
                                const storyTellerData = allSpeakers?.find(s => s.id === storyTeller?.speaker?.id);
                                if (storyTellerData) {
                                    storyTeller += `${storyTellerData?.heading}, `;
                                }
                            });
    
                            sessionDetails += `
                                <b style="color:red;">Session:</b> ${session?.name}<br/>
                                ${speakersName?.length > 0 ? `<b>Speakers:</b> ${speakersName?.slice(0, -2)}<br/>` : "" }
                                ${moderators?.length > 0 ? `<b>Moderators:</b> ${moderators?.slice(0, -2)}<br/>`: ""}
                                ${storyTeller?.length > 0 ? `<b>Story Teller:</b> ${storyTeller?.slice(0, -2)}<br/>`: ""}
                                <b>Date:</b> ${moment(session?.date).format('Do, dddd')} <b>Time:</b> ${moment(session?.time_from, 'HH:mm:ss').format('h:mm A')} - ${moment(session?.time_to, 'HH:mm:ss').format('h:mm A')}<br/>
                                <b>Venue:</b> ${session?.venue?.name}<br/><br/>
                            `;
                        });
                    });
                }
            }
    
        }

        if (sessionDetails) {
            data.push({ name: 'Bot', message: sessionDetails.trim() });
        } else {
            data.push({ name: 'Bot', message: "Sorry, I couldn't find any session or speaker related to your input." });
        }

        setMessages([...messages, ...data]);
        setInput('');
        
    };

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    return (
        <div className={`chatbot-container ${expanded ? 'expanded' : ''}`}>
            {!open && (
                <button onClick={handleOpen} className='btn-chatbot'>
                    <BotMessageSquare size={24} color="white" />
                </button>
            )}
            {open && (
                <div className="chatbot-content">
                    <div className="chatbot-header">
                        <div className='chatbot-title'>
                            <Bot size={24} color="black" fill='white' />
                            <span className='chatbot-name'>
                                KLF Assistant
                                <span className='chatbot-beta'>Beta</span>
                            </span>
                        </div>
                        <div className="chatbot-controls">
                            <button onClick={toggleExpand}>
                                {expanded ? (
                                    <Minimize2 size={20} color="black" />
                                ) : (
                                    <Maximize2 size={20} color="black" />
                                )}
                            </button>
                            <button onClick={handleClose}>
                                <X size={20} color="black" />
                            </button>
                        </div>
                    </div>
                    <div className="chatbot-body">
                        <div className="chatbot-message">
                            {messages.map((message, index) => (
                                <div 
                                    className={`message ${message.name === 'You' ? 'right' : 'left'}`} 
                                    key={index}
                                >
                                    <div className="message-content">
                                        {message.name === 'Bot' ? (
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: message.message,
                                                }}
                                            />
                                        ) : (
                                            <span>{message.message}</span>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div ref={messagesEndRef} />
                    </div>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        onSubmit();
                    }}>
                        <div className="chatbot-footer">
                            <input 
                                onChange={(e) => setInput(e.target.value)} 
                                value={input} 
                                type="text" 
                                placeholder="Type a message" 
                            />
                            <button type='submit'>
                                <Send size={15} color="white" />
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default Chatbot;
