
import { useMutation } from "react-query";
import groupService from "../services/groupService";

const useGroupQrcreateMutation = (onData) => {
    return useMutation(groupService.gqrcode, {
      onSuccess: (data) => {
        onData(data);
      },
    });
  };

  const groupQueries = {
    useGroupQrcreateMutation
                   
};

export default groupQueries;
