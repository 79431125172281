import { useState, useEffect } from 'react';
import { userStore } from '../../store/userStore';

export const usePermissions = () => {
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
      const user = userStore.getState().user;
      const adminPermissions = user?.user?.adminpermissions?.[0] ?? []; 
      setPermissions(adminPermissions);
  }, []);

  const hasPermission = (feature) => permissions?.[feature] === 1;


  return { hasPermission, permissions};
};
