import React, { useEffect, useState } from 'react'
import sessionQueries from '../../queries/sessionQueries';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import Loader from '../Loader/Loader';
import { useNavigate } from 'react-router-dom';
import { PencilSquareIcon } from '@heroicons/react/24/solid'

export default function VenueList() {

    const [venueData, setVenueData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    
   useEffect(() => {
      setIsLoading(true); 
      venuelisting.mutateAsync();
    }, []);

    const venuelisting = sessionQueries.useVenuelistMutation((response) => {
        setVenueData(response?.data);
        setIsLoading(false); 
      });

      const handleAddSession = () => {
        navigate('/venueform'); 
      };

      const handleEditSession = (data) => {
        navigate(`/edit-venue/${data?.id}?name=${data?.name}&place=${data?.place}&status=${data?.status}&number=${data?.number}`);
      }
    
  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <Breadcrumb pageName="Venue" />
        <button 
          className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-2 shadow-lg transition duration-300 ease-in-out transform hover:scale-150"
          title='Add Session'
          aria-label="Add Session"
          onClick={handleAddSession}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
        </button>
      </div>

    {isLoading ? (
      <Loader /> 
    ) : (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
        
      </h4>

      <div className="flex flex-col">

          <table className="border-b border-stroke text-black dark:border-strokedark bg-gray-800 dark:bg-meta-4 w-full text-[17px]">
            <thead>
              <tr>
                <th className="p-2.5 xl:p-5 text-sm font-[500] uppercase text-left text-[16px]">Sno</th>
                <th className="p-2.5 xl:p-5 text-sm font-[500] uppercase text-left text-[16px]">Venue Number</th>
                <th className="p-2.5 xl:p-5 text-sm font-[500] uppercase text-left text-[16px]">Venue Name</th>
                <th className="p-2.5 xl:p-5 text-sm font-[500] uppercase text-left text-[16px]">Location</th>
                <th className="p-2.5 xl:p-5 text-sm font-[500] uppercase text-left text-[16px]">Status</th>
                <th className="p-2.5 xl:p-5 text-sm font-[500] uppercase text-left text-[16px]">Action</th>
              </tr>
            </thead>
            <tbody>
              {venueData?.map((venue, index) => (
                <tr
                  key={venue.id || index}
                  className={`${
                    index !== venueData.length - 1 ? 'border-b border-stroke dark:border-strokedark' : ''
                  }`}
                >
                  <td className="p-2.5 xl:p-5">{index + 1}</td>
                  <td className="p-2.5 xl:p-5">{venue?.number}</td>
                  <td className="p-2.5 xl:p-5 overflow-hidden text-ellipsis whitespace-nowrap max-w-[200px]">
                    {venue?.name}
                  </td>
                  <td className="p-2.5 xl:p-5 overflow-hidden text-ellipsis whitespace-nowrap max-w-[200px]">
                    {venue?.place}
                  </td>
                  <td className="p-2.5 xl:p-5">{venue?.status}</td>
                  <td className="p-2.5 xl:p-5">
                    <button
                      onClick={() => handleEditSession(venue)}
                      aria-label={`Edit ${venue?.name || 'Venue'}`}
                      className="p-2"
                    >
                      <PencilSquareIcon
                        className="h-6 w-6 text-black-600 cursor-pointer"
                        aria-hidden="true"
                        color='gray'
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
      </div>
    </div>
    )}
    </>
  )
}


