import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { useScrollToTop } from '../hooks/ScrollTop';
import '../css/developer2.css';
import siteQueries from '../queries/siteQueries';
import '../css/bootstrap.min.css';
import '../css/font.css';
import gmapicon from '../images/gmap-icon.png'
import blue from '../images/session-card-shape-blue.png'
import green from '../images/session-card-shape-green.png'
import orange from '../images/session-card-shape-orange.png'
import purple from '../images/session-card-shape-purple.png'
import yellow from '../images/session-card-shape-yellow.png'
import red from '../images/session-card-shape-red.png'
import SessionFavModal from './SessionFavModal';
import SessionShareModal from './SessionShareModal';

export default function SpeakerviewSite() {
    const [isOpen, setIsOpen] = useState(false);
    const [speaker, setSpeaker] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const [sessions, setSessions] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);

    const speakerData = siteQueries.useSpeakerviewsiteMutation(
        async (response) => {
            setSpeaker(response?.data);
            setSessions(response?.session)
            setIsLoading(false);
        },
    );

    // const sessionList = siteQueries.useSessionlistsiteMutation(
    //     async (response) => {
    //         setSessions(response?.data?.items || []);
    //         setIsLoading(false);
    //     },
    //     {
    //         onError: (error) => {
    //             console.error('Sessions fetch error:', error);
    //             setError('Failed to fetch sessions. Please try again later.');
    //             setIsLoading(false);
    //         }
    //     }
    // );

    // const buildParams = () => {
    //     const params = new URLSearchParams();
    //     if (id) {
    //         params.append('speakervalue', id);
    //     }
    //     return params.toString();
    // };

    useEffect(() => {
        setIsLoading(true);
        speakerData.mutateAsync({ id });
        // const params = buildParams();
        // sessionList.mutateAsync(params);
    }, [id]);

    useEffect(() => {
        if (speaker) {
            document.title = `${speaker.heading} | Kerala Literature Festival 2025`;
            const metaDescription = `${speaker.heading} | KLF 2025 News - Kerala Literature Festival`;
            const descriptionMeta = document.querySelector('meta[name="description"]');
            if (descriptionMeta) {
                descriptionMeta.setAttribute('content', metaDescription);
            }
            const metaKeywords = `${speaker.heading}, kerala, literature, festival, 2025`;
            const keywordsMeta = document.querySelector('meta[name="keywords"]');
            if (keywordsMeta) {
                keywordsMeta.setAttribute('content', metaKeywords);
            }
        }
        return () => {
            document.title = 'KLF 2025 | Kerala Lit Fest | Literature Festival | Literary Festival 2025';
            const descriptionMeta = document.querySelector('meta[name="description"]');
            const keywordsMeta = document.querySelector('meta[name="keywords"]');
            if (descriptionMeta) {
                descriptionMeta.setAttribute(
                    'content',
                    'KLF 2025 Registration - Kerala Literature Festival | Register for Kerala Lit Fest'
                );
            }
            if (keywordsMeta) {
                keywordsMeta.setAttribute(
                    'content',
                    'KLF 2025 Registration - Kerala Literature Festival | Register for Kerala Lit Fest'
                );
            }
        };
    }, [speaker]);

    const decodeHtml = (html) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = html;
        return textArea.value;
    };

    useScrollToTop();
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        // Retrieve current page from navigation state
        const navigationState = location.state;
        if (navigationState?.currentPage !== undefined) {
            setCurrentPage(navigationState.currentPage);
            localStorage.setItem('speakerListCurrentPage', navigationState.currentPage.toString());
        }
    }, [location]);

    localStorage.setItem('speakerListingCurrentPage', currentPage.toString());

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const isValidDate = (dateString) => {
        try {
            const date = new Date(dateString);
            return !isNaN(date.getTime());
        } catch {
            return false;
        }
    };

    const isValidTime = (timeString) => {
        try {
            if (!timeString) return false;
            const time = new Date(`1970-01-01T${timeString}`);
            return !isNaN(time.getTime());
        } catch {
            return false;
        }
    };

    const formatDate = (dateString) => {
        return new Intl.DateTimeFormat('en-GB', {
            day: '2-digit',
            month: 'short'
        }).format(new Date(dateString));
    };

    const formatTime = (timeString) => {
        return new Date(`1970-01-01T${timeString}`).toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
    };

    const isValidSession = (session) => {
        return session?.name &&
            isValidDate(session?.date) &&
            isValidTime(session?.time_from) &&
            isValidTime(session?.time_to);
    };

    const colors = ['yellow', 'blue', 'green', 'red']; // Define your four colors
    return (
        <>
            <Navbar handleOpen={handleOpen} />
            <div className="innerbanner container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <span className="logo-strips"></span>{' '}
                            <h2>
                                Speaker: <span>{speaker?.heading}</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className="speakers-wrpr speaker-details-wrpr">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10 speaker-pic-and-details-wrpr">
                            <div className="card">
                                <div className="speaker-pic-wrpr">
                                    {speaker?.photo ? (
                                        <img
                                            src={`${process.env.REACT_APP_BASE_URL}/${speaker.photo}`}
                                            alt={speaker.heading || 'Speaker'}
                                        />
                                    ) : (
                                        <div className="placeholder-image"></div>
                                    )}
                                    <div className="speaker-name">{speaker?.heading}</div>
                                </div>
                                <div className="card-body p-4">
                                    <h4>{speaker?.heading}</h4>
                                    <p>
                                        <div
                                            className="prose max-w-none mb-8 text-gray-600 leading-relaxed"
                                            dangerouslySetInnerHTML={{
                                                __html: decodeHtml(speaker?.content),
                                            }}
                                        />
                                    </p>

                                    {sessions && sessions.length > 0 && (
                                     <>
                                    <h4 className="mb-3">Programme of {speaker?.heading}</h4>
                                     </>
                                      )}
                                    <div className="row sessions-slide">
                                        {sessions.map((session, index) => (
                                            <div key={index} className="col-md-6 text-center">
                                                <div className="card session-card">
                                                    <div className="card-header scard-header">
                                                        <div className="symbol-venue-fav-wrpr">
                                                        {session?.delegative_exclusive === "YES" && (
                                                                <div className="delegate-only">Delegate Only</div>
                                                                )}
                                                           <div className="symbol-venue">
                                                               <div className="venue">
                                                                    <div
                                                                        style={{
                                                                        backgroundColor: "#eecf40",
                                                                        color: "#000",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        fontWeight: "normal",
                                                                        height: 18,
                                                                        width: 18,
                                                                        borderRadius: "50%",
                                                                        marginRight: 10
                                                                        }}
                                                                    >
                                                                        {session?.venue?.number}
                                                                    </div>
                                                                    <div className="venue-eng">{session?.venue?.name}</div>
                                                                    <a href={session?.venue?.place} class="icon-location-custom" target="_blank" title="Venue Location"><img src={gmapicon} /></a>
                                                                    </div>
                                                            </div>
                                                        </div>
                                                        <div className="fav-wrpr">
                                                          <SessionFavModal/>
                                                        </div>
                                                        <SessionShareModal/>
                                                    </div>

                                                    <div className="card-body">
                                                        
                                                        <div className="date-time">
                                                            <div className={`date ${colors[session?.id % colors.length]}`}>
                                                                <span>
                                                                    Jan&nbsp;
                                                                    {new Intl.DateTimeFormat('en-GB', { day: '2-digit' }).format(new Date(session?.date))}
                                                                </span>
                                                            </div>
                                                            <div className="time">
                                                                <i className="icon-time1"></i>
                                                                <span>
                                                                    {new Date(`1970-01-01T${session?.time_from}`).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} -
                                                                    {new Date(`1970-01-01T${session?.time_to}`).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="scard-heading">
                                                            <h2>{session?.name}</h2>
                                                        </div>

                                                       
                                                            <div className="session-type" style={{ 
                                                          visibility: session?.category?.name ? 'visible' : 'hidden'
                                                        }}>
                                                                <div className="session-type-label">{session?.category?.name}</div>
                                                            </div>
                                                      

                                                        {session?.session_speackers?.length > 0 && (
                                                            <div className="session-members speakers">
                                                                <ul>
                                                                    {[...new Set(session.session_speackers?.map(speakerSession => speakerSession?.speaker?.heading))]
                                                                        .map((heading, idx) => {
                                                                            const speakerId = session?.session_speackers?.find(speakerSession => speakerSession?.speaker?.heading === heading)?.speaker?.id;
                                                                            const urlHeading = heading
                                                                          ?.toLowerCase()
                                                                          .replace(/[^a-z0-9]+/g, '-') 
                                                                          .replace(/^-+|-+$/g, ''); 
                                                    
                                                                            return (
                                                                                <li key={idx} className="mb-1">
                                                                                    <a
                                                                                        href={`/speakerview/${speakerId}/${urlHeading}`}
                                                                                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                                                                                    >
                                                                                        {heading}
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        })}
                                                                </ul>
                                                            </div>
                                                        )}

                                                        
                                                            <div className="session-members modarators" style={{
                                                    visibility: session?.session_moderators?.length > 0 ? 'visible' : 'hidden',
                                                  }}>
                                                                <ul>
                                                                    {[...new Set(session.session_moderators?.map(moderatorSession => moderatorSession?.speaker?.heading))]
                                                                        .map((heading, idx) => {
                                                                            const speakerId = session?.session_moderators?.find(moderatorSession => moderatorSession?.speaker?.heading === heading)?.speaker?.id;
                                                                            const urlHeading = heading
                                                                          ?.toLowerCase()
                                                                          .replace(/[^a-z0-9]+/g, '-') 
                                                                          .replace(/^-+|-+$/g, ''); 
                                                                            return (
                                                                                <li key={idx} className="mb-1">
                                                                                    <a
                                                                                        href={`/speakerview/${speakerId}/${urlHeading}`}
                                                                                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                                                                                    >
                                                                                        {heading}
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        })}
                                                                </ul>
                                                            </div>
                                                        
                                                            <div className="session-members storyteller" style={{
                                                    visibility: session?.speakers?.length > 0 ? 'visible' : 'hidden',
                                                  }}>
                                                                <ul>
                                                                {[...new Set(session?.speakers?.map(speaker => speaker?.heading))].map((heading, idx) => {
                                                                            // console.log(">>>sessionspeakers>", heading);
                                                                            const speaker = session?.speakers?.find(speaker => speaker?.heading === heading);
                                                                            const speakerId = speaker?.id;                                                                            const urlHeading = heading
                                                                          ?.toLowerCase()
                                                                          .replace(/[^a-z0-9]+/g, '-') 
                                                                          .replace(/^-+|-+$/g, ''); 
                                                                            return (
                                                                                <li key={idx} className="mb-1">
                                                                                    <a
                                                                                        href={`/speakerview/${speakerId}/${urlHeading}`}
                                                                                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                                                                                    >
                                                                                        {heading}
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        })}
                                                                </ul>
                                                            </div>
                                                        
                                                       
                                                    </div>

                                                   <div
                                                      className="sponsoredby-title"
                                                      style={{ visibility: session?.session_sponsors?.length > 0 ? 'visible' : 'hidden',
                                                        marginLeft: '35%'}}
                                                    >
                                                      Supported by:
                                                    </div>

                                                    <div className="date-and-time" style={{ textAlign: 'center' }}>
                                                    <div className="sponsor-logo-ruppe-icon">
                                                        {session?.session_sponsors?.map((sponsor, idx) => (
                                                            <div key={idx} style={{ display: 'inline-block', textAlign: 'center' }}>
                                                                <strong>
                                                                    {sponsor.logo?.name}
                                                                    {idx < session.session_sponsors.length - 1 ? ',' : ''}
                                                                </strong>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>


                                                    {/* <div className="date-and-time">
                                                        <div className="sponsor-logo-ruppe-icon">
                                                            {session?.session_sponsors?.map((sponsor, idx) => (
                                                                <div key={idx}>
                                                                    <strong>{sponsor.logo?.name}</strong>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div> */}

                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}
