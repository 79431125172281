import React, { Fragment, useState } from 'react';
import { Modal } from 'react-bootstrap';

export default function SessionShareModal() {

  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  
  const handleClose = () => {
    setShowModal(false);
  }

  return (
    <Fragment>
        <div
        style={{
        cursor: 'pointer'
        }} 
        title="Add favorites"
        onClick={handleShow}>
            <span data-bs-toggle="modal" data-bs-target="#install-app" title="Add to favorites" class="icon-share2 share-session"></span>
        </div>

        <Modal contentClassName='fav-modal' show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title style={{fontSize: '19px', fontWeight: 'normal'}}>Download KLF App</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{background:'#2f3685', color: '#fff', }}>
                <Modal.Title style={{fontSize: '17px', fontWeight: '300', textAlign: 'center',}}>Download KLF App to Favorite Programme, view your QR code, Share Programme and many many more...</Modal.Title>
                <div style={{display: 'flex', gap: "5px", marginTop: '20px'}}>
                    <img onClick={()=> {window.open("https://apps.apple.com/in/app/klf/id6444764749", "_blank")}} src={require('../images/mobileappdown/app-store-btn.jpg')} style={{borderRadius: '5px'}} />
                    <img onClick={()=> {window.open("https://play.google.com/store/apps/details?id=com.dcbooksklf", "_blank")}} src={require('../images/mobileappdown/playstore-btn.jpg')} style={{borderRadius: '5px'}} />
                </div>
            </Modal.Body>
        </Modal>

    </Fragment>
  );
}