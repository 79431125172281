import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { userStore } from '../store/userStore';

const AdminRestriction = ({ permissionKey, children }) => {
  const [permissions, setPermissions] = useState(null);

  useEffect(() => {
    const user = userStore.getState().user;
    const adminPermissions = user?.user?.adminpermissions?.[0] ?? null; 
    setPermissions(adminPermissions);
  }, []);

  // Show a loading state while permissions are being fetched
  if (permissions === null) {
    return <div>Loading...</div>; // You can replace this with a spinner or skeleton
  }

  // Check if the user has the required permission
  if (permissions?.[permissionKey] !== 1) {
    return <Navigate to="/notfound" replace />;
  }

  // If the user has permission, render the children
  return children;
};

export default AdminRestriction;
