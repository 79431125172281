import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import Select from '../Select/Select';
import sessionQueries from '../../queries/sessionQueries';
import Switcher from '../ToggleSwitch/SwitchOne';
import { useNavigate, useParams } from 'react-router-dom';
import MultiSelect from '../Multiselect/MultiSelect';
import ErrorModal from '../ErrorModal/Errormodal';
import '../../css/style.css';
import userQueries from '../../queries/userQueries';
import Loader from '../Loader/Loader';
import siteQueries from '../../queries/siteQueries';



export default function Addfeaturedspeaker() {
  const { id } = useParams();
  const isEditMode = !!id;

 
  const navigate = useNavigate();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [speakerList, setSpeakerList] = useState([]);
  const [submissionType, setSubmissionType] = useState('');

  const [alreadyAddedSpeakers, setAlreadyAddedSpeakers] = useState([]);

  const { control, handleSubmit, reset,formState: { errors } } = useForm();

  useEffect(() => {
    fetchData();
    fetchSessionData();
   
  }, []);

  const fetchData = async () => {
    
    await speakerListing1.mutateAsync();
  
  };

  const featuredspeakerListing = siteQueries.useFeaturedspeakerListingMutation(
    (response) => {
      const addedSpeakers = response?.data?.map(featuredSpeaker => ({
        value: featuredSpeaker.speakerid.toString(),
        label: featuredSpeaker.speaker.heading
      })) || [];
      setAlreadyAddedSpeakers(addedSpeakers);
    },
    {
      onError: (error) => {
        console.error('Failed to fetch added speakers', error);
      }
    }
  );

 

  const speakerListing1 = userQueries.useListpublishedspeakersMutation((response) => {
    const speakerOptions = response?.data?.map(item => ({
      value: item.id.toString(),
      label: `${item.heading}`
    }));
    setSpeakerList(speakerOptions);
  });

  const sessionSpeaker1 = userQueries.useFeaturedspeakeraddMutation((response) => {
    console.log(response.message)
   if(response?.message === "Speakers added successfully."){
    navigate('/featuredspeaker')
   }
   else if(response?.message === "Cannot add speakers. Would exceed the maximum of 8 active speakers.")
   {
        alert("You cannot add more than 8 featured speakers.")
   }
   
  });

  

  const onSubmit = async (data) => {
    try {
      const speakerIds = data.speakers?.map(speaker => speaker.value);
      console.log("Speaker IDs:", speakerIds);
  
      await sessionSpeaker1.mutateAsync({ speakerIds });
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred');
    }
  };
  const fetchSessionData = async () => {
    try {
      const data = await featuredspeakerListing.mutateAsync();
      const sessionData = data.data;
      
      if (sessionData && sessionData.length > 0) {
        reset({
          speakers: sessionData.map(featuredSpeaker => ({
            value: featuredSpeaker.speakerid.toString(),
            label: featuredSpeaker.speaker.heading
          }))
        });
      }
    } catch (error) {
      console.error('Error fetching session data:', error);
      setErrorMessage('Error fetching session data');
      setIsErrorModalOpen(true);
    }
  };



  return (
    <div className="container mx-auto p-6">
      <Breadcrumb pageName={isEditMode ? "Edit Featured Speaker" : "Add Featured Speaker"} />

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">

         
          <div>
            <Controller
              name="speakers"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  name="speakers"
                  control={control}
                  options={speakerList}
                  defaultValue={alreadyAddedSpeakers}
                  isMulti={true}
                  label="Speakers"
                  placeholder="Select speakers"
                  error={errors.speakers?.message}
                />
              )}
            />
            <div className="flex justify-end">
            <button 
              type="button"
              onClick={handleSubmit((data) => onSubmit(data))}
              className="bg-primary text-white py-2 px-4 rounded hover:bg-opacity-90"
              
            >
              Add Featured Speakers
            </button>
          </div>
          </div>

         

         
            </form>
      </div>
      
    </div>
  );
}
