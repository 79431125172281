import React from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import { userStore } from '../store/userStore';
import Login from "../components/Login/Login";
import RouteConstants from "../constant/Routeconstant";
import DefaultLayout from "../Layout/DefaultLayout";
import Dashboard from "../components/Dashboard/Dashboard";
import TableOne from "../components/User/Listing";
import SpeakerListing from "../components/Speaker/speakerlisting";

import { Import } from "lucide-react";
// import '../css/developer.css';
import AddSpeaker from "../components/Speaker/addspeaker";
import SliderListing from "../components/Slider/sliderlisting";
import CreateSlider from "../components/Slider/createslider";
import EditSpeaker from "../components/Speaker/editspeaker";
import SessionForm from "../components/Session/SessionForm";
import SessionList from "../components/Session/SessionList";
import VenueList from "../components/Venue/VenueList";
import VenueForm from "../components/Venue/VenueForm";
import Site from "../site/Site";
import AdminListing from "../components/Admin/adminList";
import DelegateListing from "../components/Delegate/delegatListing";
import CCAvenuePay from "../components/ccavenue/Ccavenue";
import DelegateRegistrationForm from "../site/Delegate";
import AttendeeRegistrationForm from "../site/AttendeRegistrationform";
import Terms from "../site/Terms";
import About from "../site/About";
import EditAdmin from "../components/Admin/editAdmin";
import PaymentSuccessPage from "../site/Success";
import AttendeSuccessPage from "../site/AttendeSuccess";
import StudentForm from "../site/StudentRegistration";
import StudentList from "../components/students/StudentList";
import PaymentFailed from "../site/Failed";
import RegisterPage from "../site/RegisterPage";
import StudentListzoho from "../components/students/StudentListzoho";
import TableOnezoho from "../components/User/Listingzoho";
import DelegateListingzoho from "../components/Delegate/delegateListingzoho";
import NewsListing from "../components/News/listnews";
import Createnews from "../components/News/createnews";
import EditNews from "../components/News/editnews";
import ListVideos from "../components/Videos/listvideos";
import CreateVideos from "../components/Videos/createvideos";
import EditVideos from "../components/Videos/editvideos";
import PhotogalleryListing from "../components/Photogallery/listphotogallery";
import Createphotogallery from "../components/Photogallery/createphotogallery";
import Editphotogallery from "../components/Photogallery/editphotogallery";
import VideoListSite from "../site/VideoListSite";
import PhotoSiteListing from "../site/PhotoSiteListing";
import NewsView from "../site/NewsView";
import NewsSite from "../site/NewsListing";
import SpeakerListingsite from "../site/SpeakerListing";
import Speakerview from "../components/Speaker/speakerview";
import SpeakerviewSite from "../site/Speakerview";
import NotFoundPage from "../components/NotFound/NotFoundpage";
import TestimonialsListing from "../components/Testimonials/listtestimonials";
import Createtestimonials from "../components/Testimonials/createtestimonials";
import EditTestimonials from "../components/Testimonials/edittestimonials";
import Listtestimonials from "../site/testimonialslisting";
import EditSlider from "../components/Slider/editslider";
import Slider from "../site/Slider";
import AttendeeGroupRegistration from "../site/AttendeGroupRegistration";
import Creatediscountcoupon from "../components/discountcoupon/discountcouponcreate";
import Listdiscountcoupon from "../components/discountcoupon/discountcouponlist";
import Editdiscountcoupon from "../components/discountcoupon/discountcouponedit";
import StudentGroup from "../site/StudentGroup";
import DelegateGroup from "../site/DelegateGroup";
import SessioncategoryList from "../components/sessioncategory/sessioncategorylist";
import Sessioncategorycreate from "../components/sessioncategory/sessioncategorycreate";
import AddAdmin from "../components/Admin/createAdmin";
import AdminRestriction from './AdminRestriction';
import ImageEditor from "../components/ImageCrop/ImageCrop";
import Partnerlogolist from "../components/partnerlogo/partnerlogolist";
import Createpartnerlogo from "../components/partnerlogo/partnerlogocreate";
import Editpartnerlogo from "../components/partnerlogo/partnerlogoedit";
import Sessionslist from "../site/sessionslist";
import TermsAndConditions from "../site/TermsAndConditions";
import PrivacyPolicy from "../site/PrivacyPolicy";
import Featuredspeaker from "../components/home/featuredspeaker";
import Addfeaturedspeaker from "../components/home/addfeaturedspeaker";
import Addfeaturednews from "../components/home/addfeaturednews";
import Featurednews from "../components/home/featurednews";
import Featuredphotogallery from "../components/home/featuredphotogallery";
import Addfeaturedphotogallery from "../components/home/addfeaturedphotogallery";
import Featuredvideos from "../components/home/featuredvideos";
import AddFeaturedVideos from "../components/home/addfeaturedvideos";
import SessionOldList from "../site/SessionOldList";
import Welcome from "../site/welcome";
import Reports from "../components/Reports/Reports";
import PushNotification from "../components/Pushntification/Pushnotification";
import TestRegistrationForm from "../site/DelegateTest";




const AppRoutes = () => {
  const location = useLocation();
  const store = userStore.getState().user;

  // Check if user is authenticated
  const isAuthenticated = !!store.token; // Adjust this based on your actual authentication check

  // Protected Route component
  const ProtectedRoute = ({ children }) => {
    if (!isAuthenticated) {
      return <Navigate to={RouteConstants.NOTFOUND} state={{ from: location }} replace />;
    }
    return <DefaultLayout>{children}</DefaultLayout>;
  };
  const newsViewBaseRoute = RouteConstants.NEWSVIEW.replace('/:id', '');
  const newsViewRegex = new RegExp(`^${newsViewBaseRoute}`);
  const speakerViewBaseRoute = RouteConstants.SPEAKERVIEWSITE.replace('/:id/:name', '');
  const speakerViewRegex = new RegExp(`^${speakerViewBaseRoute}`);
  // Conditionally import developer.css
  if (location.pathname !== RouteConstants.ROOT && 
    location.pathname !== RouteConstants.LOGIN  && 
    location.pathname !== RouteConstants.DELEGATE  && 
    location.pathname !== RouteConstants.ATTENDEE && 
    location.pathname !== RouteConstants.TERMS && 
    location.pathname !== RouteConstants.ABOUT && 
    location.pathname !== RouteConstants.SUCCESS && 
    location.pathname !== RouteConstants.ATSUCCESS && 
    location.pathname !== RouteConstants.STUDENT   && 
    location.pathname !== RouteConstants.FAILED  && 
    location.pathname !== RouteConstants.REGISTER &&
    location.pathname !== RouteConstants.VIDEOSITELIST &&
    location.pathname !== RouteConstants.GALLERYSITELIST &&
    location.pathname !== RouteConstants.NEWSLIST && 
    // location.pathname !== RouteConstants.NEWSVIEW &&
    !newsViewRegex.test(location.pathname) &&
    location.pathname !== RouteConstants.SPEAKERLISTINGSITE &&
    // location.pathname !== RouteConstants.SPEAKERVIEWSITE && 
    !speakerViewRegex.test(location.pathname) &&
    location.pathname !== RouteConstants.LISTTESTIMONIALS &&
    location.pathname !== RouteConstants.ATTENDEEGROUP && 
    location.pathname !== RouteConstants.SGROUP &&
    location.pathname !== RouteConstants.DGROUP &&
    location.pathname !== RouteConstants.LISTTESTIMONIALS &&
    location.pathname !== RouteConstants.SESSIONSLIST &&
    location.pathname !== RouteConstants.PUBLICSESSIONLIST &&
    location.pathname !== RouteConstants.WELCOME 
    
  ) {
    import('../css/developer.css');
  }

  if (location.pathname === RouteConstants.ROOT || 
    location.pathname === RouteConstants.LOGIN || 
    location.pathname === RouteConstants.DELEGATE || 
    location.pathname === RouteConstants.ATTENDEE || 
    location.pathname === RouteConstants.TERMS || 
    location.pathname === RouteConstants.ABOUT || 
    location.pathname === RouteConstants.SUCCESS || 
    location.pathname === RouteConstants.ATSUCCESS || 
    location.pathname === RouteConstants.STUDENT || 
    location.pathname === RouteConstants.FAILED || 
    location.pathname === RouteConstants.REGISTER ||
    location.pathname === RouteConstants.VIDEOSITELIST ||
    location.pathname === RouteConstants.GALLERYSITELIST ||
    location.pathname === RouteConstants.NEWSLIST || 
    location.pathname === RouteConstants.NEWSVIEW ||
    location.pathname === RouteConstants.SPEAKERVIEWSITE ||
    location.pathname === RouteConstants.SGROUP ||
    location.pathname === RouteConstants.DGROUP ||
    location.pathname === RouteConstants.ATTENDEEGROUP ||
    location.pathname === RouteConstants.SPEAKERLISTINGSITE ||
    location.pathname === RouteConstants.SESSIONSLIST ||
    location.pathname === RouteConstants.PUBLICSESSIONLIST ||
    location.pathname === RouteConstants.SESSIONOLDLIST ||
    location.pathname === RouteConstants.WELCOME 
    
  ) {
    import('../css/bootstrap.min.css');
    import('../css/font.css');
  }
  import('../css/style.css');
    
    
  return (
    <Routes>
      <Route path={RouteConstants.ROOT} element={<Site />} />
      <Route path={RouteConstants.NOTFOUND} element={<NotFoundPage />} />
      <Route path={RouteConstants.DELEGATE} element={<DelegateRegistrationForm />} />
      <Route path={RouteConstants.TESTDELEGATE} element={<TestRegistrationForm />} />
      <Route path={RouteConstants.ATTENDEE} element={<AttendeeRegistrationForm />} />
      <Route path={RouteConstants.STUDENT} element={<StudentForm />} />
      <Route path={RouteConstants.TERMS} element={<Terms />} />
      <Route path={RouteConstants.ABOUT} element={<About />} />
      <Route path={RouteConstants.SUCCESS} element={<PaymentSuccessPage />} />
      <Route path={RouteConstants.ATSUCCESS} element={<AttendeSuccessPage />} />
      <Route path={RouteConstants.FAILED} element={<PaymentFailed />} />
      <Route path={RouteConstants.REGISTER} element={<RegisterPage />} />
      <Route path={RouteConstants.VIDEOSITELIST} element={<VideoListSite />} />
      <Route path={RouteConstants.GALLERYSITELIST} element={<PhotoSiteListing />} />
      <Route path={RouteConstants.NEWSLIST} element={<NewsSite />} />
      <Route path={RouteConstants.NEWSVIEW} element={<NewsView/>} /> 
      <Route path={RouteConstants.SPEAKERLISTINGSITE} element={<SpeakerListingsite />} />
      <Route path={RouteConstants.SPEAKERVIEWSITE} element={<SpeakerviewSite />} />
      <Route path={RouteConstants.LISTTESTIMONIALS} element={<Listtestimonials />} />
      <Route path={RouteConstants.ATTENDEEGROUP} element={<AttendeeGroupRegistration />} />
      <Route path={RouteConstants.SGROUP} element={<StudentGroup />}/>
      <Route path={RouteConstants.DGROUP} element={<DelegateGroup />}/>
      <Route path={RouteConstants.SESSIONSLIST} element={<Sessionslist />} /> 
      <Route path={RouteConstants.PUBLICSESSIONLIST} element={<Sessionslist />} /> 
      <Route path={RouteConstants.SESSIONOLDLIST} element={<SessionOldList />} />	
      <Route path={RouteConstants.TERMSANDCONDITIOND} element={<TermsAndConditions />} />
      <Route path={RouteConstants.PRIVACY} element={<PrivacyPolicy/>} />
      <Route path={RouteConstants.WELCOME} element={<Welcome />} />
      <Route path={RouteConstants.LOGIN} element={
        isAuthenticated ? <Navigate to={RouteConstants.DASHBOARD} replace /> : <Login />
      } />

      <Route element={<ProtectedRoute><Outlet /></ProtectedRoute>}>
        <Route path={RouteConstants.DASHBOARD} element={<Dashboard />} />
        <Route path={RouteConstants.USERLISTING} element={<TableOne />} />
        <Route path={RouteConstants.SESSIONFORM} element={<SessionForm />} />
        <Route path={RouteConstants.SESSIONLIST} element={<SessionList />} />
        <Route path={RouteConstants.VENUELIST} element={<VenueList />} />
        <Route path={RouteConstants.VENUEFORM} element={<VenueForm />} />
        <Route path={RouteConstants.VENUEEDIT} element={<VenueForm />} />
        <Route path={RouteConstants.SESSIONEDIT} element={<SessionForm />} />
          
        {/* Admin Routes */}
        
        <Route path={RouteConstants.SPEAKERLISTING} element={<AdminRestriction permissionKey="speakers"><SpeakerListing /></AdminRestriction>} />
	      <Route path={RouteConstants.ADMINLIST} element={<AdminRestriction permissionKey="admins"><AdminListing/></AdminRestriction>} />
        <Route path={RouteConstants.NEWSLISTING} element={<AdminRestriction permissionKey="news"><NewsListing /></AdminRestriction>} />
        <Route path={RouteConstants.VIDEOLISTING} element={<AdminRestriction permissionKey="videos"><ListVideos /></AdminRestriction>} />
        <Route path={RouteConstants.PHOTOGALLERYLISTING} element={<AdminRestriction permissionKey="photogallery"><PhotogalleryListing /></AdminRestriction>} />
        <Route path={RouteConstants.SLIDERLISTING} element={<AdminRestriction permissionKey="slider"><SliderListing /></AdminRestriction>} />
        <Route path={RouteConstants.TESTIMONIALSLISTING} element={<AdminRestriction permissionKey="testimonials"><TestimonialsListing /></AdminRestriction>} />
        <Route path={RouteConstants.SESSIONLIST} element={<AdminRestriction permissionKey="session"><SessionList /></AdminRestriction>} />
        <Route path={RouteConstants.SESSIONCATEGORYLIST} element={<AdminRestriction permissionKey="sessioncategory"><SessioncategoryList /> </AdminRestriction>} />
        <Route path={RouteConstants.VENUELIST} element={<AdminRestriction permissionKey="venuelist"><VenueList /> </AdminRestriction>} />
        <Route path={RouteConstants.LISTDISCOUNTCOUPON} element={<AdminRestriction permissionKey="discountcoupon"><Listdiscountcoupon /> </AdminRestriction>} />
 	      <Route path={RouteConstants.SPEAKERLISTING} element={<SpeakerListing /> } />
        <Route path={RouteConstants.ADDSPEAKER} element={<AddSpeaker />} />
        <Route path={RouteConstants.SLIDERLISTING} element={<SliderListing />} />
        <Route path={RouteConstants.SLIDERCREATE} element={<CreateSlider />} />
        <Route path={RouteConstants.EDITSPEAKER} element={<EditSpeaker />} />
	      <Route path={RouteConstants.ADMINLIST} element={<AdminListing/>} />
        <Route path={RouteConstants.DELEGATELIST} element={<DelegateListing/>} />
	      <Route path={RouteConstants.CCAVENUE} element={<CCAvenuePay />} />
        <Route path={RouteConstants.EDITADMIN} element={<EditAdmin />} />
        <Route path={RouteConstants.CREATEADMIN} element={<AddAdmin />} />
        <Route path={RouteConstants.STUDENTLIST} element={<StudentList />} />
        <Route path={RouteConstants.STUDENTLISTZOHO} element={<StudentListzoho />} />
        <Route path={RouteConstants.DELEGATELISTZOHO} element={<DelegateListingzoho/>} />
        <Route path={RouteConstants.USERLISTINGZOHO} element={<TableOnezoho />} />
        <Route path={RouteConstants.NEWSLISTING} element={<NewsListing />} />
        <Route path={RouteConstants.CREATENEWS} element={<Createnews />} />
        <Route path={RouteConstants.EDITNEWS} element={<EditNews />} />
        <Route path={RouteConstants.VIDEOLISTING} element={<ListVideos />} />
        <Route path={RouteConstants.CREATEVIDEOS} element={<CreateVideos />} />
        <Route path={RouteConstants.EDITVIDEOS} element={<EditVideos />} />
        <Route path={RouteConstants.PHOTOGALLERYLISTING} element={<PhotogalleryListing />} />
        <Route path={RouteConstants.CREATEPHOTOGALLERY} element={<Createphotogallery />} />
        <Route path={RouteConstants.EDITPHOTOGALLERY} element={<Editphotogallery />} />
        <Route path={RouteConstants.SPEAKERVIEW} element={<Speakerview />} />
        <Route path={RouteConstants.SLIDEREDIT} element={<EditSlider />} />
        <Route path={RouteConstants.SLIDER} element={<Slider />} />

        <Route path={RouteConstants.TESTIMONIALSLISTING} element={<TestimonialsListing />} />
        <Route path={RouteConstants.CREATETESTIMONIALS} element={<Createtestimonials />} />
        <Route path={RouteConstants.EDITTESTIMONIALS} element={<EditTestimonials />} />
        <Route path={RouteConstants.REPORTS} element={<Reports />} />

        <Route path={RouteConstants.CREATEDISCOUNTCOUPON} element={<Creatediscountcoupon />} />
        <Route path={RouteConstants.EDITDISCOUNTCOUPON} element={<Editdiscountcoupon />} />
        <Route path={RouteConstants.SESSIONCATEGORYCREATE} element={<Sessioncategorycreate />} />
        <Route path={RouteConstants.SESSIONCATEGORYEDIT} element={<Sessioncategorycreate />} />
        <Route path={RouteConstants.IMAGEEDITOR} element={<ImageEditor />} />
	      <Route path={RouteConstants.SESSIONCATEGORYLIST} element={<SessioncategoryList />} />		
        <Route path={RouteConstants.PARTNERLOGOLIST} element={<Partnerlogolist />} />
        <Route path={RouteConstants.PARTNERLOGOCREATE} element={<Createpartnerlogo />} />
        <Route path={RouteConstants.PARTNERLOGOEDIT} element={<Editpartnerlogo />} />
        <Route path={RouteConstants.FEATUREDSPEAKER} element={<Featuredspeaker />} />
        <Route path={RouteConstants.ADDFEATUREDSPEAKER} element={<Addfeaturedspeaker />} />
        <Route path={RouteConstants.FEATUREDNEWS} element={<Featurednews />} />
        <Route path={RouteConstants.ADDFEATUREDNEWS} element={<Addfeaturednews />} />
        <Route path={RouteConstants.FEATUREDPHOTOGALLERY} element={<Featuredphotogallery />} />
        <Route path={RouteConstants.ADDFEATUREDPHOTOGALLERY} element={<Addfeaturedphotogallery/>} />
        <Route path={RouteConstants.FEATUREDVIDEOS} element={<Featuredvideos />} />
        <Route path={RouteConstants.ADDFEATUREDVIDEOS} element={<AddFeaturedVideos/>} />
        <Route path={RouteConstants.PUSHNOTIFICATION} element={<PushNotification/>} />

        {/* Catch-all route for undefined routes */}
        <Route path="*" element={<Navigate to={RouteConstants.DASHBOARD} replace />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
