import React, { useState, useEffect, useMemo } from 'react';
import { Search,Edit } from 'lucide-react';
import userQueries from '../../queries/userQueries';
import { userStore } from '../../store/userStore';
import Pagination from '../../common/Pagination/Pagination';
import Loader from '../Loader/Loader';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { StdViewModal } from './viewModal';
import { Users, User } from 'lucide-react';


const StudentList = () => {      
  const [users, setUsers] = useState([]);   
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { searchResults } = userStore();
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateFilter, setDateFilter] = useState(searchParams.get('filter') || '');
  const [startDate, setStartDate] = useState(searchParams.get('startDate') || '');
  const [endDate, setEndDate] = useState(searchParams.get('endDate') || '');
  const [paymentstatusFilter, setPaymentstatusFilter] = useState(searchParams.get('paymentstatus') || '');
  const [totalPage, setTotalPage] = useState(0);
  const [isExporting, setIsExporting] = useState(false);
  const [colorMapping, setColorMapping] = useState({});


  useEffect(() => {
    setIsLoading(true);
    fetchUsers(dateFilter); 
  }, [dateFilter,startDate,endDate,paymentstatusFilter]); 
  

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setDateFilter(value);
    setStartDate('');
    setEndDate('');

    // Update URL parameters
    if (value) {
      setSearchParams({ filter: value });
    } else {
      searchParams.delete('filter');
      searchParams.delete('startDate');
      searchParams.delete('endDate');
      setSearchParams(searchParams);
    }
  };
  const handleFilterChangepaymentstatus = (e) => {
    const value = e.target.value;
    setPaymentstatusFilter(value);
    
    if (value) {
      searchParams.set('paymentstatus', value);
    } else {
      searchParams.delete('paymentstatus');
    }
    setSearchParams(searchParams);
  };

  const handleStartDateChange = (e) => {
    const value = e.target.value;
    setStartDate(value);
    setDateFilter(''); // Clear preset filter when using custom dates
    
    // Update URL parameters
    searchParams.delete('filter');
    if (value) {
      searchParams.set('startDate', value);
    } else {
      searchParams.delete('startDate');
    }
    if (endDate) {
      searchParams.set('endDate', endDate);
    }
    setSearchParams(searchParams);
  };

  const handleEndDateChange = (e) => {
    const value = e.target.value;
    setEndDate(value);
    setDateFilter(''); // Clear preset filter when using custom dates
    
    // Update URL parameters
    searchParams.delete('filter');
    if (value) {
      searchParams.set('endDate', value);
    } else {
      searchParams.delete('endDate');
    }
    if (startDate) {
      searchParams.set('startDate', startDate);
    }
    setSearchParams(searchParams);
  };

  const clearDateFilters = () => {
    setDateFilter('');
    setStartDate('');
    setEndDate('');
    searchParams.delete('filter');
    searchParams.delete('startDate');
    searchParams.delete('endDate');
    setSearchParams(searchParams);
  };

  const studentList = userQueries.useStudentlistMutation(
    async (response) => {
      setUsers(response?.data?.items);
      
      setTotalCount(response?.data?.totalItems);
      setTotalPage(response?.data?.totalPages);
      setIsLoading(false);
    },
    {
      onError: (error) => {
        console.error('Userlist fetch error:', error);
        setError('Failed to fetch user list. Please try again later.');
        setIsLoading(false);
      }
    }
  );

  useEffect(() => {
    setIsLoading(true);
    fetchUsers();
  }, [currentPage, pageSize, searchTerm]);

  useEffect(() => {
    if (searchResults?.length > 0) {
      setUsers(searchResults);
      setTotalCount(searchResults?.length);
      setCurrentPage(0);
    }
  }, [searchResults]);

  const buildParams = () => {
    const params = new URLSearchParams({
      page: currentPage,
      size: pageSize,
      search: searchTerm,
      filter: dateFilter,
      paymentstatus: paymentstatusFilter,
    });

    if (startDate && endDate) {
      params.append('startDate', startDate);
      params.append('endDate', endDate);
    }

    return params.toString();
  };

  const fetchUsers = () => {
    
  const params = buildParams();
  studentList.mutate(params);
  };

  const handleSearch = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    setCurrentPage(0); 
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };


  const totalPages = totalPage;
  // const userId = users.id;

  const exportFileDownload = userQueries.useStudentsexportMutation(
    (response) => {
      if (response.fileName) {
        window.open(`${process.env.REACT_APP_BASE_URL}/user/downloadstudents/${response.fileName}`, '_blank');
      }
    }
  );
  
  const exportFile = () => {
    setIsExporting(true);
    // Pass the same parameters used for filtering to the export endpoint
    exportFileDownload.mutateAsync(buildParams())
      .finally(() => {
        setIsExporting(false);
      });
  };
 
    // Function to generate a random color
    const generateRandomColor = () => {
      const colors = [
        '#FF6B6B', '#4ECDC4', '#45B7D1', '#96CEB4', '#FFEEAD',
        '#D4A5A5', '#9B5DE5', '#F15BB5', '#00BBF9', '#00F5D4',
        '#FFD700', '#DA70D6', '#8B0000', '#8A2BE2', '#20B2AA'
      ];
      return colors[Math.floor(Math.random() * colors.length)];
    };
    useEffect(() => {
      const newColorMapping = {};
      const seenOrderIds = new Set();
    
      users?.forEach(user => {
        const orderId = user?.user_detail?.order_id;
        const isLeader = user?.user_detail?.leader === 1;
    
        if (orderId) {
          // Assign a color to users with the same orderId
          if (!seenOrderIds.has(orderId)) {
            seenOrderIds.add(orderId);
            // Assign a shared color for users with the same orderId
            const sharedColor = generateRandomColor();
            users
              .filter(u => u?.user_detail?.order_id === orderId)
              .forEach(u => {
                newColorMapping[u.user_detail.id] = sharedColor;
              });
          }
        } 
      });
    
      setColorMapping(newColorMapping);
    }, [users]);

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      
      <div className="flex justify-between items-center mb-4">
      <Breadcrumb pageName="Students"/>
      
      <div className="flex flex-col sm:flex-row gap-2">
      <button
      onClick={exportFile}
      disabled={isExporting}
      // style={{ width: '127px' }}
      className='rounded-md border border-stroke bg-primary py-1 px-2 text-sm text-white'
    >
      {isExporting ? 'Exporting...' : 'Export'}
    </button>
        {/* Predefined Filters */}
        <select
          value={dateFilter}
          onChange={handleFilterChange}
          className="w-20 rounded-md border border-stroke bg-transparent py-1 px-2 text-sm"
        >
          <option value="">All</option>
          <option value="today">Today</option>
          <option value="thisWeek">This Week</option>
          <option value="thisMonth">This Month</option>
        </select>
        <select
          value={paymentstatusFilter}
          onChange={handleFilterChangepaymentstatus}
          className="w-20 rounded-md border border-stroke bg-transparent py-2 pl-3 outline-none focus:border-primary dark:border-strokedark dark:bg-meta-4 dark:focus:border-primary"
        >
          <option value="Success">Success</option>
          <option value="Failure">Failure</option>
          <option value="Aborted">Aborted</option>
          <option value="Invalid">Invalid</option>
          <option value="Pending">Pending</option>
        </select>

        {/* Custom Date Range Filter */}
        <div className="flex items-center gap-2">
          <input
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            className="rounded-md border border-stroke bg-transparent py-2 px-3 outline-none focus:border-primary dark:border-strokedark dark:bg-meta-4 dark:focus:border-primary"
            placeholder="Start Date"
          />
          <span className="text-gray-500">to</span>
          <input
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            className="rounded-md border border-stroke bg-transparent py-2 px-3 outline-none focus:border-primary dark:border-strokedark dark:bg-meta-4 dark:focus:border-primary"
            placeholder="End Date"
          />
        </div>
      </div>
      </div>

      
    

        <div className="mb-4 flex items-center">
          <Search className="mr-2 h-5 w-5 text-gray-500" />
          <input
            type="text"
            placeholder="Search..."
            className="w-full rounded-md border border-stroke bg-transparent py-2 pl-10 pr-4 outline-none focus:border-primary dark:border-strokedark dark:bg-meta-4 dark:focus:border-primary"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>

        <div className="mb-4 text-gray-700 dark:text-gray-300 flex justify-between items-center">
  <div>
    {users?.length} out of {totalCount} users found
  </div>
  <div className="flex space-x-2">
    <span className="rounded-full bg-white p-1 flex items-center justify-center border border-black w-8 h-8">
    <User 
      size={16}
      className="text-black dark:text-gray-300" />
      </span>
      <span className="ml-2 text-black">Member</span>
    <span className="rounded-full bg-white p-1 flex items-center justify-center border border-black w-8 h-8">
  <Users 
    size={16}
    className="text-black dark:text-gray-300" />
</span>
<span className="ml-2 text-black">Leader</span>

  </div>
</div>
      {isLoading ? (
      <Loader /> 
    ) : (
        <>
      <div className="flex flex-col">
        <div className="border-b border-stroke text-black dark:border-strokedark grid grid-cols-[.5fr_.8fr_1.5fr_.8fr_.5fr_.5fr_.5fr_.5fr] sm:grid-cols-[.5fr_.8fr_1.5fr_.8fr_.5fr_.5fr_.5fr_.5fr] bg-gray-800 dark:bg-meta-4">
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">
              Sl no.
          </div>
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">
              Name
          </div>
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">
              Email
          </div>
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">
              Phone
          </div>
           {/* <div className="p-2 text-xs font-medium uppercase xsm:text-sm">
              Pincode
          </div> */}
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">
              Amount
          </div>
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">
              Registered on
          </div>
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">
              Payment Status
          </div>
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">
              Action
          </div>
        
        </div>

       
          {users?.map((user, key) => (
            <div
              className={`border-b border-stroke dark:border-strokedark grid grid-cols-[.5fr_.8fr_1.5fr_.8fr_.5fr_.5fr_.5fr_.5fr] sm:grid-cols-[.5fr_.8fr_1.5fr_.8fr_.5fr_.5fr_.5fr_.5fr] bg-gray-800 dark:bg-meta-4${
                key === users?.length - 1
                  ? ''
                  : 'border-b border-stroke dark:border-strokedark'
              }`}
              key={key}
            >
              <div className="p-2 text-xs xsm:text-sm">
                {currentPage * pageSize + key + 1}
                
              </div>
              <div className="p-2 text-xs xsm:text-sm">
                <span >  
                  {user?.user_detail?.first_name} {user?.user_detail?.last_name}
                </span>
                {
                   user?.user_detail?.leader === 0 ? (
                    // Show User icon for users with leader === 0
                    <User
                      size={16}
                      color={colorMapping[user.user_detail.id] || '#gray'}
                      title={`Member - Order ID: ${user.user_detail.order_id}`}
                    />
                  ) : (
                    // Show Users icon for users with leader === 1
                    <Users
                      size={16}
                      color={colorMapping[user.user_detail.id] || '#gray'}
                      title={`Leader - Order ID: ${user.user_detail.order_id}`}
                    />
                  )
                }

              </div>
              <div className="p-2 text-xs xsm:text-sm">
                {user?.user_detail?.email}
              </div>
              <div className="p-2 text-xs xsm:text-sm">
                {user?.user_detail?.phone}
              </div>
              {/* <div className="p-2 text-xs xsm:text-sm">
                {user?.user_detail?.pin_code}
              </div> */}
              <div className="p-2 text-xs xsm:text-sm">
                {user?.delegate_payments?.[0]?.amount}
              </div>

              <div className="p-2 text-xs xsm:text-sm">
                {new Date(user.user_detail.createdAt).toLocaleDateString('en-IN')} 
              </div>

              <div className="p-2 text-xs xsm:text-sm">
                {user?.delegate_payments?.[0]?.payment_status}
              </div>

              <div className="p-2 text-xs xsm:text-sm">
                  <StdViewModal userDetails={user}/>
               
              </div>
            </div>
        )
        )}
      </div>
     
      <div className="mt-4 flex justify-center">
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
     </div>
    </>
   )}
</div>

  );
};

export default StudentList;

