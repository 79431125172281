import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import Loader from '../Loader/Loader';
import RouteConstants from '../../constant/Routeconstant';
import userQueries from '../../queries/userQueries';

const Listdiscountcoupon = () => {
  const [coupons, setCoupons] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const couponlist = userQueries.useDiscountcouponlistMutation(
    async (response) => {
      setCoupons(response?.data || []);
      setIsLoading(false);
    },
    {
      onError: (error) => {
        setError('Failed to fetch coupons list. Please try again later.');
        setIsLoading(false);
      }
    }
  );

  useEffect(() => {
    setIsLoading(true);
    fetchCoupons();
  }, []);

  const fetchCoupons = () => {
    couponlist.mutate('');
  };

  const handleAddDiscountCoupons = () => {
    navigate(RouteConstants.CREATEDISCOUNTCOUPON);
  };

  const deletecoupons= userQueries.useDeletediscountcouponMutation(
    (response) => {
      
        fetchCoupons();
    }
  );

  const handleDelete = async (event, id) => {
    event.preventDefault();
    if (window.confirm('Are you sure you want to delete this item?')) {
      await deletecoupons.mutateAsync(id);
    }
  }

  const activateordeactivatecoupon = userQueries.useActivatedeactivatediscountcouponMutation(
    (response) => {
      if (response?.message === "deactivated successfully") {
        alert("Coupon Deactivated")
        fetchCoupons();
      }
      else if (response?.message === "activated successfully") {
        alert("Coupon Activated")
        fetchCoupons();
      }
    }
  );

  const hnadleactivateordeactivate = (id) => {
    activateordeactivatecoupon.mutateAsync(id);
  }

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <div className="flex justify-between items-center mb-4">
        <Breadcrumb pageName="Discount Coupons" />
        <div className="flex flex-col sm:flex-row gap-2">
          <button 
            className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-2 shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
            title="Add Discount Coupons"
            aria-label="Add Discount Coupons"
            onClick={handleAddDiscountCoupons}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
          </button>
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <div className="text-red-500 text-center p-4">{error}</div>
      ) : (
        <div className="flex flex-col">
          <div className="grid grid-cols-[0.5fr_0.5fr_0.5fr_0.5fr_0.5fr_0.5fr_0.5fr_0.5fr] sm:grid-cols-[0.5fr_0.5fr_0.5fr_0.5fr_0.5fr_0.5fr_0.5fr_0.5fr] border-b border-stroke bg-gray-800 dark:bg-meta-4">
            
            <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Sl no.</div>
            <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Coupon offered by</div>
            <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Coupon Code</div>
            <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Amount</div>
            <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Usage Limit</div>
            <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Limit Used</div>
            <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Status</div>
            <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Actions</div>
          </div>

          {coupons.map((couponsdata, index) => (
            <div
              key={index}
              className="grid grid-cols-[0.5fr_0.5fr_0.5fr_0.5fr_0.5fr_0.5fr_0.5fr_0.5fr] sm:grid-cols-[0.5fr_0.5fr_0.5fr_0.5fr_0.5fr_0.5fr_0.5fr_0.5fr] border-b border-stroke bg-gray-200 dark:bg-meta-4"
            >
             
              <div className="p-2 text-xs xsm:text-sm">{index + 1}</div>
              <div className="p-2 text-xs xsm:text-sm overflow-hidden text-ellipsis">
              {couponsdata.offeredby}
              </div>
              <div className="p-2 text-xs xsm:text-sm overflow-hidden text-ellipsis">
                {couponsdata.coupon_code}
              </div>
              <div className="p-2 text-xs xsm:text-sm overflow-hidden text-ellipsis">
                {couponsdata.amount}
              </div>
            
              <div className="p-2 text-xs xsm:text-sm overflow-hidden text-ellipsis">
                 {couponsdata.usagelimit}
              </div>
              <div className="p-2 text-xs xsm:text-sm overflow-hidden text-ellipsis">
                 {couponsdata.usedlimit}/{couponsdata.usagelimit}
              </div>
              <div className="p-2 text-xs xsm:text-sm">
              {couponsdata.status === "ACTIVE" ? (
                <button
                  className="ml-2 px-3 py-1 text-xs font-medium text-blue-500 bg-blue-100"
                  onClick={() => hnadleactivateordeactivate(couponsdata.id, "DEACTIVE")}
                >
                  Activated
                </button>
              ) : (
                <button
                  className="ml-2 px-3 py-1 text-xs font-medium text-red-500 bg-red-100"
                  onClick={() => hnadleactivateordeactivate(couponsdata.id, "ACTIVE")}
                >
                Deactivated
                </button>
              )}
              </div>
              
              <div className="p-2 text-xs xsm:text-sm flex items-center gap-3">
                <PencilSquareIcon
                  onClick={() => navigate(`/editdiscountcoupon/${couponsdata.id}`)}
                  className="h-6 w-6 text-black-600 cursor-pointer hover:text-blue-600"
                  aria-hidden="true"
                />
                <TrashIcon
                  onClick={(e) => handleDelete(e, couponsdata.id)}
                  className="h-6 w-6 text-black-600 cursor-pointer hover:text-red-600"
                  aria-hidden="true"
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Listdiscountcoupon;