import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import Select from '../Select/Select';
import sessionQueries from '../../queries/sessionQueries';
import Switcher from '../ToggleSwitch/SwitchOne';
import { useNavigate, useParams,useLocation } from 'react-router-dom';
import MultiSelect from '../Multiselect/MultiSelect';
import ErrorModal from '../ErrorModal/Errormodal';
import '../../css/style.css';
import userQueries from '../../queries/userQueries';
import Loader from '../Loader/Loader';
import siteQueries from '../../queries/siteQueries';
import Highlight from '../ToggleSwitch/HighlightBt';
import moment from 'moment';



export default function SessionForm() {
  const { id } = useParams();
  const isEditMode = !!id;

  const [venueList, setVenueList] = useState([]);
  const [enabled, setEnabled] = useState(false);
  const navigate = useNavigate();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [speakerList, setSpeakerList] = useState([]);
  const [submissionType, setSubmissionType] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [venueError, setVenueError] = useState('');
  const [categoryError, setCategoryError] = useState('');
  const [nameError, setNameError] = useState('');
  const [titleError, setTitleError] = useState('');
  const [dateError, setDateError] = useState('');
  const [starttimeError, setStarttimeError] = useState('');
  const [endtimeError, setEndtimeError] = useState('');
  const [partnerlogo, setpartnerLogo] = useState([]);
  const [sponsorList, setSponsorList] = useState([]);

  const statusOptions = [
    { value: 'ACTIVE', label: 'ACTIVE' },
    { value: 'INACTIVE', label: 'INACTIVE' },
  ];

  const { control, handleSubmit, setValue, reset, formState: { errors } } = useForm({
    // resolver: yupResolver(submissionType === 'UNPUBLISH' ? draftSchema : publishSchema),
    defaultValues: {
      name: '',
      // title: '',
      subject: '',
      // description: '',
      date: '',
      time_from: '',
      time_to: '',
      delegative_exclusive: false,
      status: 'ACTIVE',
      draft: false,
      sponsors: '',
      language: '',
    }
  });

  useEffect(() => {
    fetchData();
    logo.mutateAsync();
    if (isEditMode) {
      fetchSessionData();
    }
  }, [isEditMode]);


  const logo = sessionQueries.useLogolistMutation(
    async (response) => {
      console.log("testt",response?.data?.name)
      setpartnerLogo(response?.data|| []);
    },
    {
      onError: (error) => {
        console.error('Venues fetch error:', error);
      }
    }
  );


  const speakerconnect = sessionQueries.useSpeakerconnectMutation(
    (response) => {
      if (response?.message === "Speakers and moderators retrieved and emails sent successfully") {
        alert("Email sent successfully");
      }
      
    }
  );

  const handlespeakerconnect = () => {

    speakerconnect.mutateAsync(id);
    setLoading(false);
  }



  const fetchData = async () => {
    await venueListing.mutateAsync();
    await speakerListing.mutateAsync();
    await categoryListing.mutateAsync();
    await sponsorlisting.mutateAsync();
  };
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    // Retrieve current page from navigation state
    const navigationState = location.state;
    if (navigationState?.currentPage !== undefined) {
      setCurrentPage(navigationState.currentPage);
      localStorage.setItem('sessionlistcurrentpage', navigationState.currentPage.toString());
    }
  }, [location]);
  const venueListing = sessionQueries.useVenuelistMutation((response) => {
    const venueOptions = response.data.map(item => ({
      value: item.id.toString(),
      label: item.name
    }));
    setVenueList(venueOptions);
  });

  const categoryListing = sessionQueries.useCategorylistMutation((response) => {
    const categoryOptions = response.data.map(item => ({
      value: item.id.toString(),
      label: item.name
    }));
    setCategoryList(categoryOptions);
  });

  const speakerListing = siteQueries.useSpeakerlistsitelistingMutation((response) => {
    const speakerOptions = response?.data?.map(item => ({
      value: item.id.toString(),
      label: `${item.heading}`
    }));
    setSpeakerList(speakerOptions);
  });

  const sponsorlisting = sessionQueries.useLogolistMutation((response) => {
    console.log("res",response.data)
    const sponsorOptions = response?.data?.map(item => ({
      value: item.id.toString(),
      label: `${item.name}`
    }));
    setSponsorList(sponsorOptions);
  });

  
  const sessionSpeaker = sessionQueries.useSessionSpeakerMutation((response) => {
    if (response?.message === 'Speakers added to session successfully') {
      navigate('/sessionlist');
    }
   
  });

  const sessionModerator = sessionQueries.useSessionModeratorsMutation((response) => {
    if (response?.message === 'Speakers successfully added to the session') {
      navigate('/sessionlist');
    }
   
  });

  const sessionSponsors = sessionQueries.useSponsorsaddMutation((response) => {
    if (response?.message === 'Sponsors added to the session') {
      navigate('/sessionlist');
    }
   
  });


  const sessionView = sessionQueries.useSessionViewMutation(async (response) => {});

  const sessionCreate = sessionQueries.useSessionCreateMutation(async (response) => {});

  const sessionUpdate = sessionQueries.useSessionUpdateMutation(async (response) => {});

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  const fetchSessionData = async () => {
    try {
      const data = await sessionView.mutateAsync(id);
      const sessionData = data.data;
      console.log("session data",sessionData)
      console.log("sess",sessionData)
      if (sessionData) {
        reset({
          name: sessionData.name,
          // title: sessionData.title,
          subject: sessionData.subject,
          // description: sessionData.description,
          date: new Date(sessionData.date),
          time_from: sessionData.time_from,
          time_to: sessionData.time_to,
          delegative_exclusive: sessionData.delegative_exclusive === 'YES',
          status: sessionData.status || 'ACTIVE',
          venu_id: sessionData.venu_id,
          category_id: sessionData.category_id,
          sponsors: sessionData.sponsors,
          highlight: sessionData?.highlight? 1 : 0,
          language: sessionData.language || '',
          // Correctly map over session_speackers to extract speakers
          speakers: sessionData.session_speackers.map((speakerObj) => ({
            value: speakerObj.speaker.id.toString(),
            label: `${speakerObj.speaker.heading}`,
          })),

          moderators: sessionData.session_moderators.map((moderatorObj) => ({
            value: moderatorObj.speaker.id.toString(),
            label: `${moderatorObj.speaker.heading}`,
          })),
          sponsors: sessionData.session_sponsors.map((sponsorObj) => ({
            value: sponsorObj.logo.id.toString(),
            label: `${sponsorObj.logo.name}`,
          })),
          storytellerIds: sessionData?.speakers?.map((speakerObj) => ({
            value: speakerObj?.id.toString(),
            label: `${speakerObj?.heading}`,
          })),
        });
      }
    } catch (error) {
      console.error('Error fetching session data:', error);
      setErrorMessage('Error fetching session data');
      setIsErrorModalOpen(true);
    }
  };
  
  // const AddSpeaker = async (sessionId, speakerSelected) => {
  //   if (speakerSelected ) {
  //     const data = speakerSelected.map(speaker => ({
  //       sessionId: sessionId,
  //       speakerId: speaker.value
  //     }));
  //     await sessionSpeaker.mutateAsync(data);
  //   } else {
  //     console.log('No speakers selected, skipping speaker assignment.');
  //   }
  // };

  // const AddModerators = async (sessionId, speakerSelected) => {
  //   if (speakerSelected ) {
  //     const data = speakerSelected.map(speaker => ({
  //       sessionId: sessionId,
  //       speakerId: speaker.value
  //     }));
  //     await sessionModerator.mutateAsync(data);
  //   } else {
  //     console.log('No speakers selected, skipping speaker assignment.');
  //   }
  // };

  // const AddSponsors = async (sessionId, sponsorsSelected) => {
  //   if (sponsorsSelected && sponsorsSelected.length > 0) {
  //     const data = sponsorsSelected.map(sponsors => ({
  //       sessionId: sessionId,
  //       sponsorId: sponsors.value
  //     }));
  //     await sessionSponsors.mutateAsync(data);
  //   } else {
  //     console.log('No sponsors selected, skipping sponsor assignment.');
  //   }
  // };
  const AddSpeaker = async (sessionId, speakerSelected) => {
    if (!sessionId) {
      console.error('Session ID is required');
      return;
    }
  
    // Always create a data object with sessionId, even when speakers array is empty
    const data = {
      sessionId: sessionId,
      speakers: speakerSelected ? speakerSelected.map(speaker => ({
        speakerId: speaker.value
      })) : []
    };
    
    return await sessionSpeaker.mutateAsync(data);
  };

  // const AddModerators = async (sessionId, speakerSelected) => {
  //   // Always send the data, even if empty, to handle removal
  //   const data = (speakerSelected || []).map(speaker => ({
  //     sessionId: sessionId,
  //     speakerId: speaker.value
  //   }));
  //   await sessionModerator.mutateAsync(data);
  // };
  const AddModerators = async (sessionId, speakerSelected) => {
    // Always create a data array, even if empty, and include sessionId
    const data = {
      sessionId: sessionId,
      moderators: speakerSelected ? speakerSelected.map(speaker => ({
        sessionId: sessionId,
        speakerId: speaker.value
      })) : []
    };
    
    return await sessionModerator.mutateAsync(data);
  };

  // const AddSponsors = async (sessionId, sponsorsSelected) => {
  //   // Always send the data, even if empty, to handle removal
  //   const data = (sponsorsSelected || []).map(sponsors => ({
  //     sessionId: sessionId,
  //     sponsorId: sponsors.value
  //   }));
  //   await sessionSponsors.mutateAsync(data);
  // };
  const AddSponsors = async (sessionId, sponsorsSelected) => {
    if (!sessionId) {
      console.error('Session ID is required');
      return;
    }
  
    // For empty or null sponsorsSelected, send array with single object
    if (!sponsorsSelected || sponsorsSelected.length === 0) {
      const data = [{
        sessionId: sessionId  // Always include sessionId
      }];
      return await sessionSponsors.mutateAsync(data);
    }
  
    // For normal case with sponsors selected
    const data = sponsorsSelected.map(sponsor => ({
      sessionId: sessionId,
      sponsorId: sponsor.value
    }));
    
    await sessionSponsors.mutateAsync(data);
  };

  const onSubmit = async (data, submitType) => {
      setNameError('');
      setTitleError('');
      setDateError('');
      setStarttimeError('');
      setEndtimeError('');
      setCategoryError('');
      setVenueError('');

    setLoading(true);
    setSubmissionType(submitType);
   
    if(submitType === "UNPUBLISH")
    {  
      // if(data.category_id === "" ||  data.category_id === undefined)
      //   {
      //     setCategoryError("Please select a category");
      //     setLoading(false);
      //     return false;
         
      //   }
      
    
    }
    else
    {
      if(data.name === "" || data.name === "undefined" || data.name === undefined)
        {
            
            setNameError("Session name is required");
            setLoading(false);
            return;
           
        }
       

      
    }

    const startTime = new Date(`1970-01-01T${data.time_from}`);
    const endTime = new Date(`1970-01-01T${data.time_to}`);

    if (endTime <= startTime) {
      setEndtimeError("End time must be later than start time");
      setLoading(false);
      return;
    }
    
    try {
      const isDraft = submitType === 'UNPUBLISH';
      console.log("isDraft",submitType)
      const storytellerValues = data?.storytellerIds?.map(storyteller => Number(storyteller.value)) || [];
      const submissionData = {
        ...data,
        status: submitType || 'ACTIVE',
        time_from: data.time_from || '00:00', 
        time_to: data.time_to || '23:59', 
        venu_id:data.venu_id || '',
        category_id:data.category_id || '',
        highlight: data.highlight ? 1 : 0,
        language: data.language === "" ? "" : data.language,
        storytellerIds :storytellerValues
      };

      let response;
      if (isEditMode) {
        // Prepare JSON data for update
        const jsonData = {
          ...submissionData,
          id: id,
          date: moment(submissionData.date).format('YYYY-MM-DD'),
          delegative_exclusive: submissionData.delegative_exclusive ? 'YES' : 'NO',
          highlight: submissionData.highlight ? 1 : 0,
          speakers: submissionData.speakers?.map(speaker => speaker.value) || [],
          venu_id: data.venu_id || undefined,
          category_id: data.category_id || undefined,
          language: data.language || null,
          storytellerIds: storytellerValues,
        };
        response = await sessionUpdate.mutateAsync(jsonData);
        await AddSpeaker(id, submissionData.speakers || []);
        await AddModerators(id, submissionData.moderators || []);
        await AddSponsors(id, submissionData.sponsors || []);

        // if (submissionData.speakers?.length > 0) {
        //   await AddSpeaker(id, submissionData.speakers);
        // }
        // if (submissionData.moderators?.length > 0) {
        //   await AddModerators(id, submissionData.moderators);
        // }
        // if (submissionData.sponsors?.length > 0) {
        //   await AddSponsors(id, submissionData.sponsors);
        // }

        localStorage.setItem('sessionlistcurrentpage', currentPage.toString());
      } else {
        // For create, we'll keep using FormData
        const formData = new FormData();
        Object.keys(submissionData).forEach(key => {
          // if (key === 'date') {
          //   formData.append(key, submissionData[key].toISOString());
          // } 

          if (key === 'date') {
            if (submissionData[key]) {
              formData.append(key, moment(submissionData[key]).format('YYYY-MM-DD'));
            } else {
              formData.append(key, '');
            }
          }
          
          else if (key === 'delegative_exclusive') {
            formData.append(key, submissionData[key] ? 'YES' : 'NO');
          }
          // else if (key === 'highlight') {
          //   // Convert to number before appending
          //   formData.append(key, submissionData[key] ? 1 : 0);
          // }
          else if (key === 'venu_id' || key === 'category_id') {
            // Only append if the value exists
            if (submissionData[key]) {
              formData.append(key, submissionData[key]);
            }
          } 
          else if (key !== 'speakers') {
            formData.append(key, submissionData[key]);
          }
        });
        response = await sessionCreate.mutateAsync(formData);

        if (response?.data?.id && submissionData.speakers?.length > 0) {
          await AddSpeaker(response.data.id, submissionData.speakers);
        }
        if (response?.data?.id && submissionData.moderators?.length > 0) {
          await AddModerators(response.data.id, submissionData.moderators);
        }
        if (response?.data?.id && submissionData.sponsors?.length > 0) {
          await AddSponsors(response.data.id, submissionData.sponsors);
        }
      }

      if (response?.message === 'Success') {
        // if (!isEditMode) {
        //   await AddSpeaker(response.data.id, data.speakers);
        // }
        // if (!isEditMode) {
        //   await AddModerators(response.data.id, data.moderators);
        // }
        navigate('/sessionlist');
      } else {
        setIsErrorModalOpen(true);
        setErrorMessage(isEditMode ? 'Error updating session' : 'Session Already Exists');
        setIsErrorModalOpen(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setIsErrorModalOpen(true);
      setErrorMessage('An error occurred');
      setIsErrorModalOpen(true);
    }
  };

  const allowedDates = [
    new Date(2025, 0, 23), // January 23, 2025
    new Date(2025, 0, 24), // January 24, 2025
    new Date(2025, 0, 25), // January 25, 2025
    new Date(2025, 0, 26), // January 26, 2025
  ];

  
  const inputClassName = "w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary";

  return (
    <div className="container mx-auto p-6">
      <Breadcrumb pageName={isEditMode ? "Edit Session" : "Add Session"} />
      
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label htmlFor="sessionName" className="block text-sm font-medium text-gray-700">
                Session Name
              </label>
              <Controller
                name="name"
                control={control}
                render={({ field }) => <input {...field} className={inputClassName} />}
              />
              {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>}
              {nameError && <div className="text-red-600 text-s">{nameError}</div>}
            </div>

            <div>
            <label className="block text-sm font-medium text-gray-700 sm:grid-cols-2 md:grid-cols-3">Date</label>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <DatePicker
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  includeDates={allowedDates}
                  dateFormat="dd/MM/yyyy"
                  className={inputClassName}
                  popperProps={{
                    modifiers: [
                      {
                        name: 'zIndex',
                        options: {
                          zIndex: 100,
                        },
                      },
                    ],
                  }}
                />
              )}
            />
            {errors.date && <p className="text-red-500 text-sm mt-1">{errors.date.message}</p>}
            {dateError && <div className="text-red-600 text-s">{dateError}</div>}
          </div>
           
          </div>

          <div className="mb-5 col-span-2">
          
          

              <div>
              <Controller
                name="sponsors"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    name="sponsors"
                    control={control}
                    options={sponsorList}
                    isMulti={true}
                    label="sponsors"
                    placeholder="Select sponsors"
                    error={errors.sponsors?.message}
                  />
                )}
              />
              
            </div>
        
      </div>
            


  
            

          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label htmlFor="timeFrom" className="block text-sm font-medium text-gray-700">
                Start Time
              </label>
              <Controller
                name="time_from"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    type="time"
                    className={inputClassName}
                  />
                )}
              />
              {errors.time_from && <p className="text-red-500 text-sm mt-1">{errors.time_from.message}</p>}
              {starttimeError && <div className="text-red-600 text-s">{starttimeError}</div>}
            </div>
            <div>
              <label htmlFor="timeTo" className="block text-sm font-medium text-gray-700">
                End Time
              </label>
              <Controller
                name="time_to"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    type="time"
                    className={inputClassName}
                  />
                )}
              />
              {errors.time_to && <p className="text-red-500 text-sm mt-1">{errors.time_to.message}</p>}
              {endtimeError && <div className="text-red-600 text-s">{endtimeError}</div>}
            </div>
          </div>

          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">


          <div>
          <Controller
            name="category_id"
            control={control}
            render={({ field }) => (
              <Select
                label="category"
                options={categoryList}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  setValue('category', e.target.value);
                }}
                placeholder="Select your category"
                value={field.value}
                required={false}
              />
            )}
          />
          {errors.category_id && <p className="text-red-500 text-sm mt-1">{errors.category_id.message}</p>}
          {categoryError && <div className="text-red-600 text-s">{categoryError}</div>}
        </div>
            <div>
              <Controller
                name="venu_id"
                control={control}
                render={({ field }) => (
                  <Select
                    label="Venue"
                    options={venueList}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setValue('venue', e.target.value);
                    }}
                    placeholder="Select your venue"
                    value={field.value}
                   
                  />
                )}
              />
              {errors.venu_id && <p className="text-red-500 text-sm mt-1">{errors.venu_id.message}</p>}
              {venueError && <div className="text-red-600 text-s">{venueError}</div>}
            </div>
          {/*
            <div>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Select
                    label="Status"
                    options={statusOptions}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setValue('status', e.target.value);
                    }}
                    value={field.value}
                    defaultValue="ACTIVE"  
                    required={true}
                  />
                )}
              />
              {errors.status && <p className="text-red-500 text-sm mt-1">{errors.status.message}</p>}
            </div>
            */}
          </div>

          <div>
            <Controller
              name="speakers"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  name="speakers"
                  control={control}
                  options={speakerList}
                  isMulti={true}
                  label="Speakers"
                  placeholder="Select speakers"
                  error={errors.speakers?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="moderators"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  name="moderators"
                  control={control}
                  options={speakerList}
                  isMulti={true}
                  label="Moderators"
                  placeholder="Select Moderators"
                  error={errors.moderators?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="storytellerIds"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  name="storytellerIds"
                  control={control}
                  options={speakerList}
                  isMulti={true}
                  label="Storyteller"
                  placeholder="Select Storyteller"
                  error={errors.speakers?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="language"
              control={control}
              render={({ field }) => (
                <Select
                  label="language"
                  options={[
                    { value: 'Malayalam', label: 'Malayalam' },
                    { value: 'English', label: 'English' },
                  ]}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    setValue('language', e.target.value);
                  }}
                  placeholder="Select your language"
                  value={field.value}
                  
                />
              )}
            />
            {errors.venu_id && <p className="text-red-500 text-sm mt-1">{errors.venu_id.message}</p>}
            {venueError && <div className="text-red-600 text-s">{venueError}</div>}
          </div>


          <div className="flex items-center space-x-2">
            <Controller
              name="delegative_exclusive"
              control={control}
              render={({ field }) => (
                <Switcher
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
            <label htmlFor="toggle3" className="text-sm font-medium text-gray-700">
            Check this if this session only for paid delegates:
            </label>
          </div>
          <div className="flex items-center space-x-2">
            <Controller
              name="highlight"
              control={control}
              render={({ field }) => (
                <Highlight
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
                />
              )}
            />
            <label htmlFor="highlight" className="text-sm font-medium text-gray-700">
            Check this to highlight the session.
            </label>
          </div>
{isEditMode && (
        <div className="flex justify-center mb-6">
          <button
            onClick={handlespeakerconnect}
            className="bg-purple-600 hover:bg-purple-700 text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Send speaker connect email
          </button>
        </div>
      )}
          <div className="flex space-x-4">
            
            <button
            type="submit"
            onClick={handleSubmit((data) => onSubmit(data, 'UNPUBLISH'))}
            className="col-span-2 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
          Save as Draft
          </button>
            <button
            type="submit"
            onClick={handleSubmit((data) => onSubmit(data, 'PUBLISH'))}
            className="col-span-2 w-full text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Save and Publish
          </button>
            </div>
            </form>
      </div>
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={closeErrorModal}
        errorMessage={errorMessage}
      />
      {loading && (
          <Loader />
      )}
      </div>

    </div>
    
  );
}
