import React, { useState, useRef } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Upload, Save, RotateCcw } from 'lucide-react';

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

const ImageEditor = () => {
  const [imgSrc, setImgSrc] = useState('');
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(undefined);
  const [error, setError] = useState('');
  
  const imgRef = useRef(null);
  const canvasRef = useRef(null);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const generateDownload = (canvas, crop) => {
    if (!crop || !canvas) {
      return;
    }

    try {
      const dataUrl = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.download = 'cropped-image.png';
      link.href = dataUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to generate download. Please try again.');
    }
  };

  const canvasPreview = async () => {
    const image = imgRef.current;
    const canvas = canvasRef.current;
    if (!image || !canvas || !completedCrop) {
      setError('Missing image or crop data');
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      setError('Could not get canvas context');
      return;
    }

    // Clear previous errors
    setError('');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    // Set canvas size to match the cropped area
    canvas.width = completedCrop.width * scaleX;
    canvas.height = completedCrop.height * scaleY;

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Set rendering quality
    ctx.imageSmoothingQuality = 'high';

    const pixelRatio = window.devicePixelRatio;
    canvas.style.width = `${completedCrop.width}px`;
    canvas.style.height = `${completedCrop.height}px`;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

    if (rotate) {
      ctx.save();
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((rotate * Math.PI) / 180);
      ctx.translate(-canvas.width / 2, -canvas.height / 2);
    }

    // Draw the cropped image
    try {
      ctx.drawImage(
        image,
        completedCrop.x * scaleX,
        completedCrop.y * scaleY,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
        0,
        0,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
      );
    } catch (err) {
      console.error('Draw error:', err);
      setError('Error drawing image. Please try again.');
      return;
    }

    if (rotate) {
      ctx.restore();
    }
  };

  const handleDownload = () => {
    if (!canvasRef.current || !completedCrop) {
      setError('Please make a crop selection first');
      return;
    }

    generateDownload(canvasRef.current, completedCrop);
  };

  const aspectRatios = [
    { label: 'Free', value: undefined },
    { label: '1:1', value: 1 },
    { label: '16:9', value: 16 / 9 },
    { label: '4:3', value: 4 / 3 },
  ];

  return (
    <div className="w-full max-w-4xl mx-auto p-6 space-y-6">
      {/* Error Display */}
      {error && (
        <div className="p-4 bg-red-100 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      {/* Upload Section */}
      {!imgSrc && (
        <div className="flex flex-col items-center justify-center p-8 border-2 border-dashed border-gray-300 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors">
          <label className="flex flex-col items-center gap-2 cursor-pointer">
            <Upload size={32} className="text-gray-400" />
            <span className="text-lg font-medium">Drop an image here or click to upload</span>
            <span className="text-sm text-gray-500">Supports: JPG, PNG, GIF</span>
            <input
              type="file"
              accept="image/*"
              onChange={onSelectFile}
              className="hidden"
            />
          </label>
        </div>
      )}

      {/* Editor Section */}
      {imgSrc && (
        <div className="space-y-4">
          {/* Controls */}
          <div className="flex flex-wrap gap-4 p-4 bg-gray-100 rounded-lg">
            {/* Aspect Ratio Selection */}
            <div className="flex items-center gap-2">
              <label className="text-sm font-medium">Aspect Ratio:</label>
              <select
                value={aspect}
                onChange={(e) => {
                  const newAspect = e.target.value ? Number(e.target.value) : undefined;
                  setAspect(newAspect);
                  if (imgRef.current && newAspect) {
                    const { width, height } = imgRef.current;
                    setCrop(centerAspectCrop(width, height, newAspect));
                  }
                }}
                className="px-2 py-1 rounded border"
              >
                {aspectRatios.map((ratio) => (
                  <option key={ratio.label} value={ratio.value}>
                    {ratio.label}
                  </option>
                ))}
              </select>
            </div>

            {/* Scale Control */}
            <div className="flex items-center gap-2">
              <label className="text-sm font-medium">Scale:</label>
              <input
                type="range"
                min="0.1"
                max="2"
                step="0.1"
                value={scale}
                onChange={(e) => setScale(Number(e.target.value))}
                className="w-24"
              />
              <span className="text-sm">{Math.round(scale * 100)}%</span>
            </div>

            {/* Rotation Control */}
            <div className="flex items-center gap-2">
              <button
                onClick={() => setRotate((prev) => (prev - 90) % 360)}
                className="flex items-center gap-1 px-3 py-1 bg-white rounded hover:bg-gray-50"
              >
                <RotateCcw size={16} />
                Rotate
              </button>
            </div>

            {/* Download Button */}
            <button
              onClick={handleDownload}
              disabled={!completedCrop}
              className="flex items-center gap-2 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors ml-auto disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Save size={20} />
              Save
            </button>
          </div>

          {/* Image Preview */}
          <div className="border rounded-lg overflow-hidden bg-gray-50">
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => {
                setCompletedCrop(c);
                canvasPreview();
              }}
              aspect={aspect}
            >
              <img
                ref={imgRef}
                alt="Upload"
                src={imgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                className="max-w-full transition-transform"
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </div>

          {/* Hidden Canvas for Processing */}
          <canvas
            ref={canvasRef}
            className="hidden"
          />
        </div>
      )}
    </div>
  );
};

export default ImageEditor;