const RouteConstants = { 
    ROOT: '/',
    LOGIN: '/login',
    DASHBOARD: '/dashboard',
    USERLISTING: '/userlisting',
    SPEAKERLISTING: '/speakerlisting',
    SPEAKERVIEW: '/speaker-view',
    ADDSPEAKER: '/adduser',
    SLIDERLISTING: '/sliderlisting',
    SLIDERCREATE: '/createslider',
    EDITSPEAKER: '/editspeaker/:id',
    SESSIONFORM: '/sessionform',
    SESSIONLIST: '/sessionlist',
    VENUELIST: '/venuelist',
    VENUEFORM: '/venueform',
    VENUEEDIT: '/edit-venue/:id',
    SESSIONEDIT: '/edit-session/:id',
    ADMINLIST: '/adminlisting',
    DELEGATELIST: '/delegatelisting',
    CCAVENUE:'/ccavenue',
    DELEGATE:'/delegate-register',
    TESTDELEGATE:'/delegate-test-register',
    ATTENDEE:'/attendee-register',
    STUDENT:'/student-register',
    TERMS:'/terms-conditions',
    ABOUT:'/about',
    EDITADMIN:'/adminedit/:id',
    SUCCESS:'/success',
    ATSUCCESS:'/atsuccess',
    STUDENTLIST:'/student',
    FAILED:'/failed',
    REGISTER:'/register',
    STUDENTLISTZOHO:'/studentlist',
    DELEGATELISTZOHO: '/delegatelist',
    USERLISTINGZOHO: '/userlist',
    NEWSLISTING: '/newslist',
    CREATENEWS: '/createnews',
    EDITNEWS:'/editnews/:id',
    VIDEOLISTING: '/videoslist',
    CREATEVIDEOS: '/createvideos',
    EDITVIDEOS:'/editvideos/:id',
    PHOTOGALLERYLISTING: '/photogallerylist',
    CREATEPHOTOGALLERY: '/createphotogallery',
    EDITPHOTOGALLERY:'/editphotogallery/:id',
    VIDEOSITELIST: '/videos',
    GALLERYSITELIST: '/photos',
    NEWSLIST: '/news',
    NEWSVIEW: '/newsview/:id',
    SPEAKERLISTINGSITE: '/speakers',
    SPEAKERVIEWSITE: '/speakerview/:id/:name',
    TESTIMONIALSLISTING: '/testimonialslist',
    REPORTS: '/reports',
    CREATETESTIMONIALS: '/createtestimonials',
    EDITTESTIMONIALS: '/edittestimonials/:id',
    LISTTESTIMONIALS: '/testimonials',
    NOTFOUND:'/notfound',
    SLIDEREDIT: '/editslider/:id',
    SLIDER:'/slider',
    ATTENDEEGROUP:'/attendee-group',
    CREATEDISCOUNTCOUPON:'/creatediscountcoupon',
    LISTDISCOUNTCOUPON:'/listdiscountcoupon',
    EDITDISCOUNTCOUPON:'/editdiscountcoupon/:id',
    SGROUP: '/student-group',
    DGROUP: '/delegate-group', 
    SESSIONCATEGORYLIST:'/sessioncategorylist',
    SESSIONCATEGORYCREATE:'/sessioncategorycreate',
    SESSIONCATEGORYEDIT: '/edit-sessioncategory/:id', 
    IMAGEEDITOR:'/imageeditor',
    CREATEADMIN: '/add-admin', 
    PARTNERLOGOLIST:'/partnerlogolist',
    PARTNERLOGOCREATE:'/createpartnerlogo',
    PARTNERLOGOEDIT:'/editpartnerlogo/:id',
    SESSIONSLIST:'/sessionslist',
    PUBLICSESSIONLIST:'/sessionslist',
    SESSIONCATEGORYLIST:'/sessioncategorylist',
    SESSIONCATEGORYCREATE:'/sessioncategorycreate',
    SESSIONCATEGORYEDIT: '/edit-sessioncategory/:id',
    TERMSANDCONDITIOND: '/terms-and-condissions',
    PRIVACY:'/privacy-policy',
    FEATUREDSPEAKER:'/featuredspeaker',
    ADDFEATUREDSPEAKER:'/addfeaturedspeaker',
    FEATUREDNEWS:'/featurednews',
    ADDFEATUREDNEWS:'/addfeaturednews',
    FEATUREDPHOTOGALLERY:'/featuredphotogallery',
    ADDFEATUREDPHOTOGALLERY:'/addfeaturedphotogallery',
    FEATUREDVIDEOS:'/featuredvideos',
    ADDFEATUREDVIDEOS:'/addfeaturedvideos',
    SESSIONOLDLIST:'/session_old_list',
    WELCOME:'/welcome',
    PUSHNOTIFICATION:'/notification'
};
export default RouteConstants
