import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import Loader from '../Loader/Loader';
import RouteConstants from '../../constant/Routeconstant';
import userQueries from '../../queries/userQueries';
import { Search,Edit, DownloadCloud } from 'lucide-react';

const Partnerlogolist = () => {
  const [partnerlogo, setPartnerlogo] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const listpartnerlogo = userQueries.usePartnerlogolistMutation(
    async (response) => {
      setPartnerlogo(response?.data || []);
      setIsLoading(false);
    },
    {
      onError: (error) => {
        setError('Failed to fetch speaker list. Please try again later.');
        setIsLoading(false);
      }
    }
  );

  useEffect(() => {
    setIsLoading(true);
    fetchLogos();
  }, [searchTerm]);

  // const fetchLogos = () => {
  //   listpartnerlogo.mutate('');
  // };

  const fetchLogos = () => {
    const params = new URLSearchParams({
      search: searchTerm,
    });
    listpartnerlogo.mutate(params.toString());
  };

  const handleSearch = (e) => {
    
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
   
  };

  const handleAddpartnerlogo = () => {
    navigate(RouteConstants.PARTNERLOGOCREATE);
  };

  const deletepartnerlogo = userQueries.useDeletepartnerlogoMutation(
    (response) => {
      alert("Successfully deleted")
      navigate('/partnerlogolist')
    }
  );

  const handleDelete = async (event, id) => {
    event.preventDefault();
    if (window.confirm('Are you sure you want to delete this item?')) {
      await deletepartnerlogo.mutateAsync(id);
    }
  }

  const publishpartnerlogo = userQueries.usePublishpartnerlogoMutation(
    (response) => {
      if (response?.message === "published successfully") {
        alert("published successfully")
        navigate('/partnerlogolist');
      }
      else if (response?.message === "unpublished successfully") {
        alert("unpublished successfully")
        navigate('/partnerlogolist');
      }
    }
  );

  const handlepublish = (id) => {
    publishpartnerlogo.mutateAsync(id);
  }

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <div className="flex justify-between items-center mb-4">
        <Breadcrumb pageName="Partner Logo" />
        <div className="flex flex-col sm:flex-row gap-2">
          <button 
            className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-2 shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
            title="Add Partner Logo"
            aria-label="Add Partner Logo"
            onClick={handleAddpartnerlogo}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
          </button>
        </div>
      </div>

      <div className="flex items-center w-2/3 mb-4"> {/* Adjust width here */}
    <Search className="mr-2 h-5 w-5 text-gray-500" />
    <input
      type="text"
      placeholder="Search logo name..."
      className="w-full rounded-md border border-stroke bg-transparent py-2 pl-10 pr-4 outline-none focus:border-primary dark:border-strokedark dark:bg-meta-4 dark:focus:border-primary"
      value={searchTerm}
      onChange={handleSearch}
    />
  </div>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <div className="text-red-500 text-center p-4">{error}</div>
      ) : (
        <div className="flex flex-col">
        <div className="grid grid-cols-[0.5fr_.8fr_1.5fr_1fr_1fr_1fr_0.5fr_0.5fr] sm:grid-cols-[0.5fr_.8fr_1.5fr_1fr_1fr_1fr_0.5fr_0.5fr] border-b border-stroke bg-gray-800 dark:bg-meta-4">
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Sl no.</div>
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Name</div>
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Image</div>
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Type</div>
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Display Order</div>
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Publish Status</div>
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Actions</div>
        </div>
      
        {partnerlogo.map((logo, index) => (
          <div
            key={index}
            className="grid grid-cols-[0.5fr_.8fr_1.5fr_1fr_1fr_1fr_0.5fr_0.5fr] sm:grid-cols-[0.5fr_.8fr_1.5fr_1fr_1fr_1fr_0.5fr_0.5fr] border-b border-stroke bg-gray-200 dark:bg-meta-4"
          >
            <div className="p-2 text-xs xsm:text-sm">{index + 1}</div>
            

            <div className="p-2 text-xs xsm:text-sm">
              {logo.name}
            </div>
            
            <div className="p-2 text-xs xsm:text-sm">
              {logo.image ? (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/${logo.image}`}
                  alt="Speaker"
                  className="h-12 w-12 object-cover"
                />
              ) : (
                <div className="h-12 w-12 bg-gray-200" />
              )}
            </div>

            <div className="p-2 text-xs xsm:text-sm">
                 {logo.text}
            </div>

            <div className="p-2 text-xs xsm:text-sm">
              {logo.displayorder}
            </div>

            <div className="p-2 text-xs xsm:text-sm">
            {logo.status === 'Unpublish' ? (
               logo.image  ? (
                <button
                  className="px-3 py-1 text-xs font-medium text-red-500 bg-red-100"
                  onClick={() => handlepublish(logo.id)}
                >
                  Unpublished
                </button>
              ) : (
                <button
                  className="px-3 py-1 text-xs font-medium text-yellow-500 bg-yellow-100"
                  onClick={() => alert("This item is in draft mode and cannot be published until all required fields are filled.")}
                >
                  Draft
                </button>
              )
            ) : (
              <button
                className="px-3 py-1 text-xs font-medium text-green-500 bg-green-100"
                onClick={() => handlepublish(logo.id)}
              >
                Published
              </button>
            )}
          </div>
          

            
            <div className="p-2 text-xs xsm:text-sm flex items-center gap-3">
              <PencilSquareIcon
                onClick={() => navigate(`/editpartnerlogo/${logo.id}`)}
                className="h-6 w-6 text-black-600 cursor-pointer hover:text-blue-600"
                aria-hidden="true"
              />
              <TrashIcon
                onClick={(e) => handleDelete(e, logo.id)}
                className="h-6 w-6 text-black-600 cursor-pointer hover:text-red-600"
                aria-hidden="true"
              />
            </div>
          </div>
        ))}
      </div>
      )}
    </div>
  );
};

export default Partnerlogolist;