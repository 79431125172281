import apiRequest from '../apis/api-request';
import { userStore } from '../store/userStore';

const accessToken = userStore.getState().user.token;

const reportService = {
   
    getReport: async (data) => {
        return apiRequest({
            method: "POST",
            url: `/report/${data.url || ''}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    },
    
    reportDropdown: async (data) => {
        return apiRequest({
            method: "GET",
            url: `/report/report-dropdown`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    }

}

export default reportService;