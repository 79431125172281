import apiRequest from '../apis/api-request';

const partnerService = {
    logoSitelisting: async (data) => {
        const size = data.size;
        const page = data.page;
        return apiRequest({
            method:"GET",
            url: `/logo/list/logo?page=${page}&size=${size}`,
        });
        },

}

export default partnerService;
