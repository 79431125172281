
import { useMutation } from "react-query";
import sessionService from "../services/sessionService";


const useVenuelistMutation = (onData) => {
    return useMutation(sessionService.venueList, {
      onSuccess: (data) => {
        onData(data);
      },
    });
};

const useSessionlistMutation = (onData) => {
    return useMutation(sessionService.sessionList, {
      onSuccess: (data) => {
        onData(data);
      },
    });
};


const useSessionCreateMutation = (onData) => {
    return useMutation(sessionService.sessionCreate, {
      onSuccess: (data) => {
        onData(data);
      },
    });
};

const useSessionUpdateMutation = (onData) => {
  return useMutation(sessionService.sessionUpdate, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useSessionViewMutation = (onData) => {
  return useMutation(sessionService.sessionView, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useSessionSpeakerMutation = (onData) => {
  return useMutation(sessionService.sessionSpeaker, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useVenueCreateMutation = (onData) => {
  return useMutation(sessionService.venueCreate, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useVenueUpdateMutation = (onData) => {
  return useMutation(sessionService.venueUpdate, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};


const useSpeakerlistMutation = (onData) => {
  return useMutation(sessionService.speakerList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useSessionPublishMutation = (onData) => {
  return useMutation(sessionService.sessionPublish, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCategorylistMutation = (onData) => {
  return useMutation(sessionService.categoryList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useSessionModeratorsMutation = (onData) => {
  return useMutation(sessionService.sessionModerators, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useSessionDeleteMutation = (onData) => {
  return useMutation(sessionService.sessionDelete, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};
const useSessioncategorylistMutation = (onData) => {
  return useMutation(sessionService.sessioncategoryList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};


const useSessioncategoryCreateMutation = (onData) => {
  return useMutation(sessionService.sessioncategoryCreate, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useSessioncategoryUpdateMutation = (onData) => {
return useMutation(sessionService.sessioncategoryUpdate, {
  onSuccess: (data) => {
    onData(data);
  },
});
};
const useVenuelistsiteMutation = (onData) => {
  return useMutation(sessionService.venueListsite, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};


const useDeletesessioncategoryMutation = (onDeletesessioncategorySuccess) => {
  return useMutation(sessionService.sessioncategoryDelete, {
    onSuccess: (data) => {
      onDeletesessioncategorySuccess(data);
    },
  });
};

const useSpeakerconnectMutation = (onSpeakerconnectSuccess) => {
  return useMutation(sessionService.speakerConnect, {
    onSuccess: (data) => {
      onSpeakerconnectSuccess(data);
    },
  });
};

const useLogolistMutation = (onLogolistSuccess) => {
  return useMutation(sessionService.logolist, {
    onSuccess: (data) => {
      onLogolistSuccess(data);
    },
  });
};

const useSponsorsaddMutation = (onSponsorsaddSuccess) => {
  return useMutation(sessionService.addsponsors, {
    onSuccess: (data) => {
      onSponsorsaddSuccess(data);
    },
  });
};




const sessionQueries = {
    useVenuelistMutation,
    useSessionCreateMutation,
    useSessionlistMutation,
    useSessionSpeakerMutation,
    useVenueCreateMutation,
    useVenueUpdateMutation,
    useSpeakerlistMutation,
    useSessionUpdateMutation,
    useSessionViewMutation,
    useSessioncategoryUpdateMutation,
    useSessioncategorylistMutation,
    useVenuelistsiteMutation,
    useSessionPublishMutation,
    useDeletesessioncategoryMutation,
    useCategorylistMutation,
    useSessionModeratorsMutation,
    useSessionDeleteMutation,
    useSessioncategoryUpdateMutation,
    useSessioncategoryCreateMutation,
    useSessioncategorylistMutation,
    useDeletesessioncategoryMutation,
    useSpeakerconnectMutation,
    useLogolistMutation,
    useSponsorsaddMutation,
    useSessionViewMutation,
    useSessioncategoryUpdateMutation,
    useSessioncategoryCreateMutation,
    useSessioncategorylistMutation,
    useCategorylistMutation,
    useVenuelistsiteMutation,
    useDeletesessioncategoryMutation,
    useSpeakerconnectMutation

};

export default sessionQueries;
