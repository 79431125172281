// import React, { useEffect, useState } from 'react';
// import klfLogo from '../images/klf-logo.png';
// import { Link, useNavigate } from 'react-router-dom';
// export default function Navbar({ handleOpen }) {
//   const navigate = useNavigate();
//   const [isOpen, setIsOpen] = useState(false); // State to control navbar toggle
 
//   const handleAbout = () => {
//     navigate('/about');
//     setIsOpen(false); // Close menu after navigation
//   };

//   const handleArchives = () => {
//     // window.location.href = 'https://archives.keralaliteraturefestival.com'; 
//     window.open('https://archives.keralaliteraturefestival.com');
//     setIsOpen(false);
// };

//   const handleHome = () => {
//     navigate('/');
//     setIsOpen(false); 
//   };


//   const handleRegister = () => {
//     navigate('/register');
//   };

//   const toggleNavbar = () => {
//     setIsOpen(!isOpen); // Toggle menu open/close state
//   };

//   const handleSpeaker = () => {
//     if (window.location.pathname === '/speakers') {
//       window.location.reload(); 
//     } else {
//       navigate('/speakers'); 
//     }
//   };

//   const handleProgramme = () => {
//     navigate('/sessionslist');
//   };

//   return (
//     <nav id="navbar" className="navbar navbar-expand-lg justify-content-center sticky-top bg-white">
//       <div className="container-fluid menu-wrpr">
//         <a style={{ cursor: 'pointer' }} onClick={handleHome} className="navbar-brand d-block text-left order-0 order-md-1">
//           <img src={klfLogo} width="180" height="46" alt="Kerala Literature Festival" />
//         </a>
//         <a className="nav-link pl-0 reqdemo phoneonly phone-reg btn-programme" data-bs-toggle="modal" data-bs-target="#registrationType" onClick={handleProgramme} >Programme</a>
//         <button className="navbar-toggler" type="button" onClick={toggleNavbar}>
//         <span className="navbar-toggler-icon">
// 						<span></span>
// 					</span>
//         </button>
//         {/* Animated dropdown */}
//         <div className={`navbar-collapse collapse text-center dual-nav order-1 order-md-1`}>
//           <div className="menu-and-search-wrpr ms-auto">
//             <ul style={{ cursor: 'pointer' }} className="navbar-nav headernav menuAnim">
//             <li className="nav-item">
//               <Link className="nav-link pl-0" to='/home'>
//               Home
//               </Link>
//             </li>
//             <li className="nav-item">
//               <Link className="nav-link pl-0" to='/welcome'>
//               Welcome to KLF
//               </Link>
//             </li>
//               <li className="nav-item">
//                 <a className="nav-link pl-0" onClick={handleAbout}>
//                   About
//                 </a>
//               </li>
//               <li className="nav-item">
//                 <a className="nav-link pl-0" onClick={handleSpeaker}>
//                   Speakers
//                 </a>
//               </li>
//               <li className="nav-item">
//                 <Link className="nav-link pl-0" to='/news'>
//                   News
//                 </Link>
//               </li>
//               <li className="nav-item">
//                 <Link className="nav-link pl-0" to='/videos'>
//                   Video
//                 </Link>
//               </li>
//               <li className="nav-item">
//                 <Link className="nav-link pl-0" to='/photos'>
//                   Photos
//                 </Link>
//               </li>
//               <li className="nav-item">
//               <Link className="nav-link pl-0" to='/'>
//               Programme
//               </Link>
//             </li> 
//             <li className="nav-item">
//               <Link className="nav-link pl-0" to='https://themagweb-klfsessions.s3.ap-south-1.amazonaws.com/KLF_Schedule_Calendar_2025.pdf' target="_blank">
//               Download Schedule
//               </Link>
//             </li>
   
//               <li className="nav-item">
//                 <a className="nav-link pl-0 reqdemo" onClick={handleRegister} data-bs-toggle="modal" data-bs-target="#myModal">
//                   Register
//                 </a>
//               </li>
//             </ul>
//           </div>
//         </div>

//         <div className="phoneonly">
//           <div className={`phone-nav navbar-collapse collapse ${isOpen ? 'show' : ''}`} id="mobnav">
//             <ul className="navbar-nav headernav">
//             <li className="nav-item">
//               <Link className="nav-link pl-0" to='/home'>
//               Home
//               </Link>
//             </li>
//             <li className="nav-item">
//               <a className="nav-link pl-0" href='/welcome'>
//               Welcome to KLF
//               </a>
//             </li>
//               <li className="nav-item">
//               <a className="nav-link pl-0" onClick={handleAbout}>
//                   About
//                 </a>
//               </li>
//               <li className="nav-item">
//               <a className="nav-link pl-0" onClick={handleSpeaker}>
//               Speakers
//                 </a>
//               </li>
//               <li className="nav-item">
//                 <a className="nav-link pl-0" href='/news'>
//                   News
//                 </a>
//               </li>
//               <li className="nav-item">
//                 <a className="nav-link pl-0" href='/videos'>
//                   Video
//                 </a>
//               </li>
//               <li className="nav-item">
//                 <a className="nav-link pl-0" href='/photos'>
//                   Photos
//                 </a>
//               </li>
//               <li className="nav-item">
//                 <a className="nav-link pl-0" href='/'>
//                   Programme
//                 </a>
//               </li>

              
//               <li className="nav-item">
//                 <a className="nav-link pl-0" href='https://themagweb-klfsessions.s3.ap-south-1.amazonaws.com/KLF_Schedule_Calendar_2025.pdf' target="_blank">
//                 Download Schedule
//                 </a>
//               </li>

//               <li className="nav-item">
//                 <a onClick={handleRegister} className="nav-link pl-0 reqdemo" data-bs-toggle="modal" data-bs-target="#myModal">
//                   Register
//                 </a>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </nav>
//   );
// }







import React, { useEffect, useState } from 'react';
import klfLogo from '../images/klf-logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, Space } from 'antd';

export default function Navbar({ handleOpen }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); // State to control navbar toggle
 
  const handleAbout = () => {
    navigate('/about');
    setIsOpen(false); // Close menu after navigation
  };

  const handleArchives = () => {
    window.open('https://archives.keralaliteraturefestival.com');
    setIsOpen(false);
  };

  const handleHome = () => {
    navigate('/');
    setIsOpen(false); 
  };

  const handleRegister = () => {
    navigate('/register');
  };

  const toggleNavbar = () => {
    setIsOpen(!isOpen); // Toggle menu open/close state
  };

  const handleSpeaker = () => {
    if (window.location.pathname === '/speakers') {
      window.location.reload(); 
    } else {
      navigate('/speakers'); 
    }
  };

  const handleProgramme = () => {
    navigate('/sessionslist');
  };


  const items = [
    {
      key: '1',
      danger: false,
      label: 'Photos',
      label: (
        <Link  className="nav-link pl-0 px-4" style={{textAlign: 'left'}} rel="noopener noreferrer" to="/photos">
          Photos
        </Link>
      ),
    },
    {
      key: '2',
      danger: false,
      label: 'Videos',
      label: (
        <Link className="nav-link pl-0 px-4" style={{textAlign: 'left'}} rel="noopener noreferrer" to="/videos">
          Videos
        </Link>
      )
    },
  ];


  return (
    <nav id="navbar" className="navbar navbar-expand-lg justify-content-center sticky-top bg-white">
      <div className="container-fluid menu-wrpr">
        <a style={{ cursor: 'pointer' }} onClick={handleHome} className="navbar-brand d-block text-left order-0 order-md-1">
          <img src={klfLogo} width="180" height="46" alt="Kerala Literature Festival" />
        </a>
        <a className="nav-link pl-0 reqdemo phoneonly phone-reg btn-programme" data-bs-toggle="modal" data-bs-target="#registrationType" onClick={handleProgramme} >Programme</a>
        <button className="navbar-toggler" type="button" onClick={toggleNavbar}>
          <span className="navbar-toggler-icon">
            <span></span>
          </span>
        </button>
        {/* Animated dropdown */}
        <div className={`navbar-collapse collapse text-center dual-nav order-1 order-md-1`}>
          <div className="menu-and-search-wrpr ms-auto">
            <ul style={{ cursor: 'pointer' }} className="navbar-nav headernav menuAnim">
              <li className="nav-item">
                <Link className="nav-link pl-0" to='/home'>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link pl-0" to='/welcome'>
                  Welcome to KLF
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link pl-0" onClick={handleAbout}>
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link pl-0" onClick={handleSpeaker}>
                  Speakers
                </a>
              </li>
              <li className="nav-item">
                <Link className="nav-link pl-0" to='/news'>
                  News
                </Link>
              </li>
              <li className="nav-item dropdown dropup">
                <Dropdown
                  menu={{
                    items,
                  }}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      Gallery
                    </Space>
                  </a>
                </Dropdown>
              </li>
              <li className="nav-item">
                <Link className="nav-link pl-0" to='/'>
                  Programme
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link pl-0" to='https://themagweb-klfsessions.s3.ap-south-1.amazonaws.com/KLF_Schedule_Calendar_2025.pdf' target="_blank">
                  Download Schedule
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link pl-0 reqdemo" onClick={handleRegister} data-bs-toggle="modal" data-bs-target="#myModal">
                  Register
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="phoneonly">
          <div className={`phone-nav navbar-collapse collapse ${isOpen ? 'show' : ''}`} id="mobnav">
            <ul className="navbar-nav headernav">
              <li className="nav-item">
                <Link className="nav-link pl-0" to='/home'>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link pl-0" href='/welcome'>
                  Welcome to KLF
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link pl-0" onClick={handleAbout}>
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link pl-0" onClick={handleSpeaker}>
                  Speakers
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link pl-0" href='/news'>
                  News
                </a>
              </li>
              <li className="nav-item dropdown dropup">
                <Dropdown
                  menu={{
                    items,
                  }}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      Gallery
                    </Space>
                  </a>
                </Dropdown>
              </li>
              <li className="nav-item">
                <a className="nav-link pl-0" href='/'>
                  Programme
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link pl-0" href='https://themagweb-klfsessions.s3.ap-south-1.amazonaws.com/KLF_Schedule_Calendar_2025.pdf' target="_blank">
                  Download Schedule
                </a>
              </li>
              <li className="nav-item">
                <a onClick={handleRegister} className="nav-link pl-0 reqdemo" data-bs-toggle="modal" data-bs-target="#myModal">
                  Register
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
