import React, {useEffect, useState, useCallback} from 'react'
import { useMediaQuery } from "react-responsive";
// import klfBanner1 from '../images/banner-website-01.jpg'
// import klfBanner2 from '../images/banner-website-02.jpg'
// import klfBanner3 from '../images/banner-website-03.jpg'
// import klfBanner from '../images/klf-banner.jpg'
// import mobileBanner from '../images/klf-mobile-banner.jpg'
// import speakerOne from '../images/speaker-01.png'
// import speakerTwo from '../images/speaker-02.png'
// import speakerThree from '../images/speaker-03.png'
// import speakerFour from '../images/speaker-04.png'
// import newsOne from '../images/news-1.png'
// import newsOne from '../images/newsone.jpeg'
// import newsInfotwo from '../images/newstwo.jpg'
// import newsInfothree from '../images/newsthree.jpg'
// import newsInfofour from '../images/newsfour.jpg'
// import newsInfofive from '../images/newsfive.jpg'

// import newsTwo from '../images/news-2.png'
// import newsThree from '../images/news-3.png'
// import newsFour from '../images/news-4.png'
import PatternOne from '../images/pattern-for-counter-section.png';
import PatternTwo from '../images/pattern-for-counter-section-2.png';
import aboutsUsBgPic from '../images/aboutus-bg-mobile-pic.jpg';

import whyshouldyou from '../images/why-should-you.png';
import beastudent from '../images/be-a-student.png';
import spacetoconnect from '../images/space-to-connect.png';
import spacetoconnectMobile from '../images/space-to-connect.jpg';
import gmap from '../images/gmap.jpg';



//import p1 from '../images/photos-01-thumb.jpg'

// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation, Pagination, Autoplay, EffectCoverflow } from 'swiper/modules';
// import 'swiper/css';
// import 'swiper/css/effect-coverflow';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/autoplay';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import Testimonials from './Testimonial'
import Footer from './Footer'
//import RegisterModal from './RegisterModal'
//import DelegateRegisterModal from './DelegateRegister'
//import authQueries from '../queries/authQueries'
//import Session from './Session'
//import GoogleMap from './GoogleMap'
import InitModal from './initModal'
import Navbar from './Navbar'
import { useNavigate } from 'react-router-dom';
import Videos from './Video'
import PhotoGallery from './Photogallery'
import BootstrapLoader from '../components/Loader/BootstrapLoader'
import News from './News'
import Slider from './Slider'
import SpeakerSlider from './SpeakerSlider'
import FestivalPartner from './FestivalPartners';
import DownloadApp from './DownloadApp';
import Featuredsessionslist from './featuredsessions';




export default function Site() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDelegateOpen, setIsDelegateOpen] = useState(false);
  const [amount, setAmount] = useState(500);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [registerData, setRegisterData] = useState({});
  const [delegateData, setDelegateData] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [isOpennn, setIsOpennn] = useState(false);
  

    useEffect(() => {
      AOS.init({
        duration: 1200,
      })
      }, []);

useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const marketingId = urlParams.get('marketingid');
        
        if (marketingId) {
          localStorage.setItem('marketingId', marketingId);
        }
      }, []);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleDelegateOpen = () => {setIsDelegateOpen(true)};
  const handleDelegateClose = () => setIsDelegateOpen(false);

  const navigate = useNavigate();

  const handleAbout = () => {
      navigate('/about');
  }

  const handleRegisterAttendee = () => {
    navigate('/attendee-register');
}


const handleRegisterDelegate = () => {
  navigate('/delegate-register');
}

const handleRegisterStudent = () => {
  navigate('/student-register');
}
const handleRegister = () => {
  navigate('/register');

}


    const [loading, setLoading] = useState(true);

    useEffect(() => {
      // Show loader for 2 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 500);

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }, []); // Empty dependency array means this runs once on mount

          return (
          <>
          {loading ? (
            // Loader component
            <div style={{
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <BootstrapLoader />
            </div>
          ) : (
            <>
        <Navbar handleOpen={handleOpen}  />

     
        <Slider />

       

        <WhyDelegate handleOpen={handleOpen} handleRegister={handleRegister} handleRegisterAttendee={handleRegisterAttendee} handleRegisterDelegate={handleRegisterDelegate} handleRegisterStudent={handleRegisterStudent}/>

        <AboutUs handleOpen={handleOpen} handleAbout={handleAbout}  />
        <Featuredsessionslist />

        <SpeakerSlider/>


        <Videos />

        {/* <Speakers />

        <Session /> */}
      
        <News />


        <PhotoGallery />

        {/* <KLFCounts /> */}

        <Testimonials />

        <FestivalPartner/> 

        <DownloadApp/>
        
        {/* <iframe  style={{top:'11px', position:'relative', border:0 }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7826.057786429875!2d75.76394492177349!3d11.259284767979793!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba6592f11eb8511%3A0xf103dcf3408d910e!2sKozhikode+Beach!5e0!3m2!1sen!2sin!4v1542012559435" width="100%" height="450" ></iframe> */}

        <div class="googlemap-container">
          <a target="_blank" href="https://maps.app.goo.gl/zrr92pwCMMxGG3Fw9"><img src={gmap} alt="Location at Kozhikode" width="1900" height="449" /></a>
        </div>


        <Footer handleOpen={handleOpen} handleDelegateOpen={handleDelegateOpen} />


        {/* <RegisterModal isOpen={isOpen} onClose={handleClose} /> */}


        
        <div className="floated-regbar fixed-bottom phoneonly">
          <a onClick={handleRegister} data-bs-toggle="modal" data-bs-target="#registrationType" className="btn btn-dark btn-register">Register</a>
        </div>
      
        <InitModal isOpen={isOpen} onClose={handleClose} />
        </>
         )}
     
    </>
  )
}

const WhyDelegate = ({
  handleOpen,
  handleRegister, 
  handleRegisterAttendee, 
  handleRegisterDelegate,
  handleRegisterStudent 
}) => {
  const [isSpaceModalOpen, setIsSpaceModalOpen] = useState(false);
  const [isWhyModalOpen, setIsWhyModalOpen] = useState(false);

  // Add useEffect to handle body scrolling and backdrop
  useEffect(() => {
    if (isSpaceModalOpen || isWhyModalOpen) {
      document.body.style.overflow = 'hidden';
      document.body.classList.add('modal-open');
    } else {
      document.body.style.overflow = 'unset';
      document.body.classList.remove('modal-open');
    }
    
    // Cleanup function
    return () => {
      document.body.style.overflow = 'unset';
      document.body.classList.remove('modal-open');
    };
  }, [isSpaceModalOpen, isWhyModalOpen]);

  const handleSpaceClose = () => {
    setIsSpaceModalOpen(false);
  };

  const handleWhyClose = () => {
    setIsWhyModalOpen(false);
  };

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });


  return (
    <>
    <div className="why-delegate-2 wdhome container-fluid whyDelegate-new-2">
      <div className="container">
        <div className="row">
          <div className="col-md-4 regfree" onClick={() => setIsSpaceModalOpen(true)} style={{cursor:'pointer'}}>
            <div className="imgpadding">

              {/* <img src={spacetoconnect} alt="Space to Connect" width="456"   height="319" /> */}

                  <img
                    src={isMobile ? spacetoconnectMobile : spacetoconnect}
                    alt="Space to Connect"
                    width={isMobile ? "300" : "456"}
                    height={isMobile ? "200" : "319"}
                  />

              <a 
                onClick={() => setIsSpaceModalOpen(true)} 
                style={{cursor:'pointer'}}
                className="readmore-1"
              >
                Read More <i className="icon-keyboard_arrow_right"></i>
              </a>
              <a href="#" onClick={handleRegisterAttendee} className="register"></a>
            </div>
          </div>
          <div className="col-md-4 whyshould" onClick={() => setIsWhyModalOpen(true)} style={{cursor:'pointer'}}>
            <div className="imgpadding">
              <img src={whyshouldyou} alt="Why Should You" width="374" height="319"  />
              <a 
                onClick={() => setIsWhyModalOpen(true)} 
                style={{cursor:'pointer'}}
                className="readmore-1"
              >
                Read More <i className="icon-keyboard_arrow_right"></i>
              </a>
              <a href="#" onClick={handleRegisterDelegate} className="register"></a>
            </div>
          </div>
          <div className="col-md-4 beStudent" onClick={handleRegisterStudent} style={{cursor:'pointer'}}>
            <div className="imgpadding">
              <img src={beastudent} alt="Be a Student" width="438"  height="319" />
              <a href="#" onClick={handleRegisterStudent} className="register"></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <img className="phoneonly" src={aboutsUsBgPic} width="1065" heigth="677" />

    {/* Space to Connect Modal */}
    {isSpaceModalOpen && (
      <>
        <div 
          className="modal show spacetoConnect" 
          id="spacetoConnect" 
          style={{ display: 'block' }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  <span></span> Register free as an attendee at KLF
                </h4>
                <button 
                  type="button" 
                  className="close" 
                  onClick={handleSpaceClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row popup-columns">
                  <div className="col-md-12">
                    <div className="dele-perks">
                      <p className="mb-3">
                        KLF, the largest literary festival in Asia, is a free event that draws hundreds of thousands of people from diverse backgrounds each year.
                      </p>
                      <p className="mb-3">
                        For many, especially the youth and the general public, it has become an eagerly anticipated annual gathering—a celebration of art, culture, music, and literature.
                      </p>
                      <p className="mb-3">
                        Kerala Literature Festival offers a unique experience for all, making it a true celebration for the public.
                      </p>
                      <p className="mb-3">
                        Register as an attendee to stay informed about upcoming events and special offerings at the venue.
                      </p>
                      <p className="mb-3">
                        Take the opportunity to meet your favorite speakers, celebrities, Nobel Prize winners, Booker Prize recipients, Oscar winners, and Olympic medalists.
                      </p>
                      <p className="mb-3">
                        Don't forget to sign up as an attendee.
                      </p>
                      <p className="mb-3">
                        See you in January on the beach with your friends, for a spectacular event - KLF.
                      </p>
                      <div className="reg-popup-wrpr" >
								        <a className="nav-link pl-0 reqdemo" onClick={handleRegisterAttendee} style={{cursor:'pointer'}}>Register</a>
							        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop show"></div>
      </>
    )}

    {/* Why Should You Be Modal */}
    {isWhyModalOpen && (
      <>
        <div
          className="modal show WhyshouldYoube" 
          id="WhyshouldYoube" 
          style={{ display: 'block' }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  <span></span> Why should you be a delegate at KLF?
                </h4>
                <button 
                  type="button" 
                  className="close" 
                  onClick={handleWhyClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row popup-columns">
                  <div className="col-md-12">
                    <div className="dele-perks">
                      <p className="mb-3">As a valued supporter of the Kerala Literature Festival (KLF), we invite you to be part of our Delegate Network. KLF has grown into Asia's largest literature festival, attracting half-a-million participants, thanks to your enthusiasm and encouragement. This achievement is further celebrated with Kozhikode being recognized as India's first UNESCO 'City of Literature'.</p>
                      <p className="mb-3">As a not-for-profit organization, we rely on contributions to sustain KLF's mission of fostering free and open conversations and making literature accessible to all.</p>
                      <p className="mb-3">While there are no obligations or financial commitments, your contribution will help ensure KLF's long-term viability. </p>
                      
                      <p className="mb-3">You can be a delegate by paying <span className="rupees me-2 orange">Rs. 1399.</span> 
                      {/* <br/>Early Bird Offer <span className="rupees yellow">@ Rs 1199 </span> valid till 20th, Dec 2024. */}
                      </p>
                      
                      <p>By being a Delegate you will have access to our finest speakers and exclusively curated sessions, along with priority book signing access, gift coupons and other goodies.</p>
                      
                      <div className="reg-popup-wrpr" >
								        <a className="nav-link pl-0 reqdemo" onClick={handleRegisterDelegate} style={{cursor:'pointer'}}>Register</a>
							        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop show"></div>
      </>
    )}
    </>
  );
};






//   const Banner = () => {
//   return (
//     <div className="banner container-fluid">
//       <div className="row">
//         <div className="col-md-12 p-0 text-center">
//           <div className="desktoponly">
//             <Swiper
//               modules={[Autoplay, Pagination, Navigation]}
//               loop={true}
//               speed={500}
//               slidesPerView={1}
//               autoplay={{
//                 delay: 2500,
//                 disableOnInteraction: false,
//               }}
//               pagination={{
//                 el: '.swiper-pagination.banner-pagination',
//                 clickable: true,
//               }}
//               navigation={{
//                 nextEl: '.swiper-button-next.banner-next',
//                 prevEl: '.swiper-button-prev.banner-prev',
//               }}
                 
//               className="swiper-container main-banner"
              
              
            
//             >
//               <SwiperSlide className="swiper-slide">
//                 <img src={klfBanner1} alt="Gallery 1" />
//               </SwiperSlide>
//               <SwiperSlide className="swiper-slide">
//                 <img src={klfBanner2} alt="Gallery 2" />
//               </SwiperSlide>
//               <SwiperSlide className="swiper-slide">
//                 <img src={klfBanner3} alt="Gallery 3" />
//               </SwiperSlide>
              
//               <div class="swiper-button-next banner-next"></div>
// 					  <div class="swiper-button-prev banner-prev"></div>
//             </Swiper>
//           </div>

//           <div className="phoneonly">
//             <Swiper
//               modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
//               effect="coverflow"
//               grabCursor={true}
//               centeredSlides={true}
//               loop={true}
//               speed={500}
//               slidesPerView={1}
//               coverflowEffect={{
//                 rotate: 10,
//                 stretch: 0,
//                 depth: 300,
//                 modifier: 3,
//                 slideShadows: true,
//               }}
//               autoplay={{
//                 delay: 2500,
//                 disableOnInteraction: false,
//               }}
//               pagination={{
//                 el: '.swiper-pagination.gallery-pagination-mobile',
//                 clickable: true,
//               }}
//               navigation={{
//                 nextEl: '.gallery-next-mobile',
//                 prevEl: '.gallery-prev-mobile',
//               }}
//               className="swiper-container main-phone-banner"
//               data-aos="fade-up"
//               data-aos-delay="300"
//               breakpoints={{
//                 320: {
//                   slidesPerView: 1,
//                   spaceBetween: 20
//                 },
//                 480: {
//                   slidesPerView: 3,
//                   spaceBetween: 30
//                 },
//                 640: {
//                   slidesPerView: 3,
//                   spaceBetween: 40
//                 }
//               }}
//             >
//               <SwiperSlide className="swiper-slide">
//                 <img src={klfBanner1} alt="Gallery 1" />
//               </SwiperSlide>
//               <SwiperSlide className="swiper-slide">
//                 <img src={klfBanner2} alt="Gallery 2" />
//               </SwiperSlide>
//               <SwiperSlide className="swiper-slide">
//                 <img src={klfBanner3} alt="Gallery 3" />
//               </SwiperSlide>
//               <div className="swiper-pagination gallery-pagination-mobile"></div>
//               <div className="swiper-button-next gallery-next-mobile"></div>
//               <div className="swiper-button-prev gallery-prev-mobile"></div>
//             </Swiper>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
  

  

  const AboutUs = ({handleOpen, handleAbout}) => (
    <div className="aboutus-wrpr container-fluid text-white">
    <div className="container">
      <div className="row">
        <div className=""></div>
        <div className="col-md-12 p-0 text-center">
          <h2 data-aos="fade-up" data-aos-delay="100">
            About <strong>KLF</strong>
          </h2>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <p data-aos="fade-up" data-aos-delay="300">
                The Kerala Literature Festival stands as Asia's largest literature
                festival with over half a million footfall. Nestled along the
                shores of the Arabian Sea in Calicut, KLF caters to all ages and
                interests, fostering connections between readers and writers for
                inspiration, entertainment, and discussion.
              </p>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-12 text-center section-btn-wrpr">
              <a
                className="nav-link pl-0 btn btn-sm btn-dark btn-yellow"
                data-aos="fade-up"
                data-aos-delay="500"
                href="/about"
              >
                Read More
              </a>
              <a class="nav-link pl-0 btn btn-sm btn-dark btn-yellow aos-init aos-animate" data-aos="fade-up" data-aos-delay="500" href="/welcome">Welcome to KLF</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  );
 
 

 

  // const Sessions = () => (
  //   <div className="sessions-wrpr">
  //     <div className="container">
  //       <div className="row">
  //         <div className="col-md-12 p-0 text-center">
  //           <h2 data-aos="fade-up" data-aos-delay="100">
  //             <span className="speaker-pattern"></span>sessions<span className="speaker-pattern"></span>
  //           </h2>
  //         </div>
  //         <div className="col-md-12 pt-5 pb-5 mt-5" style={{position: 'relative'}}>
  //           <div className="swiper-leftarrow">
  //             <div className="swiper-button-prev speakers-prev"></div>
  //           </div>

  //           <Swiper
  //            spaceBetween={20}
  //            speed={500}
  //            loop={true}
  //            autoplay={{
  //            delay: 2500,
  //            disableOnInteraction: false,
  //            }}
  //            slidesPerView={4}
  //            navigation={{
  //                nextEl: ".swiper-button-next.speakers-next",
  //                prevEl: ".swiper-button-prev.speakers-prev",
  //            }}
  //            modules={[Autoplay, Pagination, Navigation]}
  //            className="swiper-container sessions-slide"
  //            breakpoints={{
  //             320: {
  //               slidesPerView: 1,
  //               spaceBetween: 20
  //             },
  //             // when window width is >= 480px
  //             480: {
  //               slidesPerView: 3,
  //               spaceBetween: 30
  //             },
  //             // when window width is >= 640px
  //             640: {
  //               slidesPerView: 4,
  //               spaceBetween: 40
  //             }
  //            }}
  //           data-aos="fade-up" 
  //           data-aos-delay="300"
  //           >
  //             <div class="swiper-wrapper">
  //             <SwiperSlide className='swiper-slide text-center'>
  //               <div className="card session-card yellow">
  //                 <div className="scard-header">
  //                   <div>Thoolika</div>
  //                   <div>തൂലിക</div>
  //                 </div>
  //                 <div className="scard-heading">
  //                   <h2>Beyond Fear: True stories on life in the Indian Armed Forces</h2>
  //                 </div>
  //                 <div className="session-type">
  //                   <div className="session-type-label">Discussion</div>
  //                 </div>
  //                 <div className="session-members">
  //                   <ul>
  //                     <li>Major General Lan Cardozo</li>
  //                     <li>Bindu Amat</li>
  //                     <li>Dr. (Col) P. S James</li>
  //                   </ul>
  //                 </div>
  //                 <div className="sponsor-logo-ruppe-icon">
  //                   <div>Sponsor Logo</div>
  //                   <a href="#" className="icon-ruppee"></a>
  //                 </div>
  //                 <div className="date-and-time">
  //                   <div className="date"><span></span> 10 Jan 2024</div>
  //                   <div className="time"><span></span> 09:30 PM - 12:00 AM</div>
  //                 </div>
  //               </div>
  //             </SwiperSlide>

  //             <SwiperSlide className='swiper-slide text-center'>
  //               <div className="card session-card red">
  //                 <div className="scard-header">
  //                   <div>Thoolika</div>
  //                   <div>തൂലിക</div>
  //                 </div>
  //                 <div className="scard-heading">
  //                   <h2>Beyond Fear: True stories on life in the Indian Armed Forces</h2>
  //                 </div>
  //                 <div className="session-type">
  //                   <div className="session-type-label">Discussion</div>
  //                 </div>
  //                 <div className="session-members">
  //                   <ul>
  //                     <li>Major General Lan Cardozo</li>
  //                     <li>Bindu Amat</li>
  //                     <li>Dr. (Col) P. S James</li>
  //                   </ul>
  //                 </div>
  //                 <div className="sponsor-logo-ruppe-icon">
  //                   <div>Sponsor Logo</div>
  //                   <a href="#" className="icon-ruppee"></a>
  //                 </div>
  //                 <div className="date-and-time">
  //                   <div className="date"><span></span> 10 Jan 2024</div>
  //                   <div className="time"><span></span> 09:30 PM - 12:00 AM</div>
  //                 </div>
  //               </div>
  //             </SwiperSlide>

  //             <SwiperSlide className='swiper-slide text-center'>
  //               <div className="card session-card blue">
  //                 <div className="scard-header">
  //                   <div>Thoolika</div>
  //                   <div>തൂലിക</div>
  //                 </div>
  //                 <div className="scard-heading">
  //                   <h2>Beyond Fear: True stories on life in the Indian Armed Forces</h2>
  //                 </div>
  //                 <div className="session-type">
  //                   <div className="session-type-label">Discussion</div>
  //                 </div>
  //                 <div className="session-members">
  //                   <ul>
  //                     <li>Major General Lan Cardozo</li>
  //                     <li>Bindu Amat</li>
  //                     <li>Dr. (Col) P. S James</li>
  //                   </ul>
  //                 </div>
  //                 <div className="sponsor-logo-ruppe-icon">
  //                   <div>Sponsor Logo</div>
  //                   <a href="#" className="icon-ruppee"></a>
  //                 </div>
  //                 <div className="date-and-time">
  //                   <div className="date"><span></span> 10 Jan 2024</div>
  //                   <div className="time"><span></span> 09:30 PM - 12:00 AM</div>
  //                 </div>
  //               </div>
  //             </SwiperSlide>

  //             <SwiperSlide className='swiper-slide text-center'>
  //               <div className="card session-card orange">
  //                 <div className="scard-header">
  //                   <div>Thoolika</div>
  //                   <div>തൂലിക</div>
  //                 </div>
  //                 <div className="scard-heading">
  //                   <h2>Beyond Fear: True stories on life in the Indian Armed Forces</h2>
  //                 </div>
  //                 <div className="session-type">
  //                   <div className="session-type-label">Discussion</div>
  //                 </div>
  //                 <div className="session-members">
  //                   <ul>
  //                     <li>Major General Lan Cardozo</li>
  //                     <li>Bindu Amat</li>
  //                     <li>Dr. (Col) P. S James</li>
  //                   </ul>
  //                 </div>
  //                 <div className="sponsor-logo-ruppe-icon">
  //                   <div>Sponsor Logo</div>
  //                   <a href="#" className="icon-ruppee"></a>
  //                 </div>
  //                 <div className="date-and-time">
  //                   <div className="date"><span></span> 10 Jan 2024</div>
  //                   <div className="time"><span></span> 09:30 PM - 12:00 AM</div>
  //                 </div>
  //               </div>
  //             </SwiperSlide>

  //             <SwiperSlide className='swiper-slide text-center'>
  //               <div className="card session-card orange">
  //                 <div className="scard-header">
  //                   <div>Thoolika</div>
  //                   <div>തൂലിക</div>
  //                 </div>
  //                 <div className="scard-heading">
  //                   <h2>Beyond Fear: True stories on life in the Indian Armed Forces</h2>
  //                 </div>
  //                 <div className="session-type">
  //                   <div className="session-type-label">Discussion</div>
  //                 </div>
  //                 <div className="session-members">
  //                   <ul>
  //                     <li>Major General Lan Cardozo</li>
  //                     <li>Bindu Amat</li>
  //                     <li>Dr. (Col) P. S James</li>
  //                   </ul>
  //                 </div>
  //                 <div className="sponsor-logo-ruppe-icon">
  //                   <div>Sponsor Logo</div>
  //                   <a href="#" className="icon-ruppee"></a>
  //                 </div>
  //                 <div className="date-and-time">
  //                   <div className="date"><span></span> 10 Jan 2024</div>
  //                   <div className="time"><span></span> 09:30 PM - 12:00 AM</div>
  //                 </div>
  //               </div>
  //             </SwiperSlide>

  //             <SwiperSlide className='swiper-slide text-center'>
  //               <div className="card session-card orange">
  //                 <div className="scard-header">
  //                   <div>Thoolika</div>
  //                   <div>തൂലിക</div>
  //                 </div>
  //                 <div className="scard-heading">
  //                   <h2>Beyond Fear: True stories on life in the Indian Armed Forces</h2>
  //                 </div>
  //                 <div className="session-type">
  //                   <div className="session-type-label">Discussion</div>
  //                 </div>
  //                 <div className="session-members">
  //                   <ul>
  //                     <li>Major General Lan Cardozo</li>
  //                     <li>Bindu Amat</li>
  //                     <li>Dr. (Col) P. S James</li>
  //                   </ul>
  //                 </div>
  //                 <div className="sponsor-logo-ruppe-icon">
  //                   <div>Sponsor Logo</div>
  //                   <a href="#" className="icon-ruppee"></a>
  //                 </div>
  //                 <div className="date-and-time">
  //                   <div className="date"><span></span> 10 Jan 2024</div>
  //                   <div className="time"><span></span> 09:30 PM - 12:00 AM</div>
  //                 </div>
  //               </div>
  //             </SwiperSlide>
  //             </div>
  //           </Swiper>
  //           <div className="swiper-rightarrow">
  //             <div className="swiper-button-next speakers-next"></div>
  //           </div>
  
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );


  // const News = () => (
  //   <div className="news-wrpr">
  //     <div className="container">
  //       <div className="row">
  //         <div className="col-md-12 p-0 text-center">
  //           <h2 data-aos="fade-up" data-aos-delay="100">
  //             <span className="speaker-pattern"></span>KLF News<span className="speaker-pattern"></span>
  //           </h2>
  //         </div>
  //         <div className="col-md-12 pt-3 pb-2 mt-0" style={{position: 'relative'}}>
  //           <div className="swiper-leftarrow">
  //             <div className="swiper-button-prev news-prev"></div>
  //           </div>
  //           <Swiper
  //              spaceBetween={100}
  //              speed={500}
  //              loop={true}
  //              slidesPerView={3}
  //              autoplay={{
  //              delay: 2500,
  //              disableOnInteraction: false,
  //              }}
  //              pagination={{
  //               el: '.swiper-pagination.news-pagination',
  //               //dynamicBullets: true,
  //               clickable: true,
  //              }}
              
  //              navigation={{
  //               nextEl: ".swiper-button-next.news-next",
  //               prevEl: ".swiper-button-prev.news-prev",
  //              }}
  //              modules={[Autoplay, Pagination, Navigation]}
  //              className="swiper-container news-slide"
  //              breakpoints={{
  //               320: {
  //                 slidesPerView: 1,
  //                 spaceBetween: 20
  //               },
  //               // when window width is >= 480px
  //               480: {
  //                 slidesPerView: 3,
  //                 spaceBetween: 30
  //               },
  //               // when window width is >= 640px
  //               640: {
  //                 slidesPerView: 3,
  //                 spaceBetween: 40
  //               }
  //              }}
  //           >
  //             <div class="swiper-wrapper">
  //             <SwiperSlide className='swiper-slide text-center'>
  //               <div className="card news-card">
  //                 <img src={newsOne} alt="News 1" />
  //                 <h2>KLF BUS ACTIVITY: Radio Mango receives Golden Mikes Radio Advertisement Award</h2>
  //                 <p>Kerala Literature festival is one of Asia`s largest literature festivals. This year KLF at Kozhikode beach marked its 6th edition, the promotion was majorly done by Radio Mango through its Bus Activity Campaign.</p>
  //               </div>
  //             </SwiperSlide>

  //             <SwiperSlide className='swiper-slide text-center'>
  //               <div className="card news-card">
  //                 <img src={newsInfotwo} alt="News 1" />
  //                 <h2>The 6th Kerala Literature Festival Ended On A High Note</h2>
  //                 <p>On the stage, Prof. Satchidanandan announced the next year`s date of the KLF. Next year, KLF will be held on the 11th, 12th, 13th, and 14th of January....</p>
  //               </div>
  //             </SwiperSlide>

  //             <SwiperSlide className='swiper-slide text-center'>
  //               <div className="card news-card">
  //                 <img src={newsInfothree} alt="News 1" />
  //                 <h2>Personality Cults Are Never Permanent: Ramachandra Guha</h2>
  //                 <p>"In politics, the cult of personality hurts the party," Ramachandra Guha, a well-known Indian historian, environmentalist, and writer, said at the 6th edition of KLF</p>
  //               </div>
  //             </SwiperSlide>
  //             <SwiperSlide className='swiper-slide text-center'>
  //               <div className="card news-card">
  //                 <img src={newsInfofour} alt="News 1" />
  //                 <h2>Kannada In Indian Literature: Translation And Its Possibilities</h2>
  //                 <p>A session held at the Kerala Literature Festival on January 12 glimpsed an inspiring talk by renowned Kannada writer Banu Mushtaq and author and filmmaker Prathiba Nandakumar...</p>
  //               </div>
  //             </SwiperSlide>

  //             <SwiperSlide className='swiper-slide text-center'>
  //               <div className="card news-card">
  //                 <img src={newsInfofive} alt="News 1" />
  //                 <h2>Scientific Temper And Human Consciousness: A Nobel Laureate’s Perspective</h2>
  //                 <p>The speaker, Ada E. Yonath, is a crystallographer and the first woman from the Middle East to win a Nobel Prize for chemistry in 2009. She began the session by responding to Dr. C. N. Ramchand’s questions about how she came to receive the Nobel Prize....</p>
  //               </div>
  //             </SwiperSlide>
  //             </div>

            
  //             <div className="swiper-pagination-wrpr">
  //               <div className="swiper-pagination news-pagination"></div>
  //             </div>
  //           </Swiper>

  //             <div className="swiper-rightarrow">
  //               <div className="swiper-button-next news-next"></div>
  //             </div>

  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );


  // const Videosssss = () => (
  //   <div className="news-wrpr">
  //     <div className="container">
  //       <div className="row">
  //         <div className="col-md-12 p-0 text-center">
  //           <h2 data-aos="fade-up" data-aos-delay="100">
  //             <span className="speaker-pattern"></span>VIDEOS<span className="speaker-pattern"></span>
  //           </h2>
  //         </div>

  //         <div className="col-md-12 p-0 videos-wrpr" style={{position: 'relative'}}>
  //           <div className="swiper-leftarrow">
  //             <div className="swiper-button-prev video-prev"></div>
  //           </div>
  //           <Swiper
  //              spaceBetween={100}
  //              speed={500}
  //              loop={true}
  //              slidesPerView={3}
  //              autoplay={{
  //              delay: 2500,
  //              disableOnInteraction: false,
  //              }}
  //              pagination={{
  //               el: '.swiper-pagination.video-pagination',
  //               //dynamicBullets: true,
  //               clickable: true,
  //              }}
              
  //              navigation={{
  //               nextEl: ".swiper-button-next.video-next",
  //               prevEl: ".swiper-button-prev.video-prev",
  //              }}
  //              modules={[Autoplay, Pagination, Navigation]}
  //              className="swiper-container video-slide"
  //              breakpoints={{
  //               320: {
  //                 slidesPerView: 1,
  //                 spaceBetween: 20
  //               },
  //               // when window width is >= 480px
  //               480: {
  //                 slidesPerView: 3,
  //                 spaceBetween: 30
  //               },
  //               // when window width is >= 640px
  //               640: {
  //                 slidesPerView: 3,
  //                 spaceBetween: 40
  //               }
  //              }}
  //           >
  //             <div class="swiper-wrapper">
  //             <SwiperSlide className='swiper-slide venobox' data-vbtype="video" data-autoplay="true" href="https://www.youtube.com/watch?v=zH5R-bgLmKo">
  //               <img class="video-img" src="https://img.youtube.com/vi/zH5R-bgLmKo/hqdefault.jpg" />
	// 						  <div class="video-title">"I had one meeting with Rahul Gandhi in eight years," says Mani Shankar Aiyar</div>
  //             </SwiperSlide>

  //             </div>

            
  //             <div className="swiper-pagination-wrpr">
  //               <div className="swiper-pagination news-pagination"></div>
  //             </div>
  //           </Swiper>

  //             <div className="swiper-rightarrow">
  //               <div className="swiper-button-next news-next"></div>
  //             </div>

  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );


  // const PhotoGalleryyyyy = () => (
  //   <div className="photogallery-wrpr">
  //     <div className="container">
  //       <div className="row">
  //         <div className="col-md-12 p-0 text-center">
  //           <h2 data-aos="fade-up" data-aos-delay="100">
  //             <span className="speaker-pattern"></span>Photo Gallery<span className="speaker-pattern"></span>
  //           </h2>
  //         </div>
  //         <div className="col-md-12 pt-5 pb-5 mt-5" style={{position: 'relative'}}>
  //           <div className="swiper-leftarrow">
  //             <div className="swiper-button-prev gallery-prev"></div>
  //           </div>
  //           <Swiper
  //             modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
  //             effect='coverflow'
  //             grabCursor={true}
  //             centeredSlides={true}
  //             loop={true}
  //             speed={500}
  //             slidesPerView={3}
  //             coverflowEffect={{
  //               rotate: 10,
  //               stretch: 0,
  //               depth: 300,
  //               modifier: 3,
  //               slideShadows: true,
  //             }}
  //             autoplay={{
  //               delay: 2500,
  //               disableOnInteraction: false,
  //             }}
  //             pagination={{
  //               el: '.swiper-pagination.gallery-pagination',
  //               clickable: true,
  //             }}
  //             navigation={{
  //               nextEl: '.gallery-next',
  //               prevEl: '.gallery-prev',
  //             }}
  //             className="swiper-container photogallery-slide"
  //             data-aos="fade-up"
  //             data-aos-delay="300"

  //             breakpoints={{
  //               320: {
  //                 slidesPerView: 1,
  //                 spaceBetween: 20
  //               },
  //               // when window width is >= 480px
  //               480: {
  //                 slidesPerView: 3,
  //                 spaceBetween: 30
  //               },
  //               // when window width is >= 640px
  //               640: {
  //                 slidesPerView: 3,
  //                 spaceBetween: 40
  //               }
  //              }}
  //           >
  //             <SwiperSlide className='swiper-slide'>
  //               <img src={p1} alt="Gallery 1" />
  //             </SwiperSlide>
  //             <SwiperSlide className='swiper-slide'>
  //               <img src={newsTwo} alt="Gallery 2" />
  //             </SwiperSlide>
  //             <SwiperSlide className='swiper-slide'>
  //               <img src={newsThree} alt="Gallery 3" />
  //             </SwiperSlide>
  //             <SwiperSlide className='swiper-slide'>
  //               <img src={newsFour} alt="Gallery 4" />
  //             </SwiperSlide>
  //           </Swiper>
  //           <div className="swiper-rightarrow">
  //             <div className="swiper-button-next gallery-next"></div>
  //           </div>
  //           <div className="swiper-pagination-wrpr">
  //             <div className="swiper-pagination gallery-pagination"></div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  const KLFCounts = () => {
    const [counts, setCounts] = useState({
      discussions: 0,
      speakers: 0,
      venues: 0
    });
  
    const targetCounts = {
      discussions: 300,
      speakers: 500,
      venues: 6
    };
  
    useEffect(() => {
      // Simulate counting animation
      const interval = setInterval(() => {
        setCounts(prevCounts => ({
          discussions: Math.min(prevCounts.discussions + 10, targetCounts.discussions),
          speakers: Math.min(prevCounts.speakers + 20, targetCounts.speakers),
          venues: Math.min(prevCounts.venues + 1, targetCounts.venues)
        }));
      }, 50);
  
      return () => clearInterval(interval);
    }, []);
  
    const counters = [
      { key: 'discussions', text: "Discussions", delay: 300 },
      { key: 'speakers', text: "Speakers", delay: 500 },
      { key: 'venues', text: "Venues", delay: 700, lastCount: true }
    ];
  
    return (
      <div className="klf-counts-wrpr" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <img 
                data-aos="fade-up" 
                data-aos-delay="100" 
                src={PatternOne} 
                alt="Pattern for counter section"
              />
            </div>
            <div className="col-md-8">
              <div className="row loader" id="counters_2">
                {counters.map((counter) => (
                  <div 
                    key={counter.key} 
                    className="col-md-4" 
                    data-aos="fade-up" 
                    data-aos-delay={counter.delay}
                  >
                    <div className={`counter-cntnr count1 ${counter.lastCount ? 'lastcount' : ''}`}>
                      <div className="counter-wrpr">
                        <div>
                          <div className="counter plus">
                            {counts[counter.key]}
                          </div>
                        </div>
                      </div>
                      <div className="counter-text">
                        {counter.text}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-2" data-aos="fade-up" data-aos-delay="900">
              <img 
                src={PatternTwo}
                alt="Pattern for counter section 2"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  


