import React, { useState, useRef } from "react";
import userQueries from '../../queries/userQueries';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
const Createphotogallery = () => {
    const [susmsg, setSusmsg] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state
    const [imagePreview, setImagePreview] = useState(null);
    const navigate = useNavigate();
    const imageRef = useRef(null);
    const [imageError, setImageError] = useState('');

    const { register, handleSubmit, getValues,formState: { errors } } = useForm();

    const MAX_FILE_SIZE = 350 * 1024; // 350KB in bytes


    const AddInfodata = userQueries.useCreatephotogalleryMutation((response) => {
       
        if (response?.message === "Success") {
            setSusmsg(true);
            setLoading(false); // Stop loader
            alert(response.data.publishstatus === 0 ? "Saved as draft" : "Published successfully");
            navigate('/photogallerylist');
        }
        
    });

    const handleFormSubmit = async (data, publishStatus) => {
        setSusmsg(false);
        setLoading(true); // Start loader

        const allowedTypes = ['image/jpeg', 'image/jpg'];

        // Check if file exists
        if(publishStatus === 1)
            {
               
    
                if (!imageRef.current?.files[0]) {
                    setImageError("Image is required.");
                    setLoading(false);
                    return false;
                }
    
                if (!allowedTypes.includes(imageRef.current?.files[0].type)) {
                    setImageError("Only JPEG, JPG files are allowed.");
                    setLoading(false);
                    return false;
                }
                if (imageRef.current?.files[0].file.size > MAX_FILE_SIZE) {
                    setImageError("Image size should not exceed 350KB.");
                    setLoading(false);
                    return false;
                }
                
            }

        setImageError(""); // Clear error if all validations pass

        const formData = new FormData();
        formData.append('title', data.title);
        formData.append('publishstatus', publishStatus);
        formData.append('displayorder', data.displayorder !== null ? data.displayorder : '1');

        if (imageRef.current?.files[0]) {
            formData.append('photogallery', imageRef.current.files[0]);
        }

        try {
            const createdUser = await AddInfodata.mutateAsync(formData);
            console.log(createdUser);
           
        } catch (error) {
            console.error('Error occurred:', error);
            setLoading(false); // Stop loader if error occurs
        }
    };
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            if (!allowedTypes.includes(file.type)) {
                setImageError("Only JPEG, JPG, and PNG files are allowed.");
                setImagePreview(null);
                return;
            }

            setImageError('');
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setImagePreview(null);
        }
    };
    return (
        <>
            <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
                Create Photo Gallery
            </h4>
            <form className="max-w-lg mx-auto grid grid-cols-2 gap-4">

                <div className="mb-5 col-span-2">
                    <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Title</label>
                    <input
                        type="text"
                        id="title"
                        {...register("title", { required: "Title is required" })}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                    {errors.title && <div className="text-red-600 text-s">{errors.title.message}</div>}
                </div>

                <div className="mb-5 col-span-2">
                    <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Upload Image</label>
                        {imagePreview && (
                            
                                
                            <div className="relative w-48 h-48 mb-4">
                                <img 
                                    src={imagePreview} 
                                    alt="Preview" 
                                    className="w-full h-full object-cover rounded-lg shadow-md"
                                />
                            </div>
                        
                    )}
                
                    <input 
                        type="file" 
                        id="image" 
                        ref={imageRef}
                        onChange={handleImageChange}
                        accept="image/*"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    {imageError && <div className="text-red-600 text-s">{imageError}</div>}
                    <span className="form-text text-warning small">
                    Recommended: Use landscape images under 350 KB.
                  </span>
                </div>

                <div className="mb-5">
                    <label htmlFor="displayorder" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Display Order</label>
                    <input 
                        type="number" 
                        id="displayorder" 
                        {...register("displayorder", { required: false })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    
                </div>


                <button 
                    type="button" 
                    onClick={() => handleFormSubmit(getValues(), 0)}
                    className="col-span-2 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-3"
                >
                    Save as draft
                </button>

                <button 
                    type="button"
                    onClick={handleSubmit((data) => handleFormSubmit(data, 1))}
                    className="col-span-2 w-full text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Save and publish
                </button>

                {susmsg && <div className="text-green-600 text-lg ml-6 text-center inline-block whitespace-nowrap">
                    Photo Gallery created successfully
                </div>}

                {/* Loader */}
                {loading && (
                    <Loader />
                )}
            </form>
        </>
    );
};

export default Createphotogallery;
