import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CryptoJS from 'crypto-js';
import { countriesData } from './countryData';
import Navbar from './Navbar';
import Footer from './Footer';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import InitModal from './initModal';
import authQueries from '../queries/authQueries';
import siteQueries from '../queries/siteQueries';
import { useNavigate } from 'react-router-dom';
import BootstrapLoader from '../components/Loader/BootstrapLoader';
import { useScrollToTop } from '../hooks/ScrollTop';
// import StudentMember from './StudentGroupMemberModal';
import DelegateMember from './DelegateGroupMemberModal';
import AgeInput from '../components/Agedrop/AgeSelect';
import FeedbackModal from './FeedbackModal';
import groupQueries from '../queries/groupQueries';

// CCAvenue credentials (Warning: Exposing these on the client side is not secure)
const WORKING_KEY = process.env.REACT_APP_CCAVENUE_WORKING_KEY;
const ACCESS_CODE = process.env.REACT_APP_CCAVENUE_ACCESS_CODE;
const MERCHANT_ID = process.env.REACT_APP_CCAVENUE_MERCHANT_ID;

const CustomAlert = ({ title, message }) => (
  <div className="alert alert-success" role="alert">
    <h4 className="alert-heading">{title}</h4>
    <p>{message}</p>
  </div>
);
const schema = yup.object().shape({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    phone: yup.string().min(10, 'Phone number must be at least 10 digits').required('Phone number is required'),
    dob: yup.date().typeError('Please enter a valid date').required('Date of birth is required'),
    gender: yup.string().required('Gender is required'),
    termsAccepted: yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
  });
export default function StudentGroup() {

  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit, reset, formState: { errors, isValid }, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      dob: '2024-11-02 00:00:00+00',
      gender: '',
      pin_code: '',
      country: "India",
      state: 'Kerala',
      address:'',
      college:'',
      district:'',
      termsAccepted: false
    },
    mode: 'onChange'
  });

  const navigate = useNavigate();

    const handleTerms = () => {
        navigate('/terms-conditions');
    }


  const [submitted, setSubmitted] = useState(false);
  const [feedBacksubmit, setFeedBacksubmit] = React.useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [states, setStates] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [userCreationStatus, setUserCreationStatus] = useState(null);
  const watchAllFields = watch();
  const watchCountry = watch("country");
  const [code, setCode] = useState('in');
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [terms, setTerms] = useState(false)
  const [savedData, setSavedData] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [showError, setShowError] = useState(false);
  const [isFeedbackOpen, setFeedbackOpen] = useState(false);
  const handleFeedbackOpen = () => setFeedbackOpen(true);
  const handleFeedbackClose = () => setFeedbackOpen(false);

  // const [leaderData, setLeaderData] = useState(null);

  useScrollToTop()

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    const allFieldsFilled = Object.values(watchAllFields).every(field => field !== '' && field !== false);
    setIsFormFilled(allFieldsFilled);
  }, [watchAllFields]);

  useEffect(() => {
    const selectedCountry = countriesData.find(country => country.name === watchCountry);
    if (selectedCountry) {
      // Sort states alphabetically before setting them
      const sortedStates = [...selectedCountry.states].sort((a, b) => a.localeCompare(b));
      setStates(sortedStates);
      setValue("state", ""); // Reset state when country changes
    } else {
      setStates([]);
    }
  }, [watchCountry, setValue]);

    // Sort the countries alphabetically
    const sortedCountries = [...countriesData].sort((a, b) => 
    a.name.localeCompare(b.name)
    );

    const qrCreate = groupQueries.useGroupQrcreateMutation(async (response) => {
    });

  const handleUserPaidStatus = async () => {
    const registrationData = JSON.parse(sessionStorage.getItem('registrationData'));
    const paymentRes = JSON.parse(sessionStorage.getItem('paymentRes'));
    const groupdata = JSON.parse(sessionStorage.getItem('groupdata'));
    const validUsers = groupdata?.filter(user => user?.email && user?.phone);
    if (paymentRes) {
      const response = await userPaidstatus.mutateAsync(registrationData);
      if (response?.success) {
        for (const user of validUsers) {
        await ZohoCrm.mutateAsync({
          "firstName": user.first_name,
          "lastName": user.last_name,
          "email": user.email,
          "Phone": user.phone,
          "Zip_Code": user.pin_code,
          "Gender": user.gender,
          "State": user.state,
          "City": user.district,
          "Full_Address": user.address,
          "Amount": 699,
          "Type_Of_Registration":["Students"]
        });
      }
        setIsLoading(false)
        sessionStorage.setItem('qrdata', JSON.stringify(response));
        sessionStorage.removeItem('paymentRes');
        qrCreate.mutateAsync({id:response?.userid});
        navigate('/success');
      }else{

        sessionStorage.removeItem('paymentRes');
        navigate('/failed');
      }
      // console.log("registrationData", registrationData);
    }
  };

  useEffect(() => {
    handleUserPaidStatus();
  }, []);

  const encrypt = (plainText) => {
    const key = CryptoJS.MD5(WORKING_KEY).toString();
    const iv = CryptoJS.enc.Hex.parse('000102030405060708090a0b0c0d0e0f');
    const encrypted = CryptoJS.AES.encrypt(plainText, CryptoJS.enc.Hex.parse(key), { 
      iv: iv, 
      mode: CryptoJS.mode.CBC, 
      padding: CryptoJS.pad.Pkcs7 
    });
    return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
  };

  const decrypt = (encryptedText) => {
    const key = CryptoJS.MD5(WORKING_KEY).toString();
    const iv = CryptoJS.enc.Hex.parse('000102030405060708090a0b0c0d0e0f');
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Hex.parse(encryptedText) },
      CryptoJS.enc.Hex.parse(key),
      { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
  };



  const userPaidstatus = siteQueries.useDelagatePaidstatusMutation(async (response) => {
    
  });

  const createGroup = siteQueries.useGroupCreateMutation(async (response) => {
    
  });

  const ZohoCrm = authQueries.useZohoCrmMutation(async (response) => {
    
  });

//   const createGroupData = async (users) => {
//     try {
//         const bulkUserCreationResponse = await createGroup.mutateAsync(users);
//         await localStorage.removeItem('delegateMemberData');
//     } catch (error) {
//         console.error("Error during bulk user creation:", error);
//         setErrorMessage('An error occurred while creating group members.');
//     }
// };

const marketing_id = localStorage.getItem('marketingId');

const onSubmit = async (data) => {
    if (!savedData) {
        setErrorMessage('Please add at least one group member before submitting.');
        return;
    }

    try {
        const response = await userPaidstatus.mutateAsync(data);
        if (response?.success === true) {
            alert("User already exists");
            return;
        }
    } catch (error) {
        console.error("Error checking user existence:", error);
        setErrorMessage('An error occurred while checking if the user exists.');
        return;
    }

    const amountPerpersone = 699;
    const totalAmount = amountPerpersone + (savedData.length * amountPerpersone);
    // const submissionData = { ...data, username: data.email, role: "DELEGATE" };
    const orderId = `STUDENT_GROUP_DELEGATE_${Date.now()}`;
    
    const leaderData = {
        ...data,
        order_id: orderId,
        username: data?.email,
        role: "STUDENT",
        amount: amountPerpersone,
        age: data?.age?.value,
        leader: 1,
        marketing_id

        // dob: new Date(data.dob).toLocaleDateString('en-IN'),
    }; 
    // setLeaderData(leaderData);
    
    const usersToCreate = [
        leaderData,
        ...savedData.map(member => ({
            ...member,
            order_id: orderId,
            username: member.email || member.first_name,
            role: "STUDENT",
            amount: amountPerpersone,
            marketing_id
        })),
    ];

    setIsLoading(true);
    await createGroup.mutateAsync(usersToCreate);
    await localStorage.removeItem('delegateMemberData');

    

    let address = data.address || 'Kerala Lit Festival';
    const redirectUrl = `${window.location.origin}${window.location.pathname}`;
    const cancelUrl = `${window.location.origin}${window.location.pathname}?status=cancelled`;

    const requestData = `merchant_id=${MERCHANT_ID}&order_id=${orderId}&currency=INR&amount=${totalAmount}&redirect_url=${redirectUrl}&cancel_url=${cancelUrl}&billing_name=${leaderData.first_name} ${leaderData.last_name}&billing_address=${address}&billing_city=${leaderData.district}&billing_state=${leaderData.state}&billing_zip=${leaderData.pin_code}&billing_country=${leaderData.country}&billing_tel=${leaderData.phone}&billing_email=${leaderData.email}`;

    sessionStorage.setItem('registrationData', JSON.stringify(leaderData));
    sessionStorage.setItem('paymentRes', JSON.stringify(leaderData));
    sessionStorage.setItem('groupdata', JSON.stringify(usersToCreate));

    const encryptedData = encrypt(requestData);

    const form = document.createElement('form');
    form.method = 'POST';
    form.action = 'https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction';

    const encReqField = document.createElement('input');
    encReqField.type = 'hidden';
    encReqField.name = 'encRequest';
    encReqField.value = encryptedData;

    const accessCodeField = document.createElement('input');
    accessCodeField.type = 'hidden';
    accessCodeField.name = 'access_code';
    accessCodeField.value = ACCESS_CODE;

    form.appendChild(encReqField);
    form.appendChild(accessCodeField);

    document.body.appendChild(form);
    form.submit();
    setIsLoading(false);
   
};

  const handleSave = (newMember) => {
    setSavedData((prevData) => [...prevData, newMember]); // Append new data to the existing array
  };

  const handleClick = () => {
    if (!firstName || !lastName || !email || !phone) {
      setShowError(true); 
      return;
    }
    setShowError(false); 
  };

  const handleRemove = (indexToRemove) => {
    const updatedData = savedData.filter((_, index) => index !== indexToRemove);
    setSavedData(updatedData); 
    localStorage.setItem('delegateMemberData', JSON.stringify(updatedData)); 
  };
  
 

  return (
    <>
    
      <Navbar handleOpen={handleOpen} handleClose={handleClose} />
      <div className="innerbanner registration container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <span className="logo-strips"></span>
              <h2>Student Group<span>Registration</span></h2>
            </div>
          </div>
        </div>
      </div>
      
      {isLoading ? (
      <BootstrapLoader />

       ) : (
        <div className="container">
        <div className="row">
          <div className="col-md-7 pt-5 pb-5">
            {!paymentStatus ? (
              <form onSubmit={handleSubmit(onSubmit)} className="delegate-form pt-5 pb-5">
                <div className="form-group">
                <div className="row">
                  <div className="col-md-6">
                    
                    <Controller
                      name="first_name"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}
                          onChange={(e) => {
                            field.onChange(e); // Trigger the Controller's change handler
                            setFirstName(e.target.value);
                            setShowError(false);  
                          }}
                          type="text"
                          placeholder="First name*"
                        />
                      )}
                    />
                    {errors.first_name && <div className="invalid-feedback">{errors.first_name.message}</div>}
                  </div>
                  <div className="col-md-6">
                    <Controller
                      name="last_name"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`form-control ${errors.last_name ? 'is-invalid' : ''}`}
                          onChange={(e) => {
                            field.onChange(e); 
                            setLastName(e.target.value);
                          }}
                          type="text"
                          placeholder="Last name*"
                        />
                      )}
                    />
                    {errors.last_name && <div className="invalid-feedback">{errors.last_name.message}</div>}
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-md-6">
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                          onChange={(e) => {
                            field.onChange(e); 
                            setEmail(e.target.value);
                          }}
                          type="email"
                          placeholder="Email*"
                        />
                      )}
                    />
                    {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                  </div>
                  <div className="col-md-6">
                    
                    <Controller
                      name="phone"
                      control={control}
                      className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                      render={({ field: { onChange } }) => (
                        <PhoneInput
                            className={`phoneinput ${errors.phone ? 'is-invalid' : ''}`}
                            enableSearch={true}
                            country={code}
                            // value={appStore?.appData?.mobile}
                            onChange={(value, country, e, formattedValue) => {
                              setCode(country.countryCode)
                              onChange(value)
                              setPhone(e.target.value);
                            }}
                        /> 
                      )}
                      rules={{ required: true }}
                    />
                    {errors.phone && <div className="invalid-feedback">{errors.phone.message}</div>}
                  </div>
                </div>

                <div className="row mt-5">
               
                <AgeInput control={control} errors={errors} />
                  <div className="col-md-3 gender-wrpr">
                    <Controller
                      name="gender"
                      control={control}
                      render={({ field }) => (
                        <select
                          {...field}
                          className={`form-control ${errors.gender ? 'is-invalid' : ''}`}
                        >
                          <option value="">Select Gender</option>
                          <option value="MALE">MALE</option>
                          <option value="FEMALE">FEMALE</option>
                          <option value="OTHERS">OTHERS</option>
                          <option value="PREFER NOT TO RESPOND">PREFER NOT TO RESPOND</option>
                        </select>
                      )}
                    />
                    {errors.gender && <div className="invalid-feedback">{errors.gender.message}</div>}
                  </div>
                  {/* <div className="col-md-4">
                    <label htmlFor="pin_code">Pin Code:</label>
                    <Controller
                      name="pin_code"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`form-control ${errors.pin_code ? 'is-invalid' : ''}`}
                          type="text"
                          placeholder="Enter pin code"
                        />
                      )}
                    />
                    {errors.pin_code && <div className="invalid-feedback">{errors.pin_code.message}</div>}
                  </div> */}

                <div className="col-md-4">
                <div className='countryselect-wrpr'>
                  <Controller
                    name="country"
                    control={control}
                    render={({ field }) => (
                      <select
                        {...field}
                        className={`form-control ${errors.country ? 'is-invalid' : ''}`}
                      >
                        <option value="">Select Country</option>
                        {sortedCountries.map((country, index) => (
                          <option key={index} value={country.name}>{country.name}</option>
                        ))}
                      </select>
                    )}
                  />
                  </div>
                  {errors.country && <div className="invalid-feedback">{errors.country.message}</div>}
                </div>
                </div>

               
              <div className="row mt-5">

                <div className="col-md-4 state-wrpr">
                  
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <select
                        {...field}
                        className={`form-control ${errors.state ? 'is-invalid' : ''}`}
                        disabled={!watchCountry}
                      >
                        <option value="">Select State</option>
                        {states.map((state, index) => (
                          <option key={index} value={state}>{state}</option>
                        ))}
                      </select>
                    )}
                  />
                  {errors.state && <div className="invalid-feedback">{errors.state.message}</div>}
                </div>

                <div className="col-md-4 district-wrpr">
                 
                    <Controller
                      name="district"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`form-control ${errors.pin_code ? 'is-invalid' : ''}`}
                          type="text"
                          placeholder="Enter District"
                        />
                      )}
                    />
              
                </div>
                  <div className="col-md-6">
                    <div className="text-end">
                    
                      <button
                            className="btn btn-primary btn-registration mt-5 me-2"
                            type="submit"
                            disabled={!isValid || savedData.length === 0}
                        >
                      Register & Pay <i className="icon-arrow-right1"></i>
                    </button>
                    
                        {/* <DelegateMember savedData={savedData} onSave={handleSave} /> */}
                        <div
                            style={{ display: 'inline-block', cursor: 'pointer' }}
                            onClick={handleClick}>
                            <span 
                            // onClick={handleClick}
                            style={{ pointerEvents: firstName && lastName && email && phone ? 'auto' : 'none' }} >
                            <DelegateMember savedData={savedData} onSave={handleSave} />
                            </span>
                            </div>
                            </div>
                            {showError && <p style={{ color: 'red' }}>Please fill the leader data before proceeding.</p>}
                          </div>
                        </div>
                        </div>

                        <div className="row mt-5">
                  <div className="col-md-6">
                    <label>
                      <Controller
                        name="termsAccepted"
                        control={control}
                        render={({ field }) => (
                          <input
                          onChange={setTerms(true)}
                            type="checkbox"
                            {...field}
                            className={errors.termsAccepted ? 'is-invalid' : ''}
                          />
                        )}
                      />
                      <a className="terms"  target='_blank' href='/terms-conditions'>Terms & Conditions</a>
                    </label>
                    {errors.termsAccepted && <div className="invalid-feedback">{errors.termsAccepted.message}</div>}
                  </div>

                </div>

                <div className="row mt-5">
                  <div  className="col-md-6">

                   
                  </div>
                  <div className="col-md-6" style={{display:'none'}}>
                    <div className="text-end">
                    <a onClick={handleFeedbackOpen} className="btn btn-transparent btn-feedback">
                    Need help ? Please contact support
                    </a>
                    </div>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-md-12">
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                  </div>
                </div>
              </form>
            ) : (
              <div>
                <h2 className="text-xl font-semibold mb-2">Payment Response</h2>
                <pre className="bg-gray-100 p-4 rounded">
                  {JSON.stringify(paymentStatus, null, 2)}
                </pre>
              </div>
            )}
          </div>
           
     <div className="col-md-5 pt-5 pb-5">
     <div class="groupinfo-wrpr sticky-top">
				<div class="card">
					<div class="card-body">
      <h5 className='text-black'>Group Information</h5>
        {firstName && lastName && email && phone && ( 
       <div class="group-list-wrpr">
       <ol class="group-ol">
         <li class="group-admin">
          <span>
             {firstName} {lastName}
                    <DelegateMember savedData={savedData} onSave={handleSave} showIcon={true}/>
                    
                    </span>
                    </li>
                       
          {savedData.map((member, index) => (
            <div key={index}>
             <li>  
             <span>
                {member.first_name} {member.last_name}
                <a 
                  class="list-action icon-remove"
                  onClick={() => handleRemove(index)} 
                  title="Remove member">
                  
                </a>
              </span>

                </li>
             </div>
          ))}
          </ol>
        </div>
      )}
      </div>
      </div>
      </div>
    </div>
          
        </div>
      </div>
       )}
      {submitted && (
        <CustomAlert 
          title="Success" 
          message="Your registration has been submitted successfully!"
        />
      )}
      {feedBacksubmit && (
        <CustomAlert 
          title="Success" 
          message="Your feedback has been submitted successfully!"
        />
      )}
      <InitModal isOpen={isOpen} onClose={handleClose} />
      <Footer />
      <FeedbackModal  setFeedBacksubmit={setFeedBacksubmit}  isOpen={isFeedbackOpen} onClose={handleFeedbackClose} />
    </>
  );
}

