import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Logo from '../../images/logo/klf-logo1.png';
import { usePermissions } from '../Permission/AdminPermission';

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [showfeaturedSubMenu, setShowfeaturedSubMenu] = useState(false);
  const [showZohoSubMenu, setShowZohoSubMenu] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  );
  const { hasPermission } = usePermissions();


  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.querySelector('body')?.classList.add('sidebar-expanded');
    } else {
      document.querySelector('body')?.classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  return (
    <aside
      ref={sidebar}
      className={`absolute left-0 top-0 z-9999 flex h-screen w-72.5 flex-col overflow-y-hidden bg-black duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
    >
      {/* <!-- SIDEBAR HEADER --> */}
      <div className="h-22 bg-white flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5">
        <NavLink to="/dashboard">
          <img src={Logo} alt="Logo" />
        </NavLink>


        <button
          ref={trigger}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="block lg:hidden"
        >
          <svg
            className="fill-current"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
              fill=""
            />
          </svg>
        </button>
      </div>
      {/* <!-- SIDEBAR HEADER --> */}

      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
        <nav className="mt-5 py-4 px-4 lg:mt-9 lg:px-6">
          <div>
            <ul className="mb-6 flex flex-col gap-1.5">

            <li>
                <NavLink
                  to="/dashboard"
                  className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                    hover:bg-graydark dark:hover:bg-meta-4 
                    ${pathname.includes('dashboard') ? 'bg-graydark dark:bg-meta-4' : ''}`}
                >
                  Dashboard
                </NavLink>
              </li>
                  {/* home menu start */}

                  <li>
                  <button
                 onClick={() => setShowfeaturedSubMenu(!showfeaturedSubMenu)}
                 className="group relative flex w-full items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4"
               >
               <svg
               className="fill-current"
               width="18"
               height="18"
               viewBox="0 0 18 18"
               xmlns="http://www.w3.org/2000/svg"
             >
               <path
                 d="M9 3L3 8.25V15C3 15.4142 3.33579 15.75 3.75 15.75H7.5C7.91421 15.75 8.25 15.4142 8.25 15V11.25H9.75V15C9.75 15.4142 10.0858 15.75 10.5 15.75H14.25C14.6642 15.75 15 15.4142 15 15V8.25L9 3Z"
                 stroke="currentColor"
                 strokeWidth="1.5"
                 strokeLinecap="round"
                 strokeLinejoin="round"
                 fill="none"
               />
             </svg>
             
                 Home
                 <svg
                   className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
                     showfeaturedSubMenu && "rotate-180"
                   }`}
                   width="20"
                   height="20"
                   viewBox="0 0 20 20"
                   fill="none"
                   xmlns="http://www.w3.org/2000/svg"
                 >
                   <path
                     fillRule="evenodd"
                     clipRule="evenodd"
                     d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
                     fill=""
                   />
                 </svg>
               </button>
      
                  
                  <div className={`overflow-hidden ${showfeaturedSubMenu ? "block" : "hidden"}`}>
                    <ul className="mt-1 pl-6 text-sm font-medium">
                    <li>
                    <NavLink
                      to="/featuredspeaker"
                      className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                        hover:bg-graydark dark:hover:bg-meta-4 
                        ${pathname.includes('featuredspeaker') ? 'bg-graydark dark:bg-meta-4' : ''}`}
                    >
                    <svg
                    className="fill-current"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 9C10.6569 9 12 7.65685 12 6C12 4.34315 10.6569 3 9 3C7.34315 3 6 4.34315 6 6C6 7.65685 7.34315 9 9 9ZM3 14.25C3 12.1789 6.68629 11.25 9 11.25C11.3137 11.25 15 12.1789 15 14.25V15.75H3V14.25Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                    />
                  </svg>
                  
                      Featured Speakers
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/featurednews"
                      className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                        hover:bg-graydark dark:hover:bg-meta-4 
                        ${pathname.includes('featurednews') ? 'bg-graydark dark:bg-meta-4' : ''}`}
                    >
                    <svg
                    className="fill-current"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 3.75H15M3 7.5H15M3 11.25H9M6.75 14.25H15"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                    />
                    <rect
                      x="1.5"
                      y="1.5"
                      width="15"
                      height="15"
                      rx="2.25"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      fill="none"
                    />
                  </svg>
                  
                      Featured News
                    </NavLink>
                  </li>
                  <li>
                  <NavLink
                    to="/featuredvideos"
                    className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                      hover:bg-graydark dark:hover:bg-meta-4 
                      ${pathname.includes('featuredvideos') ? 'bg-graydark dark:bg-meta-4' : ''}`}
                  >
                    <svg
                      className="fill-current"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="2"
                        y="4"
                        width="14"
                        height="10"
                        rx="1.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        fill="none"
                      />
                      <polygon
                        points="7,6.5 7,11.5 11.5,9"
                        fill="currentColor"
                      />
                      <path
                        d="M1 4.5V13.5C1 14.3284 1.67157 15 2.5 15H15.5C16.3284 15 17 14.3284 17 13.5V4.5C17 3.67157 16.3284 3 15.5 3H2.5C1.67157 3 1 3.67157 1 4.5Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        fill="none"
                      />
                    </svg>
    
                
                    Featured videos
                  </NavLink>
                </li>
                <li>
                <NavLink
                  to="/featuredphotogallery"
                  className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                    hover:bg-graydark dark:hover:bg-meta-4 
                    ${pathname.includes('featuredphotogallery') ? 'bg-graydark dark:bg-meta-4' : ''}`}
                >
                  <svg
                    className="fill-current"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="3"
                      y="3"
                      width="12"
                      height="12"
                      rx="2"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      fill="none"
                    />
                    <path
                      d="M6 12L8.5 9L10 11L12 8.5L14 12H4"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                    />
                    <circle
                      cx="11"
                      cy="6"
                      r="1"
                      fill="currentColor"
                    />
                  </svg>
    
              
                  Featured Photo Gallery
                </NavLink>
              </li>
      
                 
                    </ul>
                  </div>
                </li>
    
                  {/* home menu end */}
              {hasPermission('admins') && (    
            <li>
              <NavLink
                to="/adminlisting"
                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                  hover:bg-graydark dark:hover:bg-meta-4 
                  ${pathname.includes('adminlisting') ? 'bg-graydark dark:bg-meta-4' : ''}`}
              >
                <svg
                  className="fill-current"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.0002 7.79065C11.0814 7.79065 12.7689 6.1594 12.7689 4.1344C12.7689 2.1094 11.0814 0.478149 9.0002 0.478149C6.91895 0.478149 5.23145 2.1094 5.23145 4.1344C5.23145 6.1594 6.91895 7.79065 9.0002 7.79065ZM9.0002 1.7719C10.3783 1.7719 11.5033 2.84065 11.5033 4.16252C11.5033 5.4844 10.3783 6.55315 9.0002 6.55315C7.62207 6.55315 6.49707 5.4844 6.49707 4.16252C6.49707 2.84065 7.62207 1.7719 9.0002 1.7719Z"
                    fill=""
                  />
                  <path
                    d="M10.8283 9.05627H7.17207C4.16269 9.05627 1.71582 11.5313 1.71582 14.5406V16.875C1.71582 17.2125 1.99707 17.5219 2.3627 17.5219C2.72832 17.5219 3.00957 17.2407 3.00957 16.875V14.5406C3.00957 12.2344 4.89394 10.3219 7.22832 10.3219H10.8564C13.1627 10.3219 15.0752 12.2063 15.0752 14.5406V16.875C15.0752 17.2125 15.3564 17.5219 15.7221 17.5219C16.0877 17.5219 16.3689 17.2407 16.3689 16.875V14.5406C16.2846 11.5313 13.8377 9.05627 10.8283 9.05627Z"
                    fill=""
                  />
                </svg>
                Admins
              </NavLink>
            </li>
              )}
                 
               {/* Registration Menu */}

               {hasPermission('registration') && (
            <li>
            <button
           onClick={() => setShowRegistration(!showRegistration)}
           className="group relative flex w-full items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4"
         >
           <svg
                    className="fill-current"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.0002 7.79065C11.0814 7.79065 12.7689 6.1594 12.7689 4.1344C12.7689 2.1094 11.0814 0.478149 9.0002 0.478149C6.91895 0.478149 5.23145 2.1094 5.23145 4.1344C5.23145 6.1594 6.91895 7.79065 9.0002 7.79065ZM9.0002 1.7719C10.3783 1.7719 11.5033 2.84065 11.5033 4.16252C11.5033 5.4844 10.3783 6.55315 9.0002 6.55315C7.62207 6.55315 6.49707 5.4844 6.49707 4.16252C6.49707 2.84065 7.62207 1.7719 9.0002 1.7719Z"
                      fill=""
                    />
                    <path
                      d="M10.8283 9.05627H7.17207C4.16269 9.05627 1.71582 11.5313 1.71582 14.5406V16.875C1.71582 17.2125 1.99707 17.5219 2.3627 17.5219C2.72832 17.5219 3.00957 17.2407 3.00957 16.875V14.5406C3.00957 12.2344 4.89394 10.3219 7.22832 10.3219H10.8564C13.1627 10.3219 15.0752 12.2063 15.0752 14.5406V16.875C15.0752 17.2125 15.3564 17.5219 15.7221 17.5219C16.0877 17.5219 16.3689 17.2407 16.3689 16.875V14.5406C16.2846 11.5313 13.8377 9.05627 10.8283 9.05627Z"
                      fill=""
                    />
                  </svg>
           Registration
           <svg
             className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
              showRegistration && "rotate-180"
             }`}
             width="20"
             height="20"
             viewBox="0 0 20 20"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
           >
             <path
               fillRule="evenodd"
               clipRule="evenodd"
               d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
               fill=""
             />
           </svg>
         </button>

            {/* Registration */}
            <div className={`overflow-hidden ${showRegistration ? "block" : "hidden"}`}>
              <ul className="mt-1 pl-6 text-sm font-medium">
              <li>
                <NavLink
                  to="/userlisting"
                  className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                    hover:bg-graydark dark:hover:bg-meta-4 
                    ${pathname.includes('userlisting') ? 'bg-graydark dark:bg-meta-4' : ''}`}
                >
                  <svg
                    className="fill-current"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.0002 7.79065C11.0814 7.79065 12.7689 6.1594 12.7689 4.1344C12.7689 2.1094 11.0814 0.478149 9.0002 0.478149C6.91895 0.478149 5.23145 2.1094 5.23145 4.1344C5.23145 6.1594 6.91895 7.79065 9.0002 7.79065ZM9.0002 1.7719C10.3783 1.7719 11.5033 2.84065 11.5033 4.16252C11.5033 5.4844 10.3783 6.55315 9.0002 6.55315C7.62207 6.55315 6.49707 5.4844 6.49707 4.16252C6.49707 2.84065 7.62207 1.7719 9.0002 1.7719Z"
                      fill=""
                    />
                    <path
                      d="M10.8283 9.05627H7.17207C4.16269 9.05627 1.71582 11.5313 1.71582 14.5406V16.875C1.71582 17.2125 1.99707 17.5219 2.3627 17.5219C2.72832 17.5219 3.00957 17.2407 3.00957 16.875V14.5406C3.00957 12.2344 4.89394 10.3219 7.22832 10.3219H10.8564C13.1627 10.3219 15.0752 12.2063 15.0752 14.5406V16.875C15.0752 17.2125 15.3564 17.5219 15.7221 17.5219C16.0877 17.5219 16.3689 17.2407 16.3689 16.875V14.5406C16.2846 11.5313 13.8377 9.05627 10.8283 9.05627Z"
                      fill=""
                    />
                  </svg>
                  Attendees
                </NavLink>
              </li>
               
            <li>
              <NavLink
                to="/delegatelisting"
                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                  hover:bg-graydark dark:hover:bg-meta-4 
                  ${pathname.includes('delegatelisting') ? 'bg-graydark dark:bg-meta-4' : ''}`}
              >
                <svg
                  className="fill-current"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.0002 7.79065C11.0814 7.79065 12.7689 6.1594 12.7689 4.1344C12.7689 2.1094 11.0814 0.478149 9.0002 0.478149C6.91895 0.478149 5.23145 2.1094 5.23145 4.1344C5.23145 6.1594 6.91895 7.79065 9.0002 7.79065ZM9.0002 1.7719C10.3783 1.7719 11.5033 2.84065 11.5033 4.16252C11.5033 5.4844 10.3783 6.55315 9.0002 6.55315C7.62207 6.55315 6.49707 5.4844 6.49707 4.16252C6.49707 2.84065 7.62207 1.7719 9.0002 1.7719Z"
                    fill=""
                  />
                  <path
                    d="M10.8283 9.05627H7.17207C4.16269 9.05627 1.71582 11.5313 1.71582 14.5406V16.875C1.71582 17.2125 1.99707 17.5219 2.3627 17.5219C2.72832 17.5219 3.00957 17.2407 3.00957 16.875V14.5406C3.00957 12.2344 4.89394 10.3219 7.22832 10.3219H10.8564C13.1627 10.3219 15.0752 12.2063 15.0752 14.5406V16.875C15.0752 17.2125 15.3564 17.5219 15.7221 17.5219C16.0877 17.5219 16.3689 17.2407 16.3689 16.875V14.5406C16.2846 11.5313 13.8377 9.05627 10.8283 9.05627Z"
                    fill=""
                  />
                </svg>
                Delegates
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/student"
                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                  hover:bg-graydark dark:hover:bg-meta-4 
                  ${pathname.includes('student') ? 'bg-graydark dark:bg-meta-4' : ''}`}
              >
                <svg
                  className="fill-current"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.0002 7.79065C11.0814 7.79065 12.7689 6.1594 12.7689 4.1344C12.7689 2.1094 11.0814 0.478149 9.0002 0.478149C6.91895 0.478149 5.23145 2.1094 5.23145 4.1344C5.23145 6.1594 6.91895 7.79065 9.0002 7.79065ZM9.0002 1.7719C10.3783 1.7719 11.5033 2.84065 11.5033 4.16252C11.5033 5.4844 10.3783 6.55315 9.0002 6.55315C7.62207 6.55315 6.49707 5.4844 6.49707 4.16252C6.49707 2.84065 7.62207 1.7719 9.0002 1.7719Z"
                    fill=""
                  />
                  <path
                    d="M10.8283 9.05627H7.17207C4.16269 9.05627 1.71582 11.5313 1.71582 14.5406V16.875C1.71582 17.2125 1.99707 17.5219 2.3627 17.5219C2.72832 17.5219 3.00957 17.2407 3.00957 16.875V14.5406C3.00957 12.2344 4.89394 10.3219 7.22832 10.3219H10.8564C13.1627 10.3219 15.0752 12.2063 15.0752 14.5406V16.875C15.0752 17.2125 15.3564 17.5219 15.7221 17.5219C16.0877 17.5219 16.3689 17.2407 16.3689 16.875V14.5406C16.2846 11.5313 13.8377 9.05627 10.8283 9.05627Z"
                    fill=""
                  />
                </svg>
                Students
              </NavLink>
              </li>
              </ul>
            </div>
          </li>
           )}
            {hasPermission('speakers') && (
            <li>
              <NavLink
                to="/speakerlisting"
              //   className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
              //     pathname.includes('profile') && 'bg-graydark dark:bg-meta-4'
              //   }`}
              // >
              className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
              hover:bg-graydark dark:hover:bg-meta-4 
              ${pathname.includes('speakerlisting') ? 'bg-graydark dark:bg-meta-4' : ''}`}
              >
              <svg
              className="fill-current"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 12c2.7 0 4.8-2.1 4.8-4.8S14.7 2.4 12 2.4 7.2 4.5 7.2 7.2 9.3 12 12 12zm0 2.4c-3.2 0-9.6 1.6-9.6 4.8v2.4h19.2V19.2c0-3.2-6.4-4.8-9.6-4.8z"
                fill=""
              />
            </svg>
            
                Speakers
              </NavLink>
            </li> 
            )}
            {hasPermission('news') && ( 
            <li>
              <NavLink
                to="/newslist"
                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                  hover:bg-graydark dark:hover:bg-meta-4 
                  ${pathname.includes('newslist') ? 'bg-graydark dark:bg-meta-4' : ''}`}
              >
              <svg
              className="fill-current"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 3H20C20.55 3 21 3.45 21 4V20C21 20.55 20.55 21 20 21H4C3.45 21 3 20.55 3 20V4C3 3.45 3.45 3 4 3ZM5 19H19V5H5V19ZM7 7H17V9H7V7ZM7 11H17V13H7V11ZM7 15H13V17H7V15Z"
                fill=""
              />
            </svg>
               News
              </NavLink>
            </li>
            )}
            {hasPermission('videos') && ( 
            <li>
              <NavLink
                to="/videoslist"
                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                  hover:bg-graydark dark:hover:bg-meta-4 
                  ${pathname.includes('videoslist') ? 'bg-graydark dark:bg-meta-4' : ''}`}
              >
              <svg
              className="fill-current"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 5V19L19 12L8 5ZM4 5H2V19H4V5ZM22 5H20V19H22V5Z"
                fill=""
              />
            </svg>
            
                Videos
              </NavLink>
            </li>
            )}
            {hasPermission('photogallery') && ( 
            <li>
              <NavLink
                to="/photogallerylist"
                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                  hover:bg-graydark dark:hover:bg-meta-4 
                  ${pathname.includes('photogallerylist') ? 'bg-graydark dark:bg-meta-4' : ''}`}
              >
              <svg
              className="fill-current"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 3H3C2.45 3 2 3.45 2 4V20C2 20.55 2.45 21 3 21H21C21.55 21 22 20.55 22 20V4C22 3.45 21.55 3 21 3ZM20 19H4V5H20V19ZM12 6.75C13.24 6.75 14.25 7.76 14.25 9C14.25 10.24 13.24 11.25 12 11.25C10.76 11.25 9.75 10.24 9.75 9C9.75 7.76 10.76 6.75 12 6.75ZM19 18H5L9.25 13.25L11.75 16.25L14.75 12.25L19 18Z"
                fill=""
              />
            </svg>
            
                Photo Gallery
              </NavLink>
            </li>
            )}
            {hasPermission('slider') && ( 
            <li>
            <NavLink
              to="/sliderlisting"
              className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                hover:bg-graydark dark:hover:bg-meta-4 
                ${pathname.includes('sliderlisting') ? 'bg-graydark dark:bg-meta-4' : ''}`}
            >
            <svg
            className="fill-current"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 3H3C2.45 3 2 3.45 2 4V20C2 20.55 2.45 21 3 21H21C21.55 21 22 20.55 22 20V4C22 3.45 21.55 3 21 3ZM20 19H4V5H20V19ZM12 6.75C13.24 6.75 14.25 7.76 14.25 9C14.25 10.24 13.24 11.25 12 11.25C10.76 11.25 9.75 10.24 9.75 9C9.75 7.76 10.76 6.75 12 6.75ZM19 18H5L9.25 13.25L11.75 16.25L14.75 12.25L19 18Z"
              fill=""
            />
          </svg>
          
             Slider
            </NavLink>
          </li>
        )}
        {hasPermission('testimonials') && (
          <li>
          <NavLink
            to="/testimonialslist"
            className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
              hover:bg-graydark dark:hover:bg-meta-4 
              ${pathname.includes('testimonialslist') ? 'bg-graydark dark:bg-meta-4' : ''}`}
          >
          <svg class="fill-current" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 6.5C8.5 5.12 9.62 4 11 4C12.38 4 13.5 5.12 13.5 6.5C13.5 7.88 12.38 9 11 9C9.62 9 8.5 7.88 8.5 6.5ZM16.5 6.5C16.5 5.12 17.62 4 19 4C20.38 4 21.5 5.12 21.5 6.5C21.5 7.88 20.38 9 19 9C17.62 9 16.5 7.88 16.5 6.5ZM11.5 12H9.67C9.18 12 8.75 12.23 8.46 12.61L5 17H11.5V12ZM19.5 12H17.67C17.18 12 16.75 12.23 16.46 12.61L13 17H19.5V12Z" fill="currentColor"></path></svg>
        
           Testimonials
          </NavLink>
        </li>
 )}
 {hasPermission('session') && (
        <li>
            <NavLink
              to="/sessionlist"
              className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                hover:bg-graydark dark:hover:bg-meta-4 
                ${pathname.includes('sessionlist') ? 'bg-graydark dark:bg-meta-4' : ''}`}
            >
            <svg
            className="fill-current"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 3H3C2.45 3 2 3.45 2 4V20C2 20.55 2.45 21 3 21H21C21.55 21 22 20.55 22 20V4C22 3.45 21.55 3 21 3ZM20 19H4V5H20V19ZM12 6.75C13.24 6.75 14.25 7.76 14.25 9C14.25 10.24 13.24 11.25 12 11.25C10.76 11.25 9.75 10.24 9.75 9C9.75 7.76 10.76 6.75 12 6.75ZM19 18H5L9.25 13.25L11.75 16.25L14.75 12.25L19 18Z"
              fill=""
            />
          </svg>
          
          Programme
            </NavLink>
          </li>
          )}
       {hasPermission('sessioncategory') && (   
        <li>
        <NavLink
          to="/sessioncategorylist"
          className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
            hover:bg-graydark dark:hover:bg-meta-4 
            ${pathname.includes('sessioncategorylist') ? 'bg-graydark dark:bg-meta-4' : ''}`}
        >
        <svg
        className="fill-current"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21 3H3C2.45 3 2 3.45 2 4V20C2 20.55 2.45 21 3 21H21C21.55 21 22 20.55 22 20V4C22 3.45 21.55 3 21 3ZM20 19H4V5H20V19ZM12 6.75C13.24 6.75 14.25 7.76 14.25 9C14.25 10.24 13.24 11.25 12 11.25C10.76 11.25 9.75 10.24 9.75 9C9.75 7.76 10.76 6.75 12 6.75ZM19 18H5L9.25 13.25L11.75 16.25L14.75 12.25L19 18Z"
          fill=""
        />
      </svg>
      
      Programme category list
        </NavLink>
      </li>
      )}
      {hasPermission('venuelist') && (
      <li>
      <NavLink
        to="/venuelist"
        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
          hover:bg-graydark dark:hover:bg-meta-4 
          ${pathname.includes('venuelist') ? 'bg-graydark dark:bg-meta-4' : ''}`}
      >
      <svg
      className="fill-current"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 3H3C2.45 3 2 3.45 2 4V20C2 20.55 2.45 21 3 21H21C21.55 21 22 20.55 22 20V4C22 3.45 21.55 3 21 3ZM20 19H4V5H20V19ZM12 6.75C13.24 6.75 14.25 7.76 14.25 9C14.25 10.24 13.24 11.25 12 11.25C10.76 11.25 9.75 10.24 9.75 9C9.75 7.76 10.76 6.75 12 6.75ZM19 18H5L9.25 13.25L11.75 16.25L14.75 12.25L19 18Z"
        fill=""
      />
    </svg>
    
      Venue
      </NavLink>
    </li>
    )}
    {hasPermission('discountcoupon') && (
        <li>
        <NavLink
          to="/listdiscountcoupon"
          className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
            hover:bg-graydark dark:hover:bg-meta-4 
            ${pathname.includes('listdiscountcoupon') ? 'bg-graydark dark:bg-meta-4' : ''}`}
        >
          <svg
            className="fill-current"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 6C3 4.89 3.89 4 5 4H19C20.1 4 21 4.89 21 6C21 6.55 21.45 7 22 7V9C22 9.55 21.55 10 21 10C20.45 10 20 10.45 20 11C20 12.1 19.1 13 18 13C16.9 13 16 12.1 16 11C16 10.45 15.55 10 15 10C14.45 10 14 9.55 14 9C14 7.9 13.1 7 12 7C10.9 7 10 7.9 10 9C10 9.55 9.55 10 9 10C8.45 10 8 10.45 8 11C8 12.1 7.1 13 6 13C4.9 13 4 12.1 4 11C4 10.45 3.55 10 3 10V7C3.45 7 4 6.55 4 6ZM5 18H19C20.1 18 21 17.1 21 16C21 15.45 20.55 15 20 15C18.9 15 18 14.1 18 13C18 12.45 17.55 12 17 12C16.45 12 16 12.45 16 13C16 14.1 15.1 15 14 15C12.9 15 12 14.1 12 13C12 12.45 11.55 12 11 12C10.45 12 10 12.45 10 13C10 14.1 9.1 15 8 15C6.9 15 6 14.1 6 13C6 12.45 5.55 12 5 12C4.45 12 4 12.45 4 13C4 14.1 3.1 15 2 15V16C2 17.1 2.9 18 4 18H5Z"
              fill=""
            />
          </svg>

      
      
         Discount Coupons
        </NavLink>
      </li>
       )}

<li>
        <NavLink
          to="/partnerlogolist"
          className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
            hover:bg-graydark dark:hover:bg-meta-4 
            ${pathname.includes('partnerlogolist') ? 'bg-graydark dark:bg-meta-4' : ''}`}
        >
        <svg class="fill-current" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 6.5C8.5 5.12 9.62 4 11 4C12.38 4 13.5 5.12 13.5 6.5C13.5 7.88 12.38 9 11 9C9.62 9 8.5 7.88 8.5 6.5ZM16.5 6.5C16.5 5.12 17.62 4 19 4C20.38 4 21.5 5.12 21.5 6.5C21.5 7.88 20.38 9 19 9C17.62 9 16.5 7.88 16.5 6.5ZM11.5 12H9.67C9.18 12 8.75 12.23 8.46 12.61L5 17H11.5V12ZM19.5 12H17.67C17.18 12 16.75 12.23 16.46 12.61L13 17H19.5V12Z" fill="currentColor"></path></svg>
      
         Partner Logo
        </NavLink>
      </li>
            {/* New Zoho Export Menu */}
            {hasPermission('zohoexport') && (
              <li>
            <button
           onClick={() => setShowZohoSubMenu(!showZohoSubMenu)}
           className="group relative flex w-full items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4"
         >
           <svg
             className="fill-current"
             width="18"
             height="18"
             viewBox="0 0 18 18"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
           >
             <path
               d="M15 10.5V13.5C15 14.0304 14.7893 14.5391 14.4142 14.9142C14.0391 15.2893 13.5304 15.5 13 15.5H5C4.46957 15.5 3.96086 15.2893 3.58579 14.9142C3.21071 14.5391 3 14.0304 3 13.5V10.5M9 11.5V3.5M9 11.5L6 8.5M9 11.5L12 8.5"
               stroke="currentColor"
               strokeWidth="1.5"
               strokeLinecap="round"
               strokeLinejoin="round"
               fill="none"
             />
           </svg>
           Zoho Export
           <svg
             className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
               showZohoSubMenu && "rotate-180"
             }`}
             width="20"
             height="20"
             viewBox="0 0 20 20"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
           >
             <path
               fillRule="evenodd"
               clipRule="evenodd"
               d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
               fill=""
             />
           </svg>
         </button>

            {/* Zoho Export Submenu */}
            <div className={`overflow-hidden ${showZohoSubMenu ? "block" : "hidden"}`}>
              <ul className="mt-1 pl-6 text-sm font-medium">
              <li>
              <NavLink
                to="/userlist"
                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                  hover:bg-graydark dark:hover:bg-meta-4 
                  ${pathname.includes('userlisting') ? 'bg-graydark dark:bg-meta-4' : ''}`}
              >
                <svg
                  className="fill-current"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.0002 7.79065C11.0814 7.79065 12.7689 6.1594 12.7689 4.1344C12.7689 2.1094 11.0814 0.478149 9.0002 0.478149C6.91895 0.478149 5.23145 2.1094 5.23145 4.1344C5.23145 6.1594 6.91895 7.79065 9.0002 7.79065ZM9.0002 1.7719C10.3783 1.7719 11.5033 2.84065 11.5033 4.16252C11.5033 5.4844 10.3783 6.55315 9.0002 6.55315C7.62207 6.55315 6.49707 5.4844 6.49707 4.16252C6.49707 2.84065 7.62207 1.7719 9.0002 1.7719Z"
                    fill=""
                  />
                  <path
                    d="M10.8283 9.05627H7.17207C4.16269 9.05627 1.71582 11.5313 1.71582 14.5406V16.875C1.71582 17.2125 1.99707 17.5219 2.3627 17.5219C2.72832 17.5219 3.00957 17.2407 3.00957 16.875V14.5406C3.00957 12.2344 4.89394 10.3219 7.22832 10.3219H10.8564C13.1627 10.3219 15.0752 12.2063 15.0752 14.5406V16.875C15.0752 17.2125 15.3564 17.5219 15.7221 17.5219C16.0877 17.5219 16.3689 17.2407 16.3689 16.875V14.5406C16.2846 11.5313 13.8377 9.05627 10.8283 9.05627Z"
                    fill=""
                  />
                </svg>
                Attendees
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/delegatelist"
                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                  hover:bg-graydark dark:hover:bg-meta-4 
                  ${pathname.includes('delegatelisting') ? 'bg-graydark dark:bg-meta-4' : ''}`}
              >
                <svg
                  className="fill-current"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.0002 7.79065C11.0814 7.79065 12.7689 6.1594 12.7689 4.1344C12.7689 2.1094 11.0814 0.478149 9.0002 0.478149C6.91895 0.478149 5.23145 2.1094 5.23145 4.1344C5.23145 6.1594 6.91895 7.79065 9.0002 7.79065ZM9.0002 1.7719C10.3783 1.7719 11.5033 2.84065 11.5033 4.16252C11.5033 5.4844 10.3783 6.55315 9.0002 6.55315C7.62207 6.55315 6.49707 5.4844 6.49707 4.16252C6.49707 2.84065 7.62207 1.7719 9.0002 1.7719Z"
                    fill=""
                  />
                  <path
                    d="M10.8283 9.05627H7.17207C4.16269 9.05627 1.71582 11.5313 1.71582 14.5406V16.875C1.71582 17.2125 1.99707 17.5219 2.3627 17.5219C2.72832 17.5219 3.00957 17.2407 3.00957 16.875V14.5406C3.00957 12.2344 4.89394 10.3219 7.22832 10.3219H10.8564C13.1627 10.3219 15.0752 12.2063 15.0752 14.5406V16.875C15.0752 17.2125 15.3564 17.5219 15.7221 17.5219C16.0877 17.5219 16.3689 17.2407 16.3689 16.875V14.5406C16.2846 11.5313 13.8377 9.05627 10.8283 9.05627Z"
                    fill=""
                  />
                </svg>
                Delegates
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/studentlist"
                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                  hover:bg-graydark dark:hover:bg-meta-4 
                  ${pathname.includes('student') ? 'bg-graydark dark:bg-meta-4' : ''}`}
              >
                <svg
                  className="fill-current"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.0002 7.79065C11.0814 7.79065 12.7689 6.1594 12.7689 4.1344C12.7689 2.1094 11.0814 0.478149 9.0002 0.478149C6.91895 0.478149 5.23145 2.1094 5.23145 4.1344C5.23145 6.1594 6.91895 7.79065 9.0002 7.79065ZM9.0002 1.7719C10.3783 1.7719 11.5033 2.84065 11.5033 4.16252C11.5033 5.4844 10.3783 6.55315 9.0002 6.55315C7.62207 6.55315 6.49707 5.4844 6.49707 4.16252C6.49707 2.84065 7.62207 1.7719 9.0002 1.7719Z"
                    fill=""
                  />
                  <path
                    d="M10.8283 9.05627H7.17207C4.16269 9.05627 1.71582 11.5313 1.71582 14.5406V16.875C1.71582 17.2125 1.99707 17.5219 2.3627 17.5219C2.72832 17.5219 3.00957 17.2407 3.00957 16.875V14.5406C3.00957 12.2344 4.89394 10.3219 7.22832 10.3219H10.8564C13.1627 10.3219 15.0752 12.2063 15.0752 14.5406V16.875C15.0752 17.2125 15.3564 17.5219 15.7221 17.5219C16.0877 17.5219 16.3689 17.2407 16.3689 16.875V14.5406C16.2846 11.5313 13.8377 9.05627 10.8283 9.05627Z"
                    fill=""
                  />
                </svg>
                Students
              </NavLink>
            </li>
              </ul>
            </div>
          </li>
            )}
            {/* <li>
              <NavLink
                to="/adduser"
                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                  pathname.includes('profile') && 'bg-graydark dark:bg-meta-4'
                }`}
              >
                <svg
                  className="fill-current"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.0002 7.79065C11.0814 7.79065 12.7689 6.1594 12.7689 4.1344C12.7689 2.1094 11.0814 0.478149 9.0002 0.478149C6.91895 0.478149 5.23145 2.1094 5.23145 4.1344C5.23145 6.1594 6.91895 7.79065 9.0002 7.79065ZM9.0002 1.7719C10.3783 1.7719 11.5033 2.84065 11.5033 4.16252C11.5033 5.4844 10.3783 6.55315 9.0002 6.55315C7.62207 6.55315 6.49707 5.4844 6.49707 4.16252C6.49707 2.84065 7.62207 1.7719 9.0002 1.7719Z"
                    fill=""
                  />
                  <path
                    d="M10.8283 9.05627H7.17207C4.16269 9.05627 1.71582 11.5313 1.71582 14.5406V16.875C1.71582 17.2125 1.99707 17.5219 2.3627 17.5219C2.72832 17.5219 3.00957 17.2407 3.00957 16.875V14.5406C3.00957 12.2344 4.89394 10.3219 7.22832 10.3219H10.8564C13.1627 10.3219 15.0752 12.2063 15.0752 14.5406V16.875C15.0752 17.2125 15.3564 17.5219 15.7221 17.5219C16.0877 17.5219 16.3689 17.2407 16.3689 16.875V14.5406C16.2846 11.5313 13.8377 9.05627 10.8283 9.05627Z"
                    fill=""
                  />
                </svg>
                Create user
              </NavLink>
            </li> */}
            {/* <li>
            <NavLink
              to="/sliderlisting"
              className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                hover:bg-graydark dark:hover:bg-meta-4 
                ${pathname.includes('sliderlisting') ? 'bg-graydark dark:bg-meta-4' : ''}`}
            >
              <svg
                className="fill-current"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.0002 7.79065C11.0814 7.79065 12.7689 6.1594 12.7689 4.1344C12.7689 2.1094 11.0814 0.478149 9.0002 0.478149C6.91895 0.478149 5.23145 2.1094 5.23145 4.1344C5.23145 6.1594 6.91895 7.79065 9.0002 7.79065ZM9.0002 1.7719C10.3783 1.7719 11.5033 2.84065 11.5033 4.16252C11.5033 5.4844 10.3783 6.55315 9.0002 6.55315C7.62207 6.55315 6.49707 5.4844 6.49707 4.16252C6.49707 2.84065 7.62207 1.7719 9.0002 1.7719Z"
                  fill=""
                />
                <path
                  d="M10.8283 9.05627H7.17207C4.16269 9.05627 1.71582 11.5313 1.71582 14.5406V16.875C1.71582 17.2125 1.99707 17.5219 2.3627 17.5219C2.72832 17.5219 3.00957 17.2407 3.00957 16.875V14.5406C3.00957 12.2344 4.89394 10.3219 7.22832 10.3219H10.8564C13.1627 10.3219 15.0752 12.2063 15.0752 14.5406V16.875C15.0752 17.2125 15.3564 17.5219 15.7221 17.5219C16.0877 17.5219 16.3689 17.2407 16.3689 16.875V14.5406C16.2846 11.5313 13.8377 9.05627 10.8283 9.05627Z"
                  fill=""
                />
              </svg>
              Sliders
            </NavLink>
          </li> */}

              {/* <li>
                <NavLink
                  to="/sessionlist"
                  className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                    hover:bg-graydark dark:hover:bg-meta-4 
                    ${pathname.includes('sessionlist') ? 'bg-graydark dark:bg-meta-4' : ''}`}
                >
                  <svg
                    className="fill-current"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect x="1" y="1" width="16" height="16" rx="2" fill="none" stroke="currentColor" stroke-width="1.5" />
                    <line x1="1" y1="5" x2="17" y2="5" stroke="currentColor" stroke-width="1.5" />
                    <circle cx="3.5" cy="3" r="0.75" fill="currentColor" />
                    <circle cx="6.5" cy="3" r="0.75" fill="currentColor" />
                    <circle cx="9.5" cy="3" r="0.75" fill="currentColor" />
                    <path d="M5 9L8 12L13 8" stroke="currentColor" stroke-width="1.5" fill="none" />
                  </svg>
                  Sessions
                </NavLink>
              </li> */}
{/*
              <li>
                <NavLink
                  to="/venuelist"
                  className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                    hover:bg-graydark dark:hover:bg-meta-4 
                    ${pathname.includes('venuelist') ? 'bg-graydark dark:bg-meta-4' : ''}`}
                >
                  <svg
                    className="fill-current"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect x="1" y="1" width="16" height="16" rx="2" fill="none" stroke="currentColor" stroke-width="1.5" />
                    <line x1="1" y1="5" x2="17" y2="5" stroke="currentColor" stroke-width="1.5" />
                    <circle cx="3.5" cy="3" r="0.75" fill="currentColor" />
                    <circle cx="6.5" cy="3" r="0.75" fill="currentColor" />
                    <circle cx="9.5" cy="3" r="0.75" fill="currentColor" />
                    <path d="M5 9L8 12L13 8" stroke="currentColor" stroke-width="1.5" fill="none" />
                  </svg>
                  Venue
                </NavLink>
              </li> */}

              {/* <li>
              <NavLink
                to="/sessioncategorylist"
                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                  hover:bg-graydark dark:hover:bg-meta-4 
                  ${pathname.includes('sessioncategorylist') ? 'bg-graydark dark:bg-meta-4' : ''}`}
              >
              <svg class="fill-current" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 6.5C8.5 5.12 9.62 4 11 4C12.38 4 13.5 5.12 13.5 6.5C13.5 7.88 12.38 9 11 9C9.62 9 8.5 7.88 8.5 6.5ZM16.5 6.5C16.5 5.12 17.62 4 19 4C20.38 4 21.5 5.12 21.5 6.5C21.5 7.88 20.38 9 19 9C17.62 9 16.5 7.88 16.5 6.5ZM11.5 12H9.67C9.18 12 8.75 12.23 8.46 12.61L5 17H11.5V12ZM19.5 12H17.67C17.18 12 16.75 12.23 16.46 12.61L13 17H19.5V12Z" fill="currentColor"></path></svg>
            
               Session Category List
              </NavLink>
            </li> */}
              <li>
                <NavLink
                to="/reports"
                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out 
                hover:bg-graydark dark:hover:bg-meta-4 
                ${pathname.includes('reports') ? 'bg-graydark dark:bg-meta-4' : ''}`}
                >
                <svg class="fill-current" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 6.5C8.5 5.12 9.62 4 11 4C12.38 4 13.5 5.12 13.5 6.5C13.5 7.88 12.38 9 11 9C9.62 9 8.5 7.88 8.5 6.5ZM16.5 6.5C16.5 5.12 17.62 4 19 4C20.38 4 21.5 5.12 21.5 6.5C21.5 7.88 20.38 9 19 9C17.62 9 16.5 7.88 16.5 6.5ZM11.5 12H9.67C9.18 12 8.75 12.23 8.46 12.61L5 17H11.5V12ZM19.5 12H17.67C17.18 12 16.75 12.23 16.46 12.61L13 17H19.5V12Z" fill="currentColor"></path></svg>
                Reports
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
