import React, { useState, useRef } from 'react';
import { Camera, X, CheckCircle } from 'lucide-react';
import { toast, Toaster } from 'sonner'; // Assuming using Sonner for toasts


const MAX_FILE_SIZE = 5 * 1024 * 1024;
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];

const PushNotification = () => {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState("");
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);

  const validateFile = (file) => {
    if (!file) return null;

    if (file.size > MAX_FILE_SIZE) {
      throw new Error(`File size must be less than 5MB`);
    }

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      throw new Error('Only JPEG, JPG and PNG files are allowed');
    }

    return file;
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    
    try {
      if (file) {
        const validatedFile = validateFile(file);
        if (validatedFile) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setImage({
              file: validatedFile,
              preview: reader.result
            });
          };
          reader.readAsDataURL(validatedFile);
        }
      }
    } catch (error) {
      toast.error('File Upload Error', {
        description: error.message
      });
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const removeImage = () => {
    setImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const sendNotification = async (formData) => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/delegate/user/notification`, {
      method: 'POST',
      body: formData
    });
    
    if (!response.ok) {
      throw new Error('Failed to send notification');
    }

    toast.success('Push Notification Sent Successfully', {
      description: 'The push notification has been sent successfully.',
      icon: <CheckCircle className="text-green-500" />
    });

    return await response.text();
  };

  const handleSend = async () => {
    try {
      setIsLoading(true);

      // Create FormData exactly as in your working example
      const formData = new FormData();
      
      // Add empty notification field first
      formData.append('notification', new Blob([JSON.stringify({})], { type: 'application/json' }));
      
      // If there's an image, replace the empty notification
      if (image?.file) {
        formData.set('notification', image.file);
      }
      
      formData.append('title', title);
      formData.append('body', body);

      await sendNotification(formData);
      

      
      // Reset form
      setTitle('');
      setBody('');
      setImage(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error('Error sending notification:', error);
      toast.error('Failed to Send Notification', {
        description: error.message || 'There was an error sending the push notification. Please try again.'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-xl mx-auto p-6 bg-white shadow-md rounded-lg relative">
       <Toaster position="top-right" richColors />
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Create a Push Notification</h2>
      
      <input
        type="text"
        placeholder="Enter Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        className="w-full px-4 py-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      
      <textarea
        placeholder="Write your post..."
        value={body}
        onChange={(e) => setBody(e.target.value)}
        className="w-full px-4 py-2 mb-4 border border-gray-300 rounded-md h-32 resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      
      <div className="mb-4">
        <input
          type="file"
          ref={fileInputRef}
          accept="image/jpeg,image/jpg,image/png"
          onChange={handleImageUpload}
          className="hidden"
          id="image-upload"
        />
        <label
          htmlFor="image-upload"
          className="flex items-center text-gray-600 cursor-pointer hover:text-blue-600"
        >
          <Camera className="mr-2" size={20} />
          {image ? 'Change Image' : 'Upload Image'}
        </label>
        <div className="mt-1 text-sm text-gray-500">
          Max file size: 5MB. Supported formats: JPEG, JPG, PNG
        </div>
      </div>
      
      {image && (
        <div className="relative mb-4 w-full">
          <img
            src={image.preview}
            alt="Uploaded"
            className="w-full h-48 object-cover rounded-md"
          />
          <button
            onClick={removeImage}
            className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
          >
            <X size={16} />
          </button>
        </div>
      )}
      
      <button
        onClick={handleSend}
        disabled={!title || !body || isLoading}
        className={`w-full py-3 rounded-md text-white font-semibold transition-colors duration-300 ${
          title && body && !isLoading
            ? 'bg-blue-500 hover:bg-blue-600 active:bg-blue-700'
            : 'bg-gray-400 cursor-not-allowed'
        }`}
      >
        {isLoading ? 'Sending...' : 'Send'}
      </button>
    </div>
  );
};

export default PushNotification;