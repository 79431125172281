import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import userQueries from "../../queries/userQueries";
import Loader from "../Loader/Loader";

const Editpartnerlogo = () => {
    const [susmsg, setSusmsg] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageUploadSuccess, setImageUploadSuccess] = useState(false);
    const [imageError, setImageError] = useState('');
    const [loading, setLoading] = useState(false);
    const [currentImageUrl, setCurrentImageUrl] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [partnerlogohierarchy, setPartnerlogohierarchy] = useState([]);
    const [selectedHierarchyId, setSelectedHierarchyId] = useState(null);
    const [hierarchyError, setHierarchyError] = useState('');

    const { id } = useParams();
    const navigate = useNavigate();

    const { register, reset, handleSubmit, getValues, setValue, formState: { errors } } = useForm();

    const partnerlogodata = userQueries.useUpdatepartnerlogoMutation(
        (response) => {
            if (response?.data) {
                const data = {
                    id: id,
                    displayorder: response?.data.displayorder,
                    name: response?.data?.name,
                    text: response?.data?.text,
                };
                reset({ ...data });
                setCurrentImage(response?.data.image || null);
                setCurrentImageUrl(response?.data.image ? `${process.env.REACT_APP_BASE_URL}/${response.data.image}` : null);
                // setSelectedHierarchyId(response?.data?.logohierarchy?.id);
            }
        }
    );

    const logohierarchy = userQueries.useLogohierarchylistMutation(
        (response) => {
            setPartnerlogohierarchy(response?.data || []);
        },
        {
            onError: (error) => {
                console.error('Hierarchy fetch error:', error);
            }
        }
    );

    const editpartnerlogo = userQueries.useUpdatepartnerlogoMutation(
        (response) => {
            if (response?.message === "Success") {
                setSusmsg(true);
                alert(response?.data.status === 'Unpublish' ? "Saved as draft" : "Published successfully");
                navigate('/partnerlogolist');
            }
        }
    );

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await Promise.all([
                partnerlogodata.mutateAsync({ id }),
                logohierarchy.mutateAsync(),
            ]);
            setLoading(false);
        };
        fetchData();
    }, [id]);

    const validateImageSize = (file) => {
        const maxSize = 256 * 1024; // 256 KB in bytes
        if (file.size > maxSize) {
            return false;
        }
        return true;
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
            const objectUrl = URL.createObjectURL(file);
            setPreviewUrl(objectUrl);
            const allowedTypes = ['image/jpeg','image/jpg'];
            if (!allowedTypes.includes(file.type)) {
                setImageError("Only JPEG and JPG files are allowed.");
                return;
            }
            if (!validateImageSize(file)) {
                setImageError("Image size should be less than 256 KB.");
               
                return;
            }
            setImageError('');
        }
    };

    const handleFormSubmit = async (data, publishStatus) => {
        setLoading(true);

        if (publishStatus === 'Publish' && !selectedImage && !currentImage) {
            setImageError("Image is required.");
            setLoading(false);
            return;
        }

        const datavalues = {
            id: id,
            logo: selectedImage,
            status: publishStatus,
            displayorder: data.displayorder,
            name: data.name,
            text: data.text,
        };

        try {
            await editpartnerlogo.mutateAsync(datavalues);
        } catch (error) {
            console.error("Error updating logo:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        return () => {
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
            }
        };
    }, [previewUrl]);

    return (
        <>
            <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
                Update Partner Logo
            </h4>
            <form className="max-w-lg mx-auto grid grid-cols-2 gap-4">
                

                <div className="mb-5 col-span-2">
                    <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Upload Image
                    </label>
                    <div className="mb-4">
                        {(previewUrl || currentImageUrl) && (
                            <div className="relative w-48 h-48 mb-4">
                                <img
                                    src={previewUrl || currentImageUrl}
                                    alt="Logo preview"
                                    className="w-full h-full object-cover rounded-lg shadow-md"
                                />
                            </div>
                        )}
                    </div>
                    <input
                        type="file"
                        id="image"
                        onChange={handleImageChange}
                        accept="image/*"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                    {imageError && <div className="text-red-600 text-sm">{imageError}</div>}
                    <span className="form-text text-warning small">
                    Recommended: Use image size under 256 KB.
                  </span>
                </div>

                <div className="mb-5 col-span-2">
                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        {...register("name", { required: "Name is required" })}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                    {errors.name && (
                        <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                            {errors.name.message}
                        </p>
                    )}
                </div>


                <div className="mb-5 col-span-2">
                    <label htmlFor="text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Type</label>
                    <input
                        type="text"
                        id="text"
                        {...register("text")}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                  
                </div>


                <div className="mb-5">
                    <label htmlFor="displayorder" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Display Order
                    </label>
                    <input
                        type="number"
                        id="displayorder"
                        {...register("displayorder")}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                </div>

                <button
                    type="button"
                    onClick={() => handleFormSubmit(getValues(), 'Unpublish')}
                    className="col-span-2 w-full text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-3"
                >
                    Save as draft
                </button>

                <button
                    type="button"
                    onClick={handleSubmit((data) => handleFormSubmit(data, 'Publish'))}
                    className="col-span-2 w-full text-white bg-green-700 hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Save and publish
                </button>

                {susmsg && (
                    <div className="text-green-600 text-lg text-center col-span-2">
                        Partner Logo updated successfully
                    </div>
                )}
            </form>
            {loading && <Loader />}
        </>
    );
};

export default Editpartnerlogo;
