import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import Loader from '../Loader/Loader';
import userQueries from '../../queries/userQueries';
import RouteConstants from '../../constant/Routeconstant';

const SliderListing = () => {
  const [sliders, setSliders] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const sliderlist = userQueries.useSliderlistingMutation(
    async (response) => {
      setSliders(response?.data);
      setIsLoading(false);
    },
    {
      onError: (error) => {
        console.error('Userlist fetch error:', error);
        setError('Failed to fetch user list. Please try again later.');
      }
    }
  );

  useEffect(() => {
    setIsLoading(true);
    fetchSliders();
  }, []);

  const fetchSliders = () => {
    sliderlist.mutate();
  };

  const publishslider = userQueries.usePublishsliderMutation(
    
    (response) => {
      if (response?.message === "published successfully") {
          alert("published successfully")
          navigate('/sliderlisting');
      }
      else if (response?.message === "unpublished successfully") {
        alert("unpublished successfully")
        navigate('/sliderlisting');
      }
  }
  );
  
    const handlepublish = (id) => {
      publishslider.mutateAsync(id);
    }

    const deleteslider = userQueries.useDeletesliderMutation(
      (response) => {
        alert("Successfully deleted");
        navigate('/sliderlisting');
      }
    );
  
    const handleDelete = async (event, id) => {
      event.preventDefault();
      if (window.confirm('Are you sure you want to delete this item?')) {
        await deleteslider.mutateAsync(id);
      }
    };

  const handleAddSlider = () => {
    navigate(RouteConstants.SLIDERCREATE); 
  };

  // const slidersCount = Array.isArray(sliders) ? sliders.length : 0;

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <div className="flex justify-between items-center mb-4">
        <Breadcrumb pageName="Sliders" />
        <div className="flex flex-col sm:flex-row gap-2">
        <button
        className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-2 shadow-lg transition duration-300 ease-in-out"
        title="Add Slider"
        aria-label="Add Slider"
        onClick={handleAddSlider}
        // disabled={slidersCount >= 3}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
        </svg>
      </button>
        
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <div className="text-red-500 text-center p-4">{error}</div>
      ) : (
        <div className="flex flex-col">
          <div className="grid grid-cols-[.5fr_1fr_2fr_.8fr_1fr_1fr_1fr] sm:grid-cols-[.5fr_1fr_2fr_.8fr_1fr_1fr_1fr] border-b border-stroke bg-gray-800 dark:bg-meta-4">
            <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Sl no.</div>
            <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Image</div>
            <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Link</div>
            <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Publish StaTus</div>
            <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Display Order</div>
            <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Platform</div>
            <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Actions</div>
          </div>

          {sliders && sliders.length > 0 ? (
            sliders.map((slider, index) => (
              <div
                key={index}
                className="grid grid-cols-[.5fr_1fr_2fr_.8fr_1fr_1fr_1fr] sm:grid-cols-[.5fr_1fr_2fr_.8fr_1fr_1fr_1fr] border-b border-stroke bg-gray-200 dark:bg-meta-4"
              >
                <div className="p-2 text-xs xsm:text-sm">{index + 1}</div>
                <div className="p-2 text-xs xsm:text-sm">
                  {slider.photo ? (
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/${slider?.photo}`}
                      alt="Slider"
                      className="h-12 w-12 object-cover"
                    />
                  ) : (
                    <div className="h-12 w-12 bg-gray-200" />
                  )}
                </div>
                 <div className="p-2 text-xs xsm:text-sm">
                  {slider?.link}
                </div>
                <div className="p-2 text-xs xsm:text-sm">
                {slider?.status === 'Unpublish' ? (
                  slider?.photo  ? (
                    <button
                      className="px-3 py-1 text-xs font-medium text-red-500 bg-red-100"
                      onClick={() => handlepublish(slider.id)}
                    >
                      Unpublished
                    </button>
                  ) : (
                    <button
                      className="px-3 py-1 text-xs font-medium text-yellow-500 bg-yellow-100"
                      onClick={() => alert("This item is in draft mode and cannot be published until all required fields are filled.")}
                    >
                      Draft
                    </button>
                  )
                ) : (
                  <button
                    className="px-3 py-1 text-xs font-medium text-green-500 bg-green-100"
                    onClick={() => handlepublish(slider.id)}
                  >
                    Published
                  </button>
                )}
              </div>
                <div className="p-2 text-xs xsm:text-sm">
                  {slider.displayorder}
                </div>
                <div className="p-2 text-xs xsm:text-sm">
                  {slider.platform}
                </div>
                <div className="p-2 text-xs xsm:text-sm flex items-center gap-3">
                  <PencilSquareIcon
                    onClick={() => navigate(`/editslider/${slider.id}`)}
                    className="h-6 w-6 text-black-600 cursor-pointer hover:text-blue-600"
                    aria-hidden="true"
                  />

                  <TrashIcon
                    onClick={(e) => handleDelete(e, slider.id)}
                    className="h-6 w-6 text-black-600 cursor-pointer hover:text-red-600"
                    aria-hidden="true"
                  />
                 
                </div>
              </div>
            ))
          ) : (
            <div className="p-5">
              <p className="text-center text-black dark:text-white">
                No sliders available.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SliderListing;