import React, { useState, useRef,useEffect } from "react";
import userQueries from '../../queries/userQueries';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
const Createpartnerlogo = () => {
    const [susmsg, setSusmsg] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state
    const [imagePreview, setImagePreview] = useState(null);
    const navigate = useNavigate();
    const imageRef = useRef(null);
    const [imageError, setImageError] = useState('');
    const [partnerlogohierarchy, setPartnerlogohierarchy] = useState([]);
    const [hierarchyError, sethierarchyError] = useState('');

    const { register, handleSubmit, getValues,formState: { errors } } = useForm();

    const AddInfodata = userQueries.useCreatepartnerlogoMutation((response) => {
       
        if (response?.message === "Success") {
            setSusmsg(true);
            setLoading(false); // Stop loader
            alert(response.data.status === 'Unpublish' ? "Saved as draft" : "Published successfully");
            navigate('/partnerlogolist');
        }
        
    });

    // const logohierarchy = userQueries.useLogohierarchylistMutation((response) => {
    //     // Ensure you're setting an array, using optional chaining and fallback
    //     console.log(response)
    //     setpartnerLogohierarchy(response?.data?.data);
    // });

    const logohierarchy = userQueries.useLogohierarchylistMutation(
        async (response) => {
            setPartnerlogohierarchy(response?.data|| []);
        },
        {
          onError: (error) => {
            console.error('Venues fetch error:', error);
          }
        }
      );

      useEffect(() => {
        logohierarchy.mutateAsync();
      
      }, []);
    
      const validateImageSize = (file) => {
        const maxSize = 256 * 1024; // 256 KB in bytes
        if (file.size > maxSize) {
            return false;
        }
        return true;
    };

    const handleFormSubmit = async (data, publishStatus) => {

        console.log(data)
        setSusmsg(false);
        setLoading(true); // Start loader

        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];

        
        // if (!data.partnerlogohierarchyId) {
        //     sethierarchyError("Partner logo hierarchy is mandatory");
        //     setLoading(false);
        //     return false;
        // }

       

        // Check if file exists
            if(publishStatus === "Publish")
            {
               
    
                if (!imageRef.current?.files[0]) {
                    setImageError("Image is required.");
                    setLoading(false);
                    return false;
                }
    
                if (!allowedTypes.includes(imageRef.current?.files[0].type)) {
                    setImageError("Only JPEG, JPG, and PNG files are allowed.");
                    setLoading(false);
                    return false;
                }
    
                
            }

        setImageError(""); // Clear error if all validations pass

        const formData = new FormData();
        formData.append('status', publishStatus);
        // formData.append('hierarchy', data.partnerlogohierarchyId);
        formData.append('name', data.name);
        formData.append('displayorder', data.displayorder !== null ? data.displayorder : '1');
        formData.append('text', data.text);

        if (imageRef.current?.files[0]) {
            formData.append('logo', imageRef.current.files[0]);
        }

        try {
            const createdUser = await AddInfodata.mutateAsync(formData);
            console.log(createdUser);
           
        } catch (error) {
            console.error('Error occurred:', error);
            setLoading(false); // Stop loader if error occurs
        }
    };
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const allowedTypes = ['image/jpeg', 'image/jpg'];
            if (!allowedTypes.includes(file.type)) {
                setImageError("Only JPEG and JPG files are allowed.");
                setImagePreview(null);
                return;
            }

            
            if (!validateImageSize(file)) {
                setImageError("Image size should be less than 256 KB.");
                setImagePreview(null);
                return;
            }

            setImageError('');
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setImagePreview(null);
        }
    };
    return (
        <>
            <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
                Create Partner Logo
            </h4>
            <form className="max-w-lg mx-auto grid grid-cols-2 gap-4">

                

                <div className="mb-5 col-span-2">
                    <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Upload Image</label>
                        {imagePreview && (
                            
                                
                            <div className="relative w-48 h-48 mb-4">
                                <img 
                                    src={imagePreview} 
                                    alt="Preview" 
                                    className="w-full h-full object-cover rounded-lg shadow-md"
                                />
                            </div>
                        
                    )}
                
                    <input 
                        type="file" 
                        id="image" 
                        ref={imageRef}
                        onChange={handleImageChange}
                        accept="image/*"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    {imageError && <div className="text-red-600 text-s">{imageError}</div>}
                    <span className="form-text text-warning small">
                    Recommended: Use image size under 256 KB.
                  </span>
                </div>

                <div className="mb-5 col-span-2">
                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        {...register("name", { required: "Name is required" })}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                    {errors.name && (
                        <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                            {errors.name.message}
                        </p>
                    )}
                </div>


                <div className="mb-5 col-span-2">
                    <label htmlFor="text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Type</label>
                    <input
                        type="text"
                        id="text"
                        {...register("text")}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                  
                </div>

                <div className="mb-5">
                    <label htmlFor="displayorder" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Display Order</label>
                    <input 
                        type="number" 
                        id="displayorder" 
                        {...register("displayorder", { required: false })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    
                </div>


                <button 
                    type="button" 
                    onClick={() => handleFormSubmit(getValues(), 'Unpublish')}
                    className="col-span-2 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-3"
                >
                    Save as draft
                </button>

                <button 
                    type="button"
                    onClick={handleSubmit((data) => handleFormSubmit(data, 'Publish'))}
                    className="col-span-2 w-full text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Save and publish
                </button>

                {susmsg && <div className="text-green-600 text-lg ml-6 text-center inline-block whitespace-nowrap">
                    Partner logo created successfully
                </div>}

                {/* Loader */}
                {loading && (
                    <Loader />
                )}
            </form>
        </>
    );
};

export default Createpartnerlogo;
