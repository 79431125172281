import React, { useState } from 'react'
import Footer from './Footer';
import InitModal from './initModal';
import Navbar from './Navbar';

export default function PrivacyPolicy() {
    const [isOpen, setIsOpen] = useState(false);

        const handleClose = () => setIsOpen(false);
        const handleOpen = () => setIsOpen(true);

    return (
        <>
        <Navbar handleOpen={handleOpen}  />
        <div class="innerbanner container-fluid">
            <div class="container">

                <div class="row">
                    <div class="col-md-12">
                        <span class="logo-strips"></span> <h2>Privacy <span>Policy</span></h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mt-o mb-5">
        <div class="row">
            <div class="col-md-12">
            <iframe
                src="/privacy.html"
                style={{ width: '100%', height: '267vh', border: 'none' }}
                title="Terms and Conditions"
            ></iframe>
        </div>
        </div>
        </div>
        <Footer handleOpen={handleOpen} />
        <InitModal isOpen={isOpen} onClose={handleClose} />
       </>
    );
};
