import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import MultiSelect from '../Multiselect/MultiSelect';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import userQueries from '../../queries/userQueries';
import siteQueries from '../../queries/siteQueries';

export default function AddFeaturedVideos() {
  const { id } = useParams();
  const isEditMode = !!id;

  const navigate = useNavigate();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [videoList, setVideoList] = useState([]);

  const [alreadyAddedVideos, setAlreadyAddedVideos] = useState([]);

  const { control, handleSubmit, reset, formState: { errors } } = useForm();

  useEffect(() => {
    fetchVideoData();
    fetchExistingFeaturedVideos();
  }, []);

  const fetchVideoData = async () => {
    await videoListing.mutateAsync();
  };

  const featuredVideoListing = siteQueries.useFeaturedvideosListingMutation(
    (response) => {
      const addedVideos = response?.data?.map(featuredVideo => ({
        value: featuredVideo.videoid.toString(),
        label: featuredVideo.video.title
      })) || [];
      setAlreadyAddedVideos(addedVideos);
    },
    {
      onError: (error) => {
        console.error('Failed to fetch added videos', error);
      }
    }
  );

  const videoListing = userQueries.useListpublishedvideosMutation((response) => {
    const videoOptions = response?.data?.map(item => ({
      value: item.id.toString(),
      label: `${item.title}`
    }));
    setVideoList(videoOptions);
  });

  const addFeaturedVideos = userQueries.useFeaturedvideosaddMutation((response) => {
    console.log(response.message)
    if(response?.message === "video added successfully."){
      navigate('/featuredvideos')
    }
    else if(response?.message === "Cannot add videos. Would exceed the maximum of 8 active videos.")
    {
      alert("You cannot add more than 8 featured videos.")
    }
  });

  const onSubmit = async (data) => {
    try {
      const videoIds = data.videos?.map(video => video.value);
      console.log("video IDs:", videoIds);
  
      await addFeaturedVideos.mutateAsync({ videoIds });
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred');
    }
  };

  const fetchExistingFeaturedVideos = async () => {
    try {
      const data = await featuredVideoListing.mutateAsync();
      const existingVideos = data.data;
      
      if (existingVideos && existingVideos.length > 0) {
        reset({
          videos: existingVideos.map(featuredVideo => ({
            value: featuredVideo.videoid.toString(),
            label: featuredVideo.video.title
          }))
        });
      }
    } catch (error) {
      console.error('Error fetching existing videos:', error);
      setErrorMessage('Error fetching existing videos');
      setIsErrorModalOpen(true);
    }
  };

  return (
    <div className="container mx-auto p-6">
      <Breadcrumb pageName={isEditMode ? "Edit Featured Videos" : "Add Featured Videos"} />

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div>
            <Controller
              name="videos"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  name="videos"
                  control={control}
                  options={videoList}
                  defaultValue={alreadyAddedVideos}
                  isMulti={true}
                  label="Videos"
                  placeholder="Select videos"
                  error={errors.videos?.message}
                />
              )}
            />
            <div className="flex justify-end">
              <button 
                type="button"
                onClick={handleSubmit((data) => onSubmit(data))}
                className="bg-primary text-white py-2 px-4 rounded hover:bg-opacity-90"
              >
                Add Featured Videos
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}