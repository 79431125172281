import React, { useState, useRef } from "react";
import userQueries from '../../queries/userQueries';
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import RichTextEditor from "../Rte/Rteeditor";
import Highlight from "../ToggleSwitch/HighlightBt";

const Createnews = () => {
    const [susmsg, setSusmsg] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const imageRef = useRef(null);
    const [imageError, setImageError] = useState('');
    const [content, setContent] = useState('');
    const [contentError,setContentError] = useState('');
    const [imagePreview, setImagePreview] = useState(null);

    // const { register, handleSubmit, formState: { errors } } = useForm();
    const { register, handleSubmit, getValues, control, formState: { errors } } = useForm();

    const MAX_FILE_SIZE = 350 * 1024;

    const AddInfodata = userQueries.useCreatenewsMutation((response) => {
        if (response?.message === "Success") {
            setSusmsg(true);
            alert(response.data.publishstatus === "0" ? "Saved as draft" : "Published successfully");
            navigate('/newslist');
        }
    });

    const validateImage = (file) => {
        const allowedTypes = ['image/jpeg', 'image/jpg'];
        
        if (!allowedTypes.includes(file.type)) {
            setImageError("Only JPEG, JPG files are allowed.");
            return false;
        }

        if (file.size > MAX_FILE_SIZE) {
            setImageError("Image size should not exceed 350KB.");
            return false;
        }

        return true;
    };

    const handleFormSubmit = async (data, publishStatus) => {
        setSusmsg(false);
        setLoading(true);  // Set loading to true when form submission starts
        setImageError("");

        const file = imageRef.current?.files[0];
        if (file && !validateImage(file)) {
            setLoading(false);
            return false;
        }
        // const allowedTypes = ['image/jpeg', 'image/jpg'];

        // Validate image for "Save and publish" only
        if(publishStatus === 1)
        {
            if (!content) {
                setImageError("");  // Clear image error
                setContentError("Content is required.");  // Set content error
                setLoading(false);
                return false;
            }

            if (!file) {
                setImageError("Image is required.");
                setLoading(false);
                return false;
            }

            // if (!allowedTypes.includes(imageRef.current?.files[0].type)) {
            //     setImageError("Only JPEG and jpg files are allowed.");
            //     setLoading(false);
            //     return false;
            // }

            
        }
           
        

        // setImageError("");

        const formData = new FormData();
        formData.append('shortdescription', data.shortdescription);
        formData.append('maindescription', content);
        formData.append('publishstatus', publishStatus);
        formData.append('displayorder', data.displayorder);
        formData.append('highlight', data.highlight ? 1 : 0);

        if (file) {
            formData.append('news', file);
        }

        try {
            await AddInfodata.mutateAsync(formData);
            
        } catch (error) {
            console.error('Error occurred:', error);
        } finally {
            setLoading(false);  // Reset loading state when form submission completes
        }
    };
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const allowedTypes = ['image/jpeg', 'image/jpg'];
            if (!allowedTypes.includes(file.type)) {
                setImageError("Only JPEG, JPG files are allowed.");
                setImagePreview(null);
                return;
            }

            setImageError('');
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setImagePreview(null);
        }
    };

    const formatFileSize = (bytes) => {
        return `${(bytes / 1024).toFixed(2)} KB`;
    };

    return (
        <>
        <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            Create News
        </h4>
        <form className="max-w-lg mx-auto grid grid-cols-2 gap-4">
            <div className="mb-5 col-span-2">
                <label htmlFor="shortdescription" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Heading
                </label>
                <textarea 
                    id="shortdescription" 
                    {...register("shortdescription", { 
                        required: "Heading is required",
                        
                        maxLength: {
                            value: 256,
                            message: "Heading cannot exceed 256 characters"
                        }
                    })} 
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    rows="4"
                />
                {errors.shortdescription && <div className="text-red-600 text-s">{errors.shortdescription.message}</div>}
            </div>

            <div className="mb-5 col-span-2">
            <label htmlFor="maindescription" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Content <span className="text-sm text-gray-500"></span>
                    </label>
            <RichTextEditor
                onChange={(value) => setContent(value)}
                name="maindescription"
                value={content}
             
            />
           
            {errors.content && <div className="text-red-600 text-s">{errors.maindescription.message}</div>}
            </div>
            {contentError && <div className="text-red-600 text-s">{contentError}</div>}

            <div className="mb-5 col-span-2">
            <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Upload Image <span className="text-sm text-gray-500">(Max size: {formatFileSize(MAX_FILE_SIZE)})</span>
                </label>
                    {imagePreview && (
                        
                            
                        <div className="relative w-48 h-48 mb-4">
                            <img 
                                src={imagePreview} 
                                alt="Preview" 
                                className="w-full h-full object-cover rounded-lg shadow-md"
                            />
                        </div>
                    
                )}
            
                <input 
                    type="file" 
                    id="image" 
                    ref={imageRef}
                    onChange={handleImageChange}
                    accept="image/*"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                />
                {imageError && <div className="text-red-600 text-s">{imageError}</div>}
                <span className="form-text text-warning small">
                    Recommended: Use landscape images under 350 KB.
                  </span>
            </div>

            <div className="mb-5">
                <label htmlFor="displayorder" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Display Order</label>
                <input 
                    type="number" 
                    id="displayorder" 
                    {...register("displayorder", { required: false })} // Remove required validation for "Save as draft"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                />
                
            </div>

            <div className="flex items-center space-x-3">
            <Controller
              name="highlight"
              control={control}
              render={({ field }) => (
                <Highlight
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
                />
              )}
            />
            <label htmlFor="highlight" className="text-sm font-medium text-gray-700">
            Check this to highlight the news.
            </label>
          </div>

            <button 
                type="button" 
                onClick={() => handleFormSubmit(getValues(), 0)}
                className="col-span-2 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-3"
            >
                Save as draft
            </button>

            <button 
                type="button"
                onClick={handleSubmit((data) => handleFormSubmit(data, 1))}
                className="col-span-2 w-full text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
                Save and publish
            </button>

            {susmsg && <div className="text-green-600 text-lg ml-6 text-center inline-block whitespace-nowrap col-span-2">
                News created successfully
            </div>}
        </form>

        {loading && <Loader />}  {/* Show loader when loading */}
        </>
    );
};

export default Createnews;