import React, { useEffect, useState ,useRef } from 'react'
import sessionQueries from '../../queries/sessionQueries';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import Loader from '../Loader/Loader';
import { useNavigate } from 'react-router-dom';
import RouteConstants from '../../constant/Routeconstant';
import { PencilSquareIcon ,TrashIcon} from '@heroicons/react/24/solid';
import Pagination from '../../common/Pagination/Pagination';
import { Search,Edit ,Calendar } from 'lucide-react';
import siteQueries from '../../queries/siteQueries';

export default function SessionList() {

    const [sessionData, setSessionData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [response, setResponse] = useState();
    const [statusFilter, setstatusFilter] = useState(''); 
    const [searchTerm, setSearchTerm] = useState('');
    const [venueList, setVenueList] = useState([]);
    const [selectedVenue, setSelectedVenue] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [speakerFilter, setSpeakerFilter] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [filterSpeakers, setFilterSpeakers] = useState([]);
    const [selectedSpeaker, setSelectedSpeaker] = useState('');

    const navigate = useNavigate();
    const dropdownRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    
    // Check localStorage
    const storedPage = localStorage.getItem('sessionlistcurrentpage');
    if (storedPage) {
      setCurrentPage(parseInt(storedPage, 10));
      localStorage.removeItem('sessionlistcurrentpage');
     
    }
    venueListing.mutateAsync();
    FilterSpeaker.mutateAsync();
    // Make API call with all parameters
    sessionListing.mutateAsync({
      page: currentPage,
      size: pageSize,
      status: statusFilter,
      search: searchTerm,
      venueId: selectedVenue, // Add venue filter to the query
      date: selectedDate,
      speakerId:selectedSpeaker
    });
  }, [currentPage, pageSize, statusFilter,searchTerm,selectedVenue,selectedDate,selectedSpeaker]);

  const handleVenueChange = (e) => {
    setSelectedVenue(e.target.value);
    setCurrentPage(0); // Reset to first page when venue filter changes
  };
  // const handleSpeakerSelect = (speaker) => {
  //   if (speaker) {
  //     setSelectedSpeaker(speaker.id); // Set the selected speaker's ID
  //     setSpeakerFilter(speaker.heading); // Set the speaker's name for display
  //   } else {
  //     setSelectedSpeaker(null); // Reset the speaker filter
  //     setSpeakerFilter('');
  //   }
  //   setIsDropdownOpen(false);
  // };
  const handleSpeakerSelect = (speaker) => {
    if (typeof speaker === 'string' && speaker === '') {
      // Handle "All Speakers" selection
      setSelectedSpeaker(''); // Clear the speaker ID
      setSpeakerFilter(''); // Clear the display text
    } else if (speaker) {
      // Handle specific speaker selection
      setSelectedSpeaker(speaker.id);
      setSpeakerFilter(speaker.heading);
    }
    setIsDropdownOpen(false);
  };
  const FilterSpeaker = siteQueries.useSpeakerlistsitelistingMutation(
    async (response) => {
        setFilterSpeakers(response?.data);
       
    },
);
useEffect(() => {
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  document.addEventListener('mousedown', handleClickOutside);
  return () => document.removeEventListener('mousedown', handleClickOutside);
}, []);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
    setCurrentPage(0);
  };

  const venueListing = sessionQueries.useVenuelistMutation((response) => {
    const venueOptions = response.data.map(item => ({
      value: item.id.toString(),
      label: item.name
    }));
    setVenueList(venueOptions);
  });
  
     
    const sessionListing = sessionQueries.useSessionlistMutation((response) => {
        setSessionData(response?.data?.items);
        setTotalCount(response?.data?.totalItems);
        setResponse(response?.data?.totalPages);
        setIsLoading(false); 
    });

    const handleAddSession = () => {
        navigate(RouteConstants.SESSIONFORM); 
    };

    
    
    function formatDate(isoDate) {
        const date = new Date(isoDate);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
        const year = date.getUTCFullYear();
      
        return `${day}-${month}-${year}`;
    }

    const handleSearch = (e) => {
      const newSearchTerm = e.target.value;
      setSearchTerm(newSearchTerm);
      setCurrentPage(0); // Reset to first page on new search
    };

   
    const handleEditSession = (data) => {
      navigate(`/edit-session/${data?.id}`, { 
        state: { 
          currentPage: currentPage,  
          pageSize: pageSize         
        } 
      });
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
const handlestatusFilterChange = (e) => {
        setstatusFilter(e.target.value);
        setCurrentPage(0);
      };

    const deleteSessions = sessionQueries.useSessionDeleteMutation(
    (response) => {
     
      sessionListing.mutateAsync({
            page: currentPage,
            size: pageSize,
            status: statusFilter,
            search: searchTerm,
            venueId: selectedVenue, // Add venue filter to the query
            date: selectedDate,
            speakerId:selectedSpeaker
        });
    }
  );

  const handleDelete = async (event, id) => {
    event.preventDefault();
    if (window.confirm('Are you sure you want to delete this item?')) {
      await deleteSessions.mutateAsync(id);
    }
  }

    const publishsession = sessionQueries.useSessionPublishMutation(
    (response) => {
      if (response?.message === "published successfully") {
        alert("published successfully")
       sessionListing.mutateAsync({
            page: currentPage,
            size: pageSize,
            status: statusFilter,
            search: searchTerm,
            venueId: selectedVenue, // Add venue filter to the query
            date: selectedDate,
            speakerId:selectedSpeaker
        });
      }
      else if (response?.message === "unpublished successfully") {
        alert("unpublished successfully")
       sessionListing.mutateAsync({
             page: currentPage,
            size: pageSize,
            status: statusFilter,
            search: searchTerm,
            venueId: selectedVenue, // Add venue filter to the query
            date: selectedDate,
            speakerId:selectedSpeaker
        });
      }
    }
  );

   

  const handlepublish = (id) => {
    publishsession.mutateAsync(id);
  }
  
  

    let totalPages = response;
                 
    return (
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            <div className="flex justify-between items-center mb-4">
                <Breadcrumb pageName="Programme" />

                <button 
                    className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-2 shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
                    title="Add Session"
                    aria-label="Add Session"
                    onClick={handleAddSession}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                    </svg>
                </button>
            </div>
      
     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
      {/* Search Input */}
      <div className="relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
        <input
          type="text"
          placeholder="Search session name..."
          className="w-full h-10 rounded-md border border-gray-200 bg-white pl-10 pr-4 outline-none focus:border-primary"
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>

      {/* Venue Dropdown */}
      <div>
        <select
          value={selectedVenue}
          onChange={handleVenueChange}
          className="w-full h-10 rounded-md border border-gray-200 bg-white px-4 outline-none focus:border-primary appearance-none cursor-pointer"
        >
          <option value="">All Venues</option>
          {venueList.map((venue) => (
            <option key={venue.value} value={venue.value}>
              {venue.label}
            </option>
          ))}
        </select>
      </div>

      {/* Date Input */}
      <div>
        <input
          type="text"
          placeholder="dd-mm-yyyy"
          onFocus={(e) => e.target.type = 'date'}
          onBlur={(e) => {
            if (!e.target.value) e.target.type = 'text'
          }}
          value={selectedDate}
          onChange={handleDateChange}
          className="w-full h-10 rounded-md border border-gray-200 bg-white px-4 outline-none focus:border-primary"
        />
      </div>

      {/* Status Dropdown */}
      <div>
        <select
          value={statusFilter}
          onChange={handlestatusFilterChange}
          className="w-full h-10 rounded-md border border-gray-200 bg-white px-4 outline-none focus:border-primary appearance-none cursor-pointer"
        >
          <option value="">All Status</option>
          <option value="PUBLISH">Published</option>
          <option value="UNPUBLISH">Unpublished</option>
        </select>
      </div>

      {/* Speaker Filter Dropdown */}
      <div className="relative" ref={dropdownRef}>
        <input
          type="text"
          className="w-full h-10 rounded-md border border-gray-200 bg-white px-4 outline-none focus:border-primary cursor-pointer"
          placeholder="Search speakers..."
          value={speakerFilter}
          onChange={(e) => {
            setSpeakerFilter(e.target.value);
            setIsDropdownOpen(true);
          }}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        />
        {isDropdownOpen && (
          <div className="absolute w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-y-auto z-50">
            <button
              className={`w-full px-4 py-2 text-left hover:bg-blue-50 ${!selectedSpeaker ? 'bg-blue-50' : ''}`}
              onClick={() => handleSpeakerSelect('')}
            >
              All Speakers
            </button>
           {filterSpeakers
  .filter((speaker) =>
    speaker.heading.toLowerCase().includes(speakerFilter.toLowerCase())
  )
  .map((speaker) => (
    <button
      key={speaker.id}
      className={`w-full px-4 py-2 text-left hover:bg-blue-50 ${
        selectedSpeaker === speaker.id ? 'bg-blue-50' : ''
      }`}
      onClick={() => handleSpeakerSelect(speaker)} // Pass the entire speaker object
    >
      {speaker.heading}
    </button>
  ))}

          </div>
        )}
      </div>
    </div>

      

      

            {isLoading ? (
                <Loader />
            ) : (
                <div className="flex flex-col">
                    <div className="grid grid-cols-[.3fr_1fr_.5fr_.5fr_.5fr_1fr_.5fr_.5fr_.5fr_.5fr] sm:grid-cols-[.3fr_1fr_.5fr_.5fr_.5fr_1fr_.5fr_.5fr_.5fr_.5fr] border-b border-stroke bg-gray-800 dark:bg-meta-4">
                        <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Sl no.</div>
                        <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Session Name</div>
                        <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Date</div>
                        <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Timing</div>
                        <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Venue</div>
                        <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Publish Status</div>
                        <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Language</div>
                        <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Highlight</div>
                        <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Delegate</div>
                        <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Actions</div>
                    </div>

                    {sessionData && sessionData?.length > 0 ? (
                        sessionData?.map((session, index) => (
                            <div
                                key={index}
                                className="grid grid-cols-[.3fr_1fr_.5fr_.5fr_.5fr_1fr_.5fr_.5fr_.5fr_.5fr] sm:grid-cols-[.3fr_1fr_.5fr_.5fr_.5fr_1fr_.5fr_.5fr_.5fr_.5fr] border-b border-stroke bg-gray-200 dark:bg-meta-4"
                            >
                                <div className="p-2 text-xs xsm:text-sm">
                                    {currentPage * pageSize + index + 1}
                                </div>
                                <div className="p-2 text-xs xsm:text-sm overflow-hidden text-ellipsis">
                                    {session?.name} : 
                                     <p className="font-xm" style={{fontSize:"15px"}}><b>Speakers:</b></p>
                                      {session.session_speackers.length > 0 ? (
                                        <p className="ml-4" style={{fontSize:"14px"}}>
                                          {session.session_speackers
                                            .map(s => s.speaker.heading)
                                            .join(', ')}
                                        </p>
                                      ) : (
                                        <p className="ml-4 text-gray-500 italic">No speakers assigned</p>
                                      )}

                                       <p className="font-xs" style={{fontSize:"15px"}}><b>Moderators:</b></p>
                                      {session.session_moderators.length > 0 ? (
                                          <p className="ml-4" style={{fontSize:"14px"}}>
                                            {session.session_moderators
                                              .map(m => m.speaker.heading)
                                              .join(', ')}
                                          </p>
                                        ) : (
                                          <p className="ml-4 text-gray-500 italic">No moderators assigned</p>
                                        )}

                                      <p className="font-xs" style={{fontSize:"15px"}}><b>storyteller:</b></p>
                                       {session?.speakers?.length > 0 ? (
                                            <p className="ml-4" style={{ fontSize: "14px" }}>
                                              {session.speakers.map((s) => s.heading).join(", ")}
                                            </p>
                                        ) : (
                                          <p className="ml-4 text-gray-500 italic">No storyteller assigned</p>
                                        )}
                                </div>
                                
                                <div className="p-2 text-xs xsm:text-sm">
                                   
                                    {new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(session?.date))}

                                </div>
                                
                                <div className="p-2 text-xs xsm:text-sm">
                                   
                                    {new Date(`1970-01-01T${session?.time_from}`).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} - 
                                    {new Date(`1970-01-01T${session?.time_to}`).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                                </div>
                                <div className="p-2 text-xs xsm:text-sm overflow-hidden text-ellipsis">
                                    {session?.venue?.name}
                                </div>
                                <div className="p-2 text-xs xsm:text-sm">
                                  {session?.status === 'UNPUBLISH' ? (
                                    session?.name  ? (
                                      <button
                                        className="px-3 py-1 text-xs font-medium text-red-500 bg-red-100"
                                         onClick={() => handlepublish(session?.id)}
                                        
                                      >
                                        Unpublished
                                      </button>
                                    ) : (
                                      <button
                                        className="px-3 py-1 text-xs font-medium text-yellow-500 bg-yellow-100"
                                        onClick={() => alert("This item is in draft mode and cannot be published until all required fields are filled.")}
                                      >
                                        Draft
                                      </button>
                                    )
                                  ) : (
                                    <button
                                      className="px-3 py-1 text-xs font-medium text-green-500 bg-green-100"
                                       onClick={() => handlepublish(session?.id)}
                                      
                                    >
                                      Published
                                    </button>
                                  )}
                                </div>
                                <div className="p-2 text-xs xsm:text-sm">
                                    {session?.language}
                                </div> 
                                <div className="p-2 text-xs xsm:text-sm">
                                {session?.highlight === 0 ? "No" : session?.highlight === 1 ? "Yes" : null}
                                </div>
                                <div className="p-2 text-xs xsm:text-sm">
                                    {session?.delegative_exclusive}
                                </div>            
                                <div className="p-2 text-xs xsm:text-sm flex items-center">
                                    <PencilSquareIcon
                                        onClick={() => handleEditSession(session)}
                                        className="h-6 w-6 text-black-600 cursor-pointer hover:text-blue-600"
                                        aria-hidden="true"
                                    />
                                     <TrashIcon
                                      onClick={(e) => handleDelete(e, session?.id)}
                                      className="h-6 w-6 text-black-600 cursor-pointer hover:text-red-600"
                                      aria-hidden="true"
                                    />
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="p-5">
                            <p className="text-center text-black dark:text-white">
                                No sessions available.
                            </p>
                        </div>
                    )}
                    
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </div>
            )}
        </div>
    );
}

