import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import userQueries from '../../queries/userQueries';
import Loader from '../Loader/Loader';
const Editslider = () => {
  
    const [susmsg, setSusmsg] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageUploadSuccess, setImageUploadSuccess] = useState(false);
    const [imageError, setImageError] = useState('');
    const [loading, setLoading] = useState(false);
    const imageRef = useRef(null);
    const [currentImageUrl, setCurrentImageUrl] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [currentPlatform, setCurrentPlatform] = useState('');

    const { id } = useParams();
    const navigate = useNavigate();

    const { register, reset, handleSubmit,getValues, formState: { errors } } = useForm();

    const photogallerydata = userQueries.useUpdatesliderMutation(
        (response) => {
            console.log("response is",response)
            if (response?.data) {
                let datas = ({
                    id: id,
                    displayorder: response?.data?.displayorder,
                    platform: response?.data?.platform || '',
                    link: response?.data?.link || '',
                });
                reset({ ...datas });
                setCurrentImage(response?.data?.photo || null);
                setCurrentImageUrl(response?.data?.photo ? `${process.env.REACT_APP_BASE_URL}/${response?.data?.photo}` : null);
                setCurrentPlatform(response?.data?.platform || '');
            }
        }
    );

    useEffect(() => {
        photogallerydata.mutateAsync({ id });
    }, [id]);

    const editphotogallery = userQueries.useUpdatesliderMutation(
        (response) => {
            if (response?.message === "Success") {
                setSusmsg(true);
                alert(response?.data?.status === 'Unpublish' ? "Saved as draft" : "Published successfully");
                navigate('/sliderlisting');
            }
           
        }
    );

    // const handleImageChange = (e) => {
    //     if (e.target.files && e.target.files[0]) {
    //         setSelectedImage(e.target.files[0]);
    //     }
    // };
    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setSelectedImage(file);
            
            // Create a preview URL for the newly selected image
            const objectUrl = URL.createObjectURL(file);
            setPreviewUrl(objectUrl);
        }
    };

    const handleFormSubmit = async (data, publishStatus) => {
        setLoading(true);// Start loading

        const allowedTypes = ['image/jpeg', 'image/jpg'];
        const MAX_FILE_SIZE = 500 * 1024; // 1MB in bytes
        if(selectedImage)
        {
            if (!allowedTypes.includes(selectedImage.type)) {
                setImageError("Only JPEG, JPG files are allowed.");
                setLoading(false);
                return;
            }
    
            // Check file size
            if (selectedImage.size > MAX_FILE_SIZE) {
                setImageError("File size must be less than or equal to 500Kb.");
                setLoading(false);
                return;
            }
        }
       

        if(publishStatus === 'Publish')
        {
                
           
            const allowedTypes = ['image/jpeg','image/jpg'];
            if(selectedImage){
                if (!allowedTypes.includes(selectedImage.type)) {
                    setImageError("Only JPEG, JPG and PNG files are allowed.");
                    setLoading(false);  // Re-enable buttons if validation fails
                    return false;
                }
            }
            else if(!selectedImage && !currentImage)
            {
                setImageError("Image Required");
                setLoading(false);  // Re-enable buttons if validation fails
                return false;
            }
        }

        const datavalues = {
            id: id,
            slider: selectedImage,
            status: publishStatus,
            displayorder: data?.displayorder || 1,
            platform: data?.platform || currentPlatform,
            link: data?.link,


        };
       console.log(datavalues)
        try {
            await editphotogallery.mutateAsync(datavalues);
           
        } catch (error) {
            console.error("Error updating slider:", error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        return () => {
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
            }
        };
    }, [previewUrl]);

    return (
        <>
            <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
                Update Slider
            </h4>
            <form className="max-w-lg mx-auto grid grid-cols-2 gap-4">
                

                {/* New Image Upload Section */}
                <div className="mb-5 col-span-2">
                    <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Upload Image
                    </label>
                     {/* Image Preview Section */}
                     <div className="mb-4">
                     {(previewUrl || currentImageUrl) && (
                         <div className="relative w-48 h-48 mb-4">
                             <img
                                 src={previewUrl || currentImageUrl}
                                 alt="Speaker preview"
                                 className="w-full h-full object-cover rounded-lg shadow-md"
                             />
                         </div>
                     )}
                 </div>
                    <input
                        type="file"
                        id="image"
                        ref={imageRef}
                        onChange={handleImageChange}
                        accept="image/*"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                    <span class="form-text text-warning small">Recommended image size: less than 1000 KB.</span>
                    
                    {imageError && <div className="text-red-600 text-s">{imageError}</div>}
                    {imageUploadSuccess && (
                        <div className="mt-2 text-sm text-green-600">
                            Image uploaded successfully!
                        </div>
                    )}
                </div>

                 <div className="mb-5">
                    <label htmlFor="link" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Link</label>
                    <input 
                        type="text" 
                        id="link" 
                        {...register("link", { required: false })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                   
                </div>

                <div className="mb-5">
                    <label htmlFor="displayorder" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Display Order</label>
                    <input 
                        type="number" 
                        id="displayorder" 
                        {...register("displayorder", { required: false })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                   
                </div>

                {/* Platform Selection Section */}
                <div className="mb-2 col-span-1">
                    <label htmlFor="platform" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Platform
                    </label>
                    <select
                        id="platform"
                        {...register("platform", { required: false })}
                        value={currentPlatform}
                        onChange={(e) => setCurrentPlatform(e.target.value)} // Update platform on change
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    >
                        
                        <option value="Web">Web</option>
                        <option value="Mobile">Mobile</option>
                    </select>
                </div>

                {/* Save as Draft Button */}
                <button 
                    type="button"
                    onClick={() => handleFormSubmit(getValues(), 'Unpublish')}
                    className="col-span-2 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-3"
                >
                    Save as draft
                </button>

                <button 
                    type="button"
                    onClick={handleSubmit((data) => handleFormSubmit(data, 'Publish'))}
                    className="col-span-2 w-full text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Save and publish
                </button>

                {susmsg && (
                    <div className="text-green-600 text-lg ml-6 text-center inline-block whitespace-nowrap col-span-2">
                       Slider updated successfully
                    </div>
                )}
            </form>
            {loading && <Loader />}
        </>
    );
};

export default Editslider;
