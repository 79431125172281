import logo from './logo.svg';
import './App.css';
import AppRoutes from './Routes/AppRoute';
import { BrowserRouter } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Chatbot from './site/chatbot/Chatbot';
import { userStore } from './store/userStore';


function App() {

  const [chatbot, setChatbot] = useState(true)

  const getSettings = async () => {
    try {
      const response = await fetch("https://themagweb-klfsessions.s3.ap-south-1.amazonaws.com/settings.json");
      const data = await response.json();
      const chatbot = data?.chatbot;
      if(chatbot){
        setChatbot(true)
      }else{
        setChatbot(false)
      }
    }catch(error){
      console.error("Error fetching or parsing the settings:", error.message);
    }
  }

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <div className="App">
        {
          chatbot &&
          <Chatbot />
        }
      <AppRoutes />
    </div>
  );
}

export default App;
