import React, { useState } from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

const ageOptions = Array.from({ length: 100 }, (_, i) => ({
  value: i + 10,
  label: `${i + 10}`
}));

function AgeInput({ control, errors }) {
  return (
    <div className="col-md-3 age-wrpr">
      <Controller
        name="age"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            options={ageOptions}
            className={`form-control ageinput ${errors.age ? 'is-invalid' : ''}`}
            placeholder="Age"
            isClearable
          />
        )}
      />
      {errors.age && <div className="invalid-feedback">{errors.age.message}</div>}
    </div>
  );
}

export default AgeInput;
