import React, { useState,useEffect } from "react";
import userQueries from '../../queries/userQueries';
import adminQueries from '../../queries/adminQueries';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";



const EditAdmin = () => {
  
    const [usernameExist, setUsernameExist] = useState('')
    const [usernameerror,setUsernameerror] = useState(false);
    const [susmsg,setSusmsg] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();


    const { id } = useParams();
    const { userDetails } = location.state || {};

    const checkEmail = adminQueries.useEmailChekingMutation({

    })
    
     const admin = userQueries.useSpeakerupdateMutation(
        (response) => {
            if(response?.data){
                let datas = {
                    id: id,
                    first_name: response?.data.first_name,
                    last_name: response?.data.last_name,
                    age: response?.data.age,
                    email: response?.data.email,
                    gender: response?.data.gender,
                    phone: response?.data.phone,
                    pin_code: response?.data.pin_code,
                };
                
                // Reset the form with the fetched data
                reset({ ...datas });
               
            }
        }
    );
    useEffect(() => {
        admin.mutateAsync({ id });
    }, [id]);


    const { register ,reset, control, handleSubmit, formState:{ errors } } = useForm({
        
    });
  
    const editadmin = userQueries.useSpeakerupdateMutation(
        (response) => {
            if(response?.message === "Success"){
        alert("Admin updated successfully");
         navigate('/adminlisting');
            }
            
        }   
    );
        
    
    const onSubmit = async (data) => {
        try {
          const existingEmail = userDetails.email; 
          // Check if the email has changed
          if (data.email !== existingEmail) {
            const response = await checkEmail.mutateAsync({ email: data.email });
            console.log(">>>>response>>", response)
             if (response.message === "Email already exists") {
              alert("Email already in use");
              return;
            }
          }
      
          // Prepare data for update
          const datavalues = {
            id,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone: data.phone,
            username: data.email,
          };
      
          // Update speaker data
          await editadmin.mutateAsync(datavalues);
        //   alert("Admin updated successfully");
        } catch (error) {
          console.error("Error in onSubmit:", error);
          alert(`An error occurred. Please try again. ${error.message}`);
        }
      };
      
    return (
        <>
        <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            Update Admin
        </h4>
        <form  
        className="max-w-lg mx-auto grid grid-cols-2 gap-4"
        onSubmit={handleSubmit(onSubmit)}>
    
        {/* First Name */}
        <div className="mb-1 col-span-2">
            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
            <input type="text" id="first_name" {...register("first_name", { required: "First name is required" })} 
            name="first_name" 
            defaultValue={userDetails?.first_name}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" />
            {errors.first_name && <div className="text-red-600 text-sm">{errors.first_name.message}</div>}
        </div>
    
        {/* Last Name */}
        <div className="mb-1 col-span-2">
            <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
            <input type="text" id="last_name" {...register("last_name",{ required: "Last name is required" })} 
            name="last_name" 
            defaultValue={userDetails?.last_name}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" />
            {errors.last_name && <div className="text-red-600 text-sm">{errors.last_name.message}</div>}
        </div>

         {/* Email */}
         <div className="mb-1 col-span-1">
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
            <input type="email" id="email" 
            {...register("email", { required: "Email name is required" })} 
            defaultValue={userDetails?.email}
            name="email" 
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" />
            {usernameerror && <div className="text-red-600 text-s">Please Enter email</div>}
            {usernameExist && <div className="text-red-600 text-s">Email already exists</div>}
            {errors.email && <div className="text-red-600 text-sm">{errors.email.message}</div>}
        </div>
         
        {/* Phone */}
        <div className="mb-1 col-span-1">
            <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
            <input type="tel" id="phone" {...register("phone", { required: "Phone name is required" })} 
            name="phone" 
            defaultValue={userDetails?.phone}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" />
            {errors.phone && <div className="text-red-600 text-sm">{errors.phone.message}</div>}
        </div>

        {/* Password */}
        {/* <div className="mb-1 col-span-1">
                    <label htmlFor="password" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                    <input 
                        type="password" 
                        id="password" 
                        {...register("password", { required: "Password is required" })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    {errors.password && <div className="text-red-600 text-sm">{errors.password.message}</div>}
                </div> */}
    
        <button type="submit" className="col-span-2 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
           Update Admin
        </button>
    
        {susmsg && <div className="text-green-600 text-lg ml-6 text-center inline-block whitespace-nowrap">
            Admin updated successfully
        </div>}
    </form>
    
    
        </>
    );
};

export default EditAdmin;
