import React, { useEffect, useState } from 'react';
import patterns from '../images/pattern-for-counter-section.png';
import ravi from '../images/ravi.jpeg';
import Footer from './Footer';
import Navbar from './Navbar';
import InitModal from './initModal';
import { useScrollToTop } from '../hooks/ScrollTop';
import KLF20241 from '../images/KLF-2024-1.jpeg';
import KLF20242 from '../images/KLF-2024-2.jpeg';
import KLF20243 from '../images/KLF-2024-3.jpeg';
import KLF20245 from '../images/KLF-2024-5.jpeg';
import KLF20246 from '../images/KLF-2024-6.jpeg';
import KLF20247 from '../images/KLF-2024-7.jpeg';
import WELCOME1 from '../images/welcome_3.jpeg';
import WELCOME2 from '../images/welcome_1.jpeg';
import WELCOME5 from '../images/welcome_5.jpeg';
import WELCOME6 from '../images/tharoor.jpeg';
import WELCOME7 from '../images/welcome_7.jpeg';
import WELCOME4 from '../images/welcome_4.jpeg';
import pattern1 from '../images/pattern-for-counter-section.png';


const Welcome = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  return (
    <div>
      <Navbar handleOpen={handleOpen} />
      <div className="w-full">
        {/* Banner */}
        <div className="innerbanner container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <span className="logo-strips" />
                <h2>Welcome to the 8th Edition of the Kerala Literature Festival</h2>
              </div>
            </div>
          </div>
        </div>

        {/* Festival Content */}
        <div className="container festival-on-beach">
          <div className="row">
            {/* Dear Friends Section */}

            <div class="col-md-10 pe-5 my-auto dearfreiends-wrpr">
              <h2><img class="dear-friends" src={patterns} /> Dear Friends,</h2>
              <p>I am delighted to welcome you to the 8th edition of the Kerala Literature Festival (KLF), a vibrant celebration of stories, ideas, cultures, and conversations, held on the historic shores of Kozhikode, now proudly recognized as Kerala’s first UNESCO City of Literature. We are honored to have UNESCO as the Official Knowledge Partner for this edition, strengthening KLF’s role as a global platform for literary and cultural exchange.</p>
              <p>Over the years, KLF has grown into one of the largest and most dynamic literary festivals in the world, where Nobel and Booker Prize-winning authors, acclaimed writers from India and abroad, cinema legends, musicians, performers, and filmmakers come together to celebrate the power of words and creative expression.</p>
            </div>

            <div class="col-md-2 ps-0 innagupic"><br/>
              <img src={ravi} />
            </div> 

            <div class="col-md-4 ps-0 innagupic">
              <img src={WELCOME1} />
            </div> 

            <div class="col-md-8 pe-5 my-auto dearfreiends-wrpr">
              <p>This year, we are privileged to welcome France as the Guest Nation. A distinguished French delegation of writers, publishers, and cultural ambassadors, led by the Ambassador of France in India, will enrich the festival with France’s rich literary heritage and vibrant contributions to art, cuisine, and performance. Their participation adds a global dimension to KLF, fostering meaningful cross-cultural dialogue and creative exchange.</p>
              <p>Adding even more excitement, the 26th DC International Book Fair is being held alongside KLF, offering visitors access to over one million books from 350 publishers around the world. This grand literary showcase provides an unmatched opportunity for readers and collectors to explore a vast array of literature across genres, languages, and cultures.</p>
            </div>

            <div class="col-md-8 pe-5 my-auto dearfreiends-wrpr">
              <p>Guiding KLF’s vision since its inception has been the world-renowned poet and literary figure, Prof. K. Satchidanandan, whose role as Festival Director for the past seven editions has been invaluable. His profound understanding of literature and global cultures has shaped KLF into a platform that not only celebrates literature but also promotes diverse voices and creative collaborations.</p>
              <p>Continuing the inspiring legacy of DC Kizhakemuri, the third generation—Govind Deecee and Siddarth Deecee—are now leading the festival with passion and vision. Their dynamic leadership brings the best of global intellectual discourse to KLF while introducing innovative and immersive experiences for every attendee.</p>
            </div>
            <div class="col-md-4 ps-0 innagupic">
              <img src={WELCOME2} />
            </div> 

            <div class="col-md-12 my-auto mb-5">	
            <div class="card strengthening-card mt-5">
              <p><img class="dear-friends" src={pattern1}/> Strengthening this effort is the dedicated Team DC Books, whose intellectual capital and relentless commitment have made KLF even more engaging and vibrant. Their hard work continues to elevate KLF as a beacon of literary and cultural celebration.</p>
            </div>
          </div>

          <div class="col-md-4 ps-0 innagupic">
            <img src={WELCOME5} />
          </div> 
          <div class="col-md-8 pe-5 my-auto dearfreiends-wrpr">
            <p>With close to 600 speakers participating in thought-provoking sessions, we are proud to anticipate over 600,000 attendees across four remarkable days. This extraordinary gathering reflects the universal love for literature and the arts, making KLF a truly democratic festival driven by public participation and collective enthusiasm.</p>
            <p>KLF draws inspiration from Kerala’s rich literary tradition. From the Revathi Pattathanam Sabha at the Thali Temple in Kozhikode in the 14th century to DC Kizhakemuri’s pioneering of India’s literary festival movement with the Samastha Kerala Sahitya Parishad in 1957, KLF continues this legacy of intellectual gatherings. In 2024, DC Books, a principal promoter of KLF, proudly celebrates 50 years of nurturing literary culture.</p>
          </div>

          <div class="col-md-12 mb-5 ">
            <div class="card strengthening-card">
              <div class="row">
                
                  <div class="col-md-4 ps-0 my-auto ">	
                    <img src={WELCOME6} style={{height:350, objectFit:'cover', marginBottom:0}}/>
                  </div>
                  <div class="col-md-8 my-auto ">	
                    <div class="custom-card">
                      <p>This year, KLF introduces exciting and innovative experiences:</p>
                      <ul class="klf-list">
                        <li>Page to Performance: Bringing stories alive on stage.</li>
                        <li>Tales On-Air: Live storytelling and audio experiences.</li>
                        <li>Interactive Children’s Sessions: Featuring stars like Prakash Raj, Naseeruddin Shah, and Nadia Moidu.</li>
                        <li>KLF Music Stage with dynamic performances: Music by Muktiyar Ali, Harish Sivaramakrishnan of Agam, Stephen Devassy, and Atul Nerukara.</li>
                        <li>A Bird’s Eye View into the Hortus Malabaricus Garden prototype at Cheruthuruthy.</li>
                        <li>The Kerala Startup Mission’s Super Fab Lab and innovation showcase.</li>
                        <li>Kerala Lalithakala Akademi’s video installations.</li>
                        <li>Two-day Publishers conference interacting with French publishers delegation.</li>
                        <li>An AR/VR experience of Kafka’s works for an immersive literary journey.</li>
                        
                      </ul>
                    </div>
                  </div>
              </div>
            </div>
          </div>

        <div class="col-md-8 pe-5 my-auto dearfreiends-wrpr">
          <p>No festival in Kozhikode would be complete without a culinary journey. The Paragon Food Court, a festival partner since inception, continues to serve the authentic flavors of Calicut, offering guests a world-class dining experience.</p>
          <p>The unwavering support from the Government of Kerala, especially the Honorable Chief Minister, along with contributions from the Ministry of Culture and the Ministry of Tourism, has been instrumental in KLF’s evolution into one of the largest literary festivals in the world. The involvement of cultural organizations like Samskarika Vedi and the Calicut Chapter of the Indian Institute of Architects has also played a vital role in engaging and inspiring the youth.</p>
        </div>
        <div class="col-md-4 ps-0 innagupic">
          <img src={WELCOME7}/>
        </div> 

        <div class="col-md-4 ps-0 innagupic">
          <img src={WELCOME4} />
        </div> 
        <div class="col-md-8 pe-5 my-auto dearfreiends-wrpr">
          <p>KLF has set a precedent for literary festivals across Kerala, inspiring similar events by media houses, corporations, academic institutions, cities, panchayats, and NGOs. Together, we are nurturing a culture of reading, sparking intellectual dialogue, and creating spaces for meaningful exchanges.</p>
          <p>As we celebrate 50 years of DC Books and the vibrant literary spirit of Kerala, KLF stands as a bridge between the past and the future, the local and the global.</p>
          <p>This year, KLF’s programming grew beyond expectations with an exciting lineup of sessions and guests. To accommodate the demand, we expanded from 7 to 9 stages, including separate children’s stages tailored to different age groups, ensuring a more focused and engaging experience for all.</p>
        </div>
		
        <div class="col-md-12 my-auto">
				<p>I extend my deepest gratitude to every supporter, partner, sponsor, and the thousands of attendees who make this celebration of literature possible.</p>
				<p>From the page to the stage, from insightful discussions to artistic performances, I invite you all to join us in celebrating the boundless joy of words, ideas, and stories.</p>
				<p>Welcome to KLF 2025!</p>
				
				<p>Warmly,</p>
				<h5 class="mb-0"><strong>Ravi Deecee</strong></h5>
					<p>Founder and Chief Facilitator<br/>
					Kerala Literature Festival</p>
		    </div>
      </div>
    </div>

        {/* Overview Section */}
        <div className="container-fluid klf-overview">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center text-white">
                <h2 className="mb-5">KLF 2025 Overview</h2>
              </div>
              <div className="col-md-12 my-auto">
                <div className="row">
                  <div className="col">
                    <div className="overview-circle yellow one">4</div>
                    <div className="overview-text">Days</div>
                  </div>
                  <div className="col">
                    <div className="overview-circle red two">600</div>
                    <div className="overview-text">Speakers</div>
                  </div>
                  <div className="col">
                    <div className="overview-circle green three">5,00,000</div>
                    <div className="overview-text">Audience</div>
                  </div>
                  <div className="col">
                    <div className="overview-circle blue four">400</div>
                    <div className="overview-text">Programme</div>
                  </div>
                  <div className="col">
                    <div className="overview-circle orange five">9</div>
                    <div className="overview-text">Venues</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer handleOpen={handleOpen} />
      <InitModal isOpen={isOpen} onClose={handleClose} />
    </div>
  );
};

export default Welcome;