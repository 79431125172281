import React, { useState, useEffect } from 'react';
import { Search,Edit } from 'lucide-react';
import userQueries from '../../queries/userQueries';
import { userStore } from '../../store/userStore';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../common/Pagination/Pagination';
import Loader from '../Loader/Loader';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import adminQueries from '../../queries/adminQueries';
import { PencilSquareIcon ,TrashIcon} from '@heroicons/react/24/solid';
import warning from 'antd/es/_util/warning';

const AdminListing = () => {      
  const [users, setUsers] = useState([]);   
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { searchResults } = userStore();
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    fetchUsers();
    
  }, [currentPage, pageSize, searchTerm]);

  useEffect(() => {
    if (searchResults?.length > 0) {
      setUsers(searchResults);
      setTotalCount(searchResults?.length);
      setCurrentPage(0);
    }
  }, [searchResults]);

  const fetchUsers = () => {
    const params = new URLSearchParams({
      page: currentPage,
      size: pageSize,
      search: searchTerm
    });
    adminList.mutate(params.toString());
  };

  const adminList = userQueries.useAdminListingMutation(
    async (response) => {
      // console.log('response is', response);
      setUsers(response?.data?.items);
      setTotalCount(response?.data?.totalItems);
      setTotalPage(response?.data?.totalPages);
      setIsLoading(false);
    },
    {
      onError: (error) => {
        console.error('Userlist fetch error:', error);
        setError('Failed to fetch user list. Please try again later.');
      }
    }
  );

  const changePermission = adminQueries.useAdminPermissionCreateMutation(
    (res)=>{
      console.log(res)
    }
  );
 
  const handleToggle = (userId, column, currentValue) => {
    // Compute the new value for the toggled column
    const updatedValue = currentValue ? 0 : 1;
    // Update the local state first
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.user_detail.user_id === userId
          ? {
              ...user,
              adminpermissions: [
                {
                  ...user?.adminpermissions[0] || [],
                  [column.toLowerCase()]: updatedValue, // Update the toggled column
                },
              ],
            }
          : user
      )
    );
  
    // Prepare the payload with updated values
    const updatedPayload = {
      userId: userId,
      // registration: users.find((user) => user.user_detail.user_id === userId).adminpermissions[0]?.registration,
      speakers: users.find((user) => user.user_detail.user_id === userId).adminpermissions[0]?.speakers,
      news: users.find((user) => user.user_detail.user_id === userId).adminpermissions[0]?.news,
      videos: users.find((user) => user.user_detail.user_id === userId).adminpermissions[0]?.videos,
      photogallery: users.find((user) => user.user_detail.user_id === userId).adminpermissions[0]?.photogallery,
      testimonials: users.find((user) => user.user_detail.user_id === userId).adminpermissions[0]?.testimonials,
      slider: users.find((user) => user.user_detail.user_id === userId).adminpermissions[0]?.slider,
      zohoexport: users.find((user) => user.user_detail.user_id === userId).adminpermissions[0]?.zohoexport,
      session: users.find((user) => user.user_detail.user_id === userId).adminpermissions[0]?.session,
      partnerlogo: users.find((user) => user.user_detail.user_id === userId).adminpermissions[0]?.partnerlogo,
      discountcoupon: users.find((user) => user.user_detail.user_id === userId).adminpermissions[0]?.discountcoupon,
      sessioncategory: users.find((user) => user.user_detail.user_id === userId).adminpermissions[0]?.sessioncategory,
      home: users.find((user) => user.user_detail.user_id === userId).adminpermissions[0]?.home,
      venuelist: users.find((user) => user.user_detail.user_id === userId).adminpermissions[0]?.venuelist,
      [column.toLowerCase()]: updatedValue,
    };
  
    // Trigger the mutation with the updated payload
    changePermission.mutateAsync(updatedPayload);
  };
    

  const handleSearch = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    setCurrentPage(0); 
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };


  const totalPages = totalPage;
const handleAddSpeaker = () => {
  navigate('/add-admin');
}
  // const handleEditSession = (user) => {
  //   navigate(`/adminedit/${user.user_detail.user_id}`);
  // }useDeleteadminMutation

  const deleteAdmin = adminQueries.useDeleteadminMutation(
    (response) => {
      if(response.message === "user deleted successfully"){
        fetchUsers();
      }
    }
  );
  

  const handleDelete = async (id) => {
    try {
      if(window.confirm('Are you sure you want to delete this admin?')){
        await deleteAdmin.mutateAsync(id);
      }
      
    } catch (error) {
      console.error("Error deleting admin:", error);
    }
  };

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
     <div className="flex justify-between items-center mb-4">
      <Breadcrumb pageName="Admins"/>
      {/* </div>
      <div className="flex flex-col sm:flex-row gap-2"> */}
      <button 
            className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-2 shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
            title="Add Admin"
            aria-label="Add Admin"
            onClick={handleAddSpeaker}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
          </button>
          </div>
      <div className="mb-4 flex items-center">
        <Search className="mr-2 h-5 w-5 text-gray-500" />
        <input
          type="text"
          placeholder="Search..."
          className="w-full rounded-md border border-stroke bg-transparent py-2 pl-10 pr-4 outline-none focus:border-primary dark:border-strokedark dark:bg-meta-4 dark:focus:border-primary"
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>

        {/* Display the count */}
        <div className="mb-4 text-gray-700 dark:text-gray-300">
        {users?.length} out of {totalCount} users found
      </div>
      {isLoading ? (
      <Loader /> 
    ) : (
  
  <>
  <div className="overflow-x-auto bg-white shadow-md rounded-lg">
    {/* Table */}
    <table className="table-auto w-full border-collapse border border-gray-200 dark:border-gray-700">
      {/* Table Header */}
      <thead className="bg-gray-800 dark:bg-meta-4 text-black">
        <tr>
          <th className="p-2 text-xs font-medium text-left uppercase border border-gray-300 dark:border-gray-700 xsm:text-sm">
            Sl No.
          </th>
          <th className="p-2 text-xs font-medium text-left uppercase border border-gray-300 dark:border-gray-700 xsm:text-sm">
            Name
          </th>
          {/* <th className="p-2 text-xs font-medium text-left uppercase border border-gray-300 dark:border-gray-700 xsm:text-sm">
            Admins
          </th> */}
          <th className="p-2 text-xs font-medium text-left uppercase border border-gray-300 dark:border-gray-700 xsm:text-sm">
            Registration
          </th>
          <th className="p-2 text-xs font-medium text-left uppercase border border-gray-300 dark:border-gray-700 xsm:text-sm">
            Speakers
          </th>
          <th className="p-2 text-xs font-medium text-left uppercase border border-gray-300 dark:border-gray-700 xsm:text-sm">
            News
          </th>
          <th className="p-2 text-xs font-medium text-left uppercase border border-gray-300 dark:border-gray-700 xsm:text-sm">
            Videos
          </th>
          <th className="p-2 text-xs font-medium text-left uppercase border border-gray-300 dark:border-gray-700 xsm:text-sm">
            Photo Gallery
          </th>
          <th className="p-2 text-xs font-medium text-left uppercase border border-gray-300 dark:border-gray-700 xsm:text-sm">
            Testimonials
          </th>
          <th className="p-2 text-xs font-medium text-left uppercase border border-gray-300 dark:border-gray-700 xsm:text-sm">
            Slider
          </th>
          <th className="p-2 text-xs font-medium text-left uppercase border border-gray-300 dark:border-gray-700 xsm:text-sm">
            Zoho
          </th>
          <th className="p-2 text-xs font-medium text-left uppercase border border-gray-300 dark:border-gray-700 xsm:text-sm">
          session
          </th>
          <th className="p-2 text-xs font-medium text-left uppercase border border-gray-300 dark:border-gray-700 xsm:text-sm">
          sessioncategory
          </th>
          <th className="p-2 text-xs font-medium text-left uppercase border border-gray-300 dark:border-gray-700 xsm:text-sm">
          venuelist
          </th>
          <th className="p-2 text-xs font-medium text-left uppercase border border-gray-300 dark:border-gray-700 xsm:text-sm">
          Discount Coupons
          </th>
          <th className="p-2 text-xs font-medium text-left uppercase border border-gray-300 dark:border-gray-700 xsm:text-sm">
            Action
          </th>
        </tr>
      </thead>

      {/* Table Body */}
      <tbody>
        {users?.map((user, key) => (
          <tr
            key={key}
            className={`${
              key % 2 === 0
                ? "bg-gray-100 dark:bg-gray-800"
                : "bg-white dark:bg-gray-900"
            }`}
          >
            {/* Serial Number */}
            <td className="p-2 text-xs text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-700 xsm:text-sm">
              {currentPage * pageSize + key + 1}
            </td>

            {/* Name */}
            <td className="p-2 text-xs text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-700 xsm:text-sm">
              {user?.user_detail?.first_name} {user?.user_detail?.last_name}
            </td>

            {/* Dynamic Columns */}
            {[
              // "Admins",
              "Registration",
              "Speakers",
              "News",
              "Videos",
              "photogallery",
              "Testimonials",
              "Slider",
              "zohoexport",
              "session",
              "sessioncategory",
              "venuelist",
              "discountcoupon"
            ].map((column, colIndex) => (
              <td
                key={colIndex}
                className="p-2 text-center border border-gray-300 dark:border-gray-700"
              >
                <div
                  onClick={() =>
                    handleToggle(
                      user?.user_detail?.user_id,
                      column,
                      user?.adminpermissions?.[0]?.[column.toLowerCase()]
                    )
                  }
                  className={`w-10 h-5 rounded-full flex items-center cursor-pointer ${
                    user?.adminpermissions?.[0]?.[column.toLowerCase()]
                      ? "bg-green-500"
                      : "bg-gray-300"
                  }`}
                >
                  <div
                    className={`w-5 h-5 rounded-full bg-white shadow-md transform duration-300 ${
                      user?.adminpermissions?.[0]?.[column.toLowerCase()]
                        ? "translate-x-5"
                        : ""
                    }`}
                  />
                </div>
              
              </td>
              
            ))}
            {/* Action Button */}
            <td className="p-2 text-xs text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-700 xsm:text-sm">
                      <div className="p-2 text-xs xsm:text-sm">
                        <PencilSquareIcon
                         onClick={() =>
                          navigate(`/adminedit/${user?.user_detail?.user_id}`, {
                            state: { userDetails: user?.user_detail },
                          })
                        }
                        
                          className="h-6 w-6 text-black-600 cursor-pointer"
                          aria-hidden="true"
                        />
                        <TrashIcon
                          onClick={() => handleDelete(user?.user_detail?.user_id)}
                          className="h-6 w-6 text-black-600 cursor-pointer hover:text-red-600"
                          aria-hidden="true"
                        />
                        {/* <Eye style={{cursor:'pointer'}} onClick={()=>{handleNavigateWithState(user)}} /> */}
                      </div>
            </td>
          </tr>
        ))}
        
      </tbody>
    </table>
  </div>

  {/* Pagination */}
  <div className="mt-4 flex justify-center">
    <Pagination
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={handlePageChange}
    />
  </div>
</>

      )}
    </div>
  );
};
export default AdminListing;