import apiRequest from '../apis/api-request';
import { userStore } from '../store/userStore';

const accessToken = userStore.getState().user.token;

const speakerService = {
   
    speakerExport: async ({status}) => {
        return apiRequest({
            method: "GET",
            url: `/speakers/export-speaker?status=${status}`,
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
    }

}

export default speakerService;