import React, { useState, useRef } from "react";
import userQueries from '../../queries/userQueries';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';

const AddAdmin = () => {
    const [usernameExist, setUsernameExist] = useState('');
    const [susmsg, setSusmsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
  
    const navigate = useNavigate();
    
    const { register, handleSubmit, control, formState: { errors } } = useForm();

    const AddInfodata = userQueries.useCreateuserMutation((response) => {
        if (response?.message === "Success") {
            navigate('/adminlisting');
            setSusmsg(true);
            alert(response.message);
        } else if (response?.message.includes("User Exists")) {
            alert("User exists with this Phone or Email");
            setLoading(false);
        } else if (response?.message === "Admin Restored"){
         navigate('/adminlisting');
            setSusmsg(true);
            alert(response.message);
        }
    });

    const handleFormSubmit = async (data) => {
        const userData = {
            first_name: data.first_name,
            last_name: data.lname,
            username: data.email,
            email: data.email,
            password: data.password, // Add password field
            role: 'ADMIN',
            phone: data.phone,
        };

        try {
            setLoading(true);
            const createdUser = await AddInfodata.mutateAsync(userData);
            if (createdUser?.message === 'Success') {
                const formData = new FormData();
               
                formData.append('userId', createdUser.data.id);
                navigate('/adminlisting');
            }else if (createdUser?.message === "username already exists") {
                alert("User exists with this Phone or Email");
                setLoading(false);
            }
            
        } catch (error) {
            console.error('Error occurred:', error);
            setErrorMsg('Failed to create admin. Please try again.');
        } finally {
            setLoading(false);
        }
    };
   
    return (
        <>
            <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
                Create Admin
            </h4>
            <form className="max-w-lg mx-auto grid grid-cols-2 gap-4" onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="mb-1 col-span-2">
                    <label htmlFor="first_name" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
                    <input 
                        type="text" 
                        id="first_name" 
                        {...register("first_name", { required: "First name is required" })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    {errors.first_name && <div className="text-red-600 text-sm">{errors.first_name.message}</div>}
                </div>
                <div className="mb-1 col-span-2">
                    <label htmlFor="lname" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                    <input 
                        type="text" 
                        id="lname" 
                        {...register("lname", { required: "Last name is required" })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    {errors.lname && <div className="text-red-600 text-sm">{errors.lname.message}</div>}
                </div>
                <div className="mb-1 col-span-1">
                    <label htmlFor="phone" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
                    <input
                        type="tel"
                        id="phone"
                        {...register("phone", {
                            required: "Phone is required",
                            pattern: {
                                value: /^(?:\+91|91)?\d{10}$/,
                                message: "Please enter a valid phone number"
                            }
                        })}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                    {errors.phone && <div className="text-red-600 text-sm">{errors.phone.message}</div>}
                </div>

                <div className="mb-1 col-span-1">
                    <label htmlFor="email" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                    <input 
                        type="email" 
                        id="email" 
                        {...register("email", { required: "Email is required" })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    {errors.email && <div className="text-red-600 text-sm">{errors.email.message}</div>}
                </div>

                <div className="mb-1 col-span-1">
                    <label htmlFor="password" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                    <input 
                        type="password" 
                        id="password" 
                        {...register("password", { required: "Password is required" })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    {errors.password && <div className="text-red-600 text-sm">{errors.password.message}</div>}
                </div>

                <button 
                    type="submit" 
                    className="col-span-2 w-full text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5"
                >
                    Create
                </button>
            </form>

            {loading && <Loader />}
            {susmsg && <div className="text-green-600">Admin added successfully!</div>}
            {errorMsg && <div className="text-red-600">{errorMsg}</div>}
        </>
    );
};

export default AddAdmin;
