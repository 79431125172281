import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import siteQueries from '../queries/siteQueries';
import { PlusCircleIcon } from '@heroicons/react/24/solid';

const schema = yup.object().shape({
  first_name: yup.string().required('First name is required.'),
  last_name: yup.string().required('Last name is required.'),
  email: yup.string().email('Email format is invalid.').nullable(),
  phone: yup.string()
  .nullable()
  .notRequired()
  .matches(/^\d{10}$/, 'Phone number must be 10 digits.')
  .transform(value => value || null)
})

export default function AttendeeMember({ onSave, showIcon = false }) {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema),defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
    },
  });

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    setLoading(false);
    window.scrollTo(0, 0);
    reset(); 
  }
  const userPaidstatus = siteQueries.useDelagatePaidstatusMutation(async (response) => {
    
  });

  const onSubmit = async (data, shouldCloseModal = true) => {
    setLoading(true);
    let savedData = JSON.parse(localStorage.getItem('delegateMemberData')) || [];
        if (savedData.length >= 10) {
        alert('You have reached the maximum limit of 10 members.');
        return;
      }
      const isMemberExists = savedData.some((member) => {
        return (
          (data.email && member.email === data.email) || 
          (data.phone && member.phone === data.phone)
        );
      });
    
    // const isMemberExists = savedData.some(
    //   (member) => member.email === data.email || member.phone === data.phone
    // );

    if (isMemberExists) {
      alert('A member with this email or phone number already exists in the saved data.');
      setLoading(false);

      return;
    }
    try {
        const response = await userPaidstatus.mutateAsync(data);
        if (response?.success === true) {
            alert("User already exists");
            setLoading(false);

            return;
        }
    } catch (error) {
        console.error("Error checking user existence:", error);
        return;
    }

    savedData.push(data);
    localStorage.setItem('delegateMemberData', JSON.stringify(savedData));
    onSave(data);

    reset(); 
    if (shouldCloseModal) {
      handleClose();
      window.scrollTo(0, 0, {behavior: 'smooth'},);
    }
    setLoading(false);
  };


  return (
    <>
      {/* <Button className="btn btn-secondary btn-add mt-5 me-1" onClick={handleShow}>
        Add Member
      </Button> */}
      {showIcon ? (
        <PlusCircleIcon style = {{width: '30px', height: '30px', color: 'yellow'}} title="Add member" onClick={handleShow} />
      ) : (
        <Button className="btn btn-secondary btn-add mt-5 me-1" 
        title="Add member"
        onClick={handleShow}>
          Add Member
        </Button>
      )}

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="formFirstName">
              <Form.Label>First Name <span className='text-danger'>*</span></Form.Label>
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    isInvalid={!!errors.first_name}
                    {...field}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">{errors.first_name?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formLastName" className="mt-3">
              <Form.Label>Last Name <span className='text-danger'>*</span></Form.Label>
              <Controller
                name="last_name"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder="Enter last name"
                    isInvalid={!!errors.last_name}
                    {...field}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">{errors.last_name?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formEmail" className="mt-3">
              <Form.Label>Email</Form.Label>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    isInvalid={!!errors.email}
                    {...field}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
            </Form.Group>

            {/* <Form.Group controlId="formPhone" className="mt-3">
              <Form.Label>Phone</Form.Label>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder="Enter phone number"
                    isInvalid={!!errors.phone}
                    {...field}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">{errors.phone?.message}</Form.Control.Feedback>
            </Form.Group> */}
<Form.Group controlId="formPhone" className="mt-3">
  <Form.Label>Phone</Form.Label>
  <Controller
    name="phone"
    control={control}
    render={({ field }) => (
      <Form.Control
        type="text"
        placeholder="Enter phone number"
        isInvalid={!!errors.phone}
        {...field}
      />
    )}
  />
  <Form.Control.Feedback type="invalid">{errors.phone?.message}</Form.Control.Feedback>
</Form.Group>


            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button 
              variant="primary" 
              onClick={handleSubmit((data) => onSubmit(data, true))} 
              disabled={loading}
              >
                {loading ? 'Saving...' : 'Save'}
              </Button>
              <Button 
                variant="primary" 
                onClick={handleSubmit((data) => onSubmit(data, false))}  
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Add member'}
              </Button>

            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

