import React, {useEffect, useState} from 'react'
import Footer from './Footer';
import Navbar from './Navbar';
import InitModal from './initModal';
import { useScrollToTop } from '../hooks/ScrollTop';
import siteQueries from '../queries/siteQueries';
import Pagination from '../common/Pagination/Pagination';
import Loader from '../components/Loader/Loader';
import VenoBox from 'venobox';
import 'venobox/dist/venobox.min.css';

export default function PhotoSiteListing() {

    const [isOpen, setIsOpen] = useState(false);
    const [gallery, setGallery] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [totalPage, setTotalPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
 
    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);
    
    const Gallery = siteQueries.useGalleryMutation(
        async (response) => {
          setGallery(response?.data?.items);
          setTotalCount(response?.data?.totalItems);
          setTotalPage(response?.data?.totalPages);
          setIsLoading(false);
        },
      );

      useEffect(() => {
        Gallery.mutateAsync({
            page: currentPage,
            size: pageSize,
        })
        
      }, [currentPage, pageSize,]);

      useEffect(() => {
        // Initialize VenoBox
        const venobox = new VenoBox({
          selector: '.photo-venobox',
          numeration: true,
          infinigall: true,
          share: true,
          spinner: 'rotating-plane',
          spinColor: '#F4D03F',
          titleattr: 'data-title',
          titlePosition: 'top',
          titleStyle: 'bar',
          // Gallery navigation arrows
          navKeyboard: true,
          navTouch: true,
          // Callbacks
          cb_init: function(obj) {
            console.log('VenoBox initialized');
          },
          cb_post_open: function(obj) {
            console.log('Image opened');
          },
          cb_pre_close: function(obj) {
            console.log('Closing gallery');
          }
        });
      
        // Cleanup on unmount
        return () => {
          // No venobox.destroy() since it's not available
          // Placeholder for any potential cleanup tasks
        };
      }, [gallery]);

      const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
      };

      const totalPages = totalPage;

      useEffect(() => {
       
        document.title = `Photogallery | Kerala Literature Festival 2025`;
    

    return () => {
        document.title = 'KLF 2025 | Kerala Lit Fest | Literature Festival | Literary Festival 2025';
    };
  
}, []);
   
  useScrollToTop();
  return (
    <div>
        {isLoading ? (
      <Loader /> 
    ) : (
        <>
        <Navbar handleOpen={handleOpen}  />
        <div class="innerbanner container-fluid">
            <div class="container">

                <div class="row">
                    <div class="col-md-12">
                    <span class="logo-strips"></span> <h2>Gallery</h2>
                    </div>
                </div>
            </div>
        </div>
            <div>
            <div className="container my-4">
                <div className="row">
                    {gallery?.map((image, index) => (
                    <div className="col-12 col-md-6 col-lg-3 mb-4" key={index}>
                       <div
                            className="card h-100 shadow-sm photo-venobox"
                            data-href={`${process.env.REACT_APP_BASE_URL}/${image?.image}`}
                            data-gall="gallery"
                            data-title={image?.title}
                            style={{ maxHeight: '400px', overflow: 'hidden', cursor: 'pointer' }}
                            >
                            <img
                                style={{ height: '200px', width: '100%', objectFit: 'cover', }}
                                src={`${process.env.REACT_APP_BASE_URL}/${image?.image}`}
                                alt={image?.alt}
                            />
                            
                        <div className="card-body">
                        <span className="video-title ">{image?.title}</span>
                        </div> 
                        </div>
                    </div>
                    ))}
                </div>
                
                <div 
                className="d-flex justify-content-center mt-4">
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
                </div>
                </div>
                
            </div>
                
        <Footer handleOpen={handleOpen} />

        <InitModal isOpen={isOpen} onClose={handleClose} />
        </>
    )}
    
    </div>
  )
}
