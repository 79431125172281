import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment/moment';

const initialState = {
  allSessions: [],
  allSpeakers: [],
  allSponsers: [],
  allVenues: [],
  selectedSpeaker: null,
  selectedVenue: null,
  selectedCategory: null,
  selectedLanguage: null,
  selectedDelegate: "",
  activeTab: 'Jan 25',
  search: ''
};

const sessionSlice = createSlice({
    name: 'sessions',
    initialState,
    reducers: {
      setAllSessions: (state, action) => {
        state.allSessions = action.payload
      },
      setAllSpeakers: (state, action) => {
        state.allSpeakers = action.payload
      },
      setAllSponsers: (state, action) => {
        state.allSponsers = action.payload
      },
      setAllVenues: (state, action) => {
        state.allVenues = action.payload
      },
      setSelectedSpeaker: (state, action) => {
        state.selectedSpeaker = action.payload
      },
      setSelectedVenue: (state, action) => {
        state.selectedVenue = action.payload
      },
      setSelectedCategory: (state, action) => {
        state.selectedCategory = action.payload
      },
      setSelectedDelegate: (state, action) => {
        state.selectedDelegate = action.payload
      },
      setSelectedLanguage: (state, action) => {
        state.selectedLanguage = action.payload
      },
      setSearch: (state, action) => {
        state.search = action.payload
      },
      setActiveTab: (state, action) => {
        state.activeTab = action.payload
      },
      resetData: (state) => {
        state.selectedSpeaker = null;
        state.selectedVenue = null;
        state.selectedCategory = null;
        state.activeTab = moment().format('MMM DD');
        state.selectedLanguage = null;
        state.search = '';
        state.selectedDelegate = "";
      }
    }
})

export const { setAllSessions, setAllSpeakers, setAllSponsers, setAllVenues, setSelectedSpeaker, setSelectedVenue, setSelectedCategory, setSelectedLanguage, setSelectedDelegate, setSearch, setActiveTab, resetData } = sessionSlice.actions;
export default sessionSlice.reducer;