import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller} from "react-hook-form";
import { useNavigate, useParams ,useLocation} from "react-router-dom";
import userQueries from '../../queries/userQueries';
import Loader from '../Loader/Loader';
import RichTextEditor from "../Rte/Rteeditor";
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Upload, Save, RotateCcw, X } from 'lucide-react';


const EditSpeaker = () => {
    const [gender, setGender] = useState("");
    const [usernameExist, setUsernameExist] = useState('');
    const [usernameerror, setUsernameerror] = useState(false);
    const [susmsg, setSusmsg] = useState(false);
    const [gendererror, setGendererror] = useState(false);
    const [imageError, setImageError] = useState('');
    const [currentImage, setCurrentImage] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageUploadSuccess, setImageUploadSuccess] = useState(false);
    const [currentPublishStatus, setCurrentPublishStatus] = useState(0);
    const [loading, setLoading] = useState(false); // Add loading state
    const imageRef = useRef(null);
    const [currentImageUrl, setCurrentImageUrl] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);


        // Image Editor States
        const [showImageEditor, setShowImageEditor] = useState(false);
        const [crop, setCrop] = useState();
        const [completedCrop, setCompletedCrop] = useState();
        const [scale, setScale] = useState(1);
        const [rotate, setRotate] = useState(0);
        const [aspect, setAspect] = useState(undefined);
        const [editingImage, setEditingImage] = useState(null);
        const imgRef = useRef(null);
        const canvasRef = useRef(null);
        

    const { id } = useParams();
    const navigate = useNavigate();

    const { register, reset, handleSubmit,getValues, control, formState: { errors } } = useForm();

    const MAX_FILE_SIZE = 350 * 1024; // 350KB in bytes

    const Speakerdata = userQueries.useUpdatespeakerMutation(
        (response) => {
            setLoading(false); // Stop the loader once data is fetched
            if (response?.data) {
                let datas = ({
                    id: id,
                    heading: response?.data.heading,
                    email: response?.data.email,
                    phone: response?.data.phone,
                    contact_person: response?.data.contact_person,
                    content: response?.data.content,
                    publishStatus: response?.data.publishStatus,
                    displayorder: response?.data.display_order,
                    country_code: response?.data.country_code,
                    country_code1: response?.data.country_code1,
                });
                reset({ ...datas });
                setGender(response?.data.gender);
                setCurrentImage(response?.data.photo || null);
                setCurrentPublishStatus(response?.data.status || 'Unpublish');
               
                setCurrentImageUrl(response?.data.photo ? `${process.env.REACT_APP_BASE_URL}/${response.data.photo}` : null);
            }
        }
    );

    const UpdateUserImage = userQueries.useSpeakerimageMutation(
        (response) => {
            if (response?.message === "Profile image uploaded successfully") {
                setImageUploadSuccess(true);
                setTimeout(() => {
                    setImageUploadSuccess(false);
                }, 3000);
            }
        }
    );

    useEffect(() => {
        setLoading(true); // Set loader to true when fetching data
        Speakerdata.mutateAsync({ id });
    }, [id]);

    const location = useLocation();
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    // Retrieve current page from navigation state
    const navigationState = location.state;
    if (navigationState?.currentPage !== undefined) {
      setCurrentPage(navigationState.currentPage);
      localStorage.setItem('speakerListCurrentPage', navigationState.currentPage.toString());
    }
  }, [location]);

    const editSpeaker = userQueries.useUpdatespeakerMutation(
        (response) => {
            setLoading(false); // Stop the loader after submission
            if (response?.message === "Speaker updated successfully") {
                setSusmsg(true);
                alert(response?.data.status === 'Unpublish' ? "Saved as draft" : "Published successfully");
                // if (selectedImage) {
                //     const formData = new FormData();
                //     formData.append('speakers', selectedImage);
                //     formData.append('userId', id);
                //     UpdateUserImage.mutateAsync(formData);
                // }

                if (imageRef.current.files[0]) {
                    const formData = new FormData();
                    formData.append('speakers', imageRef.current.files[0]);
                    formData.append('userId', id);
                    UpdateUserImage.mutateAsync(formData);
                }
                // localStorage.setItem('speakerListCurrentPage', '1');
                localStorage.setItem('speakerListCurrentPage', currentPage.toString());
                navigate('/speakerlisting');
               
            }
            else if(response?.message === "Phone already exists")
            {
                alert("User Exists with this phone number")
            }
            else if(response?.message === "Email already exists")
            {
                alert("User Exists with this Email")
            }
            else if(response?.message === "Phone or Email already exists")
            {
                alert("User Exists with this Phone or Email")
            }
        }
    );

    // const handleImageChange = (e) => {
    //     if (e.target.files && e.target.files[0]) {
    //         setSelectedImage(e.target.files[0]);
    //         const objectUrl = URL.createObjectURL(file);
    //         setPreviewUrl(objectUrl);
    //     }
    // };
    // const handleImageChange = (e) => {
    //     if (e.target.files && e.target.files[0]) {
    //         const file = e.target.files[0];
    //         setSelectedImage(file);
            
    //         // Create a preview URL for the newly selected image
    //         const objectUrl = URL.createObjectURL(file);
    //         setPreviewUrl(objectUrl);
    //     }
    // };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const allowedTypes = ['image/jpeg', 'image/jpg'];
            if (!allowedTypes.includes(file.type)) {
                setImageError("Only JPEG, JPG, files are allowed.");
                setImagePreview(null);
                return;
            }
            if (file.size > MAX_FILE_SIZE) {
                setImageError("Image size should not exceed 350KB.");
                setImagePreview(null);
                return false;
            }

            setImageError('');
            const reader = new FileReader();
            reader.onloadend = () => {
                setEditingImage(reader.result);
                setShowImageEditor(true);
            };
            reader.readAsDataURL(file);
        }
    };


    const generateCroppedImage = async () => {
        if (!completedCrop || !canvasRef.current || !imgRef.current) {
            return null;
        }

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            return null;
        }

        const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
        const scaleY = imgRef.current.naturalHeight / imgRef.current.height;

        canvas.width = completedCrop.width * scaleX;
        canvas.height = completedCrop.height * scaleY;

        ctx.drawImage(
            imgRef.current,
            completedCrop.x * scaleX,
            completedCrop.y * scaleY,
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
            0,
            0,
            completedCrop.width * scaleX,
            completedCrop.height * scaleY
        );

        return new Promise((resolve) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        setImageError("Failed to create image");
                        return;
                    }
                    resolve(blob);
                },
                'image/jpeg',
                0.95
            );
        });
    };

    const handleCropComplete = async () => {
        const croppedImageBlob = await generateCroppedImage();
        if (croppedImageBlob) {
            const croppedImageUrl = URL.createObjectURL(croppedImageBlob);
            setImagePreview(croppedImageUrl);
            setShowImageEditor(false);
            
            // Create a new File object from the blob
            const croppedImageFile = new File([croppedImageBlob], 'cropped-image.jpg', {
                type: 'image/jpeg'
            });
            
            // Create a new DataTransfer object and add the file
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(croppedImageFile);
            
            // Update the file input's files property
            if (imageRef.current) {
                imageRef.current.files = dataTransfer.files;
            }
        }
    };


    const handleFormSubmit = (data, publishStatus) => {
       
        if(publishStatus === "Publish"){

            if (!imageRef.current.files[0] && !currentImage) {
                setImageError("Image is required.");
                return; // Stop here if no image
            } else {
                setImageError(""); // Clear error if image is selected
            }
        }
        const datavalues = {
            id: id,
            heading: data.heading,
            email: data.email,
            phone: data.phone,
            contact_person: data.contact_person,
            content: data.content,
            status: publishStatus,
            display_order: data.displayorder,
            country_code: data.country_code,
            country_code1: data.country_code1,
        };
        editSpeaker.mutateAsync(datavalues);
       
    };

    useEffect(() => {
        return () => {
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
            }
        };
    }, [previewUrl]);

    return (
        <>
            <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
                Update Speaker
            </h4>
            <form className="max-w-lg mx-auto grid grid-cols-2 gap-4">
                <div className="mb-5 col-span-2">
                    <label htmlFor="heading" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Heading</label>
                    <input type="text" id="heading" {...register("heading", { required: "Heading is required" })} name="heading"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"/>
                    {errors.heading && <div className="text-red-600 text-s">Heading is required</div>}
                </div>

                <div className="mb-5 col-span-2">
                    <label htmlFor="content" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Content</label>
                    <Controller
                    name="content"
                    control={control}
                    rules={{ required: "Content is required" }}
                    render={({ field }) => (
                        <RichTextEditor
                            {...field}
                            id="content"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            rows="4"
                        />
                    )}
                    />
                    {errors.content && <div className="text-red-600 text-s">content is required</div>}
                </div>

                <div className="mb-5 col-span-2">
                    <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Profile Image
                    </label>
                  
                     {/* Image Preview Section */}
                    <div className="mb-4">
                        {(imagePreview || currentImageUrl) && (
                            <div className="relative w-48 h-48 mb-4">
                                <img
                                    src={imagePreview || currentImageUrl}
                                    alt="Speaker preview"
                                    className="w-full h-full object-cover rounded-lg shadow-md"
                                />
                            </div>
                        )}
                    </div>
                    <input
                        type="file" 
                        id="image"
                        ref={imageRef}
                        onChange={handleImageChange}
                        accept="image/*"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    /> 
                    <div className="form-text text-warning small">
                    Recommended image size: less than 350 KB.
                  </div>
                   
                    {imageUploadSuccess && (
                        <div className="mt-2 text-sm text-green-600">
                            Image uploaded successfully!
                        </div>
                    )}
                    {imageError && <div className="text-red-600 text-s">{imageError}</div>}
                </div>


                {/* Image Editor Modal */}
                 {showImageEditor && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-xl font-semibold">Edit Image</h3>
                                <button
                                    type="button"
                                    onClick={() => setShowImageEditor(false)}
                                    className="text-gray-500 hover:text-gray-700"
                                >
                                    <X size={24} />
                                </button>
                            </div>
                            
                            <div className="space-y-4">
                                <div className="flex gap-4 mb-4">
                                    <button
                                        type="button"
                                        onClick={() => setRotate((prev) => (prev - 90) % 360)}
                                        className="flex items-center gap-2 px-3 py-1 bg-gray-100 rounded hover:bg-gray-200"
                                    >
                                        <RotateCcw size={16} />
                                        Rotate
                                    </button>
                                    
                                    <div className="flex items-center gap-2">
                                        <label className="text-sm font-medium">Scale:</label>
                                        <input
                                            type="range"
                                            min="0.1"
                                            max="2"
                                            step="0.1"
                                            value={scale}
                                            onChange={(e) => setScale(Number(e.target.value))}
                                            className="w-24"
                                        />
                                        <span className="text-sm">{Math.round(scale * 100)}%</span>
                                    </div>
                                </div>

                                <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                    aspect={aspect}
                                >
                                    <img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={editingImage}
                                        style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                        className="max-w-full"
                                    />
                                </ReactCrop>

                                <div className="flex justify-end gap-2 mt-4">
                                    <button
                                        type="button"
                                        onClick={() => setShowImageEditor(false)}
                                        className="px-4 py-2 text-gray-600 bg-gray-100 rounded hover:bg-gray-200"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleCropComplete}
                                        className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                                    >
                                        Apply
                                    </button>
                                </div> 
                            </div>
                            
                            <canvas
                                ref={canvasRef}
                                className="hidden"
                            />
                        </div>
                    </div>
                )}

                <div className="flex gap-2 col-span-2">
                <div className="mb-5">
                <label htmlFor="country_code" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Country Code</label>
                <input
                    type="text"
                    id="country_code"
                    {...register("country_code", {
                        
                     
                    })}
                    name="country_code"
                    inputMode="numeric" // Ensures numeric keyboard on mobile devices
                    maxLength={5} // Limits the input to a maximum of 5 characters
                    onInput={(e) => {
                    // Remove non-numeric characters from the input
                    e.target.value = e.target.value.replace(/\D/g, '');
                    }}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-40 p-2.5"
                />
              
            </div>
            

                <div className="mb-5 flex-1">
                <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
                <input
                    type="tel"
                    id="phone"
                    {...register("phone", {
                        
                        pattern: {
                            value: /^(?:\+91|91)?\d{10}$/,
                            message: "Please enter a  valid phone number"
                        }
                    })}
                    name="phone"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                />
                {errors.phone && <div className="text-red-600 text-sm">{errors.phone.message}</div>}
            </div>
</div>

                
                <div className="flex gap-2 col-span-2">
                <div className="mb-5">
                <label htmlFor="country_code" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Country Code</label>
                <input
                    type="text"
                    id="country_code1"
                    {...register("country_code1", {
                        
                     
                    })}
                    name="country_code1"
                    inputMode="numeric" // Ensures numeric keyboard on mobile devices
                    maxLength={5} // Limits the input to a maximum of 5 characters
                    onInput={(e) => {
                    // Remove non-numeric characters from the input
                    e.target.value = e.target.value.replace(/\D/g, '');
                    }}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-40 p-2.5"
                />
              
            </div>
            

                <div className="mb-5 flex-1">
                <label htmlFor="contact_person" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">contact person phone Number</label>
                <input
                    type="tel"
                    id="contact_person"
                    {...register("contact_person", {
                        
                        pattern: {
                            value: /^(?:\+91|91)?\d{10}$/,
                            message: "Please enter a  valid phone number"
                        }
                    })}
                    inputMode="numeric" // Ensures numeric keyboard on mobile devices
                    onInput={(e) => {
                    // Remove non-numeric characters from the input
                    e.target.value = e.target.value.replace(/\D/g, '');
                    }}
                    name="contact_person"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                />
                {errors.contact_person && <div className="text-red-600 text-sm">{errors.contact_person.message}</div>}
            </div>
</div>

                

                <div className="mb-5 col-span-1">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                    <input type="email" id="email" {...register("email", {  pattern: { 
                        value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, message: "Enter a valid email address" }})} name="email"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                    {errors.email && <div className="text-red-600 text-s">Please Enter email</div>}
                    {usernameerror && <div className="text-red-600 text-s">Please Enter email</div>}
                    {usernameExist && <div className="text-red-600 text-s">Email already exists</div>}
                </div>
                <div className="mb-5">
                    <label htmlFor="displayorder" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Display Order</label>
                    <input 
                        type="number" 
                        id="displayorder" 
                        {...register("displayorder", { required: false })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    
                </div>

                {/* Draft and Publish buttons */}
                <button 
                    type="button" 
                    onClick={() => handleFormSubmit(getValues(), 'Unpublish')}
                    className="col-span-2 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-3"
                >
                    Save as draft
                </button>

                <button 
                    type="button"
                    onClick={handleSubmit((data) => handleFormSubmit(data, 'Publish'))}
                    className="col-span-2 w-full text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Save and publish
                </button>

                {susmsg && (
                    <div className="text-green-600 text-lg ml-6 text-center inline-block whitespace-nowrap col-span-2">
                        Speaker updated successfully
                    </div>
                )}

                {/* Loader Spinner */}
                {loading && (
                    <Loader />
                )}
            </form>
        </>
    );
};

export default EditSpeaker;
