import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import MultiSelect from '../Multiselect/MultiSelect';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import userQueries from '../../queries/userQueries';
import siteQueries from '../../queries/siteQueries';

export default function AddFeaturedPhotoGallery() {
  const { id } = useParams();
  const isEditMode = !!id;

  const navigate = useNavigate();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [photoGalleryList, setPhotoGalleryList] = useState([]);

  const [alreadyAddedPhotoGalleries, setAlreadyAddedPhotoGalleries] = useState([]);

  const { control, handleSubmit, reset, formState: { errors } } = useForm();

  useEffect(() => {
    fetchPhotoGalleryData();
    fetchExistingFeaturedPhotoGalleries();
  }, []);

  const fetchPhotoGalleryData = async () => {
    await photoGalleryListing.mutateAsync();
  };

  const featuredPhotoGalleryListing = siteQueries.useFeaturedphotogalleryListingMutation(
    (response) => {
      const addedPhotoGalleries = response?.data?.map(featuredPhotoGallery => ({
        value: featuredPhotoGallery.galleryid.toString(),
        label: featuredPhotoGallery.photogallery.title
      })) || [];
      setAlreadyAddedPhotoGalleries(addedPhotoGalleries);
    },
    {
      onError: (error) => {
        console.error('Failed to fetch added photo galleries', error);
      }
    }
  );

  const photoGalleryListing = userQueries.useListpublishedphotogalleryMutation((response) => {
    const photoGalleryOptions = response?.data?.map(item => ({
      value: item.id.toString(),
      label: `${item.title}`
    }));
    setPhotoGalleryList(photoGalleryOptions);
  });

  const addFeaturedPhotoGallery = userQueries.useFeaturedphotogalleryaddMutation((response) => {
    console.log(response.message)
    if(response?.message === "Photogallery added successfully."){
      navigate('/featuredphotogallery')
    }
    else if(response?.message === "Cannot add photogallery. Would exceed the maximum of 8 active photogallery.")
    {
      alert("You cannot add more than 8 featured Photo Galleries.")
    }
  });

  const onSubmit = async (data) => {
    try {
      const galleryIds = data.photoGalleries?.map(gallery => gallery.value);
      console.log("gallery IDs:", galleryIds);
  
      await addFeaturedPhotoGallery.mutateAsync({ galleryIds });
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred');
    }
  };

  const fetchExistingFeaturedPhotoGalleries = async () => {
    try {
      const data = await featuredPhotoGalleryListing.mutateAsync();
      const existingPhotoGalleries = data.data;
      
      if (existingPhotoGalleries && existingPhotoGalleries.length > 0) {
        reset({
          photoGalleries: existingPhotoGalleries.map(featuredPhotoGallery => ({
            value: featuredPhotoGallery.galleryid.toString(),
            label: featuredPhotoGallery.photogallery.title
          }))
        });
      }
    } catch (error) {
      console.error('Error fetching existing photo galleries:', error);
      setErrorMessage('Error fetching existing photo galleries');
      setIsErrorModalOpen(true);
    }
  };

  return (
    <div className="container mx-auto p-6">
      <Breadcrumb pageName={isEditMode ? "Edit Featured Photo Gallery" : "Add Featured Photo Gallery"} />

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div>
            <Controller
              name="photoGalleries"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  name="photoGalleries"
                  control={control}
                  options={photoGalleryList}
                  defaultValue={alreadyAddedPhotoGalleries}
                  isMulti={true}
                  label="Photo Galleries"
                  placeholder="Select photo galleries"
                  error={errors.photoGalleries?.message}
                />
              )}
            />
            <div className="flex justify-end">
              <button 
                type="button"
                onClick={handleSubmit((data) => onSubmit(data))}
                className="bg-primary text-white py-2 px-4 rounded hover:bg-opacity-90"
              >
                Add Featured Photo Gallery
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}