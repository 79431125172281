import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';

import App from './App';


import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ApiProvider } from './utils/ApiProvider';
import { persistor, store } from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();
root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ApiProvider>
            <App />
          </ApiProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </BrowserRouter>
);
