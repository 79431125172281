import apiRequest from '../apis/api-request';
import { userStore } from '../store/userStore';

const siteService = {

    sessionList: async (Data) => {
        return apiRequest({
            method:"GET",
            url: "/session/list",
            data: Data,
            headers: {
                Authorization: "Bearer " + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzQsInVzZXJuYW1lIjoiYWRtaW4iLCJyb2xlcyI6WyJhZG1pbiJdLCJpYXQiOjE3MjgzNTAwNDd9.E-C79gZDzIOZhqXJbPPDeOyEDLomdtoJzuX5x5RyTUY',
            }
        });
    },

    userExist: async (Data) => {
        return apiRequest({
            method:"GET",
            url: "/delegatePayment/existing?email="+Data.email+"&phone="+Data.phone,
            data: Data,
            headers: {
                Authorization: "Bearer " + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzQsInVzZXJuYW1lIjoiYWRtaW4iLCJyb2xlcyI6WyJhZG1pbiJdLCJpYXQiOjE3MjgzNTAwNDd9.E-C79gZDzIOZhqXJbPPDeOyEDLomdtoJzuX5x5RyTUY',
            }
        });
    },
    userPaid: async (Data) => {
        return apiRequest({
            method:"GET",
            url: "/delegatePayment/delegateuser?email="+Data.email+"&phone="+Data.phone,
        });
    },

    qrcode: async (Data) => {
        return apiRequest({
            method:"GET",
            url: "/user/userqr?id="+Data.id,
        });
    },

    addDelegate: async (Data) => {
        return apiRequest({
            method:"POST",
            url: "/delegate/user/create",
            data: Data,
        });
    },

    feeback: async (Data) => {
        return apiRequest({
            method:"POST",
            url: "/feedback/sendmail",
            data: Data,
        });
    },

    videos: async (data) => {
        const page = data.page;
        const size = data.size 
        return apiRequest({
            method:"GET",
            url: `/videos/list/videos?page=${page}&size=${size}`,
            
        });
    },

    gallery: async (data) => {
        const page = data.page;
        const size = data.size 
        return apiRequest({
            method:"GET",
            url: `/photogallery/list/photogallery?page=${page}&size=${size}`,
            
        });
    },

    News: async (data) => {
        const size = data.size;
        const page = data.page;
        return apiRequest({
            method:"GET",
            url: `/news/list/news?page=${page}&size=${size}`,
        });
    },

    speakerlisting: async (data) => {
	const size = data.size;
	const page = data.page;
	return apiRequest({
	    method:"GET",
	    url: `/speakers/list-site?page=${page}&size=${size}&search=${data.heading}`,
	});
    },
    Sliderlist: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/slider/list-site`,
            data:data,
        });
    },

    Testimonials: async (data) => {
        const size = data.size;
        const page = data.page;
        return apiRequest({
            method:"GET",
            url: `/testimonials/list-site?page=${page}&size=${size}`,
        });
    },

    

    speakerviewsite: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/speakers/view-speaker/${data.id}`,
          
        });
      },



      newsviewsite: async (data) => {
        return apiRequest({
            method:"GET",
            url: `/news/view-news/${data.id}`,  
        });
      },

      sessionslist: async (params = '') => {
        return apiRequest({
            method:"GET",
            url: `/session/list-site?${params}`,  
        });
      },
attendeegroup: async (Data) => {
        return apiRequest({
            method:"POST",
            url: "/groupregistration/attendee-group",
            data: Data,
        });
    },

 studentsgroupcreate: async (data) => {
        return apiRequest({
            method: "POST",
            url: `/groupregistration/arrey-create`,
            data:data,
            
                   
          });
      },

      speakerslistsitelisting: async () => {
        return apiRequest({
            method:"GET",
            url: `/speakers/list-sitelisting`,  
        });
      },
    couponvalidation: async (Data) => {
        return apiRequest({
            method:"POST",
            url: "/delegate/validatecoupon",
            data: Data,
        });
    },

    speakerlistingfilter: async (Data) => {
        return apiRequest({
            method:"GET",
            url: "/speakers/list-speakerfilter",
            data: Data,
        });
    },


      speakerslistsitelisting: async () => {
        return apiRequest({
            method:"GET",
            url: `/speakers/list-sitelisting`,  
        });
      },
      featuredspeakerlisting: async (data) => {
       
        return apiRequest({
            method:"GET",
            url: `/speakers/list-featuredspeaker`,
        });
        },

        featurednewslisting: async (data) => {
          
            return apiRequest({
                method:"GET",
                url: `/news/list-featurednews`,
            });
        },
    
        featuredvideoslisting: async (data) => {
          
            return apiRequest({
                method:"GET",
                url: `/videos/list-featuredvideos`,
            });
        },
    
        featuredphotogallerylisting: async (data) => {
              
            return apiRequest({
                method:"GET",
                url: `/photogallery/list-featuredphotogallery`,
            });
        },

        featuredsessionList: async (Data) => {
            return apiRequest({
                method:"GET",
                url: "/session/listfeaturedsession", 
            });
        },

      

      

     
}

export default siteService;
