import React, {useEffect, useState} from 'react'
import  festivalImg from '../images/Festival-on-the-Beach.jpg'
import patern from '../images/pattern-for-counter-section.png'
import PatternOne from '../images/pattern-for-counter-section.png';
import PatternTwo from '../images/pattern-for-counter-section-2.png';
import beach from '../images/Kozhikode-beach.jpg';
import sasi from '../images/klf-sasi-tharoor.jpg'
import klf2 from '../images/klf-pic-2.jpg';
import klf4 from '../images/klf-pic-4.jpg'
import klf3 from '../images/klf-pic-3.jpg';
import klf5 from '../images/klf-pic-5.jpg';
import klf6 from '../images/klf-pic-6.jpg'


import Footer from './Footer';
import Navbar from './Navbar';
import InitModal from './initModal';
import { useScrollToTop } from '../hooks/ScrollTop';


export default function About() {

    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);

    useEffect(() => {
       
      document.title = `Aboutus | Kerala Literature Festival 2025`;
  

  return () => {
      document.title = 'KLF 2025 | Kerala Lit Fest | Literature Festival | Literary Festival 2025';
  };

}, []);
  useScrollToTop();
  return (
    <div>
        <Navbar handleOpen={handleOpen}  />
        <div class="innerbanner container-fluid">
            <div class="container">

                <div class="row">
                    <div class="col-md-12">
                        <span class="logo-strips"></span> <h2>About <span>KLF</span></h2>
                    </div>
                </div>
            </div>
        </div>

        <FestivalOnBeach />

        <KLFOverview />

        <KLFPhotosSection />

        <KizhakemuriFoundation />

        

        {/* <KLFCounts /> */}

     

        <Venue />

        <DCAndCKLFSection />
        
        <Footer handleOpen={handleOpen} />

        <InitModal isOpen={isOpen} onClose={handleClose} />

      
    </div>
  )
}



const FestivalOnBeach = () => {
  return (
    <div className="container festival-on-beach">
      <div className="row">
        <div className="col-md-7">
          <img 
            src={beach} 
            alt="Kozhikode Beach"
            className="img-fluid"
          />
          <div className="pic-desc">
            Venue: Cultural Beach Grounds, Calicut
          </div>
        </div>
        <div className="col-md-5 my-auto">
          <h2>A Global Celebration of Words, Cultures and Ideas</h2>
          <p>
            The Kerala Literature Festival (KLF) is the largest literary event in the country 
            with over half- a-million attendees over four dynamic days. Founded in 2016, KLF 
            is organized by the DC Kizhakemuri Foundation and co-promoted by DC Books. The 
            Festival is held annually on the beaches of Kozhikode, India's first ever UNESCO 
            City of Literature.
          </p>
        </div>
      </div>

      <div className="row mt-5 pt-5">
        <div className="col-md-5 my-auto">
          <p>
            Recognised as the largest literary gathering in Asia, KLF features a diverse 
            line-up of speakers, including Nobel laureates, Booker Prize winners, Oscar 
            winners, celebrities, acclaimed authors and thought leaders. A cultural meeting 
            point as well, KLF hosts top-notch performances of music, dance, theatre and 
            other performative and participatory arts. The event is non-exclusive and 
            attendance is free of cost.
          </p>
        </div>
        <div className="col-md-7">
          <img 
            src={festivalImg} 
            alt="Festival on the Beach"
            className="img-fluid"
          />
        </div>
      </div>
    </div>
  );
};

const KLFOverview = () => {
  const overviewData = [
    { number: '4', text: 'Days', color: 'yellow', order: 'one' },
    { number: '520', text: 'Speakers', color: 'red', order: 'two' },
    { number: '5,00,000', text: 'Audiences', color: 'green', order: 'three' },
    { number: '330', text: 'Sessions', color: 'blue', order: 'four' },
    { number: '7', text: 'Venues', color: 'orange', order: 'five' }
  ];

  return (
    <div className="container-fluid klf-overview">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center text-white">
            <h2 className="mb-5">KLF 2024 Overview</h2>
          </div>
          <div className="col-md-12 my-auto">
            <div className="row">
              {overviewData.map((item, index) => (
                <div className="col" key={index}>
                  <div className={`overview-circle ${item.color} ${item.order}`}>
                    {item.number}
                  </div>
                  <div className="overview-text">{item.text}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const KLFPhotosSection = () => {
  return (
    <div className="container-fluid klf-photos">
      <div className="container">
        {/* Description Row */}
        <div className="row">
          <div className="col-md-12">
            <p>
              The upcoming 8th edition of KLF is scheduled for January 23–26, 2025. 
              K Satchidanandan, the esteemed poet and critic, is the Festival Director. 
              Each year, KLF spotlights a Guest Nation to foster cultural exchange; 
              France will be the Guest Nation for the 8th edition whereas Turkey was 
              honoured in the 7th edition.
            </p>
          </div>
        </div>

        {/* First Row of Pictures */}
        <div className="row first-row-pics">
          <div className="col-md-6 p-0">
            <img 
              className="pic1" 
              src={sasi}
              alt="Shashi Tharoor at KLF"
            />
          </div>
          <div className="col-md-2 p-0">
            <img 
              className="pic2" 
              src={klf2}
              alt="KLF Event"
            />
            <img 
              className="pic3" 
              src={klf4} 
              alt="KLF Event"
            />
          </div>
          <div className="col-md-4 p-0">
            <img 
              className="pic4" 
              src={klf3} 
              alt="KLF Event"
            />
          </div>
        </div>

        {/* Second Row of Pictures */}
        <div className="row second-row-pics">
          <div className="col-md-6 p-0">
            <img 
              className="pic5" 
              src={klf5}
              alt="KLF Event"
            />
          </div>
          <div className="col-md-6 p-0">
            <img 
              className="pic6" 
              src={klf6} 
              alt="KLF Event"
            />
          </div>
        </div>

        {/* Third Row with Logo Strips and Text */}
        <div className="row third-row-pics">
          <div className="col-md-2 p-0">
            <span className="logo-strips"></span>
          </div>
          <div className="col-md-10 p-0">
            <div>
              <p>
                With its dynamic programming and dedication to promoting literature 
                and arts, KLF has earned international acclaim as a premier platform 
                for cultural exchange and intellectual dialogue. The most anticipated 
                literary festival in Asia, KLF acts as a point of convergence, 
                bringing the best of the literary world together.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const KizhakemuriFoundation = () => {
    return (
      <div className="container-fluid kizhakemuri">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <img src={patern} alt="Pattern for counter section" />
            </div>
            <div className="col-md-10">
              <h2>DC Kizhakemuri Foundation</h2>
              <p>
              KLF is organized by the DC Kizhakemuri Foundation, a philanthropic organization established to honour the legacy of the late Shri D.C. Kizhakemuri, the doyen of Indian publishing and the founder of DC Books. KLF is an initiative by the Foundation to contribute to the social and cultural fabric of Kerala. The Foundation organizes a wide range of activities aimed at promoting arts, education and culture. The Foundation also seeks to further the mission of the DC Group of Educational Institutions named the DC School of Management and Technology (DCSMAT), enhancing the literacy and technical prowess of youth in the country. 
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };


  const Venue = () => {
    return (

        <div className="container-fluid klf-venue">
        <div className="container">
            <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-8 my-auto">
                <div className="card">
                <div className="row">
                    <div className="col-md-4">
                    <img src={PatternOne} alt="Pattern for Counter Section" />
                    </div>
                    <div className="col-md-8">
                    <h2>Calicut &amp; KLF Venue</h2>
                    <p>
                        Calicut or Kozhikode in Kerala is famous for trade and culture. Renowned Portuguese
                        explorer Vasco da Gama touched down the Indian subcontinent on the shores of Calicut
                        in 1498.
                    </p>
                    <p>
                        KLF Venue is curated on the beaches of Calicut.The "City of Spices", known for its rich history and cultural heritage, the waves and the evening sun, provides a picturesque setting for the Literature Festival.
                    </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
)};


const DCAndCKLFSection = () => {
  return (
    <div className="container-fluid dc-cklf">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="content-wrpr">
              <h2>DC Books</h2>
              <p>
                KLF is co-promoted by DC Books, the number one book publishing house 
                in Kerala. A cultural organization as well, DC Books actively 
                intervenes in socio-cultural spaces in the state and across the 
                country. DC Books is committed to crafting KLF as a people's 
                festival, providing an open and liberal platform for debates and 
                discussion to foster progressive discourses. The efforts of DC Books 
                have been successful in turning KLF into a haven for all things 
                literary.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="content-wrpr">
              <h2>Children's Kerala Literature Festival (CKLF)</h2>
              <p>
              CKLF is dedicated to imparting a love for literature among young minds. Filled with fun and games, the Festival aims at nurturing voracious readers at a young age by inculcating a passion for reading. Storytelling, accompanied by art workshops and games, add to the charm of CKLF. The Festival will feature famous authors and Nobel laureates, giving children a memorable experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const KLFCounts = () => {
    const [counts, setCounts] = useState({
      discussions: 0,
      speakers: 0,
      venues: 0
    });
  
    const targetCounts = {
      discussions: 300,
      speakers: 500,
      venues: 6
    };
  
    useEffect(() => {
      // Simulate counting animation
      const interval = setInterval(() => {
        setCounts(prevCounts => ({
          discussions: Math.min(prevCounts.discussions + 10, targetCounts.discussions),
          speakers: Math.min(prevCounts.speakers + 20, targetCounts.speakers),
          venues: Math.min(prevCounts.venues + 1, targetCounts.venues)
        }));
      }, 50);
  
      return () => clearInterval(interval);
    }, []);
  
    const counters = [
      { key: 'discussions', text: "Discussions", delay: 300 },
      { key: 'speakers', text: "Speakers", delay: 500 },
      { key: 'venues', text: "Venues", delay: 700, lastCount: true }
    ];
  
    return (
      <div className="klf-counts-wrpr" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <img 
                data-aos="fade-up" 
                data-aos-delay="100" 
                src={PatternOne} 
                alt="Pattern for counter section"
              />
            </div>
            <div className="col-md-8">
              <div className="row loader" id="counters_2">
                {counters.map((counter) => (
                  <div 
                    key={counter.key} 
                    className="col-md-4" 
                    data-aos="fade-up" 
                    data-aos-delay={counter.delay}
                  >
                    <div className={`counter-cntnr count1 ${counter.lastCount ? 'lastcount' : ''}`}>
                      <div className="counter-wrpr">
                        <div>
                          <div className="counter plus">
                            {counts[counter.key]}
                          </div>
                        </div>
                      </div>
                      <div className="counter-text">
                        {counter.text}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-2" data-aos="fade-up" data-aos-delay="900">
              <img 
                src={PatternTwo}
                alt="Pattern for counter section 2"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };