
import { useMutation } from "react-query";
import authService from "../services/authervice";

const useLoginMutation = (onLoginSuccess) => {
    return useMutation(authService.login, {
      onSuccess: (data) => {
        onLoginSuccess(data);
      },
    });
};

const useUserCreateMutation = (onData) => {
  return useMutation(authService.userCreate, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const usePaymentOrderMutation = (onData) => {
  return useMutation(authService.paymentOrder, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const usePaymentVerificationMutation = (onData) => {
  return useMutation(authService.paymentVerification, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};


const useZohoCrmMutation = (onData) => {
  return useMutation(authService.zohoLead, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};


const usePushNotificaitonMutation = (onData) => {
  return useMutation(authService.pushnotification, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};


const authQueries = {
  useLoginMutation,
  useUserCreateMutation,
  usePaymentOrderMutation,
  usePaymentVerificationMutation,
  useZohoCrmMutation,
  usePushNotificaitonMutation
};

export default authQueries;
