
import React, { useState, useEffect } from "react";
import userQueries from '../../queries/userQueries';
import { Eye } from 'lucide-react';

export function DelegateView({ userDetails }) {
  const [open, setOpen] = useState(false);
  // const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
    
  };

  const handleClose = () => {
    setOpen(false);
  }

  // const { mutateAsync: fetchUser } = userQueries.useUserViewtMutation((data) => {
  //   setUserDetails(data?.user);
  //   setLoading(false);
  // });

  // useEffect(() => {
  //   if (uid) {
  //     setLoading(true);
  //     fetchUser(uid);
  //   }
  // }, []);
  return (
    <>
      <button
        onClick={handleOpen}
        className="blue py-.7 px-1 rounded text-sm"
      >
        <Eye/>
      </button>

      {open && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-blue-50 rounded-lg shadow-lg p-4 w-full max-w-2xl md:max-w-2xl md:max-h-90">
          <h2 className="text-xl font-semibold text-black bg-grey-700">User Details</h2>
          <div className="mt-4">
              {loading && <p>Loading...</p>}
              {userDetails && (
                <div>
                  <p><strong>Name:</strong> {userDetails?.user_detail?.first_name} {userDetails?.user_detail?.last_name}</p>
                  <p><strong>Email:</strong> {userDetails?.user_detail?.email}</p>
                  <p><strong>Phone:</strong> {userDetails?.user_detail?.phone}</p>
                  <p><strong>Gender:</strong> {userDetails?.user_detail?.gender}</p>
                  <p><strong>Pincode:</strong> {userDetails?.user_detail?.pin_code}</p>
                  <p><strong>Country:</strong> {userDetails?.user_detail?.country}</p>
                  <p><strong>State:</strong> {userDetails?.user_detail?.state}</p>
                  <p><strong>District:</strong> {userDetails?.user_detail?.district}</p>
                  {/* <p><strong>Date of birth:</strong> {userDetails?.user_detail?.dob? new Date(userDetails?.user_detail?.dob).toLocaleDateString('en-IN') : 'N/A'}</p> */}
                  <p><strong>Marketing id:</strong> {userDetails?.user_detail?.marketing_id}</p>

                  <p><strong>Address:</strong> {userDetails?.user_detail?.address}</p>
                  <p><strong>Amount:</strong> {userDetails?.delegate_payments?.[0]?.amount}</p>
                </div>
              )}
              {!loading && !userDetails && <p>No user details available.</p>}
              <span className="flex justify-end">
              <button
                onClick={handleClose}
                className="bg-green-500 text-white font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
              </span>
           
            </div>
            {/* <div className="mt-6 flex">
              <button
                onClick={handleClose}
                className="bg-green-500 text-white font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
            </div> */}
          </div>
        </div>
      )}
    </>
  )
}
 


  
