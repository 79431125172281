import { DownloadCloud } from 'lucide-react'
import React, { Fragment, useEffect, useState } from 'react'
import reportQueries from '../../queries/reportQueries'

const Reports = () => {

    const [date, setDate] = useState('')
    const [language, setLanguage] = useState('')
    const [programId, setProgramId] = useState('')
    const [text, setText] = useState('')
    const [dropdown, setDropdown] = useState({})

    const reports = [
        {label: "Programme Datewise Report", url: "program-date-wise-report", isLanguage: true},
        {label: "Venue wise Report", url: "venue-wise-report"},
        {label: "Speakers List Report", url: "speakers-list-report", isDate: true},
        {label: "Supporter's List Report", url: "sponsors-wise-report", isDate: true},
        {label: "Speakerwise Report", url: "session-by-speaker-report"},
        {label: "Session Speaker List (fixed time interval)", url: "time-wise-speaker-report"},
        {label: "Program Details", url: "program-detail-report", isProgram: true},
        {label: "Venue Wise Speakers List", url: "venue-date-wise-speaker-report"},
    ]

    const getDropdown = reportQueries.useReportDropdown(
        (res)=>{
            if(res?.success){
                setDropdown(res.data)
            }
        }
    )

    useEffect(() => {
        getDropdown.mutateAsync()
    }, []);

    const getReport = reportQueries.useReportQueries(
        (res)=>{
            if(res?.success){
                window.open(`${process.env.REACT_APP_BASE_URL}${res.url}`, '_blank')
            }else{
                alert(res?.message || 'Failed to fetch dropdown')
            }
        }
    )

    const handleClick = (url) => {
        if(url === 'program-detail-report' && (!programId || !text)){
            alert('Please select program and enter reason')
            return
        }
        getReport.mutateAsync({url, date, language, text, id: programId})
    }

  return (
    <div className='grid grid-cols-12 max-[768px]:grid-cols-6 gap-3'>
        {
            getReport.isLoading && getReport.isLoading ? 'Downloading...':
            reports.map((report, index) => {
                return (
                    <div className={`w-full ${report?.isProgram ? "col-span-12" : "col-span-6"} flex max-[425px]:flex-col justify-between items-center py-3 px-3 bg-white text-black rounded-xl`} key={index}>
                        <div className='text-[16px] font-semibold'>{report.label}</div>
                        <div className='flex flex-row gap-3 justify-center items-center'>
                            {
                                report.isLanguage && (
                                    <select
                                    onChange={(e) => setLanguage(e.target.value)}    
                                    className='outline-none border-none bg-slate-200 rounded-md p-1 w-[140px] h-[40px]'
                                    >
                                        <option value=''>All Language</option>
                                        <option value="Malayalam">Malayalam</option>
                                        <option value="English">English</option>
                                    </select>
                                )
                            }
                            {
                                report.isProgram && (
                                    <div className='flex flex-row gap-3 max-[768px]:!flex-col'>
                                        <select
                                        onChange={(e) => setProgramId(e.target.value)}    
                                        className='outline-none border-none bg-slate-200 rounded-md p-1 w-[300px] h-[40px]'
                                        >
                                            <option value=''>Select Program</option>
                                            {
                                                dropdown?.sessions?.map((program, index) => {
                                                    return (
                                                        <option key={index} value={program.id}>{program.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <input
                                        placeholder='Enter reason'
                                        onChange={(e) => setText(e.target.value)}    
                                        className='outline-none border-none bg-slate-200 rounded-md px-2 text-[14px] w-[200px] h-[40px]'
                                        />
                                    </div>
                                )
                            }
                            {
                                report.isDate && (
                                    <select
                                    onChange={(e) => setDate(e.target.value)}    
                                    className='outline-none border-none bg-slate-200 rounded-md p-1 w-[140px] h-[40px]'
                                    >
                                        <option value=''>All</option>
                                        <option value="23">23/01/2025</option>
                                        <option value="24">24/01/2025</option>
                                        <option value="25">25/01/2025</option>
                                        <option value="26">26/01/2025</option>
                                    </select>
                                )
                            }
                            <button
                            onClick={()=> {handleClick(report.url)}}
                            className='outline-none border-none bg-black rounded-md p-2'
                            >
                            <DownloadCloud size={18} color='white' />
                            </button>
                        </div>
                    </div>
                )
            })
        }
    </div>
  )
}

export default Reports