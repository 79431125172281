import { useMutation } from "react-query";
import partnerService from "../services/partnerService";


const usePartnerSiteListingMutation = (onData) => {
    return useMutation(partnerService.logoSitelisting, {
      onSuccess: (data) => {
        onData(data);
      },
    });
  };

const partnerQueries = {
    usePartnerSiteListingMutation
    
};

export default partnerQueries;