
import { useMutation } from "react-query";
import reportService from "../services/reportService";

const useReportQueries = (onData) => {
    return useMutation(reportService.getReport, {
        onSuccess: (data) => {
            onData(data);
        },
    });
};

const useReportDropdown = (onData) => {
    return useMutation(reportService.reportDropdown, {
        onSuccess: (data) => {
            onData(data);
        },
    });
};

const reportQueries = {
    useReportQueries,
    useReportDropdown
};

export default reportQueries
