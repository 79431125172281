
import { useMutation } from "react-query";
import speakerService from "../services/speakerService";

const useSpeakerExport = (onData) => {
    return useMutation(speakerService.speakerExport, {
        onSuccess: (data) => {
            onData(data);
        },
    });
};

const speakerQueries = {
    useSpeakerExport
};

export default speakerQueries
