import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import siteQueries from '../queries/siteQueries';
import gmapicon from '../images/gmap-icon.png';
import SessionFavModal from './SessionFavModal';
import SessionShareModal from './SessionShareModal';

const Featuredsessionslist = () => {
  const [sessionsData, setSessionsData] = useState([]);
  const navigate = useNavigate();

  const featuredsessionslist = siteQueries.useFeaturedsessionslistMutation(
    async (response) => {
      if (response?.data) {
        console.log("response is", response.data);
        setSessionsData(response.data);
      }
    },
    {
      onError: (error) => {
        console.error('Error fetching sessions:', error);
      }
    }
  );

  useEffect(() => {
    featuredsessionslist.mutateAsync({});
  }, []);

  const colors = ['yellow', 'blue', 'green', 'red']; // Define your four colors
  const numbercolors = ['#eecf40','#e13231', '#31a452', '#4aa6df']; // Array of 4 colors
  

  return (
    <div className="sessions-wrpr sessions-home-wrpr">
      <div className="container">
        <div className="row">
          <div className="col-md-12 pb-5">
            <div className="sessions-slide row" data-aos="fade-up" data-aos-delay="300">
            {sessionsData.length > 0 && (
              <div className="col-md-12 p-0 text-center mb-3">
                <h2 data-aos="fade-up" data-aos-delay="100" className="aos-init aos-animate">
                  <span className="speaker-pattern"></span>
                  <a href="#">Featured programmes</a>
                  <span className="speaker-pattern"></span>
                </h2>
                <a
                  className="nav-link pl-0 btn btn-sm btn-dark btn-yellow viewfull_prg desktoponly"
                  data-aos="fade-up"
                  data-aos-delay="500"
                  href="/"
                >
                  View Full Programme Schedule
                </a>
              </div>
            )}

              {sessionsData.map((session, index) => (
                <div key={index} className="col-md-3 text-center">
                  <div className="card session-card">
                    <div className="card-header scard-header">
                      <div className="symbol-venue-fav-wrpr">
                        <div className="symbol-venue">
                          <div 
                            style={{
                              backgroundColor: numbercolors[index % numbercolors.length], // Cycle through the colors
                              color: '#000',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontWeight: 'normal',
                              height: '18px',
                              width: '18px',
                              borderRadius: '50%',
                              marginRight: '10px',
                              fontSize: '14px',
                              lineHeight: '6px'
                            }}
                          >
                            {session?.venue?.number}
                          </div>
                          <div className="venue">
                            <div className="venue-eng">{session?.venue?.name}</div>
                            <a href={session?.venue?.place} className="icon-location-custom" title="Venue Location">
                              <img src={gmapicon} alt="Venue Location" />
                            </a>
                          </div>
                        </div>
                        <a href="#" className="icon-share2 share-session" style={{display:'none'}}></a>
                        <div className="fav-wrpr">
                            <SessionFavModal/>
                        </div>
                        <SessionShareModal/>
                      </div>
                    </div>

                    <div className="card-body">
                      {session?.delegative_exclusive === "YES" && (
                        <div className="delegate-only">Delegate Only</div>
                      )}

                      <div className="date-time">
                        <div className={`date ${colors[session?.id % colors.length]}`}>
                          <span>
                            Jan&nbsp;
                            {new Intl.DateTimeFormat('en-GB', { day: '2-digit' }).format(new Date(session?.date))}
                          </span>
                        </div>
                        <div className="time">
                          <i className="icon-time1"></i>
                          <span>
                            {new Date(`1970-01-01T${session?.time_from}`).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} -
                            {new Date(`1970-01-01T${session?.time_to}`).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                          </span>
                        </div>
                      </div>

                      <div className="scard-heading">
                        <h2>{session?.name}</h2>
                      </div>

                      <div className="session-type category-wrpr">
                        <div className="session-type-label" style={{visibility: session?.category?.name ? 'visible' : 'hidden'
                           }}>{session?.category?.name}
                        </div>
                      </div>

                      {session?.session_speackers?.length > 0 && (
                        <div className="session-members speakers">
                          <ul>
                            {[...new Set(session.session_speackers?.map(speakerSession => speakerSession?.speaker?.heading))]
                              .map((heading, idx) => {
                                const speakerId = session?.session_speackers?.find(
                                  speakerSession => speakerSession?.speaker?.heading === heading
                                )?.speaker?.id;
                                const urlHeading = heading
                                  ?.toLowerCase()
                                  .replace(/[^a-z0-9]+/g, '-')
                                  .replace(/^-+|-+$/g, '');

                                return (
                                  <li key={idx} className="mb-1">
                                    <a
                                      href={`/speakerview/${speakerId}/${urlHeading}`}
                                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                                    >
                                      {heading}
                                    </a>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      )}

                     <div 
                        className="session-members modarators"
                        style={{
                          visibility: session?.session_moderators?.length > 0 ? 'visible' : 'hidden'
                        }}
                      >
                        <ul>
                          {[...new Set(session?.session_moderators?.map(moderatorSession => moderatorSession?.speaker?.heading))].length > 0 ? (
                            [...new Set(session?.session_moderators?.map(moderatorSession => moderatorSession?.speaker?.heading))]
                              .map((heading, idx) => {
                                const speakerId = session?.session_moderators?.find(
                                  moderatorSession => moderatorSession?.speaker?.heading === heading
                                )?.speaker?.id;
                                const urlHeading = heading
                                  ?.toLowerCase()
                                  .replace(/[^a-z0-9]+/g, '-')
                                  .replace(/^-+|-+$/g, '');

                                return (
                                  <li key={idx} className="mb-1">
                                    <a
                                      href={`/speakerview/${speakerId}/${urlHeading}`}
                                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                                    >
                                      {heading}
                                    </a>
                                  </li>
                                );
                              })
                          ) : (
                            <li className="mb-1"></li>
                          )}
                        </ul>
                      </div>


                     <div 
                      className="session-members storyteller"
                      style={{
                        visibility: session?.speakers?.length > 0 ? 'visible' : 'hidden'
                      }}
                    >
                      <ul>
                        {[...new Set(session?.speakers?.map(speaker => speaker?.heading))].length > 0 ? (
                          [...new Set(session?.speakers?.map(speaker => speaker?.heading))]
                            .map((heading, idx) => {
                              const speaker = session?.speakers?.find(speaker => speaker?.heading === heading);
                              const speakerId = speaker?.id;
                              const urlHeading = heading
                                ?.toLowerCase()
                                .replace(/[^a-z0-9]+/g, '-')
                                .replace(/^-+|-+$/g, '');

                              return (
                                <li key={idx} className="mb-1">
                                  <a
                                    href={`/speakerview/${speakerId}/${urlHeading}`}
                                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                                  >
                                    {heading}
                                  </a>
                                </li>
                              );
                            })
                        ) : (
                          <li className="mb-1"></li>
                        )}
                      </ul>
                    </div>

                    </div>

                     <div className="sponsoredby-title"
                          style={{ visibility: session?.session_sponsors?.length > 0 ? 'visible' : 'hidden',
                        }}
                      >
                            Supported by:
                      </div>
                    <div className="date-and-time">
                      <div className="sponsor-logo-ruppe-icon">
                            {session?.session_sponsors?.map((sponsor, idx) => (
                                  <div key={idx} style={{ display: 'inline-block', textAlign: 'center' }}>
                                    <strong>
                                        {sponsor.logo?.name}
                                        {idx < session.session_sponsors.length - 1 ? ',' : ''}
                                    </strong>
                                  </div>
                            ))}
                        </div>
                    </div>
                  </div>
                </div>
              ))}

               {sessionsData.length > 0 && (
                  <div className="col-md-12 text-center section-btn-wrpr">
                    <a
                      className="nav-link ps-4 pe-4 btn btn-sm btn-dark btn-yellow aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-delay="500"
                      href="/"
                    >
                      View Full Programme Schedule
                    </a>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featuredsessionslist;