import React, { useState, useRef } from "react";
import userQueries from '../../queries/userQueries';
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import RichTextEditor from "../Rte/Rteeditor";
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Upload, Save, RotateCcw, X } from 'lucide-react';

const AddSpeaker = () => {
    const [usernameExist, setUsernameExist] = useState('');
    const [susmsg, setSusmsg] = useState(false);
    const [usernameerror, setUsernameerror] = useState(false);
    const [error, setError] = useState(false);
    const [imageError, setImageError] = useState('');
    const [gendererror, setGendererror] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    
    // Image Editor States
    const [showImageEditor, setShowImageEditor] = useState(false);
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [aspect, setAspect] = useState(undefined);
    const [editingImage, setEditingImage] = useState(null);
    
    const navigate = useNavigate();
    const imageRef = useRef(null);
    const imgRef = useRef(null);
    const canvasRef = useRef(null);

    const { register, handleSubmit, getValues, control, formState: { errors } } = useForm();

    const MAX_FILE_SIZE = 350 * 1024; // 350KB in bytes


    const AddInfodata = userQueries.useCreatespeakerMutation((response) => {
        if (response?.message === "Success") {
            setSusmsg(true);
            alert(response?.data.status === 'Unpublish' ? "Saved as draft" : "Published successfully");
        } else if(response?.message === "User Exists with this Phone") {
            alert("User Exists with this phone number")
            setLoading(false);
        } else if(response?.message === "User Exists with this Email") {
            alert("User Exists with this Email")
            setLoading(false);
        } else if(response?.message === "User Exist") {
            alert("User Exists with this Email or Phone Number")
            setLoading(false);
        }
    });

    const UpdateUserImage = userQueries.useSpeakerimageMutation((response) => {
        if (response?.message === "Profile image uploaded successfully") {
            setSusmsg(true);
        }
    });

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const allowedTypes = ['image/jpeg', 'image/jpg'];
            if (!allowedTypes.includes(file.type)) {
                setImageError("Only JPEG, JPG, files are allowed.");
                setImagePreview(null);
                return;
            } if (file.size > MAX_FILE_SIZE) {
                setImageError("Image size should not exceed 350KB.");
                setImagePreview(null);
                return false;
            }

            setImageError('');
            const reader = new FileReader();
            reader.onloadend = () => {
                setEditingImage(reader.result);
                setShowImageEditor(true);
            };
            reader.readAsDataURL(file);
        }
    };

    const generateCroppedImage = async () => {
        if (!completedCrop || !canvasRef.current || !imgRef.current) {
            return null;
        }

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            return null;
        }

        const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
        const scaleY = imgRef.current.naturalHeight / imgRef.current.height;

        canvas.width = completedCrop.width * scaleX;
        canvas.height = completedCrop.height * scaleY;

        ctx.drawImage(
            imgRef.current,
            completedCrop.x * scaleX,
            completedCrop.y * scaleY,
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
            0,
            0,
            completedCrop.width * scaleX,
            completedCrop.height * scaleY
        );

        return new Promise((resolve) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        setImageError("Failed to create image");
                        return;
                    }
                    resolve(blob);
                },
                'image/jpeg',
                0.95
            );
        });
    };

    const handleCropComplete = async () => {
        const croppedImageBlob = await generateCroppedImage();
        if (croppedImageBlob) {
            const croppedImageUrl = URL.createObjectURL(croppedImageBlob);
            setImagePreview(croppedImageUrl);
            setShowImageEditor(false);
            
            // Create a new File object from the blob
            const croppedImageFile = new File([croppedImageBlob], 'cropped-image.jpg', {
                type: 'image/jpeg'
            });
            
            // Create a new DataTransfer object and add the file
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(croppedImageFile);
            
            // Update the file input's files property
            if (imageRef.current) {
                imageRef.current.files = dataTransfer.files;
            }
        }
    };

    const handleFormSubmit = async (data, publishStatus) => {
        setUsernameExist(false);
        setSusmsg(false);
        
        if(publishStatus === "Publish") {
            if (!imageRef.current.files[0]) {
                setImageError("Image is required.");
                return;
            } else {
                setImageError("");
            }
        }
       
        const userData = {
            username: data.email,
            heading: data.heading,
            email: data.email,
            phone: data.phone,
            contact_person: data.contact_person,
            country_code: data.country_code,
            country_code1: data.country_code1,
            content: data.content,
            role: 'SPEAKER',
            status: publishStatus, 
            display_order: data.displayorder || 1,
        };
    
        try {
            setLoading(true);
            const createdUser = await AddInfodata.mutateAsync(userData);

            if (createdUser?.message === 'Success') {
                if (imageRef.current.files[0]) {
                    const formData = new FormData();
                    formData.append('speakers', imageRef.current.files[0]);
                    formData.append('userId', createdUser.data.id);
                    await UpdateUserImage.mutateAsync(formData);
                }
                navigate('/speakerlisting');
            }
        } catch (error) {
            console.log('Error occurred:', error);
            if (error.response?.status === 409) {
                const errorMessage = error.response?.data?.message || 'Unknown error';
                if (errorMessage === 'username already exists') {
                    setUsernameExist(true);
                }
            } else {
                alert('An unexpected error occurred');
            }
            setLoading(false);
        }
    };

    return (
        <>
            <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
                Create Speaker
            </h4>
            <form className="max-w-lg mx-auto grid grid-cols-2 gap-4">
                {/* Existing form fields... */}
                <div className="mb-5 col-span-2">
                    <label htmlFor="heading" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Heading
                    </label>
                    <input 
                        type="text" 
                        id="heading" 
                        {...register("heading", { required: "Heading is required" })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    {errors.heading && <div className="text-red-600 text-s">{errors.heading.message}</div>}
                </div>

                <div className="mb-5 col-span-2">
                    <label htmlFor="content" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Content
                    </label>
                    <Controller
                        name="content"
                        control={control}
                        rules={{ required: "Content is required" }}
                        render={({ field }) => (
                            <RichTextEditor
                                {...field}
                                id="content"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                rows="4"
                            />
                        )}
                    />
                    {errors.content && <div className="text-red-600 text-sm">{errors.content.message}</div>}
                </div>

                {/* Image Upload Section */}
                <div className="mb-5 col-span-2">
                    <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Upload Image
                    </label>
                    {imagePreview && !showImageEditor && (
                        <div className="relative w-48 h-48 mb-4">
                            <img 
                                src={imagePreview} 
                                alt="Preview" 
                                className="w-full h-full object-cover rounded-lg shadow-md"
                            />
                            <button
                                type="button"
                                onClick={() => {
                                    setImagePreview(null);
                                    if (imageRef.current) {
                                        imageRef.current.value = '';
                                    }
                                }}
                                className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                            >
                                <X size={16} />
                            </button>
                        </div>
                    )}
                    
                    <input 
                        type="file" 
                        id="image" 
                        ref={imageRef}
                        onChange={handleImageChange}
                        accept="image/*"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    {imageError && <div className="text-red-600 text-s">{imageError}</div>}
                    <span className="form-text text-warning small">
                        Recommended image size: less than 300 KB.
                    </span>
                </div>

                {/* Image Editor Modal */}
                {showImageEditor && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-xl font-semibold">Edit Image</h3>
                                <button
                                    type="button"
                                    onClick={() => setShowImageEditor(false)}
                                    className="text-gray-500 hover:text-gray-700"
                                >
                                    <X size={24} />
                                </button>
                            </div>
                            
                            <div className="space-y-4">
                                <div className="flex gap-4 mb-4">
                                    <button
                                        type="button"
                                        onClick={() => setRotate((prev) => (prev - 90) % 360)}
                                        className="flex items-center gap-2 px-3 py-1 bg-gray-100 rounded hover:bg-gray-200"
                                    >
                                        <RotateCcw size={16} />
                                        Rotate
                                    </button>
                                    
                                    <div className="flex items-center gap-2">
                                        <label className="text-sm font-medium">Scale:</label>
                                        <input
                                            type="range"
                                            min="0.1"
                                            max="2"
                                            step="0.1"
                                            value={scale}
                                            onChange={(e) => setScale(Number(e.target.value))}
                                            className="w-24"
                                        />
                                        <span className="text-sm">{Math.round(scale * 100)}%</span>
                                    </div>
                                </div>

                                <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                    aspect={aspect}
                                >
                                    <img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={editingImage}
                                        style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                        className="max-w-full"
                                    />
                                </ReactCrop>

                                <div className="flex justify-end gap-2 mt-4">
                                    <button
                                        type="button"
                                        onClick={() => setShowImageEditor(false)}
                                        className="px-4 py-2 text-gray-600 bg-gray-100 rounded hover:bg-gray-200"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleCropComplete}
                                        className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                                    >
                                        Apply
                                    </button>
                                </div>
                            </div>
                            
                            <canvas
                                ref={canvasRef}
                                className="hidden"
                            />
                        </div>
                    </div>
                )}

                <div className="flex gap-2 col-span-2">

                <div className="mb-5">
                <label htmlFor="country_code" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                   Country Code
                </label>
                <input
                    type="text"
                    id="country_code"
                    {...register("country_code", {
                   
                   
                    })}
                    inputMode="numeric" // Ensures numeric keyboard on mobile devices
                    maxLength={5} // Limits the input to a maximum of 5 characters
                    onInput={(e) => {
                    // Remove non-numeric characters from the input
                    e.target.value = e.target.value.replace(/\D/g, '');
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                />
               
                </div>
              

                {/* Rest of the form fields */}
                <div className="mb-5 mb-5 flex-1">
                    <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Phone Number
                    </label>
                    <input
                        type="tel"
                        id="phone"
                        {...register("phone", {
                            pattern: {
                                value: /^(?:\+91|91)?\d{10}$/,
                                message: "Please enter a valid phone number"
                            }
                        })}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                     {errors.phone && <div className="text-red-600 text-sm">{errors.phone.message}</div>}

</div>
</div>

                

                <div className="flex gap-2 col-span-2">

                <div className="mb-5">
                <label htmlFor="country_code1" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                   Country Code
                </label>
                <input
                    type="text"
                    id="country_code1"
                    {...register("country_code1", {
                   
                   
                    })}
                    inputMode="numeric" // Ensures numeric keyboard on mobile devices
                    maxLength={5} // Limits the input to a maximum of 5 characters
                    onInput={(e) => {
                    // Remove non-numeric characters from the input
                    e.target.value = e.target.value.replace(/\D/g, '');
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                />
               
                </div>
              

                {/* Rest of the form fields */}
                <div className="mb-5 mb-5 flex-1">
                    <label htmlFor="contact_person" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Contact person phone number
                    </label>
                    <input
                        type="tel"
                       id="contact_person"
                        {...register("contact_person", {
                            pattern: {
                                value: /^(?:\+91|91)?\d{10}$/,
                                message: "Please enter a valid phone number"
                            }
                        })}
                        inputMode="numeric" // Ensures numeric keyboard on mobile devices
                        onInput={(e) => {
                    // Remove non-numeric characters from the input
                    e.target.value = e.target.value.replace(/\D/g, '');
                    }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                     {errors.contact_person && <div className="text-red-600 text-sm">{errors.contact_person.message}</div>}

</div>
</div>

                
<div className="mb-5 col-span-1">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                    <input 
                        type="email" 
                        id="email" 
                        {...register("email")} 
                        name="email" 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    {errors.email && <div className="text-red-600 text-s">{errors.email.message}</div>}
                </div>

                {usernameExist && (
                    <div className="flex justify-center">
                        <div className="text-red-600 text-s">
                            {usernameerror}
                        </div>
                    </div>
                )}

            <div className="mb-5">
                <label htmlFor="displayorder" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Display Order</label>
                <input 
                    type="number" 
                    id="displayorder" 
                    {...register("displayorder", { required: false })} 
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                />
            
            </div>


        <button 
            type="button" 
            onClick={() => handleFormSubmit(getValues(), 'Unpublish')}
            className="col-span-2 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-3"
        >
            Save as draft
        </button>

        <button 
            type="button"
            onClick={handleSubmit((data) => handleFormSubmit(data, 'Publish'))}
            className="col-span-2 w-full text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
            Save and publish
        </button>
            {/* Loader */}
            {loading && (
                <Loader />
            )}
        {susmsg && 
            <div className="text-green-600 text-lg ml-6 text-center inline-block whitespace-nowrap">
                User created successfully
            </div>
        }
        </form>
</>
);
};

export default AddSpeaker;