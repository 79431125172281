import React, { useEffect, useState, useRef, useCallback } from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import InitModal from './initModal';
import { useScrollToTop } from '../hooks/ScrollTop';
import siteQueries from '../queries/siteQueries';
import Loader from '../components/Loader/Loader';
import 'venobox/dist/venobox.min.css';
import { useNavigate } from 'react-router-dom';
import '../css/developer2.css';

export default function SpeakerListingsite() {
    const [isOpen, setIsOpen] = useState(false);
    const [speakers, setSpeakers] = useState([]);
    const [filterSpeakers, setFilterSpeakers] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [selectedSpeaker, setSelectedSpeaker] = useState('');
    const [speakerFilter, setSpeakerFilter] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isFilterLoading, setIsFilterLoading] = useState(true);
    const pageSize = 20;
    
    const observer = useRef();
    const lastSpeakerElementRef = useCallback(node => {
        if (isLoadingMore) return;
        if (observer.current) observer.current.disconnect();
        
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setCurrentPage(prevPage => prevPage + 1);
                setIsLoadingMore(true);
            }
        });
        
        if (node) observer.current.observe(node);
    }, [isLoadingMore, hasMore]);

    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);
    const navigate = useNavigate();
    
    const Speaker = siteQueries.useSpeakerListingMutation(
        async (response) => {
            const newSpeakers = response?.data?.items;
            if (currentPage === 0) {
                setSpeakers(newSpeakers);
            } else {
                setSpeakers(prev => [...prev, ...newSpeakers]);
            }
            setHasMore(response?.data?.totalPages > currentPage + 1);
            setIsLoading(false);
            setIsLoadingMore(false);
        },
    );

    const FilterSpeaker = siteQueries.useSpeakerlistforfilterMutation(
        async (response) => {
            setFilterSpeakers(response?.data);
            setIsFilterLoading(false);
        },
    );

    
    useEffect(() => {
        Speaker.mutateAsync({
            page: currentPage,
            size: pageSize,
            heading: selectedSpeaker
        });

        if (isFilterLoading) {
            FilterSpeaker.mutateAsync({
                page: 0,
                size: 1000,
                heading: ''
            });
        }
    }, [currentPage, selectedSpeaker, isFilterLoading]);

    // const handleSpeakerSelect = (speakerHeading) => {
    //     setSelectedSpeaker(speakerHeading);
    //     setIsDropdownOpen(false);
    //     setCurrentPage(0);
    //     setSpeakers([]);
    //     setHasMore(true);
    // };
    const handleSpeakerSelect = (speakerHeading) => {
        setSpeakers([]); 
        setCurrentPage(0);
        setHasMore(true); 
        setIsLoading(true); 
        
        setSelectedSpeaker(speakerHeading);
        setIsDropdownOpen(false);
         Speaker.mutateAsync({
            page: 0,
            size: pageSize,
            heading: speakerHeading
        });
    };

    const decodeHtml = (html) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = html;
        return textArea.value;
    };

    const handleNavigateWithState = (speaker) => {
        const sanitizedHeading = speaker.heading.replace(/[^a-zA-Z0-9]/g, '_');
        navigate(`/speakerview/${speaker.id}/${sanitizedHeading}`);
    };

    useEffect(() => {
        document.title = `Speakers | Kerala Literature Festival 2025`;
        return () => {
            document.title = 'KLF 2025 | Kerala Lit Fest | Literature Festival | Literary Festival 2025';
        };
    }, []);

    useScrollToTop();

    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <Navbar handleOpen={handleOpen} />
                    <div className="innerbanner container-fluid">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <span className="logo-strips"></span> <h2>Speakers</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container mt-4">
                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                                <div className="dropdown speaker-filter">
                                   <input
                                    type="text"
                                    className="form-control form-control-sm text-left d-flex justify-content-between align-items-center"
                                    style={{ backgroundColor: 'white', height: '40px', }}
                                    placeholder="Search speakers..."
                                    value={speakerFilter}
                                    onChange={(e) => {
                                        setSpeakerFilter(e.target.value);
                                        setIsDropdownOpen(true); 
                                    }}
                                    onFocus={() => setIsDropdownOpen(true)} 
                                />
                                {isDropdownOpen && (
                                    <div
                                        className="dropdown-menu show w-100"
                                        style={{
                                            maxHeight: '300px',
                                            overflowY: 'auto',
                                            padding: '10px',
                                            display: 'block',
                                            position: 'absolute',
                                            zIndex: 1000,
                                        }}
                                    >
                                        <button
                                            className={`dropdown-item hover-blue ${!selectedSpeaker ? 'active' : ''}`}
                                            onClick={() => {
                                                handleSpeakerSelect('');
                                                setSpeakerFilter('');
                                            }}
                                        >
                                            All Speakers
                                        </button>
                                        {filterSpeakers
                                            .filter((speaker) =>
                                                speaker.heading.toLowerCase().includes(speakerFilter.toLowerCase())
                                            )
                                            .map((speaker) => (
                                                <button
                                                    key={speaker.id}
                                                    className={`dropdown-item hover-blue ${
                                                        selectedSpeaker === speaker.heading ? 'active' : ''
                                                    }`}
                                                    onClick={() => handleSpeakerSelect(speaker.heading)}
                                                >
                                                    {speaker.heading}
                                                </button>
                                            ))}
                                    </div>
                                )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="speakers-wrpr speakers-list">
                        <div className="container">
                            <div className="row">
                                {speakers?.map((speaker, index) => (
                                    <div
                                        ref={index === speakers.length - 1 ? lastSpeakerElementRef : null}
                                        onClick={() => handleNavigateWithState(speaker)}
                                        style={{cursor: 'pointer'}}
                                        className="col-md-3 pb-5 text-center"
                                        key={index}
                                    >
                                        <div className="card">
                                            <img
                                                src={`${process.env.REACT_APP_BASE_URL}/${speaker?.photo}`}
                                                alt={speaker?.name}
                                            />
                                            <div className="speaker-name mb-2">{speaker?.heading}</div>
                                            <div className="speaker-description">
                                                <p>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: decodeHtml(speaker?.content)
                                                        }}
                                                    />
                                                </p>
                                            </div>
                                            <a href="#" data-bs-toggle="modal" data-bs-target="#about-speaker" className="btn btn-dark btn-sm btn-readmore rounded-pill mt-3 stretched-link">Read More</a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {isLoadingMore && (
                                <div className="text-center py-4">
                                    <Loader />
                                </div>
                            )}
                        </div>
                    </div>

                    <Footer handleOpen={handleOpen} />
                    <InitModal isOpen={isOpen} onClose={handleClose} />
                </>
            )}
        </div>
    );
}