import apiRequest from '../apis/api-request';
import { userStore } from '../store/userStore';


const accessToken = userStore.getState().user.token
const adminService = {

    permission: async (data) => {
        // const id = data.id 
        return apiRequest({
            method:"POST",
            url: `adminpermission/create`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    email: async (data) => {
        // const id = data.id 
        return apiRequest({
            method:"POST",
            url: `adminpermission/email-availability`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    adminDelete: async (id) => {
        return apiRequest({
            method:"DELETE",
            url: "/user/delete/" + id, 
            headers: {
                Authorization: "Bearer " + accessToken
            }
        });
      },

}


export default adminService;
