import { useMutation } from "react-query";
import adminService from "../services/adminService";

const useAdminPermissionCreateMutation = (onData) => {
    return useMutation(adminService.permission, {
      onSuccess: (data) => {
        onData(data);
      },
    });
  };

  const useEmailChekingMutation = () => {
    return useMutation(adminService.email, {
      onSuccess: (data) => {
      },
    });
  };

  const useDeleteadminMutation = (onDeletete) => {
    return useMutation(adminService.adminDelete, {
      onSuccess: (data) => {
        onDeletete(data);
      },
    });
  };

   const adminQueries = {

    useAdminPermissionCreateMutation,
    useDeleteadminMutation,
    useEmailChekingMutation
  };
  
  export default adminQueries;
  