import React, { useState } from 'react'
import Footer from './Footer';
import InitModal from './initModal';
import Navbar from './Navbar';

export default function TermsAndConditions() {
    const [isOpen, setIsOpen] = useState(false);

        const handleClose = () => setIsOpen(false);
        const handleOpen = () => setIsOpen(true);

    return (
        <>
       <Navbar handleOpen={handleOpen} handleClose={handleClose} />
             <div className="innerbanner registration container-fluid">
               <div className="container">
                 <div className="row">
                   <div className="col-md-12">
                        <span class="logo-strips"></span> <h2>Terms &amp; <span>Conditions</span></h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mt-0 mb-5">
        <div class="row">
            <div class="col-md-12">
            <iframe
                src="/terms.html"
                style={{ 
                    width: '100%', 
                    height: '175vh', 
                    border: 'none' }}
                title="Terms and Conditions"
            ></iframe>
        </div>
        </div>
        </div>
        <Footer handleOpen={handleOpen} />
        <InitModal isOpen={isOpen} onClose={handleClose} />
       </>
    );
};
