import React, {useState, useRef, useEffect} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { countriesData } from './countryData'; 
import authQueries from '../queries/authQueries';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Navbar from './Navbar';
import Footer from './Footer';
import InitModal from './initModal';
import { useNavigate } from 'react-router-dom';
import BootstrapLoader from '../components/Loader/BootstrapLoader';
import siteQueries from '../queries/siteQueries';
import { useScrollToTop } from '../hooks/ScrollTop';
import FeedbackModal from './FeedbackModal';
import AgeInput from '../components/Agedrop/AgeSelect';
import AttendeeMember from './AttendeGroupMember';
import groupQueries from '../queries/groupQueries';


const CustomAlert = ({ title, message }) => (
  <div className="alert alert-success" role="alert">
    <h4 className="alert-heading">{title}</h4>
    <p>{message}</p>
  </div>
);

const schema = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup.string().min(10, 'Phone number must be at least 10 digits').required('Phone number is required'),
  gender: yup.string().required('Gender is required'),
});


export default function AttendeeGroupRegistration() {
  const navigate = useNavigate();

  const [isFeedbackOpen, setFeedbackOpen] = useState(false);

  const handleFeedbackOpen = () => setFeedbackOpen(true);
  const handleFeedbackClose = () => setFeedbackOpen(false);

    const { control, handleSubmit,reset, formState: { errors, isValid }, watch, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          dob: '2024-11-02 00:00:00+00',
          gender: '',
          pin_code: '',
          country: "India",
          state: 'Kerala',
          address:'',
          district:'',
          termsAccepted: true
        },
        mode: 'onChange'
      });

  const [submitted, setSubmitted] = React.useState(false);
  const [feedBacksubmit, setFeedBacksubmit] = React.useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [states, setStates] = useState([]);
  const watchAllFields = watch();
  const watchCountry = watch("country");
  const [errorMessage, setErrorMessage] = useState('');
  const [code, setCode] = useState('in');
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const [savedData, setSavedData] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState('');
  const [showError, setShowError] = useState(false);
  
  const userPaidstatus = siteQueries.useDelagatePaidstatusMutation(async (response) => {
    
  });

  const userCreate = siteQueries.useAttendeeGroupMutation(async (response) => {
  });

  // const qrCreate = siteQueries.useQrcreateMutation(async (response) => {
  // });
  const qrCreate = groupQueries.useGroupQrcreateMutation(async (response) => {
  });

  const ZohoCrm = authQueries.useZohoCrmMutation(async (response) => {
  });

  const marketing_id = localStorage.getItem('marketingId');
 

  const onSubmit = async (data) => {
    const response = await userPaidstatus.mutateAsync(data);
        if (response?.success === true) {
            alert("User already exists");
            return;
        }
    // } catch (error) {
    //     console.error("Error checking user existence:", error);
    //     setErrorMessage('An error occurred while checking if the user exists.');
    //     return;
    // }
    const leaderData = {
        ...data,
        username: data.email,
        role: "USER",
        age: data?.age?.value,
        leader: 1,
        marketing_id

        // dob: new Date(data.dob).toLocaleDateString('en-IN'),
    };

    if(data.gender===''){
      leaderData.gender='PREFER NOT TO RESPOND'
    }
    const usersToCreate = [
        leaderData,
        ...savedData.map(member => ({
            ...member,
            username: member.email ||member.first_name,
            role: "USER",
            marketing_id,
        })),
    ]; 
    console.log("usersToCreate>>>",usersToCreate)
    setIsLoading(true);
    // await createGroupData(usersToCreate);
      try {
        const response = await userCreate.mutateAsync(usersToCreate);
        const validUsers = usersToCreate.filter(user => user.email && user.phone);

  // if (validUsers.length === 0) {
  //   setIsLoading(false);
  //   setErrorMessage("No valid users with email or phone to process.");
  //   return;
  // }
        if (response.message === 'Success') {
          const qrcode =  await qrCreate.mutateAsync({id:response?.leaderid});
          for (const user of validUsers) {
            await ZohoCrm.mutateAsync({
              "firstName": user.first_name,
              "lastName": user.last_name,
              "email": user.email,
              "Phone": user.phone,
              "Zip_Code": user.pin_code,
              "Gender": user.gender,
              "State": user.state,
              "City": user.district,
              "Full_Address": user.address,
              "Type_Of_Registration": ["Attendees"],
            });
          }
          sessionStorage.setItem('qrdata', JSON.stringify(qrcode));
          setIsLoading(false)
          reset(); // Reset the form
          localStorage.removeItem('delegateMemberData');
          setSubmitted(true);
          navigate('/atsuccess');
          // You might want to show a success message or redirect the user
        } else {
          setIsLoading(false)
          setErrorMessage('Email Or Phone Already Exist.');
        }
      } catch (error) {
        setIsLoading(false)
        console.error("Error during registration:", error);
        
        if (error.response) {
          setIsLoading(false)
          setErrorMessage(error.response.data.message || 'An error occurred during registration.');
        } else if (error.request) {
          setIsLoading(false)
          setErrorMessage('No response received from the server. Please try again.');
        } else {
          setIsLoading(false)
          setErrorMessage('Email Or Phone Already Exist.');
        }
      }
    
};
  useEffect(() => {
    const allFieldsFilled = Object.values(watchAllFields).every(field => field !== '' && field !== false);
    setIsFormFilled(allFieldsFilled);
  }, [watchAllFields]);

  useEffect(() => {
    const selectedCountry = countriesData.find(country => country.name === watchCountry);
    if (selectedCountry) {
      // Sort states alphabetically before setting them
      const sortedStates = [...selectedCountry.states].sort((a, b) => a.localeCompare(b));
      setStates(sortedStates);
      setValue("state", ""); // Reset state when country changes
    } else {
      setStates([]);
    }
  }, [watchCountry, setValue]);

  // Sort the countries alphabetically
  const sortedCountries = [...countriesData].sort((a, b) => 
  a.name.localeCompare(b.name)
  );
  
  const handleSave = (newMember) => {
    setSavedData((prevData) => [...prevData, newMember]); // Append new data to the existing array
  };

  // const leaderDataErrorMsg = () => {
  //   if(firstName.length && lastName.length === 0){
  //     setShowError(true)
  //   }setShowError(false)
  // }

  const handleClick = () => {
    if (!firstName || !lastName || !email || !phone || !gender) {
      setShowError(true); 
      return;
    }
    setShowError(false); 
  };

  const handleRemove = (indexToRemove) => {
    const updatedData = savedData.filter((_, index) => index !== indexToRemove);
    setSavedData(updatedData); 
    localStorage.setItem('delegateMemberData', JSON.stringify(updatedData)); 
  };
 
  useScrollToTop();

  return (
    <>
    <Navbar handleOpen={handleOpen} handleClose={handleClose} />
    <div className="innerbanner registration container-fluid" >
        <div className="container">
            <div className="row">
                {/* <div className="col-md-12 mb-3"> */}
                <div class="col-md-6">
                    <span className="logo-strips "></span> <h2>Attendee <span>Registration</span></h2>
                    <p>Entry to KLF 2025 is free but registration is mandatory.</p>
                </div>
            </div>
        </div>
    </div>

    {/* <div className="why-delegate">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h5>Why be a KLF Delegate?</h5>
              <p class="mb-2">As a valued supporter of the Kerala Literature Festival (KLF), we invite you to be part of our Delegate Network. KLF has grown into Asia's largest literature festival, attracting half-a-million participants, thanks to your enthusiasm and encouragement. This achievement is further celebrated with Kozhikode being recognized as India's first UNESCO 'City of Literature'.</p>
				
            </div>
          </div>
        </div>
      </div> */}

    {isLoading ? (
      <BootstrapLoader />

       ) : (
      <div className="container">
         <h2 className='pt-1'> Group Leader Information</h2>
         <div className="col-md-6">
                 <p>QR code ticket will be generated for members for whom unique email id is provided</p>
                </div>
        <div className="row">
          <div className="col-md-7 pt-2 pb-5">
            <form onSubmit={handleSubmit(onSubmit)} className="delegate-form pt-3 pb-5">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-6">
                    <Controller
                      name="first_name"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}
                          onChange={(e) => {
                            field.onChange(e); // Trigger the Controller's change handler
                            setFirstName(e.target.value);
                            setShowError(false);  
                          }}
                          type="text"
                          placeholder="First name"
                        />
                      )}
                    />
                    {errors.first_name && <div className="invalid-feedback">{errors.first_name.message}</div>}
                  </div>
                  <div className="col-md-6">
                    <Controller
                      name="last_name"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`form-control ${errors.last_name ? 'is-invalid' : ''}`}
                          onChange={(e) => {
                            field.onChange(e); 
                            setLastName(e.target.value);
                          }}
                          type="text"
                          placeholder="Last name"
                        />
                      )}            
                    />
                    {errors.last_name && <div className="invalid-feedback">{errors.last_name.message}</div>}
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-md-6">
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                          onChange={(e) => {
                            field.onChange(e); 
                            setEmail(e.target.value);
                          }}
                          type="email"
                          placeholder="Email"
                        />
                      )}
                    />
                    {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                  </div>
                  <div className="col-md-6">

                    <Controller
                      name="phone"
                      control={control}
                      className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                      render={({ field: { onChange } }) => (
                        <PhoneInput
                            className={`phoneinput ${errors.phone ? 'is-invalid' : ''}`}
                            enableSearch={true}
                            country={code}
                            // value={appStore?.appData?.mobile}
                            onChange={(value, country, e, formattedValue) => {
                              setCode(country.countryCode)
                              onChange(value)
                              setPhone(e.target.value); 
                            }}
                        /> 
                      )}
                      rules={{ required: true }}
                    />
                   {errors.phone && <div className="invalid-feedback">{errors.phone.message}</div>}
                  </div>
                </div>

                <div className="row mt-5">
                <AgeInput control={control} errors={errors} />
                  <div className="col-md-4 gender-wrpr">
                    <Controller
                      name="gender"
                      control={control}
                      render={({ field }) => (
                        <select
                          {...field}
                          onChange={(e) => {
                            field.onChange(e); 
                            setGender(e.target.value);
                          }}
                          className={`form-control ${errors.gender ? 'is-invalid' : ''}`}
                        >
                          <option value="">Select Gender</option>
                          <option value="MALE">MALE</option>
                          <option value="FEMALE">FEMALE</option>
                          <option value="OTHERS">OTHERS</option>
                          <option value="PREFER NOT TO RESPOND">PREFER NOT TO RESPOND</option>
                        </select>
                      )}
                    />
                    {errors.gender && <div className="invalid-feedback">{errors.gender.message}</div>}
                  </div>

                  <div className="col-md-3">
                  <div className='countryselect-wrpr'>
                  <Controller
                    name="country"
                    control={control}
                    render={({ field }) => (
                      <select
                        {...field}
                        className={`form-control ${errors.country ? 'is-invalid' : ''}`}
                      >
                        <option value="">Select Country</option>
                        {sortedCountries.map((country, index) => (
                          <option key={index} value={country.name}>{country.name}</option>
                        ))}
                      </select>
                    )}
                  />
                  </div>
                  {errors.country && <div className="invalid-feedback">{errors.country.message}</div>}
                </div>

                  {/* <div className="col-md-4">
                    <label htmlFor="pin_code">Pin Code:</label>
                    <Controller
                      name="pin_code"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`form-control ${errors.pin_code ? 'is-invalid' : ''}`}
                          type="text"
                          placeholder="Enter pin code"
                        />
                      )}
                    />
                    {errors.pin_code && <div className="invalid-feedback">{errors.pin_code.message}</div>}
                  </div> */}
                </div>

               
              <div className="row mt-5">

                <div className="col-md-4 state-wrpr">
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <select
                        {...field}
                        className={`form-control ${errors.state ? 'is-invalid' : ''}`}
                        disabled={!watchCountry}
                      >
                        <option value="">Select State</option>
                        {states.map((state, index) => (
                          <option key={index} value={state}>{state}</option>
                        ))}
                      </select>
                    )}
                  />
                  {errors.state && <div className="invalid-feedback">{errors.state.message}</div>}
                </div>
                <div className="col-md-4 district-wrpr">
                    <Controller
                      name="district"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`form-control ${errors.pin_code ? 'is-invalid' : ''}`}
                          type="text"
                          placeholder="Enter District"
                        />
                      )}
                    />
              
                </div>
              </div>

                <div className="row mt-5">
                  <div style={{display:'none'}} className="col-md-6">
                    <label>
                      <Controller
                        name="termsAccepted"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="checkbox"
                            checked
                            {...field}
                            className={errors.termsAccepted ? 'is-invalid' : ''}
                          />
                        )}
                      />
                      <a className="terms" href="#">Terms & Conditions</a>
                    </label>
                    {errors.termsAccepted && <div className="invalid-feedback">{errors.termsAccepted.message}</div>}
                  </div>
                  <div className="col-md-6">
                    <div className="text-end">
                    <button 
                      className="btn btn-primary btn-registration mt-5 me-2"
                      type="submit"
                      disabled={!isValid || savedData.length === 0}
                    >
                      Register <i className="icon-arrow-right1"></i>
                    </button> 
                    <div
                            style={{ display: 'inline-block', cursor: 'pointer' }}
                            onClick={handleClick}>
                            <span 
                            // onClick={handleClick}
                            style={{ pointerEvents: firstName && lastName && email && phone && gender? 'auto' : 'none' }} >
                            <AttendeeMember savedData={savedData} onSave={handleSave} />
                            </span>
                            </div>
                    </div>
                    {showError && <p style={{ color: 'red' }}>Please fill the leader data before proceeding.</p>}
                  </div>
                  <div className="row mt-5">

                
              </div>
                </div>

                <div className="row mt-5">
                  <div style={{display:'none'}} className="col-md-6">

                   
                  </div>
                  <div className="col-md-6" style={{display:'none'}}>
                    <div className="text-end">
                    <a onClick={handleFeedbackOpen} className="btn btn-transparent btn-feedback">
                    Need help ? Please contact support
                    </a>
                    </div>
                  </div>
                </div>


              </div>
              <div className="row mt-5">
                  <div className="col-md-12">
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                  </div>
              </div>
            </form>
          </div>
         
          <div className="col-md-5 pt-5 pb-5">
     <div class="groupinfo-wrpr sticky-top">
				<div class="card">
					<div class="card-body">
      <h5 className='text-black'>Group Information</h5>
        {firstName && lastName && email && phone && gender &&( 
       <div class="group-list-wrpr">
       <ol class="group-ol">
         <li class="group-admin">
          <span>
             {firstName} {lastName}
                    <AttendeeMember savedData={savedData} onSave={handleSave} showIcon={true}/>
                    
                    </span>
                    </li>
                       
          {savedData.map((member, index) => (
            <div key={index}>
             <li>  
             <span>
                {member.first_name} {member.last_name}
                <a 
                  class="list-action icon-remove"
                  onClick={() => handleRemove(index)} 
                  title="Remove member">
                  
                </a>
              </span>

                </li>
             </div>
          ))}
          </ol>
        </div>
      )}
      </div>
      </div>
      </div>
    </div>
        </div>
      </div>
      )}
      {submitted && (
        <CustomAlert 
          title="Success" 
          message="Your registration has been submitted successfully!"
        />
      )}

      {feedBacksubmit && (
        <CustomAlert 
          title="Success" 
          message="Your feedback has been submitted successfully!"
        />
      )}
      <InitModal isOpen={isOpen} onClose={handleClose} />
      <FeedbackModal  setFeedBacksubmit={setFeedBacksubmit}  isOpen={isFeedbackOpen} onClose={handleFeedbackClose} />
      <Footer />
    </>
  );
}


