import React, { useState, useRef,useEffect } from "react";
import userQueries from '../../queries/userQueries';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';

const Creatediscountcoupon = () => {
    const [susmsg, setSusmsg] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state
    const navigate = useNavigate();
    const [couponerror, setCouponerror] = useState(false);
    

    const { register, handleSubmit, getValues,formState: { errors } } = useForm();

    const discountcoupon = userQueries.useCreatediscountcouponMutation((response) => {
       
        if (response?.message === "Success") {
            setSusmsg(true);
            setLoading(false); // Stop loader
            alert("Coupon Created successfully")
            navigate('/listdiscountcoupon');
        }
        else if(response?.message === "Coupon code already exists")
        {
            setLoading(false);
            setCouponerror("Coupon code already exists");
        }
        
    });

    

    
    const handleFormSubmit = async (data) => {
        setSusmsg(false);
        setLoading(true);
        const coupondata = {
            amount: data.amount,
            offeredby: data.offeredby,
            coupon_code: data.coupon_code,
            usagelimit: data.usagelimit

        };
        try {
            const createdUser = await discountcoupon.mutateAsync(coupondata);
           
            
        } catch (error) {
            console.error('Error occurred:', error);
        }
    };

   
   
    return (
        <div className="container mx-auto px-4">
            <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
                Create Discount Coupon
            </h4>
            <form 
                onSubmit={handleSubmit(handleFormSubmit)} 
                className="max-w-lg mx-auto grid grid-cols-2 gap-4"
            >
                <div className="mb-5 col-span-2">
                    <label 
                        htmlFor="amount" 
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                       Coupon offered by
                    </label>
                    <input 
                        type="text" 
                        id="offeredby"
                        {...register("offeredby", { 
                            required: "Coupon offered by is required",
                           
                        })}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        placeholder="Enter coupon offered by"
                    />
                    {errors.offeredby && (
                        <p className="text-red-600 text-sm mt-1">
                            {errors.offeredby.message}
                        </p>
                    )}
                </div>
                {/* Coupon Code */}
                <div className="mb-5 col-span-2">
                    <label 
                        htmlFor="coupon_code" 
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Coupon Code
                    </label>
                    <input 
                        type="text" 
                        id="coupon_code"
                        {...register("coupon_code", { 
                            required: "Coupon code is required",
                            // minLength: {
                            //     value: 4,
                            //     message: "Coupon code must be at least 4 characters"
                            // }
                        })}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        placeholder="Enter coupon code"
                    />
                    {errors.coupon_code && (
                        <p className="text-red-600 text-sm mt-1">
                            {errors.coupon_code.message}
                        </p>
                    )}
                    {couponerror && <div className="text-red-600 text-s">{couponerror}</div>}
                </div>

                
                {/* Amount */}
               
                <div className="mb-5 col-span-2">
                    <label 
                        htmlFor="amount"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Discount Amount
                    </label>
                    <input
                        type="number"
                        id="amount"
                        {...register("amount", {
                        required: "Discount amount is required",
                        min: {
                            value: 0,
                            message: "Amount must be a positive number"
                        },
                        validate: {
                            integer: value => Number.isInteger(Number(value)) || "Please enter a whole number"
                        }
                        })}
                        onKeyDown={(e) => {
                        // Prevent decimal point
                        if (e.key === '.' || e.key === 'e' || e.key === 'E') {
                            e.preventDefault();
                        }
                        }}
                        onWheel={(e) => e.target.blur()} // Add this line
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                        placeholder="Enter discount amount"
                        step="1"
                    />
                    {errors.amount && (
                        <p className="text-red-600 text-sm mt-1">
                        {errors.amount.message}
                        </p>
                    )}
                </div>

                {/* Usage Limit */}
                <div className="mb-5 col-span-2">
                    <label
                        htmlFor="usagelimit"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Usage Limit
                    </label>
                    <input
                        type="number"
                        id="usagelimit"
                        {...register("usagelimit", {
                        required: "Usage limit is required"
                        })}
                        onWheel={(e) => e.target.blur()} // Add this line
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                        placeholder="Enter usage limit"
                    />
                    {errors.usagelimit && (
                        <p className="text-red-600 text-sm mt-1">
                        {errors.usagelimit.message}
                        </p>
                    )}
                </div>

                {/* Submit Buttons */}
                <div className="col-span-2 flex space-x-4">
                    <button 
                        type="button"
                        onClick={() => handleSubmit(handleFormSubmit)()}
                        className="flex-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                        Save 
                    </button>

                  
                </div>

                {/* Success Message */}
                {susmsg && (
                    <div className="col-span-2 text-green-600 text-lg text-center">
                        Discount coupon created successfully
                    </div>
                )}

                {/* Loader */}
                {loading && <Loader />}
            </form>
        </div>
    );
};

export default Creatediscountcoupon;
