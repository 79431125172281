import React, { useEffect, useState } from 'react';
import partnerQueries from '../queries/partnerQueries';

function FestivalPartner() {
  const [partners, setPartners] = useState([]);

  const size = 100;
  const page = 0;

  // Mutation to fetch partners
  const Partners = partnerQueries.usePartnerSiteListingMutation(
    async (response) => {
      setPartners(response?.data?.items || []);
    },
  );

  // Fetch partners on component mount
  useEffect(() => {
    Partners.mutateAsync({
      size: size,
      page: page,
    });
  }, []);

  return (
    <div>
      <div className="festival-partners-wrpr">
        <div className="container">
          <div className="row">
            <div className="col-md-12 p-0 text-center">
              <h2>
                <span className="speaker-pattern"></span>Supported by
                <span className="speaker-pattern"></span>
              </h2>
            </div>

            {/* Partner Logos */}
            <div className="col-md-12 diamond-logos">
              <div className="row">
                {partners.length > 0 ? (
                  partners.map((partner) => (
                    <div
                      key={partner.id}
                      className="col-md-2 text-center"
                    >
                      <div className="card">
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}/${partner?.image}`}
                          alt={partner.name || 'Partner Logo'} title={partner?.name}
                        
                        />
                        <div className="partner-type" title={partner?.name}>
                          {partner?.text} 
                        </div>
                      
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-md-12 text-center">
                    <p>No partners available.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FestivalPartner;
